/* This method is copied from buy-button popupService.ts.
 * If you do adjustments, also apply them in buy-button. */
import { getWindowScreen } from './screenWrapper';

export const CONTACT_FORM_TARGET_URL = 'https://contact-form.rio.cloud/contact';

export function getPopupWindowProps(
    screen: { availHeight: number; availWidth: number },
    windowId: string,
    popupName: string
) {
    const popUpWidth = 1080;
    const popUpHeight = 830;
    const { availHeight, availWidth } = screen;
    const left = availWidth / 2 - popUpWidth / 2;
    const top = availHeight / 2 - popUpHeight / 2;

    if (availWidth < popUpWidth) {
        return {
            sizeString: undefined,
            windowName: '_blank',
        };
    }

    return {
        sizeString: `width=${popUpWidth},height=${popUpHeight},left=${left},top=${top}`,
        windowName: `${popupName}-${windowId}`,
    };
}

export function openContactFormInPopup(windowId: string) {
    const { windowName, sizeString } = getPopupWindowProps(getWindowScreen(), windowId, 'contactForm');

    window.open(CONTACT_FORM_TARGET_URL, windowName, sizeString);
}
