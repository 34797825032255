import pick from 'lodash/pick';
import Checkbox from '@rio-cloud/rio-uikit/lib/es/Checkbox';
import { connect } from 'react-redux';
import { ReactElement } from 'react';
import { RootDispatch, RootState } from '../../../../configuration/setup/store';
import { ContractResourceName } from '../contracts/ContractResourceName';
import { FormattedMessage } from 'react-intl';
import { ContractTableRowRenewCell } from '../contracts/ContractTableRowRenewCell';
import { freeTrialTabActions, getSelectedFreeTrialActivations } from './redux/freeTrialTab.redux';
import { TermedLevel } from '../../common/termedLevel/types';
import { termedLevelRenewalActions } from '../../common/termedLevel/redux/termedLevelRenewal.redux';
import { TermedLevelId } from '../../common/termedLevel/redux/types';
import { ProductType } from '../../common/product/product';
import { ProductTypedFormattedMessage } from '../../common/product/ProductTypedFormattedMessage';
import { useIsFleetUser } from '../../common/permissions/hooks';

export const getFreeTrialColumns = (
    productType: ProductType
): Array<{ propertyName: keyof TermedLevel; label: ReactElement; width?: string }> => [
    {
        propertyName: 'resourceName',
        label: (
            <ProductTypedFormattedMessage
                productType={productType}
                userBasedId='marketplace.freetrial.user.table.header'
                assetBasedId='marketplace.freetrial.asset.table.header'
            />
        ),
    },
    { propertyName: 'renewsToLevelName', label: <FormattedMessage id={'marketplace.freeTrialTab.renewal'} /> },
];

interface Props {
    freeTrialActivation: TermedLevel;
    updateContract: (data: TermedLevelId & { renewsToLevel: string | undefined }) => void;
    toggleSelectedContract: (contractId: TermedLevelId) => void;
    isContractSelected: boolean;
}

export function FreeTrialTableRow(props: Props) {
    const { freeTrialActivation, updateContract, toggleSelectedContract, isContractSelected } = props;
    const fleetUser = useIsFleetUser();

    const onClickRenewalButton = (e: React.MouseEvent) => {
        e.stopPropagation();
        updateContract({
            productId: freeTrialActivation.productId,
            resourceId: freeTrialActivation.resourceId,
            renewsToLevel: freeTrialActivation.renewsToLevel,
        });
    };

    return (
        <tr
            onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                toggleSelectedContract(pick(freeTrialActivation, ['productId', 'resourceId']));
            }}
        >
            <td>
                {' '}
                <Checkbox checked={isContractSelected} disabled={fleetUser} />
            </td>
            <td>
                <ContractResourceName contract={freeTrialActivation} />
            </td>
            <td>
                <ContractTableRowRenewCell contract={freeTrialActivation} onClick={onClickRenewalButton} />
            </td>
        </tr>
    );
}

const mapStateToProps = (state: RootState, ownProps: { freeTrialActivation: TermedLevel }) => ({
    isContractSelected:
        getSelectedFreeTrialActivations(state).find(
            (contractId) =>
                ownProps.freeTrialActivation.productId === contractId.productId &&
                ownProps.freeTrialActivation.resourceId === contractId.resourceId
        ) !== undefined,
});

const mapDispatchToProps = (dispatch: RootDispatch) => ({
    updateContract: (data: TermedLevelId & { renewsToLevel: string | undefined }) => {
        const { renewsToLevel, ...contractId } = data;
        dispatch(
            termedLevelRenewalActions.selectForUpdate({
                contracts: [contractId],
                renewsToLevel,
                productId: data.productId,
            })
        );
    },
    toggleSelectedContract: (contractId: TermedLevelId) =>
        dispatch(freeTrialTabActions.toggleSelectedFreeTrial(contractId)),
});

export const FreeTrialTableRowContainer = connect(mapStateToProps, mapDispatchToProps)(FreeTrialTableRow);
