import { TagToAssetsMap } from './types';

function flatMapAndRemoveDuplicates(nestedArrayOfIds: Array<Array<string>>) {
    const arrayOfIds = nestedArrayOfIds.reduce((result, assetIds) => [...result, ...assetIds], []);
    return Array.from(new Set(arrayOfIds));
}

export const getAssetsByTagIds = (tagToAssetMap: TagToAssetsMap, tagIds: Array<string>) => {
    if (tagIds.length === 0) {
        return undefined;
    }

    const filteredAssetIds = Object.entries(tagToAssetMap)
        .filter(([tagId]) => tagIds.includes(tagId))
        .map(([, assetIds]) => assetIds);

    return flatMapAndRemoveDuplicates(filteredAssetIds);
};
