import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchedHistoryEvents, HistoryState } from './types';
import { RootState } from '../../../../../configuration/setup/store';

const initialState: HistoryState = {
    events: [],
};

export const { reducer: historyReducer, actions: historyActions } = createSlice({
    name: 'myHistory',
    initialState,
    reducers: {
        successfulFetchedHistory: (state: HistoryState, action: PayloadAction<FetchedHistoryEvents>) => {
            state.events = state.events.concat(action.payload.results);
            state.nextPageCursor = action.payload.nextPageCursor;
        },
        clearHistory: () => {
            return initialState;
        },
        clearHistoryEvents: (state: HistoryState) => {
            state.events = [];
        },
        setResourceSearch: (state: HistoryState, action: PayloadAction<string | undefined>) => {
            state.resourceNameSearch = action.payload;
        },
        setSelectedProductIds: (state: HistoryState, action: PayloadAction<Array<string> | undefined>) => {
            state.selectedProductIds = action.payload;
        },
        setFromDate: (state: HistoryState, action: PayloadAction<string | undefined>) => {
            state.fromDate = action.payload;
        },
        setToDate: (state: HistoryState, action: PayloadAction<string | undefined>) => {
            state.toDate = action.payload;
        },
    },
});

const getBase = (state: RootState): HistoryState => state.marketplace.myHistory;

export const getHistoryEvents = (state: RootState) => getBase(state).events;
export const getHistoryNextPageCursor = (state: RootState) => getBase(state).nextPageCursor;
export const getHistoryResourceSearch = (state: RootState) => getBase(state).resourceNameSearch || undefined;
export const getHistorySelectedProductIds = (state: RootState) => getBase(state).selectedProductIds;
export const getHistoryFromDate = (state: RootState) => getBase(state).fromDate;
export const getHistoryToDate = (state: RootState) => getBase(state).toDate;
