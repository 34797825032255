import { FormattedMessage } from 'react-intl';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { getHasError, getIsLoading } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import { connect } from 'react-redux';
import { fetchBookableResourcesThunk } from './resourceSelection.thunk';
import LoadingErrorStateWithReload from '../../common/error/LoadingErrorStateWithReload';
import { PreSelectedResourceSelectionListContainer } from './PreSelectedResourceSelectionListContainer';
import { ResourceSelectionListContainer } from './ResourceSelectionListContainer';
import { getIsCheckoutLocked } from '../redux/checkout.redux';
import { RootDispatch, RootState } from '../../../../configuration/setup/store';

interface Props {
    hasError: boolean;
    isLoading: boolean;
    refetchResources: () => void;
    isLockedSelected: boolean;
}

export const ResourceSelection = (props: Props) => {
    const { hasError, isLoading, refetchResources, isLockedSelected } = props;

    if (hasError) {
        return (
            <LoadingErrorStateWithReload
                headline={'marketplace.resourceSelection.common.error'}
                onReload={refetchResources}
            />
        );
    }

    if (isLoading) {
        return <Spinner text={<FormattedMessage id={'marketplace.loading'} />} isInverse={false} />;
    }

    return isLockedSelected ? <PreSelectedResourceSelectionListContainer /> : <ResourceSelectionListContainer />;
};

const mapStateToProps = (state: RootState) => ({
    hasError: getHasError(state, ApiCalls.RESOURCE_SELECTION),
    isLoading: getIsLoading(state, ApiCalls.CHECKOUT_PRODUCT_DETAILS),
    isLockedSelected: getIsCheckoutLocked(state),
});

const mapDispatchToProps = (dispatch: RootDispatch) => ({
    refetchResources: () => dispatch(fetchBookableResourcesThunk),
});

export const ResourceSelectionContainer = connect(mapStateToProps, mapDispatchToProps)(ResourceSelection);
