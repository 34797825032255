import { DatedInvoices, Invoice } from './redux/types';
import { isPresent } from '../../../../utils/typescriptUtil';

export function getInvoiceNumber(invoices: Array<DatedInvoices>, invoiceId: string) {
    const foundDatedInvoices = invoices.find((invoice) => invoice.invoices.map((it) => it.id).includes(invoiceId));
    const match = foundDatedInvoices?.invoices.find((invoice) => invoice.id === invoiceId);
    return match ? match.invoiceNumber : undefined;
}

export function containsInvoices(invoices: Array<DatedInvoices>) {
    return !!invoices.find((invoiceMonth) => invoiceMonth.invoices.length > 0);
}

export function getTotalByMonth(invoices: Array<Invoice>) {
    return invoices
        .map((item) => item.amount)
        .filter(isPresent)
        .reduce((accumulator, value) => accumulator + value, 0);
}
