import { getAemLanguageCode } from '../../lang/aemLanguageConfiguration';
import { getTypo3ProductInfo } from '../../config/typo3ProductNameMapping';
import { normalizeLocaleForKoreanUserOrHandThrough } from '../utils/koreaUtil';
import { reportErrorToSentry } from '../../../../configuration/setup/sentry';
import { Tenant } from '../utils/tenants';

const brazilTermsAndConditionsUrl =
    'https://americas.rio.cloud/fileadmin/Region/Americas/Website/Platform_legal_documents/11_PT_service_terms_and_conditions.pdf';

export const getTermsAndConditionsUrl = (productId: string, tenant: Tenant) => {
    if (tenant === Tenant.BRAZIL) {
        return brazilTermsAndConditionsUrl;
    }
    const productInfo = getTypo3ProductInfo(productId);
    const locale = getAemLanguageCode();
    const regionSpecifier = tenant === Tenant.SOUTH_KOREA ? '/Region/Korea' : '';
    if (productInfo) {
        let languageCode = normalizeLocaleForKoreanUserOrHandThrough(locale, tenant === Tenant.SOUTH_KOREA);
        // Ugly workaround for Timed Data Flow because the terms&conditions only exist in German and English
        if (productId.toLowerCase() === 'rio-sku00000056' && languageCode !== 'de-DE') {
            languageCode = 'en-GB';
        }
        if (productInfo.newTermsAndConditions) {
            return `https://rio.cloud/${languageCode.substring(0, 2)}/documents/${productInfo.newTermsAndConditions}`;
        }
        return (
            `https://rio.cloud/fileadmin${regionSpecifier}/Marketplace/${productInfo.partner}` +
            `/Products/${productInfo.product}` +
            `/Localized/${languageCode}/${productInfo.termsAndConditions || productInfo.product}_TOS.pdf`
        );
    }
    reportErrorToSentry(new Error(`Called terms and condition for product:${productId} tenant:${tenant}`));
    return undefined;
};
