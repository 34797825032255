import { getRequest } from '../requests';
import { getMarketplaceBackend } from '../../config/pathURL/config';
import { getParameters } from '../parameterService';
import { mapUsageSummary } from './usageMapper';
import { UsageSummary } from '../../customerCenter/usage/redux/types';
import { ApiUsageExportCodec } from './usageExport.types';
import { ApiUsageSummaryCodec } from './usageSummary.types';
import { convertContentToBlob, decodeJson, jsonOrReject, onRejected } from '../api';

export const XLSX_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export function fetchUsageExport(params: { year: number; month: number }): Promise<Blob> {
    const encodedParameters = getParameters(params);
    return fetch(`${getMarketplaceBackend()}/usages/export${encodedParameters}`, getRequest())
        .then(jsonOrReject)
        .then(decodeJson(ApiUsageExportCodec))
        .then((res) => convertContentToBlob(res.content, XLSX_TYPE))
        .catch(onRejected());
}

export function fetchUsageSummary(params: { year: number; month: number }): Promise<UsageSummary> {
    const encodedParameters = getParameters(params);
    return fetch(`${getMarketplaceBackend()}/usages/summary${encodedParameters}`, getRequest())
        .then(jsonOrReject)
        .then(decodeJson(ApiUsageSummaryCodec))
        .then(mapUsageSummary())
        .catch(onRejected([401, 403]));
}
