import { FormattedMessage, useIntl } from 'react-intl';
import { formatPrice } from '../utils/currencyUtil';
import { ProductType } from '../product/product';
import { ProductTypedFormattedMessage } from '../product/ProductTypedFormattedMessage';

interface Props {
    price: number;
    currency: string | undefined;
    productType: ProductType | undefined;
}

export function PriceInfoDaily(props: Props) {
    const { price, currency, productType } = props;
    const intl = useIntl();
    if (price === 0) {
        return <FormattedMessage id={'marketplace.free'} />;
    }

    return (
        <>
            <span>{formatPrice(intl, price, currency)}</span>{' '}
            <ProductTypedFormattedMessage
                productType={productType}
                userBasedId='marketplace.productDetails.price.user.rate'
                assetBasedId='marketplace.productDetails.price.asset.rate'
            />
        </>
    );
}
