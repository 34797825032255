import { dataLayerPush } from './googleTagManagerUtils';
import { reportErrorToSentry } from '../../../../configuration/setup/sentry';

export function safeDataLayerPush(gtmObjectFactory: () => unknown) {
    try {
        dataLayerPush(gtmObjectFactory());
    } catch (error) {
        reportErrorToSentry(error);
    }
}
