import { Dispatch } from 'redux';
import { apiCallAction } from '../api/redux/api.redux';
import { ApiCalls } from '../api/redux/types';
import { freeTrialActions } from './redux/freeTrial.redux';
import { fetchFreeTrialStatus } from '../api/freeTrial/freeTrialCalls';

export const freeTrialStatusThunk = (dispatch: Dispatch): Promise<void> => {
    dispatch(apiCallAction(ApiCalls.FREE_TRIAL_GET, true, false));

    return fetchFreeTrialStatus()
        .then((freeTrial) => {
            dispatch(freeTrialActions.setFreeTrialStatus(freeTrial.status));
            dispatch(freeTrialActions.setIsFreeTrialEndDate(freeTrial.endsAt?.toISOString()));
            dispatch(freeTrialActions.setFreeTrialProducts(freeTrial.products));
            dispatch(apiCallAction(ApiCalls.FREE_TRIAL_GET, false, false));
        })
        .catch(() => {
            dispatch(apiCallAction(ApiCalls.FREE_TRIAL_GET, false, true));
        });
};
