import { ProductRatePlan, ServiceOverviewItem } from './types';
import { OverviewResource } from './redux/types';
import DataTab from '@rio-cloud/rio-uikit/lib/es/DataTab';
import DataTabs from '@rio-cloud/rio-uikit/lib/es/DataTabs';
import { hasTwoOrMoreLevels, isResourceActiveForLevel } from './services/serviceOverviewLevelService';
import { ServiceResourceListContainer } from './ServiceResourceListContainer';
import { makeGetServiceResourcesForSku } from './services/serviceOverviewResourceStateService';
import { connect } from 'react-redux';
import { ServiceLevelTabTitle } from './components/ServiceLevelTabTitle';
import { RootState } from '../../../../configuration/setup/store';
import { isFreeTrialLevel } from '../../common/termedLevel/freeTrialLevel';
import classnames from 'classnames';
import { ProductState } from '../../checkout/resourceSelection/redux/types';

interface Props {
    service: ServiceOverviewItem;
    resources: Array<OverviewResource>;
    handleSelectAll: (resources: Array<OverviewResource>, shouldBeSelected: boolean) => void;
    fetchContent: () => void;
    handleSelectResource: (resource: OverviewResource) => void;
    selectTab: (tab: string) => void;
    tabs: Array<Array<ProductRatePlan>>;
    tab: string;
    selectedResources: Array<OverviewResource>;
}

function ServiceBodyContent(props: Props) {
    const {
        service,
        resources,
        selectedResources,
        fetchContent,
        handleSelectAll,
        handleSelectResource,
        tabs,
        selectTab,
        tab,
    } = props;

    // don't render until resources are fetched
    if (resources.length === 0) {
        return <></>;
    }

    if (!hasTwoOrMoreLevels(service)) {
        const ratePlanLevel = service?.productRatePlans?.find(({ level }) => level !== undefined)?.level;

        return (
            <div className={'padding-15'}>
                <ServiceResourceListContainer
                    service={service}
                    resources={resources}
                    selectResource={handleSelectResource}
                    refetchContent={fetchContent}
                    selectedResources={selectedResources}
                    selectAllResources={handleSelectAll}
                    levels={ratePlanLevel ? [ratePlanLevel] : undefined}
                />
            </div>
        );
    }

    const resourceActivationMatchesLevelAndNonInactiveState = (resource: OverviewResource, ratePlan: ProductRatePlan) =>
        (resource.originLevel === ratePlan.level || resource.targetLevel === ratePlan.level) &&
        resource.productState !== ProductState.INACTIVE;

    const containsAnyNonInactiveActivation = (ratePlan: ProductRatePlan) =>
        resources.filter((resource) => resourceActivationMatchesLevelAndNonInactiveState(resource, ratePlan)).length ===
        0;

    const filterOutLegacyWithNonInactiveActivations = (ratePlans: Array<ProductRatePlan>) =>
        ratePlans.filter((ratePlan) => !(ratePlan.legacy && containsAnyNonInactiveActivation(ratePlan)));

    const activeTabs = tabs
        .map((ratePlans) => filterOutLegacyWithNonInactiveActivations(ratePlans))
        .filter((ratePlans) => ratePlans.length > 0);

    if (activeTabs.length === 1) {
        return (
            <div className={'padding-15'}>
                <ServiceResourceListContainer
                    service={service}
                    resources={resources}
                    selectResource={handleSelectResource}
                    refetchContent={fetchContent}
                    selectedResources={selectedResources}
                    selectAllResources={handleSelectAll}
                    levels={activeTabs[0].map((ratePlans) => ratePlans.level)}
                />
            </div>
        );
    }
    return (
        <div className={'ServiceOverviewItemBodyResource-resourceList'}>
            <DataTabs
                activeKey={tab}
                onSelectTab={selectTab}
                bordered={false}
                tabsWrapperClassName='bg-lightest'
                arrowClassName='bg-white border-color-light margin-bottom--1'
                tabClassName='bg-lightest'
                tabHoverClassName='bg-white'
                tabActiveClassName='bg-white'
                tabContentClassName='rounded-bottom bg-lightest'
            >
                {activeTabs.map((ratePlans, index) => {
                    const tabKey = ratePlans.filter((ratePlan) => !isFreeTrialLevel(ratePlan.level))[0].level!;
                    const resourcesForLevel = resources.filter((resource) =>
                        ratePlans.some((ratePlan) => isResourceActiveForLevel(ratePlan.level as string)(resource))
                    );
                    const dataTabClassName = classnames(
                        index === 0 && 'border-left-none',
                        index === activeTabs.length - 1 && 'border-right-none'
                    );

                    return (
                        <DataTab
                            tabKey={tabKey}
                            key={tabKey}
                            className={dataTabClassName}
                            title={
                                <ServiceLevelTabTitle
                                    ratePlan={ratePlans[0]}
                                    resources={resourcesForLevel}
                                    isActiveTab={tab === tabKey}
                                />
                            }
                        >
                            <ServiceResourceListContainer
                                service={service}
                                resources={resourcesForLevel}
                                selectResource={handleSelectResource}
                                refetchContent={fetchContent}
                                selectedResources={selectedResources}
                                selectAllResources={handleSelectAll}
                                levels={ratePlans.map((ratePlan) => ratePlan.level)}
                            />
                        </DataTab>
                    );
                })}
            </DataTabs>
        </div>
    );
}

function makeMapStateToProps() {
    const getServiceResourcesForSku = makeGetServiceResourcesForSku();

    return (state: RootState, props: { service: ServiceOverviewItem }) => ({
        resources: getServiceResourcesForSku(state, props.service.sku) ?? [],
    });
}

export const ServiceBodyContentContainer = connect(makeMapStateToProps)(ServiceBodyContent);
