import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FailedPayment, PaymentsState } from './types';
import { RootState } from '../../../../configuration/setup/store';

const initialState: PaymentsState = {
    failedPayments: undefined,
    paymentSecret: undefined,
};

export const { reducer: paymentsReducer, actions: paymentsActions } = createSlice({
    name: 'payments',
    initialState,
    reducers: {
        setFailedPayments: (state: PaymentsState, action: PayloadAction<Array<FailedPayment>>) => {
            state.failedPayments = action.payload;
        },
        setPaymentSecret: (state: PaymentsState, action: PayloadAction<string>) => {
            state.paymentSecret = action.payload;
        },
    },
});

const getBase = (state: RootState): PaymentsState => state.marketplace.payment;

export const getFailedPayments = (state: RootState) => getBase(state).failedPayments;
export const getPaymentSecret = (state: RootState) => getBase(state).paymentSecret;
