import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { getTermsAndConditionsUrl } from './getTermsAndConditionsUrl';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import { getTenant, Tenant } from '../utils/tenants';
import { connect } from 'react-redux';
import { RootState } from '../../../../configuration/setup/store';

interface Props {
    tenant: Tenant;
    serviceSku: string;
    level?: string;
    children: React.ReactNode;
}

const TermsAndConditions = ({ serviceSku, level, children, tenant }: Props) => {
    const productIdentifier = level ? `${serviceSku}-${level}` : serviceSku;
    const [show, setShow] = useState(false);
    const intl = useIntl();

    return (
        <>
            <Dialog
                show={show}
                title={<FormattedMessage id={'marketplace.myServices.termsAndConditions.dialogTitle'} />}
                body={
                    <iframe
                        className='position-absolute top-0 left-0 width-100pct height-100pct'
                        src={getTermsAndConditionsUrl(productIdentifier, tenant)}
                        title={intl.formatMessage({ id: 'marketplace.myServices.termsAndConditions.dialogTitle' })}
                    />
                }
                onHide={() => setShow(false)}
                footer={null}
                showCloseButton={true}
                bsSize={Dialog.SIZE_FULL_SCREEN}
            />
            <span
                onClick={(event) => {
                    event.preventDefault();
                    setShow(true);
                }}
            >
                {children}
            </span>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    tenant: getTenant(state),
});

export const TermsAndConditionsContainer = connect(mapStateToProps)(TermsAndConditions);
