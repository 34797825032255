import Multiselect from '@rio-cloud/rio-uikit/lib/es/Multiselect';
import { ResourceType } from './redux/types';
import { useDispatch, useSelector } from 'react-redux';
import { getFilteredResourceTypes, usageActions } from './redux/usage.redux';
import { ReactElement, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

const options: Array<{ id: ResourceType; label: ReactElement; icon: ReactElement }> = [
    {
        id: 'asset',
        label: <FormattedMessage id='marketplace.usage.summary.asset' />,
        icon: <span className='rioglyph rioglyph-truck' />,
    },
    {
        id: 'user',
        label: <FormattedMessage id='marketplace.usage.summary.user' />,
        icon: <span className='rioglyph rioglyph-user' />,
    },
];

export const UsageResourceTypeSelectorContainer = () => {
    const selectedResourceTypes = useSelector(getFilteredResourceTypes);
    const dispatch = useDispatch();

    const onChange = useMemo(
        () => (selectedItems: Array<string>) => {
            dispatch(usageActions.setFilteredResourceTypes(selectedItems as Array<ResourceType>));
        },
        [dispatch]
    );
    return (
        <Multiselect
            options={options}
            showSelectedItemIcon
            showUnselectedItemIcons
            onChange={onChange}
            value={[...selectedResourceTypes]}
        />
    );
};
