import {
    fetchTermedActivations,
    updateTermedActivations,
    UpdateTermedLevelData,
} from '../../api/contracts/contractCalls';
import { apiCallAction } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import { getDisplayMessages } from '../../../../configuration';
import { sendError } from '../../../../configuration/lang/services';
import groupBy from 'lodash/groupBy';
import { RootDispatch, RootState } from '../../../../configuration/setup/store';
import {
    getProductIdForUpdateOrThrow,
    getRenewsToLevelForUpdate,
    termedLevelRenewalActions,
} from './redux/termedLevelRenewal.redux';
import { getTermedLevelsForUpdate } from './dialog/dialogService';

export function fetchContractsThunk(reportLoading = true) {
    return (dispatch: RootDispatch) => {
        if (reportLoading) {
            dispatch(apiCallAction(ApiCalls.TERMED_LEVEL_GET, true));
        }
        return fetchTermedActivations()
            .then((contracts) => {
                dispatch(termedLevelRenewalActions.setTermedLevels(contracts));
                dispatch(apiCallAction(ApiCalls.TERMED_LEVEL_GET, false, false));
            })
            .catch((error) => {
                dispatch(apiCallAction(ApiCalls.TERMED_LEVEL_GET, false, true));
                sendError(error);
            });
    };
}

export const cancelRenewalsThunk = (dispatch: RootDispatch, getState: () => RootState): Promise<void> => {
    const productId = getProductIdForUpdateOrThrow(getState());
    const { notUpdatable, updatable } = getTermedLevelsForUpdate(getState());
    const assetIds = [...updatable, ...notUpdatable].map((contract) => contract.resourceId);

    return dispatch(updateContractThunk({ productId, resourceIds: assetIds, renewsToLevel: undefined }));
};

export const updateSelectedContractsThunk = (dispatch: RootDispatch, getState: () => RootState): Promise<void> => {
    const productId = getProductIdForUpdateOrThrow(getState());
    const resourceIds = getTermedLevelsForUpdate(getState()).updatable.map((contract) => contract.resourceId);
    const renewsToLevel = getRenewsToLevelForUpdate(getState());

    return dispatch(updateContractThunk({ productId, resourceIds: resourceIds, renewsToLevel }));
};

function updateContractThunk(updateContractData: UpdateTermedLevelData) {
    return (dispatch: RootDispatch, getState: () => RootState): Promise<void> => {
        const languageData = getDisplayMessages(getState());
        if (updateContractData.resourceIds.length === 0) {
            Notification.error(languageData['marketplace.contracts.error']);
            return Promise.resolve();
        }

        dispatch(apiCallAction(ApiCalls.CONTRACT_UPDATE, true));
        return updateTermedActivations(updateContractData)
            .then(() => {
                dispatch(apiCallAction(ApiCalls.CONTRACT_UPDATE, false, false));
                Notification.success(languageData['marketplace.contracts.success']);
                dispatch(termedLevelRenewalActions.setIsRenewDialogOpen(false));
                dispatch(termedLevelRenewalActions.clearUpdateState());
            })
            .then(() => dispatch(fetchContractsThunk(false)))
            .catch((error) => {
                Notification.error(languageData['marketplace.contracts.error']);
                dispatch(apiCallAction(ApiCalls.CONTRACT_UPDATE, false, true));
                sendError(error);
            });
    };
}

export const autoRenewToSameLevelThunk = (dispatch: RootDispatch, getState: () => RootState): Promise<void> => {
    const updatableContracts = getTermedLevelsForUpdate(getState()).updatable;
    const languageData = getDisplayMessages(getState());

    if (updatableContracts.length === 0) {
        Notification.error(languageData['marketplace.contracts.error']);
        sendError('auto renewal called without updatable contracts selected');
        return Promise.resolve();
    }

    dispatch(apiCallAction(ApiCalls.CONTRACT_UPDATE, true));
    const productId = updatableContracts[0].productId;
    const contractsByLevel = groupBy(updatableContracts, 'level');

    return Promise.all(
        Object.entries(contractsByLevel).map(([level, contractsForLevel]) => {
            return updateTermedActivations({
                productId,
                renewsToLevel: level,
                resourceIds: contractsForLevel.map((it) => it.resourceId),
            });
        })
    )
        .then(() => {
            dispatch(apiCallAction(ApiCalls.CONTRACT_UPDATE, false, false));
            Notification.success(languageData['marketplace.contracts.success']);
            dispatch(termedLevelRenewalActions.setIsRenewDialogOpen(false));
            dispatch(termedLevelRenewalActions.clearUpdateState());
        })
        .catch((error) => {
            Notification.error(languageData['marketplace.contracts.error']);
            dispatch(apiCallAction(ApiCalls.CONTRACT_UPDATE, false, true));
            sendError(error);
        })
        .then(() => dispatch(fetchContractsThunk(false)));
};
