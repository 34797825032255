import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
    checkoutActions,
    getProductLevelsForVariant,
    getSelectedProductLevel,
    getFullyDiscountedLevel,
} from '../redux/checkout.redux';
import { ProductLevel } from '../redux/types';
import RadioButton from '@rio-cloud/rio-uikit/lib/es/RadioButton';
import { sendError } from '../../../../configuration/lang/services';
import { VariantSelectionLabels } from './VariantSelectionLabels';
import { RootState } from '../../../../configuration/setup/store';
import OnboardingTip from '@rio-cloud/rio-uikit/lib/es/OnboardingTip';
import { useIntl } from 'react-intl';
import { useState } from 'react';

interface Props {
    levelsOfVariant?: Array<ProductLevel>;
    setSelectedProductLevel: (level: string) => void;
    selectedProductLevel?: string;
    fullyDiscountedLevel?: string;
}

function getWidthClassName(numberOfVariant: number): string {
    switch (numberOfVariant) {
        case 1:
            return 'width-100pct';
        case 2:
            return 'width-50pct';
        case 3:
            return 'width-33pct';
        case 4:
            return 'width-25pct';
        case 5:
            return 'width-20pct';
        default:
            sendError(`More than 5 variants present in checkout, please take care: ${numberOfVariant}`);
            return 'width-20pct';
    }
}

export const VariantSelection = ({
    levelsOfVariant,
    setSelectedProductLevel,
    selectedProductLevel,
    fullyDiscountedLevel,
}: Props) => {
    const intl = useIntl();
    const [hideOnboarding, setHideOnboarding] = useState(false);
    if (!levelsOfVariant) {
        return null;
    }
    const nonLegacyVariantLevels = levelsOfVariant.filter((level) => !level.legacy);
    const radioButtonsClassName =
        'display-flex justify-content-between column-gap-25 bg-white ' +
        'rounded-circle padding-3 border-style-solid border-width-1 border-color-light';
    const widthClassName = getWidthClassName(nonLegacyVariantLevels.length);

    return (
        /* eslint-disable-next-line max-len */
        <div className='display-flex justify-content-center padding-top-10 margin-bottom--15 z-index-1 position-relative'>
            <form>
                <VariantSelectionLabels levelsOfVariant={nonLegacyVariantLevels} widthClassName={widthClassName} />
                <div className={radioButtonsClassName}>
                    {nonLegacyVariantLevels.map((productLevel) => (
                        <OnboardingTip
                            key={productLevel.level}
                            show={fullyDiscountedLevel === productLevel.level && !hideOnboarding}
                            placement='bottom'
                            width={350}
                            textAlignment='center'
                            content={intl.formatMessage({ id: 'marketplace.checkout.levelDiscountedForOneResource' })}
                            onHide={() => setHideOnboarding(true)}
                        >
                            <span className={widthClassName}>
                                <RadioButton
                                    icon={
                                        productLevel.level === selectedProductLevel
                                            ? 'rioglyph-start'
                                            : 'rioglyph-stopover'
                                    }
                                    checked={productLevel.level === selectedProductLevel}
                                    iconSize={30}
                                    onChange={() => {
                                        if (selectedProductLevel !== productLevel.level) {
                                            setSelectedProductLevel(productLevel.level);
                                        }
                                    }}
                                />
                            </span>
                        </OnboardingTip>
                    ))}
                </div>
            </form>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    levelsOfVariant: getProductLevelsForVariant(state),
    selectedProductLevel: getSelectedProductLevel(state),
    fullyDiscountedLevel: getFullyDiscountedLevel(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setSelectedProductLevel: (level: string) => dispatch(checkoutActions.setSelectedProductLevel(level)),
});

export const VariantSelectionContainer = connect(mapStateToProps, mapDispatchToProps)(VariantSelection);
