import { Navigate, Route, Routes } from 'react-router';
import {
    MARKETPLACE_CHECKOUT_PATH,
    MARKETPLACE_CUSTOMER_CENTER_PATH,
    MARKETPLACE_HOME_PATH,
    MARKETPLACE_PAYMENTS_AUTHENTICATION,
    MARKETPLACE_PRODUCT_DETAILS_PATH,
} from './common/routes';
import ReauthenticationPageStripeContainer from './paymentReauthentication/ReauthenticationPageStripeContainer';
import { CheckoutContainer } from './checkout/Checkout';
import { ProductsOverviewRedirect } from './openMarketplaceRedirects/ProductsOverviewRedirect';
import { ProductDetailsRedirect } from './openMarketplaceRedirects/ProductDetailsRedirect';
import { CustomerCenterContainer } from './customerCenter/CustomerCenter';
import { MaintenanceView } from './common/MaintenanceView';
import { connect } from 'react-redux';
import { MAINTENANCE } from '../../configuration/featureToggle/toggles';
import { getFeatureEnabled } from '../../configuration/featureToggle/featureToggleService';
import { config } from '../../config';
import { RootState } from '../../configuration/setup/store';

interface Props {
    maintenanceMode: boolean;
}

const toOpenMarketplace = config.redirectToOpenMarketplace;

// Define routing to sub modules
const MarketplaceRoutes = (props: Props) => {
    const { maintenanceMode } = props;
    return (
        <Routes>
            <Route path={`${MARKETPLACE_HOME_PATH}`} element={<ProductsOverviewRedirect />} />
            <Route
                path={`${MARKETPLACE_CUSTOMER_CENTER_PATH}/*`}
                element={maintenanceMode ? <MaintenanceView /> : <CustomerCenterContainer />}
            />
            <Route
                path={`${MARKETPLACE_PAYMENTS_AUTHENTICATION}`}
                element={maintenanceMode ? <MaintenanceView /> : <ReauthenticationPageStripeContainer />}
            />
            <Route path={`${MARKETPLACE_PRODUCT_DETAILS_PATH}/*`} element={<ProductDetailsRedirect />} />
            <Route
                path={`${MARKETPLACE_CHECKOUT_PATH}/*`}
                element={maintenanceMode ? <MaintenanceView /> : <CheckoutContainer />}
            />
            <Route
                path='*'
                element={
                    toOpenMarketplace ? (
                        <ProductsOverviewRedirect />
                    ) : (
                        <Navigate to={`${MARKETPLACE_CUSTOMER_CENTER_PATH}`} />
                    )
                }
            />
        </Routes>
    );
};
const mapStateToProps = (state: RootState) => ({
    maintenanceMode: getFeatureEnabled(state, MAINTENANCE),
});
export const MarketplaceContent = connect(mapStateToProps)(MarketplaceRoutes);
