import { FormattedMessage } from 'react-intl';
import { useIsFleetUser } from './hooks';

export const LimitedPermissionsInfoBanner = () => {
    const fleetUser = useIsFleetUser();

    return fleetUser ? (
        <div className='alert alert-dismissible alert-warning margin-y-15'>
            <div className='display-flex'>
                <span className='text-color-warning text-size-h4 margin-right-10 rioglyph rioglyph rioglyph-exclamation-sign'></span>
                <div>
                    <strong className='text-size-16'>
                        <FormattedMessage id={'marketplace.permissions.infoBanner.title'} />
                    </strong>
                    <div className='margin-y-5'>
                        <FormattedMessage id={'marketplace.permissions.infoBanner.text'} />
                    </div>
                </div>
            </div>
        </div>
    ) : null;
};
