import { getPopupWindowProps } from '../../../common/popupService';
import { getWindowScreen } from '../../../common/screenWrapper';
import { safeDataLayerPush } from '../../../common/utils/googleTagManagerWrapper';
import { getGtmPromotionClickEvent, GtmPromotion } from './gtmPromotion';
import { MARKETPLACE_CHECKOUT_PATH } from '../../../common/routes';

const PRODUCT_ID_PARAM = 'productId';
// To avoid a URL that is too large
const MAX_ALLOWED_CHECKOUT_RESOURCES = 200;

const getCheckoutUrl = (productId: string): string =>
    `/${MARKETPLACE_CHECKOUT_PATH}/1?${PRODUCT_ID_PARAM}=${productId}`;

export const openCheckoutPopup = (
    productId: string,
    promotion: GtmPromotion,
    level?: string,
    selectedResourceIds?: Array<string>
) => {
    const levelExtra = level ? `&level=${level}` : '';
    const resourcesExtra =
        selectedResourceIds &&
        selectedResourceIds.length > 0 &&
        selectedResourceIds.length <= MAX_ALLOWED_CHECKOUT_RESOURCES
            ? `&assetIds=${selectedResourceIds.join(',')}`
            : '';
    const checkoutUrl = `${getCheckoutUrl(productId)}${levelExtra}${resourcesExtra}`;

    const callback = (event: React.MouseEvent) => {
        if (typeof event.preventDefault === 'function') {
            event.preventDefault();
        }
        const windowId = productId + (level ?? '') + (selectedResourceIds ? `${selectedResourceIds}` : '');
        const { windowName, sizeString } = getPopupWindowProps(getWindowScreen(), windowId, 'checkoutPopup');
        safeDataLayerPush(() => getGtmPromotionClickEvent(promotion));
        window.open(checkoutUrl + (sizeString ? '&popup=true' : ''), windowName, sizeString);
    };

    return { callback, checkoutUrl };
};
