import 'iframe-resizer/js/iframeResizer.contentWindow'; // Required for resizing when included as iframe
import handAlarmClock from './images/hand_alarm_clock.webp';
import { getCustomerCenterPath } from '../common/routes';
import { FormattedMessage } from 'react-intl';
import { CustomerCenterTab } from '../customerCenter/types';

export function FreeTrialTeaser() {
    const targetPath = getCustomerCenterPath(CustomerCenterTab.FREE_TRIAL);

    return (
        <div className='bg-primary padding-x-20 padding-top-20 position-relative overflow-hidden'>
            <div className='width-90pct rounded-circle bg-white aspect-ratio-1 position-absolute bottom-0 left-50pct translate-x-50pct opacity-50 blur-50'></div>
            <div className='text-size-20 text-center margin-bottom-10'>
                <FormattedMessage id={'marketplace.freeTrial.teaser.title'} />
            </div>
            <div className='display-flex justify-content-center position-absolute left-0 right-0 bottom-15 z-index-1'>
                <a className='btn btn-secondary' onClick={() => window.open(targetPath, '_top')}>
                    <FormattedMessage id={'marketplace.freeTrial.teaser.button'} />
                </a>
            </div>
            <img className='img-responsive margin-x-auto position-relative' src={handAlarmClock} alt='alarm clock' />
        </div>
    );
}
