import { ResourceSelectionItemContainer } from './ResourceSelectionItem';
import { Dispatch } from 'redux';
import { resourceSelectionActions, getSelectedResourceIds } from './redux/resourceSelection.redux';
import { connect } from 'react-redux';
import TagList from '@rio-cloud/rio-uikit/lib/es/TagList';
import { RESOURCE_SELECTION_TAGS_PER_ROW } from './ResourceSelectionListContainer';
import { ResourceGroupHeader } from './ResourceGroupHeader';
import { getSelectedProduct, getSelectedProductLevel } from '../redux/checkout.redux';
import { BookableResource } from './redux/types';
import { isMultiLevelProduct, MultiLevelProduct } from '../redux/types';
import { useIntl } from 'react-intl';
import { getProductLabel } from '../../common/utils/productLabelUtil';
import { RootState } from '../../../../configuration/setup/store';

interface Props {
    resources: Array<BookableResource>;
    handleSelection: (resourceId: string) => void;
    activeLevel: string | undefined;
    selectedProductLevel: string | undefined;
    selectedResourceIds: Array<string>;
    selectedProduct: MultiLevelProduct | undefined;
    viewOnly: boolean;
}

const BookableResourceListLevelSection = (props: Props) => {
    const {
        resources,
        handleSelection,
        activeLevel,
        selectedProductLevel,
        selectedResourceIds,
        viewOnly,
        selectedProduct,
    } = props;
    const resourcesInCurrentLevel = resources.filter((resource) => resource.targetLevel === activeLevel);
    if (resourcesInCurrentLevel.length === 0 || !selectedProduct || !selectedProductLevel) {
        return null;
    }

    const activeLevelName = selectedProduct.levels.find((it) => it.level === activeLevel)?.name;
    const selectedLevel = selectedProduct.levels.find((it) => it.level === selectedProductLevel);
    const selectedLevelName = selectedLevel ? getProductLabel(selectedLevel, useIntl()) : '';
    if (selectedLevelName === undefined) {
        throw Error(`Could not find ${selectedLevelName} in ${JSON.stringify(selectedProduct)}`);
    }

    return (
        <div>
            <ResourceGroupHeader from={activeLevel ? activeLevelName : undefined} to={selectedLevelName} />
            <TagList className={'margin-5'} tagsPerRow={RESOURCE_SELECTION_TAGS_PER_ROW}>
                {resourcesInCurrentLevel.map((resource) => {
                    return (
                        <ResourceSelectionItemContainer
                            resource={resource}
                            onSelect={viewOnly ? undefined : (selectedItem) => handleSelection(selectedItem)}
                            key={resource.id}
                            isSelected={selectedResourceIds.includes(resource.id)}
                            noBookableTooltip={viewOnly}
                        />
                    );
                })}
            </TagList>
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    const selectedProduct = getSelectedProduct(state);
    return {
        selectedProductLevel: getSelectedProductLevel(state),
        selectedProduct: isMultiLevelProduct(selectedProduct) ? selectedProduct : undefined,
        selectedResourceIds: getSelectedResourceIds(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    handleSelection: (resourceId: string) => dispatch(resourceSelectionActions.selectResource(resourceId)),
});

export const BookableResourceListLevelSectionContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(BookableResourceListLevelSection);
