import { ProductRatePlan, ServiceOverviewItem } from '../serviceOverview/types';
import { HeaderProduct, ProductBookable } from './redux/types';
import sortBy from 'lodash/fp/sortBy';
import pipe from 'lodash/fp/pipe';
import groupBy from 'lodash/fp/groupBy';
import values from 'lodash/fp/values';
import map from 'lodash/fp/map';
import flatten from 'lodash/flatten';
import { isFreeTrialLevel } from '../../common/termedLevel/freeTrialLevel';
import { isRatePlanBookable } from '../../common/product/productService';

function evaluateLegacy(legacy: boolean | undefined) {
    return legacy ? ProductBookable.LEGACY : ProductBookable.BOOKABLE;
}

function mergeLegacyForSeveralLevels(productBookable: ProductBookable, legacy: boolean | undefined) {
    if (productBookable === ProductBookable.LEGACY) {
        return evaluateLegacy(legacy);
    }
    return productBookable;
}

export function sortHeaderProducts(headerProducts: Array<HeaderProduct>): Array<HeaderProduct> {
    return pipe(
        groupBy((product: HeaderProduct) => product.productId),
        sortBy((items) => items[0].productName),
        values,
        map((products: HeaderProduct[]) => sortByLevel(products)),
        flatten
    )(headerProducts);
}

function sortByLevel(products: Array<HeaderProduct>): Array<HeaderProduct> {
    return products.sort((a, b) =>
        (a.levels.length > 0 ? a.levels[0] : '') > (b.levels.length > 0 ? b.levels[0] : '') ? 1 : -1
    );
}

export function mapProductResponse(response: Array<ServiceOverviewItem>): Array<HeaderProduct> {
    let headerProducts: Array<HeaderProduct> = [];
    response.forEach((item) => {
        if (item.productRatePlans && item.productRatePlans.length > 1) {
            item.productRatePlans.forEach((ratePlan) => {
                const productOfVariant = headerProducts.find(({ productName }) => productName === ratePlan.variantName);
                const level = ratePlan.level;
                if (productOfVariant && level) {
                    const modifiedProduct = mergeHeaderProductsWithSameVariant(productOfVariant, item, ratePlan, level);

                    headerProducts = headerProducts.map((product) =>
                        product.productName === productOfVariant.productName ? modifiedProduct : product
                    );
                    return;
                }

                const hasContractPeriod = ratePlan.contractPeriod !== undefined;
                const levels = level ? [level] : [];
                headerProducts.push({
                    productId: item.sku,
                    productName: ratePlan.variantName || ratePlan.name,
                    levels: levels,
                    bookable: evaluateLegacy(item.legacy || ratePlan.legacy),
                    canBeCancelled: !hasContractPeriod,
                    contractPeriod: ratePlan.contractPeriod,
                    levelsWithContractPeriod:
                        ratePlan.contractPeriod !== undefined || isFreeTrialLevel(level) ? levels : [],
                    bookableLevels: isRatePlanBookable(ratePlan) ? levels : [],
                });
            });
        } else {
            const ratePlanLevel = item?.productRatePlans?.find(({ level }) => level !== undefined)?.level;
            headerProducts.push({
                productId: item.sku,
                levels: ratePlanLevel ? [ratePlanLevel] : [],
                productName: item.productName ?? item.sku,
                bookable: item.hasRatePlan ? evaluateLegacy(item.legacy) : ProductBookable.DEFAULT_ACTIVATED,
                canBeCancelled: Boolean(item.hasRatePlan),
                levelsWithContractPeriod: [],
                bookableLevels: ratePlanLevel ? [ratePlanLevel] : [],
            });
        }
    });
    return sortHeaderProducts(headerProducts);
}

function mergeHeaderProductsWithSameVariant(
    productOfVariant: HeaderProduct,
    it: ServiceOverviewItem,
    ratePlan: ProductRatePlan,
    level: string
): HeaderProduct {
    return {
        ...productOfVariant,
        bookable: mergeLegacyForSeveralLevels(productOfVariant.bookable, it.legacy || ratePlan.legacy),
        levels: [...productOfVariant.levels, level],
        levelsWithContractPeriod:
            ratePlan.contractPeriod !== undefined || isFreeTrialLevel(level)
                ? [...productOfVariant.levelsWithContractPeriod, level]
                : productOfVariant.levelsWithContractPeriod,
        contractPeriod: undefined,
        bookableLevels: [...productOfVariant.bookableLevels, ...(isRatePlanBookable(ratePlan) ? [level] : [])],
    };
}
