import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { PaymentInformation } from './PaymentInformation';
import { StripePaymentFormContainer } from './StripePaymentFormContainer';
import classnames from 'classnames';
import { safeDataLayerPush } from '../utils/googleTagManagerWrapper';
import { PaymentMethod } from './redux/types';
import { PaymentMethodType } from '../../api/paymentMethods/paymentMethodTypes.types';
import { UnreachableCaseError } from '../../../../utils/typescriptUtil';
import { getGAPaymentType } from './paymentMethodsGTMUtils';
import { BankTransferPayment } from './BankTransferPayment';

function pushToGA({ paymentType }: { paymentType?: PaymentMethodType } = {}) {
    if (paymentType) {
        const createEvent = () => ({
            event: 'PaymentMethodSelected',
            eventPayload: {
                paymentType: getGAPaymentType(paymentType),
            },
        });
        safeDataLayerPush(createEvent);
    }
}

interface Props {
    paymentInformation?: PaymentMethod;
    paymentMethodTypes: PaymentMethodType[];
    setEditMode: (isEditMode: boolean) => void;
    isEditPayment: boolean;
}

type OwnState = { paymentMethodType?: PaymentMethodType; isSubmittingToStripe: boolean };

function disableOrEmpty(disableNoneActiveTabs: boolean) {
    return disableNoneActiveTabs ? 'disabled' : '';
}

export class PaymentMethodPage extends Component<Props, OwnState> {
    constructor(props: Props) {
        super(props);
        if (props.paymentInformation) {
            this.state = {
                paymentMethodType: props.paymentInformation.paymentType,
                isSubmittingToStripe: false,
            };
        } else {
            this.state = {
                paymentMethodType: PaymentMethodType.CREDIT_CARD,
                isSubmittingToStripe: false,
            };
            this.props.setEditMode(true);
        }
        this.setSubmittingToStripe = this.setSubmittingToStripe.bind(this);
    }

    componentWillUnmount() {
        this.props.setEditMode(false);
    }

    setSubmittingToStripe(isSubmittingToStripe: boolean) {
        this.setState({ isSubmittingToStripe });
    }

    setPaymentMethodType(paymentMethodType: PaymentMethodType) {
        pushToGA({ paymentType: paymentMethodType });
        this.setState({ paymentMethodType });
    }

    handleShowCurrentPaymentMethod() {
        this.props.setEditMode(false);
        this.setState({
            paymentMethodType: this.props.paymentInformation?.paymentType,
        });
    }

    renderPaymentForm(paymentMethod?: PaymentMethodType, paymentInformation?: PaymentMethod) {
        if (paymentMethod === PaymentMethodType.BANK_TRANSFER) {
            return (
                <div className='max-width-700'>
                    <BankTransferPayment />
                </div>
            );
        }
        return (
            <div className='max-width-700'>
                <StripePaymentFormContainer
                    paymentMethod={paymentMethod}
                    paymentInformation={paymentInformation}
                    setSubmittingToStripe={this.setSubmittingToStripe}
                />
            </div>
        );
    }

    renderPaymentInformation(paymentInformation?: PaymentMethod) {
        return (
            <div>
                <h4>
                    <FormattedMessage id='marketplace.payment.information.header.current' />
                </h4>
                <PaymentInformation paymentInformation={paymentInformation} />
            </div>
        );
    }

    renderPaymentTabs({
        paymentMethodType,
        disableNoneActiveTabs,
        paymentMethodTypes,
    }: {
        paymentMethodType?: PaymentMethodType;
        disableNoneActiveTabs: boolean;
        paymentMethodTypes: PaymentMethodType[];
    }) {
        const paymentTypeProperties1 = paymentMethodTypes.map((type) => {
            switch (type) {
                case PaymentMethodType.SEPA:
                    return {
                        type,
                        className: `tabSepa cursor-pointer`,
                        headerId: 'marketplace.payment.method.sepa',
                    };
                case PaymentMethodType.CREDIT_CARD:
                    return {
                        type,
                        className: `tabCreditCard cursor-pointer`,
                        headerId: 'marketplace.payment.method.creditCard',
                    };
                case PaymentMethodType.BANK_TRANSFER:
                    return {
                        type,
                        className: 'tabBankTransfer cursor-pointer',
                        headerId: 'marketplace.payment.method.bankTransfer',
                    };
                default:
                    throw new UnreachableCaseError(type);
            }
        });

        return (
            <ul className='nav nav-tabs flex-wrap'>
                {paymentTypeProperties1.map((it, index) => {
                    return (
                        <li
                            key={index}
                            className={`${it.className} ${
                                paymentMethodType === it.type ? 'active' : disableOrEmpty(disableNoneActiveTabs)
                            }`}
                            role='presentation'
                        >
                            {/* eslint-disable-next-line */}
                            <a onClick={() => !disableNoneActiveTabs && this.setPaymentMethodType(it.type)}>
                                <FormattedMessage id={it.headerId} />
                            </a>
                        </li>
                    );
                })}
                <div className='display-flex justify-content-end flex-1-1'>
                    {this.props.isEditPayment ? this.renderShowCurrentPaymentButton() : this.renderEditPaymentButton()}
                </div>
            </ul>
        );
    }

    renderEditPaymentButton() {
        return (
            <button
                key='editPayment'
                className={'changePayment btn btn-default'}
                onClick={() => this.props.setEditMode(true)}
            >
                <FormattedMessage id='marketplace.payment.button.changePayment' />
            </button>
        );
    }

    renderShowCurrentPaymentButton() {
        if (!this.props.paymentInformation) {
            return null;
        }
        const disabled = this.state.isSubmittingToStripe;

        return (
            <button
                key='showCurrentPayment'
                className={classnames('showCurrentPayment', 'btn', 'btn-default', disabled && 'disabled')}
                onClick={() => !disabled && this.handleShowCurrentPaymentMethod()}
            >
                <span className='rioglyph rioglyph-revert'></span>
                <FormattedMessage id='marketplace.payment.button.showCurrentPayment' />
            </button>
        );
    }

    renderPaymentMethodPageBody({
        paymentInformation,
        paymentMethodType,
        paymentMethodTypes,
    }: Pick<Props, 'paymentInformation' | 'paymentMethodTypes'> & { paymentMethodType?: PaymentMethodType }) {
        let paymentContent;

        if (!this.props.isEditPayment) {
            paymentContent = this.renderPaymentInformation(paymentInformation);
        } else {
            paymentContent = this.renderPaymentForm(paymentMethodType, paymentInformation);
        }

        const disableNoneActiveTabs = !!paymentInformation && !this.props.isEditPayment;

        return (
            <div>
                {this.renderPaymentTabs({
                    paymentMethodType,
                    disableNoneActiveTabs,
                    paymentMethodTypes,
                })}
                <div className='tab-content padding-top-25 display-flex flex-column align-items-center'>
                    {paymentContent}
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className='PaymentMethodPage'>
                {this.renderPaymentMethodPageBody({
                    paymentInformation: this.props.paymentInformation,
                    paymentMethodType: this.state.paymentMethodType,
                    paymentMethodTypes: this.props.paymentMethodTypes,
                })}
            </div>
        );
    }
}
