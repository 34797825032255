/* eslint-disable camelcase */
import { getMarketplaceBackend } from '../../config/pathURL/config';
import { getRequest } from '../requests';
import { decodeJson, jsonOrReject } from '../api';
import { getParameters } from '../parameterService';
import { ApiPaymentsCodec, ApiPaymentSecretCodec } from './payments.types';
import { FailedPayment } from '../../paymentReauthentication/redux/types';
import { addOffsetToDate, getCurrentDate } from '../../common/utils/dateUtil';

const PAYMENT_SORT_DIRECTION = {
    ASC: '+',
    DESC: '-',
};
const PAYMENT_SORT_FIELD = {
    TIMESTAMP: 'timestamp',
};

const FAILED_PAYMENT_DATE_OFFSET_IN_DAYS = -45;
const PAYMENT_STATUS = {
    FAILED: 'FAILED',
    SUCCESSFUL: 'SUCCESSFUL',
};

export function fetchFailedPayments(accountId: string): Promise<Array<FailedPayment>> {
    const date = getCurrentDate();
    const startingAfter = addOffsetToDate(date, FAILED_PAYMENT_DATE_OFFSET_IN_DAYS).toISOString();
    const params = getParameters({
        starting_after: startingAfter,
        limit: 1,
        sort: `${PAYMENT_SORT_DIRECTION.DESC}${PAYMENT_SORT_FIELD.TIMESTAMP}`,
        status_type: PAYMENT_STATUS.FAILED,
    });
    return fetch(`${getMarketplaceBackend()}/accounts/${accountId}/payments${params}`, getRequest())
        .then(jsonOrReject)
        .then(decodeJson(ApiPaymentsCodec))
        .then((response) => response.payments);
}

export function fetchPaymentSecret(accountId: string, paymentId: string): Promise<string> {
    const params = getParameters({ payment_id: paymentId });
    return fetch(`${getMarketplaceBackend()}/accounts/${accountId}/payment-secret${params}`, getRequest())
        .then(jsonOrReject)
        .then(decodeJson(ApiPaymentSecretCodec))
        .then((response) => response.secret);
}
