/* eslint-disable camelcase */
import * as t from 'io-ts';

export const INVOICE_TYPE_STRING = 'INVOICE';
export const CREDIT_MEMO_TYPE_STRING = 'CREDIT_MEMO';
export const DEBIT_MEMO_TYPE_STRING = 'DEBIT_MEMO';

const ApiInvoiceTypeCodec = t.union([
    t.literal(INVOICE_TYPE_STRING),
    t.literal(CREDIT_MEMO_TYPE_STRING),
    t.literal(DEBIT_MEMO_TYPE_STRING),
]);

export type ApiInvoiceType = t.TypeOf<typeof ApiInvoiceTypeCodec>;

export const ApiInvoiceCodec = t.type({
    id: t.string,
    number: t.string,
    partner_account_name: t.string,
    amount: t.number,
    currency: t.string,
    type: ApiInvoiceTypeCodec,
    issued_at: t.string,
});

export const ApiInvoicesCodec = t.type({
    items: t.array(ApiInvoiceCodec),
});

export type ApiInvoice = t.TypeOf<typeof ApiInvoiceCodec>;
export type ApiInvoices = t.TypeOf<typeof ApiInvoicesCodec>;

export const ApiInvoiceDownloadCodec = t.type({
    content: t.string,
});

export type ApiInvoiceDownload = t.TypeOf<typeof ApiInvoiceDownloadCodec>;
