import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ResourceSelectionState, BookableResource } from './types';
import { isResourceBookable } from '../../../common/utils/resourceUtil';
import intersection from 'lodash/intersection';
import { RootState } from '../../../../../configuration/setup/store';
import { createSelector } from 'reselect';

const initialState: ResourceSelectionState = {
    availableResources: [],
    selectedResourceIds: [],
    resourceSearchTerm: '',
};

export const NO_LEVEL = 'NO_LEVEL';

export const filterBookableSelectedResourceIds = (
    availableResources: BookableResource[],
    selectedResourceIds: string[]
) => {
    if (availableResources.length === 0) {
        return selectedResourceIds;
    }
    return intersection(
        selectedResourceIds,
        availableResources.filter(isResourceBookable).map((resource) => resource.id)
    );
};

export const filterInvalidSelectedResourceIds = (state: ResourceSelectionState, selectedResourceIds: string[]) => {
    const availableResources = state.availableResources;
    if (availableResources.length === 0) {
        return selectedResourceIds;
    }

    const bookableResourceIds = availableResources.filter(isResourceBookable).map((resource) => resource.id);
    return selectedResourceIds.filter((it) => !bookableResourceIds.includes(it));
};

export const { reducer: resourceSelectionReducer, actions: resourceSelectionActions } = createSlice({
    name: 'resourceSelection',
    initialState,
    reducers: {
        resetResourceSelectionState: () => {
            return initialState;
        },
        setAvailableResources: (state: ResourceSelectionState, action: PayloadAction<Array<BookableResource>>) => {
            state.availableResources = action.payload;
        },
        selectResource: (state: ResourceSelectionState, action: PayloadAction<string>) => {
            const resourceIdToSelect = action.payload;
            if (state.selectedResourceIds.includes(resourceIdToSelect)) {
                state.selectedResourceIds = state.selectedResourceIds.filter((id) => id !== resourceIdToSelect);
            } else {
                state.selectedResourceIds.push(resourceIdToSelect);
            }
        },
        selectAllResources: (
            state: ResourceSelectionState,
            action: PayloadAction<{ selected: boolean; level?: string | typeof NO_LEVEL }>
        ) => {
            const { level, selected } = action.payload;
            const bookableResources = state.availableResources.filter(isResourceBookable);
            if (!selected) {
                state.selectedResourceIds = [];
            } else if (level === undefined) {
                state.selectedResourceIds = bookableResources.map((resource) => resource.id);
            } else {
                const filteredLevel = level === NO_LEVEL ? undefined : level;
                state.selectedResourceIds = bookableResources
                    .filter((resource) => resource.targetLevel === filteredLevel)
                    .map((resource) => resource.id);
            }
        },
        clearSelectedResources: (state: ResourceSelectionState) => {
            state.selectedResourceIds = [];
        },
        setResourceSearchTerm: (state: ResourceSelectionState, action: PayloadAction<string>) => {
            state.resourceSearchTerm = action.payload;
        },
    },
});

const getBase = (state: RootState): ResourceSelectionState => state.marketplace.resourceSelection;
export const getAvailableResources = (state: RootState) => getBase(state).availableResources;
export const getAllSelectedResourceIds = (state: RootState) => getBase(state).selectedResourceIds;
export const getInvalidSelectedResourceIds = (state: RootState) =>
    filterInvalidSelectedResourceIds(getBase(state), getBase(state).selectedResourceIds);
export const getResourceSearchTerm = (state: RootState) => getBase(state).resourceSearchTerm;
export const getSelectedResourceIds = createSelector(
    [getAvailableResources, getAllSelectedResourceIds],
    (availableResources, selectedResourceIds) =>
        filterBookableSelectedResourceIds(availableResources, selectedResourceIds)
);
