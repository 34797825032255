import classNames from 'classnames';
import { getFreeTrialColumns, FreeTrialTableRowContainer } from './FreeTrialTableRowContainer';
import { AllContractsCheckboxContainer } from './AllFreeTrialsCheckboxContainer';
import { TermedLevel } from '../../common/termedLevel/types';
import { useMemo } from 'react';
import { ProductType } from '../../common/product/product';

const tableClassNames = () =>
    classNames(
        'table',
        'table-layout-fixed',
        'table-column-overflow-hidden',
        'table-condensed',
        'table-sticky',
        'table-sticky-in-container',
        'table-hover',
        'table-head-filled'
    );

interface Props {
    productId: string;
    freeTrialActivations: Array<TermedLevel>;
    productType: ProductType;
}

export const FreeTrialTable = (props: Props) => {
    const { productId, freeTrialActivations, productType } = props;
    const columns = useMemo(() => getFreeTrialColumns(productType), []);
    return (
        <>
            <hr className={'margin-0'} />
            <div className={'max-height-50vh width-100pct overflow-y-scroll border border-bottom-only'}>
                <table className={tableClassNames()}>
                    <colgroup>
                        <col className='table-checkbox' />
                        {columns.map((column) => (
                            <col key={column.propertyName} style={{ width: column?.width }} />
                        ))}
                    </colgroup>
                    <thead>
                        <tr>
                            <th>
                                <AllContractsCheckboxContainer productId={productId} />
                            </th>
                            {columns.map((column) => (
                                <th key={column.propertyName} className='sort-column'>
                                    <span>{column.label}</span>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {freeTrialActivations.map((contract) => (
                            <FreeTrialTableRowContainer
                                key={contract.productId + contract.resourceId}
                                freeTrialActivation={contract}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};
