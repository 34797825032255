import { connect } from 'react-redux';
import { deletePartnerConnectionThunk } from '../partners.thunk';
import ConfirmationDialog from '@rio-cloud/rio-uikit/lib/es/ConfirmationDialog';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { RootDispatch } from '../../../../../configuration/setup/store';
import { OverlayTrigger, Tooltip } from '../../../common/utils/tooltipReExports';

interface Props {
    enabled: boolean;
    connectionId: string;
    deleteConnection: (connectionId: string) => void;
}

export function PartnersTableDeleteButton({ enabled, connectionId, deleteConnection }: Props) {
    if (!enabled) {
        const tooltip = (
            <Tooltip id='tooltip' className={`width-auto max-width-300`}>
                <span>
                    <FormattedMessage id={'marketplace.partners.delete.disabledHint'} />
                </span>
            </Tooltip>
        );

        return (
            <OverlayTrigger trigger={OverlayTrigger.TRIGGER_HOVER} placement={'bottom'} overlay={tooltip}>
                <div>
                    <button className='btn btn-danger btn-link padding-left-0' type='button' disabled>
                        <span className='rioglyph rioglyph-trash text-color-danger margin-right-5' />
                        <span>
                            <FormattedMessage id={'marketplace.partners.delete.button'} />
                        </span>
                    </button>
                </div>
            </OverlayTrigger>
        );
    }

    const [showDialog, setShowDialog] = useState(false);
    return (
        <div>
            <button
                className='btn btn-danger btn-link padding-left-0'
                type='button'
                onClick={() => setShowDialog(true)}
            >
                <span className='rioglyph rioglyph-trash text-color-danger margin-right-5' />
                <span>
                    <FormattedMessage id={'marketplace.partners.delete.button'} />
                </span>
            </button>
            <ConfirmationDialog
                show={showDialog}
                bsSize={'sm'}
                title={<FormattedMessage id='marketplace.partners.delete.dialog.title' />}
                content={<FormattedMessage id='marketplace.partners.delete.dialog.content' />}
                onClickConfirm={() => deleteConnection(connectionId)}
                onClickCancel={() => setShowDialog(false)}
                cancelButtonText={<FormattedMessage id='marketplace.partners.delete.dialog.abort' />}
                confirmButtonText={<FormattedMessage id='marketplace.partners.delete.dialog.confirm' />}
            />
        </div>
    );
}

const mapDispatchToProps = (dispatch: RootDispatch) => ({
    deleteConnection: (connectionId: string) => dispatch(deletePartnerConnectionThunk(connectionId)),
});

export const PartnersTableDeleteButtonContainer = connect(null, mapDispatchToProps)(PartnersTableDeleteButton);
