import { getMarketplaceBackend } from '../../config/pathURL/config';
import { getRequest } from '../requests';
import { decodeJson, jsonOrReject, onRejected } from '../api';
import { getParameters } from '../parameterService';
import { ApiActivationsCodec } from './asset.types';
import { mapAssetActivationsResponse } from './assetMapper';
import { OverviewResource } from '../../customerCenter/serviceOverview/redux/types';

export function fetchResourcesForProduct(sku: string): Promise<Array<OverviewResource>> {
    // eslint-disable-next-line camelcase
    const params = getParameters({ product_id: sku });
    return fetch(`${getMarketplaceBackend()}/activations${params}`, getRequest())
        .then(jsonOrReject)
        .then(decodeJson(ApiActivationsCodec))
        .then(mapAssetActivationsResponse)
        .catch(onRejected());
}
