import { connect } from 'react-redux';
import Multiselect from '@rio-cloud/rio-uikit/lib/es/Multiselect';
import { assetOverviewActions, getTags } from './redux/assetOverview.redux';
import { Tag } from './redux/types';
import { useIntl } from 'react-intl';
import { RootDispatch, RootState } from '../../../../configuration/setup/store';

interface Props {
    tags: Array<Tag>;
    selectAssetsByTags: (tags: Array<string>) => void;
}

const TagSelectionDropdown = (props: Props) => {
    const { tags, selectAssetsByTags } = props;
    const intl = useIntl();

    return (
        <div>
            <Multiselect
                useFilter
                placeholder={intl.formatMessage({ id: 'marketplace.assetOverview.groupSelection' })}
                options={tags.map((it) => ({
                    id: it.id,
                    label: it.name,
                }))}
                onChange={selectAssetsByTags}
            />
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    tags: getTags(state),
});

const mapDispatchToProps = (dispatch: RootDispatch) => ({
    selectAssetsByTags: (tags: Array<string>) => {
        dispatch(assetOverviewActions.setAssetsToDisplayByTags(tags));
        dispatch(assetOverviewActions.clearAssetSelection());
        dispatch(assetOverviewActions.setTableSearch(''));
    },
});

export const TagSelectionDropdownContainer = connect(mapStateToProps, mapDispatchToProps)(TagSelectionDropdown);
