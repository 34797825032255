import qs, { IParseOptions, IStringifyOptions } from 'qs';

export const parseQueryString = (search: string) => {
    return qs.parse(search.replace('?', ''), PARSE_OPTIONS);
};

export const STRINGIFY_OPTIONS: IStringifyOptions = {
    arrayFormat: 'comma', // required to stringify arrays into comma separated strings
    indices: false, // don't use array indices
    encode: false, // don't encode the entire string as it will be done individually for certain params
    skipNulls: true, // required to remove empty params
};

export const PARSE_OPTIONS: IParseOptions = {
    comma: true, // required to parse comma separated string into array
};
