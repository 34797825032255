import pipe from 'lodash/fp/pipe';
import sortBy from 'lodash/fp/sortBy';
import groupBy from 'lodash/fp/groupBy';
import values from 'lodash/fp/values';
import map from 'lodash/fp/map';
import flatten from 'lodash/flatten';
import { Period } from '../common/termedLevel/types';

export function sortRatePlans<T extends { variantName?: string; contractPeriod?: Period; level?: string }>(
    productRatePlans: T[]
): T[] {
    return pipe(
        sortBy('level'),
        groupBy((ratePlan: T) => ratePlan.variantName),
        values,
        map((ratePlans: T[]) => sortBy((ratePlan: T) => ratePlan.contractPeriod?.length ?? 0)(ratePlans)),
        flatten
    )(productRatePlans);
}
