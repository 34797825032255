export const ProfileTab = () => {
    return (
        <div className='module-content'>
            <div className='container-fluid fluid-default margin-bottom-20'>
                <iframe
                    title='iframe'
                    id='settingsdialog-iframe'
                    // TODO MANWS-5253: use correct iframe provided by iam
                    src='https://user-menu.iam.rio.cloud/#showNewSettingsDialog'
                    className='margin-top--10 margin-bottom--20'
                    style={{
                        width: '1px',
                        minWidth: '100%',
                        border: 'none',
                        overflow: 'hidden',
                        maxHeight: '520px',
                        minHeight: '300px',
                        height: '520px',
                    }}
                    scrolling='no'
                />
            </div>
        </div>
    );
};
