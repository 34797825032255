import {
    HISTORY_READ,
    INVOICE_READ,
    PAYMENT_INFORMATION_WRITE,
    SUBSCRIPTION_READ,
    SUBSCRIPTION_WRITE,
} from '../common/permissions/permissions';
import { CustomerCenterTab, TabInfo } from './types';
import { Tenant } from '../common/utils/tenants';
import { isProfileToggleEnabled } from '../common/toggles/featureToggles';

const freeTrial: TabInfo = {
    componentName: CustomerCenterTab.FREE_TRIAL,
    limitedToTenants: [Tenant.EU],
    requiredPermissions: [SUBSCRIPTION_READ],
    labelId: 'marketplace.free.trial',
};

const services: TabInfo = {
    componentName: CustomerCenterTab.SERVICES,
    limitedToTenants: [Tenant.EU, Tenant.BRAZIL, Tenant.SOUTH_KOREA],
    requiredPermissions: [SUBSCRIPTION_READ],
    labelId: 'marketplace.myServices',
};

const bookings: TabInfo = {
    componentName: CustomerCenterTab.BOOKINGS,
    limitedToTenants: [Tenant.EU, Tenant.BRAZIL, Tenant.SOUTH_KOREA],
    requiredPermissions: [],
    labelId: 'marketplace.bookings',
};

const assets: TabInfo = {
    componentName: CustomerCenterTab.ASSETS,
    limitedToTenants: [Tenant.EU, Tenant.BRAZIL, Tenant.SOUTH_KOREA],
    requiredPermissions: [SUBSCRIPTION_READ],
    labelId: 'marketplace.preview.multilevel.vehicles',
};

const contracts: TabInfo = {
    componentName: CustomerCenterTab.CONTRACTS,
    limitedToTenants: [Tenant.EU, Tenant.BRAZIL],
    requiredPermissions: [SUBSCRIPTION_READ],
    labelId: 'marketplace.contracts',
};

const invoices: TabInfo = {
    componentName: CustomerCenterTab.INVOICES,
    limitedToTenants: [Tenant.EU],
    requiredPermissions: [INVOICE_READ],
    labelId: 'marketplace.invoices',
};

const usage: TabInfo = {
    componentName: CustomerCenterTab.USAGE,
    limitedToTenants: [Tenant.EU, Tenant.BRAZIL],
    requiredPermissions: [INVOICE_READ],
    labelId: 'marketplace.usage',
};

const history: TabInfo = {
    componentName: CustomerCenterTab.HISTORY,
    limitedToTenants: [Tenant.EU, Tenant.BRAZIL, Tenant.SOUTH_KOREA],
    requiredPermissions: [HISTORY_READ],
    labelId: 'marketplace.history',
};

const paymentMethods: TabInfo = {
    componentName: CustomerCenterTab.PAYMENT_METHODS,
    limitedToTenants: [Tenant.EU],
    requiredPermissions: [PAYMENT_INFORMATION_WRITE],
    labelId: 'marketplace.payment.method',
};

const profile: TabInfo = {
    componentName: CustomerCenterTab.PROFILE,
    limitedToTenants: [Tenant.EU, Tenant.BRAZIL, Tenant.SOUTH_KOREA],
    requiredPermissions: [],
    labelId: 'marketplace.profile',
};

const partnerConnections: TabInfo = {
    componentName: CustomerCenterTab.PARTNERS,
    limitedToTenants: [Tenant.EU],
    requiredPermissions: [SUBSCRIPTION_WRITE],
    labelId: 'marketplace.partners',
};

const inboundConnections: TabInfo = {
    componentName: CustomerCenterTab.INBOUND,
    limitedToTenants: [Tenant.EU],
    requiredPermissions: [SUBSCRIPTION_WRITE],
    labelId: 'marketplace.connections.inbound',
};

const outboundConnections: TabInfo = {
    componentName: CustomerCenterTab.OUTBOUND,
    limitedToTenants: [Tenant.EU],
    requiredPermissions: [SUBSCRIPTION_WRITE],
    labelId: 'marketplace.connections.outbound',
};

export const getCustomerCenterTabs = (): Array<TabInfo> => [
    bookings,
    freeTrial,
    services,
    assets,
    contracts,
    invoices,
    usage,
    history,
    paymentMethods,
    partnerConnections,
    ...(isProfileToggleEnabled() ? [profile] : []),
    inboundConnections,
    outboundConnections,
];
