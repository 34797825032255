import { AssetDiscount, ProductDiscount } from './redux/types';
import { DEFAULT_TOOLTIP_DELAY } from '../../config/config';
import { FormattedMessage, useIntl } from 'react-intl';
import { generateDiscountHint } from './DiscountService';
import { OverlayTrigger, Tooltip } from '../utils/tooltipReExports';

export const DiscountBadgeRaw = ({ percentage }: { percentage: number }) => {
    if (percentage === 100) {
        return <FormattedMessage id={'marketplace.free'} />;
    }
    return <>{`-${percentage}%`}</>;
};

export const DiscountBadge = ({ percentage }: { percentage: number }) => {
    return (
        <span>
            <span className='bg-success padding-left-5 padding-right-5 rounded'>
                <span className='rioglyph rioglyph-info-sign margin-right-5' />
                <DiscountBadgeRaw percentage={percentage} />
            </span>
        </span>
    );
};

export const DiscountTextBadge = () => (
    <span className='bg-success padding-left-5 padding-right-5 rounded'>
        <FormattedMessage id={'marketplace.discount'} />
    </span>
);

export const DiscountBadgeWithTooltip = ({ discount }: { discount: AssetDiscount | ProductDiscount }) => {
    const intl = useIntl();

    const overlay = (
        <Tooltip id='tooltip' className={'width-auto max-width-300'}>
            {generateDiscountHint(discount, intl)}
        </Tooltip>
    );

    return (
        <OverlayTrigger trigger={OverlayTrigger.TRIGGER_HOVER} placement='bottom' overlay={overlay}
            delay={DEFAULT_TOOLTIP_DELAY}>
            <span>
                <DiscountBadge percentage={discount.discountPercentage} />
            </span>
        </OverlayTrigger>
    );
};
