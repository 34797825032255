import { IntlShape, useIntl } from 'react-intl';
import { getSelectedProductOrProductLevel } from '../redux/checkout.redux';
import { connect } from 'react-redux';
import { isBookableProduct } from '../redux/types';
import { OverlayTrigger, Tooltip } from '../../common/utils/tooltipReExports';
import { MessageKey } from '../../../../configuration';
import { PriceInfoLongTerm } from '../../common/price/PriceInfoLongTerm';
import { RootState } from '../../../../configuration/setup/store';
import { Period } from '../../common/termedLevel/types';
import { ProductType } from '../../common/product/product';
import { PriceInfoDaily } from '../../common/price/PriceInfoDaily';
import { DEFAULT_TOOLTIP_DELAY } from '../../config/config';

const renderEstimatedPriceInfo = (intl: IntlShape, messageKey: MessageKey) => {
    return (
        <OverlayTrigger
            trigger={OverlayTrigger.TRIGGER_HOVER}
            placement='bottom'
            overlay={
                <Tooltip id='tooltip' className={'width-auto max-width-300'}>
                    {intl.formatMessage({ id: messageKey })}
                </Tooltip>
            }
            delay={DEFAULT_TOOLTIP_DELAY}
        >
            <span className='PriceDetails-estimated-info-icon rioglyph rioglyph-info-sign' />
        </OverlayTrigger>
    );
};

interface Props {
    price: number | undefined;
    currency: string | undefined;
    contractPeriod: Period | undefined;
    productType: ProductType | undefined;
}

export const PriceDetails = (props: Props) => {
    const { price, currency, contractPeriod, productType } = props;

    const intl = useIntl();

    if (price === 0 || price === undefined) {
        return null;
    }
    return (
        <div className='text-right text-size-12'>
            <PriceInfoDaily price={price} currency={currency} productType={productType} />
            <span className={'rioglyph rioglyph-triangle-right'} />
            <PriceInfoLongTerm
                price={price}
                currency={currency}
                contractPeriod={contractPeriod}
                productType={productType}
            />{' '}
            {contractPeriod === undefined
                ? renderEstimatedPriceInfo(intl, 'marketplace.productDetails.price.estimated.explanation')
                : renderEstimatedPriceInfo(intl, 'marketplace.productDetails.price.fullTerm.explanation')}
        </div>
    );
};
const mapStateToProps = (state: RootState) => {
    const selectedProduct = getSelectedProductOrProductLevel(state);
    const bookableProduct = isBookableProduct(selectedProduct) ? selectedProduct : undefined;
    return {
        price: bookableProduct?.price,
        currency: bookableProduct?.currency,
        contractPeriod: bookableProduct?.contractPeriod,
    };
};

export const PriceDetailsContainer = connect(mapStateToProps)(PriceDetails);
