/* eslint-disable camelcase */

import * as t from 'io-ts';

export const API_PRODUCT_STATE_ACTIVE = 'active';
export const API_PRODUCT_STATE_INACTIVE = 'inactive';
export const API_PRODUCT_STATE_PENDING_CANCELLATION = 'pending-cancellation';
export const API_PRODUCT_STATE_PENDING_ACTIVATION = 'pending-activation';

const ApiProductStateCodec = t.union([
    t.literal(API_PRODUCT_STATE_ACTIVE),
    t.literal(API_PRODUCT_STATE_INACTIVE),
    t.literal(API_PRODUCT_STATE_PENDING_CANCELLATION),
    t.literal(API_PRODUCT_STATE_PENDING_ACTIVATION),
]);
export type ApiProductState = t.TypeOf<typeof ApiProductStateCodec>;

const ApiMissingRequirementCodec = t.intersection([
    t.type({
        expected_values: t.array(t.string),
        inclusive: t.boolean,
        feature_name: t.string,
    }),
    t.partial({
        actual: t.string,
    }),
]);

const ApiMissingRequirementsCodec = t.array(ApiMissingRequirementCodec);

export const ApiResourceActivationCodec = t.intersection([
    t.type({
        resource_id: t.string,
        resource_type: t.string,
        activation_state: ApiProductStateCodec,
        bookable: t.boolean,
    }),
    t.partial({
        resource_name: t.string,
        missing_requirements: t.array(ApiMissingRequirementsCodec),
        origin_level: t.string,
        target_level: t.string,
        has_open_contract: t.boolean,
    }),
]);

export const ApiBookableResourcesCodec = t.type({
    resources: t.array(ApiResourceActivationCodec),
});
export type ApiBookableResources = t.TypeOf<typeof ApiBookableResourcesCodec>;
export type ApiMissingFeature = t.TypeOf<typeof ApiMissingRequirementCodec>;
