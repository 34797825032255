import { ServiceBookButtonContainer } from '../../../common/ServiceBookButtonContainer';
import { CreateOrderButton } from './CreateOrderButton';
import { isOrderEnabled } from '../../../common/toggles/featureToggles';

export const ServiceTabHeader = () => {
    return (
        <div className='bg-lighter padding-top-15 padding-bottom-15 position-sticky-ls top-module-content z-index-10'>
            <div className='table-toolbar margin-bottom-0'>
                <div className='table-toolbar-container'>
                    <div className='table-toolbar-group-left'>
                        <div className='table-toolbar-column'>
                            <ServiceBookButtonContainer />
                        </div>
                    </div>
                    {isOrderEnabled() && (
                        <div className='table-toolbar-group-right'>
                            <div className='table-toolbar-column'>
                                <CreateOrderButton />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
