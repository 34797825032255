import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { UnreachableCaseError } from '../../../../utils/typescriptUtil';
import { OverlayTrigger, Tooltip } from '../../common/utils/tooltipReExports';
import { ReactNode } from 'react';
import { HumanReadableTermedLevelState } from '../../common/termedLevel/types';

function getContractStateTranslationKey(contractState: HumanReadableTermedLevelState): string {
    switch (contractState) {
        case HumanReadableTermedLevelState.active:
            return 'intl-msg:common-message.contracts.active';
        case HumanReadableTermedLevelState.active_terminating_soon:
            return 'intl-msg:common-message.contracts.activeTerminatingSoon';
        case HumanReadableTermedLevelState.pending:
            return 'marketplace.contracts.pending';
        case HumanReadableTermedLevelState.active_without_activation:
            return 'intl-msg:common-message.contracts.activeWithoutActivation';
        default:
            throw new UnreachableCaseError(contractState);
    }
}

const StateOverlayTrigger = (props: { children: ReactNode }) => {
    return (
        <OverlayTrigger
            trigger={OverlayTrigger.TRIGGER_HOVER}
            placement={'right'}
            overlay={
                <Tooltip className={'width-auto max-width-300'}>
                    <FormattedMessage id={'intl-msg:common-message.contracts.activeWithoutActivation.info'} />
                </Tooltip>
            }
        >
            <div>{props.children}</div>
        </OverlayTrigger>
    );
};

export function ContractState({ contractState }: { contractState: HumanReadableTermedLevelState }) {
    const translatedContractState = getContractStateTranslationKey(contractState);
    return (
        <div
            className={classNames('label label-filled label-condensed label-default', {
                'label-success': contractState === 'active',
                'label-warning':
                    contractState === 'active_terminating_soon' || contractState === 'active_without_activation',
            })}
        >
            {contractState === 'active_without_activation' ? (
                <StateOverlayTrigger>
                    <FormattedMessage id={translatedContractState} />
                </StateOverlayTrigger>
            ) : (
                <FormattedMessage id={translatedContractState} />
            )}
        </div>
    );
}
