import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import messagesEN from '../../features/app/lang/locales/en-GB.json';
import { DEFAULT_LOCALE, getSupportedLocale } from './lang';
import { DisplayMessages } from '../index';
import { RootState } from '../setup/store';

interface CombinedMessages {
    [key: string]: DisplayMessages;
}

export interface LangState {
    allMessages: CombinedMessages;
    displayLocale: string;
}

interface MessagesPayload {
    locale: string;
    displayMessages: DisplayMessages;
}

const defaultMessages = {
    [DEFAULT_LOCALE]: messagesEN,
};

const initialState: LangState = {
    allMessages: defaultMessages,
    displayLocale: DEFAULT_LOCALE,
};

const langSlice = createSlice({
    name: 'lang',
    initialState,
    reducers: {
        localeChanged: (state, action: PayloadAction<string>) => {
            const preferredLocale = action.payload;

            state.displayLocale = getSupportedLocale(preferredLocale);
        },
        displayMessagesFetched: (state, action: PayloadAction<MessagesPayload>) => {
            const { locale, displayMessages } = action.payload;

            state.allMessages = {
                ...state.allMessages,
                [locale]: displayMessages,
            };
            state.displayLocale = locale;
        },
    },
});

export const { localeChanged, displayMessagesFetched } = langSlice.actions;

export const getLocale = (state: RootState) => state.lang.displayLocale;
export const getDisplayMessages = (state: RootState) => state.lang.allMessages[getLocale(state)];

export default langSlice.reducer;
