import { Dispatch } from 'redux';
import { apiCallAction } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import { getInvoices, invoicesActions } from './redux/invoices.redux';
import { getDisplayMessages } from '../../../../configuration';
import { fetchInvoiceDownload, fetchInvoices } from '../../api/invoices/invoicesCalls';
import FileSaver from 'file-saver';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import { getInvoiceNumber } from './invoiceService';
import { getAccountId } from '../../../../configuration/tokenHandling/tokenSlice';
import { RootState } from '../../../../configuration/setup/store';

export const fetchInvoicesThunk = (dispatch: Dispatch, getState: () => RootState) => {
    const accountId = getAccountId(getState());

    dispatch(invoicesActions.setDatedInvoices([]));
    dispatch(apiCallAction(ApiCalls.INVOICES_GET, true, false));
    return fetchInvoices(accountId)
        .then((response) => {
            dispatch(invoicesActions.setDatedInvoices(response));
            dispatch(apiCallAction(ApiCalls.INVOICES_GET, false));
        })
        .catch(() => {
            dispatch(apiCallAction(ApiCalls.INVOICES_GET, false, true));
        });
};

export const fetchInvoiceDownloadThunk = (invoiceId: string) => (dispatch: Dispatch, getState: () => RootState) => {
    const accountId = getAccountId(getState());
    const languageData = getDisplayMessages(getState());
    const invoices = getInvoices(getState());
    const invoiceNumber = getInvoiceNumber(invoices, invoiceId);

    dispatch(apiCallAction(ApiCalls.INVOICE_DOWNLOAD, true, false));
    dispatch(invoicesActions.setDownloadingInvoiceId(invoiceId));
    return fetchInvoiceDownload(accountId, invoiceId)
        .then((response) => {
            FileSaver.saveAs(response, `${invoiceNumber}.pdf`);
            dispatch(apiCallAction(ApiCalls.INVOICE_DOWNLOAD, false));
            dispatch(invoicesActions.setDownloadingInvoiceId(undefined));
        })
        .catch(() => {
            Notification.error(languageData['marketplace.invoice.download.error']);
            dispatch(apiCallAction(ApiCalls.INVOICE_DOWNLOAD, false, true));
            dispatch(invoicesActions.setDownloadingInvoiceId(undefined));
        });
};
