import { FormattedMessage } from 'react-intl';
import ConfirmationDialog from '@rio-cloud/rio-uikit/lib/es/ConfirmationDialog';
import { useNavigate } from 'react-router';
import { getCustomerCenterPath } from '../../common/routes';
import { CustomerCenterTab } from '../types';

interface Props {
    closeDialog: () => void;
}

export const AssetOverviewGotoFreeTrialDialog = (props: Props) => {
    const { closeDialog } = props;
    const navigate = useNavigate(); // ;
    const content = (
        <div>
            <div className='confirmationDialogText'>
                <FormattedMessage id={'marketplace.assetOverview.gotoFreeTrialsDisclaimer'} />{' '}
            </div>
        </div>
    );
    return (
        <ConfirmationDialog
            show={true}
            title={<FormattedMessage id={'marketplace.assetOverview.gotoFreeTrials.headline'} />}
            content={content}
            onClickConfirm={() => navigate(getFreeTrialsLink())}
            onClickCancel={closeDialog}
            cancelButtonText={<FormattedMessage id={'marketplace.navigations.back'} />}
            confirmButtonText={<FormattedMessage id={'marketplace.myServices.freeTrials'} />}
        />
    );
};

function getFreeTrialsLink() {
    return getCustomerCenterPath(CustomerCenterTab.FREE_TRIAL);
}
