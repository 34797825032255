import { CancelResourceSubscriptionsDialog } from '../serviceOverview/components/CancelResourceSubscriptionsDialog';
import { connect } from 'react-redux';
import { assetOverviewActions, getCancelDialogInfo, getShowCancelDialog } from './redux/assetOverview.redux';
import { cancelSubscriptionThunk } from '../serviceOverview/thunks/subscriptionCancel.thunk';
import { ServiceOverviewItem } from '../serviceOverview/types';
import { OverviewResource } from '../serviceOverview/redux/types';
import { CancelInfo } from './redux/types';
import { fetchProductInfoForAssets } from './assetOverview.thunk';
import { evaluateIfCancelWouldCancelService } from './wouldCancelServiceSubscriptionService';
import { isProductInMaintenanceMode } from '../../../../configuration/featureToggle/featureToggleService';
import { RootDispatch, RootState } from '../../../../configuration/setup/store';
import { ProductType } from '../../common/product/product';

interface Props {
    show: boolean;
    cancelInfo?: CancelInfo;
    serviceName: string;
    selectedAssets: Array<OverviewResource>;
    wouldCancelService: boolean;
    onClickConfirmCallback: (service: ServiceOverviewItem, assetIds: Array<string>, level?: string) => void;
    onClickCancelCallback: () => void;
    isProductInMaintenance: (productId?: string) => boolean;
}

export const AssetOverviewCancelDialog = (props: Props) => {
    const {
        show,
        cancelInfo,
        serviceName,
        wouldCancelService,
        selectedAssets,
        onClickCancelCallback,
        onClickConfirmCallback,
        isProductInMaintenance,
    } = props;

    if (!cancelInfo) {
        return null;
    }

    function onClickConfirmCallback2() {
        onClickConfirmCallback(
            {
                sku: cancelInfo?.productId ?? '',
                productName: cancelInfo?.productName,
                amount: 0,
                legacy: false,
                productType: ProductType.ASSET_BASED,
            },
            cancelInfo?.assets.map((it) => it.id) ?? [],
            cancelInfo?.level
        );
    }

    return (
        <CancelResourceSubscriptionsDialog
            show={show}
            isMaintenanceModeEnabled={isProductInMaintenance(cancelInfo.productId)}
            serviceName={serviceName}
            selectedResources={selectedAssets}
            wouldCancelService={wouldCancelService}
            onClickCancelCallback={onClickCancelCallback}
            onClickConfirmCallback={onClickConfirmCallback2}
        />
    );
};

const mapStateToProps = (state: RootState) => {
    const cancelInfo = getCancelDialogInfo(state);
    return {
        show: getShowCancelDialog(state),
        serviceName: cancelInfo?.productName ?? cancelInfo?.productId ?? 'Not selected',
        selectedAssets: cancelInfo?.assets ?? [],
        wouldCancelService: evaluateIfCancelWouldCancelService(state),
        cancelInfo: getCancelDialogInfo(state),
        isProductInMaintenance: isProductInMaintenanceMode(state),
    };
};

const mapDispatchToProps = (dispatch: RootDispatch) => ({
    onClickConfirmCallback: (service: ServiceOverviewItem, assetIds: Array<string>, level?: string) => {
        dispatch(cancelSubscriptionThunk(service, assetIds, level)).then(() =>
            dispatch(fetchProductInfoForAssets(false))
        );
        dispatch(assetOverviewActions.closeShowCancelDialog());
    },
    onClickCancelCallback: () => dispatch(assetOverviewActions.closeShowCancelDialog()),
});

export const AssetOverviewCancelDialogContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AssetOverviewCancelDialog);
