import { BookableResource, ProductState } from '../../checkout/resourceSelection/redux/types';
import { AssetOverviewRow, HeaderProduct, ProductBookable, ProductInfo } from './redux/types';
import { isFreeTrialLevel } from '../../common/termedLevel/freeTrialLevel';
import { isServiceCareLBeta } from '../../common/betaService';

function containsLevel(product: HeaderProduct, level?: string) {
    if (!level) {
        return product.levels.length === 0;
    }
    return product.levels.includes(level);
}

function evaluateIfAssetHasContractPeriod(asset: BookableResource, product: HeaderProduct): boolean {
    const isBooked =
        [ProductState.ACTIVE, ProductState.PENDING_ACTIVATION].includes(asset.productState) &&
        containsLevel(product, asset.targetLevel);
    return isBooked && asset.targetLevel !== undefined && product.levelsWithContractPeriod.includes(asset.targetLevel);
}

function canBeCancelled(booked: boolean, isPending: boolean, product: HeaderProduct, asset: BookableResource) {
    return (
        booked &&
        !isPending &&
        product.canBeCancelled &&
        !isFreeTrialLevel(asset.targetLevel) &&
        !isServiceCareLBeta(product.productId, asset.targetLevel) &&
        !evaluateIfAssetHasContractPeriod(asset, product)
    );
}

export function createProductInfo(asset: BookableResource, product: HeaderProduct): ProductInfo {
    const booked =
        [ProductState.ACTIVE, ProductState.PENDING_ACTIVATION].includes(asset.productState) &&
        containsLevel(product, asset.targetLevel);
    const isPending =
        (asset.productState === ProductState.PENDING_ACTIVATION && containsLevel(product, asset.targetLevel)) ||
        (asset.productState === ProductState.PENDING_CANCELLATION && containsLevel(product, asset.originLevel));

    // This level is also used to preselect the level in the checkout
    const levels = product.levels.filter((level) => !isFreeTrialLevel(level));
    const level = booked ? asset.targetLevel : levels?.[0];

    return {
        booked,
        canBeCancelled: canBeCancelled(booked, isPending, product, asset),
        bookable: product.bookable === ProductBookable.BOOKABLE && asset.bookable,
        isPending,
        productId: product.productId,
        level: level,
        productName: product.productName,
        missingRequirements: asset.missingRequirements,
        hasContractPeriod: evaluateIfAssetHasContractPeriod(asset, product),
        contractActiveForAnotherLevel: false,
        bookableLevels: product.bookableLevels,
    };
}

export function getProductColumnKey(product: HeaderProduct) {
    return product.productId + product.levels.join('-');
}

export function getRelevantResponses(
    bookableAssetsPerProduct: Array<Array<BookableResource>>,
    headerProduct: HeaderProduct,
    flatProductLevels: Array<{ productId: string; level?: string }>
): Array<Array<BookableResource>> {
    const productId = headerProduct.productId;
    const levels = headerProduct.levels.length > 0 ? headerProduct.levels : [undefined];

    const indices: Array<number> = [];
    levels.forEach((level) => {
        const foundIndex = flatProductLevels.findIndex(
            (productLevel) => productLevel.productId === productId && productLevel.level === level
        );
        indices.push(foundIndex);
    });

    const assetsOfProduct: Array<Array<BookableResource>> = [];
    bookableAssetsPerProduct.forEach((assets, index) => {
        if (indices.includes(index)) {
            assetsOfProduct.push(assets);
        }
    });
    return assetsOfProduct;
}

export function mergeResponses(bookableAssetColumns: Array<Array<BookableResource>>): Array<BookableResource> {
    const resultList: Array<BookableResource> = [];

    for (let i = 0; i < bookableAssetColumns[0].length; i++) {
        const currentElements = [];

        for (let j = 0; j < bookableAssetColumns.length; j++) {
            currentElements.push(bookableAssetColumns[j][i]);
        }

        const resultElement = currentElements[0];
        resultElement.bookable = currentElements.some((bookableAsset) => bookableAsset.bookable);
        resultList.push(resultElement);
    }
    return resultList;
}

export function mapAssetsToRows(
    bookableAssetsPerProduct: Array<Array<BookableResource>>,
    flatProductLevels: Array<{ productId: string; level?: string }>,
    headerProducts: Array<HeaderProduct>
): Array<AssetOverviewRow> {
    const assetList: Array<AssetOverviewRow> = [];

    headerProducts.forEach((headerProduct) => {
        const relevantResponses = getRelevantResponses(bookableAssetsPerProduct, headerProduct, flatProductLevels);
        const mergedResponses = mergeResponses(relevantResponses);
        const productKey = getProductColumnKey(headerProduct);

        mergedResponses.forEach((asset) => {
            const assetInList = assetList.find((it) => it.assetId === asset.id);
            if (assetInList) {
                assetInList.products[productKey] = createProductInfo(asset, headerProduct);
            } else {
                const newEntryInList: AssetOverviewRow = {
                    assetId: asset.id,
                    assetName: asset.name,
                    products: {},
                };
                newEntryInList.products[productKey] = createProductInfo(asset, headerProduct);
                assetList.push(newEntryInList);
            }
        });
    });
    return assetList.map(enhanceWithContractInfo);
}

export function enhanceWithContractInfo(row: AssetOverviewRow): AssetOverviewRow {
    const products = Object.entries(row.products);
    products.forEach(([key, value]) => {
        const hasContractActiveForAnotherLevel = Array.from(Object.values(row.products))
            .filter(({ productId, level }) => productId === value.productId && level !== value.level)
            .some(({ booked, hasContractPeriod }) => booked && hasContractPeriod);

        if (hasContractActiveForAnotherLevel) {
            row.products[key] = { ...value, contractActiveForAnotherLevel: true };
        }
    });
    return row;
}
