import { FormattedMessage, useIntl } from 'react-intl';
import { getRenewsToProductLabelFromContract } from '../../common/utils/productLabelUtil';
import { TermedLevel } from '../../common/termedLevel/types';
import { isTermedLevelSelectable } from '../../common/termedLevel/termedLevelSelectionValidationService';
import { getFreeTrialTrackingAttributes } from '../../common/utils/googleTagManagerAttributes';
import { RootState } from '../../../../configuration/setup/store';
import { getPermissions } from '../../common/permissions/redux/permissions.redux';
import { SUBSCRIPTION_WRITE } from '../../common/permissions/permissions';
import { connect } from 'react-redux';

interface Props {
    contract: TermedLevel;
    onClick: (e: React.MouseEvent) => void;
    hasPermission: boolean;
}

function RenewCell(props: Props) {
    const { contract, onClick, hasPermission } = props;
    const { renewsToLevel } = contract;

    const showButton = isTermedLevelSelectable(contract) && hasPermission;

    const selectedOptionText =
        renewsToLevel === undefined ? (
            <>
                <span className='rioglyph rioglyph-remove text-color-danger margin-right-3' />
                <FormattedMessage id='marketplace.contracts.notRenewed' />
            </>
        ) : (
            <>
                <span className='rioglyph rioglyph rioglyph-repeat text-color-success margin-right-3' />
                {getRenewsToProductLabelFromContract(contract, useIntl())}
            </>
        );

    return (
        <span className='display-flex align-items-center justify-content-between gap-10'>
            <span className='flex-1-1-0 line-height-125rel'>{selectedOptionText}</span>
            {showButton ? (
                <button
                    className='btn btn-default btn-sm btn-icon-only'
                    onClick={onClick}
                    {...getFreeTrialTrackingAttributes('click')}
                >
                    <span className='rioglyph rioglyph-pencil' aria-hidden={true} />
                </button>
            ) : undefined}
        </span>
    );
}

function makeMapStateToProps() {
    return (state: RootState) => ({
        hasPermission: getPermissions(state)?.includes(SUBSCRIPTION_WRITE) ?? false,
    });
}

export const ContractTableRowRenewCell = connect(makeMapStateToProps)(RenewCell);
