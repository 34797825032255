import classNames from 'classnames';
import SortArrows from '@rio-cloud/rio-uikit/lib/es/SortArrows';
import { ContractTableRowContainer, getColumns } from './ContractTableRowContainer';
import { useSorting } from './useSorting';
import { AllContractsCheckboxContainer } from './AllContractsCheckboxContainer';
import { TermedLevel } from '../../common/termedLevel/types';
import { ProductType } from '../../common/product/product';
import { useMemo } from 'react';

const tableClassNames = () =>
    classNames(
        'table',
        'table-layout-fixed',
        'table-column-overflow-hidden',
        'table-condensed',
        'table-sticky',
        'table-sticky-in-container',
        'table-hover',
        'table-head-filled'
    );

interface Props {
    productId: string;
    contracts: Array<TermedLevel>;
    resourceType: ProductType;
}

export const ContractTable = (props: Props) => {
    const { productId, contracts, resourceType } = props;
    const { sort, handleSortChange, sortDirectionForProperty } = useSorting<TermedLevel>('resourceName');

    const sortedContracts = sort(contracts);

    const columns = useMemo(() => getColumns(resourceType), []);

    return (
        <>
            <hr className={'margin-0'} />
            <div className={'max-height-50vh width-100pct overflow-y-scroll border border-bottom-only'}>
                <table className={tableClassNames()}>
                    <colgroup>
                        <col className='table-checkbox' />
                        {columns.map((column) => (
                            <col key={column.propertyName} style={{ width: column?.width }} />
                        ))}
                    </colgroup>
                    <thead>
                        <tr>
                            <th>
                                <span>
                                    <AllContractsCheckboxContainer productId={productId} />
                                </span>
                            </th>
                            {columns.map((column) => (
                                <th
                                    key={column.propertyName}
                                    className='sort-column'
                                    onClick={() => handleSortChange(column.propertyName)}
                                >
                                    <span>
                                        <SortArrows direction={sortDirectionForProperty(column.propertyName)} />
                                        {column.label}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {sortedContracts.map((contract) => (
                            <ContractTableRowContainer
                                key={contract.productId + contract.resourceId}
                                contract={contract}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};
