/* eslint-disable camelcase */
import { getMarketplaceBackend } from '../../config/pathURL/config';
import { postRequest } from '../requests';
import { rejectIfNotOk } from '../api';

export interface CreateOrderData {
    productId: string;
    assetIds: Array<string>;
    externalReference: string;
    level: string;
    orderInfo: string;
}

export function createOrder({
    assetIds,
    productId,
    level,
    externalReference,
    orderInfo,
}: CreateOrderData): Promise<void> {
    const requestBody = {
        product_id: productId,
        level: level,
        asset_ids: assetIds,
        external_reference: externalReference,
        order_info: orderInfo,
    };
    return fetch(`${getMarketplaceBackend()}/orders`, postRequest(requestBody)).then(rejectIfNotOk);
}
