import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { checkoutActions, getSelectedProductId, getSelectedProductLevel } from '../redux/checkout.redux';
import { resourceSelectionActions } from '../resourceSelection/redux/resourceSelection.redux';
import { fetchCheckoutProductDetailsThunk } from '../thunks/productDetails.thunk';
import { fetchBookableResourcesThunk } from '../resourceSelection/resourceSelection.thunk';
import { fetchCheckoutDiscountDataThunk } from '../../common/discount/discounts.thunk';
import isEmpty from 'lodash/isEmpty';
import flatten from 'lodash/flatten';
import { parseQueryString } from '../../common/utils/qsUtil';
import { UrlParameterKeys } from './UrlParameterKeys';
import { useAppDispatch } from '../../../../configuration/setup/store';

interface CheckoutRouteState {
    resourceIds?: string[];
    productId?: string;
    level?: string;
    locked?: boolean;
}

const readArray = <T>(arrayOfSelectedIds: T[] | T | undefined): T[] | undefined =>
    !arrayOfSelectedIds || isEmpty(arrayOfSelectedIds) ? undefined : flatten([arrayOfSelectedIds]);

const parseCheckoutRoute = (search: string): CheckoutRouteState => {
    const parsedParams = parseQueryString(search);

    const resourceIds = parsedParams[UrlParameterKeys.RESOURCE_IDS] ?? parsedParams[UrlParameterKeys.ASSET_IDS];
    const productId = parsedParams[UrlParameterKeys.PRODUCT_ID];
    const level = parsedParams[UrlParameterKeys.LEVEL];
    const locked = parsedParams[UrlParameterKeys.LOCKED];

    return {
        resourceIds: readArray(resourceIds) as Array<string>,
        productId: productId as string,
        level: level as string,
        locked: locked === undefined ? undefined : locked === 'true',
    };
};

export function useCheckoutDeepLinking() {
    const { search } = useLocation();
    const dispatch = useAppDispatch();
    const selectedProductId = useSelector(getSelectedProductId);
    const productLevel = useSelector(getSelectedProductLevel);

    useEffect(() => {
        const { resourceIds, productId, level, locked } = parseCheckoutRoute(search);

        resourceIds?.forEach((assetId) => {
            dispatch(resourceSelectionActions.selectResource(assetId));
        });
        if (productId) {
            dispatch(checkoutActions.setSelectedProductId(productId));
        }
        if (level) {
            dispatch(checkoutActions.setSelectedProductLevel(level));
        }
        const resourcesGiven = locked === undefined && resourceIds && resourceIds.length > 0;
        dispatch(checkoutActions.setCheckoutIsLocked(resourcesGiven || !!locked));
    }, []);

    useEffect(() => {
        if (selectedProductId) {
            dispatch(fetchCheckoutProductDetailsThunk);
        }
    }, [selectedProductId, fetchCheckoutProductDetailsThunk]);

    useEffect(() => {
        if (selectedProductId) {
            dispatch(fetchBookableResourcesThunk);
            dispatch(fetchCheckoutDiscountDataThunk);
        }
    }, [selectedProductId, productLevel]);
}
