/* eslint-disable camelcase */
import * as t from 'io-ts';

const ApiAddressCodec = t.intersection([
    t.type({
        country_code: t.string,
    }),
    t.partial({
        city: t.string,
        company: t.string,
        postal_code: t.string,
        street: t.string,
    }),
]);

const ApiTaxCodec = t.type({
    code: t.string,
    type: t.string,
});

export const ApiBillingAddressCodec = t.intersection([
    t.type({
        account_id: t.string,
        address: ApiAddressCodec,
    }),
    t.partial({
        email: t.string,
        tax: ApiTaxCodec,
    }),
]);

export type ApiBillingAddress = t.TypeOf<typeof ApiBillingAddressCodec>;
