import { ApiPaymentMethod, ApiPaymentMethods } from './paymentInformation.types';
import { PaymentMethod } from '../../common/payment/redux/types';

export function mapPaymentMethod(apiPaymentMethod: ApiPaymentMethod): PaymentMethod {
    return {
        id: apiPaymentMethod.id,
        bankCode: apiPaymentMethod.bank_code,
        brand: apiPaymentMethod.brand,
        country: apiPaymentMethod.country,
        expirationMonth: apiPaymentMethod.expiration_month,
        expirationYear: apiPaymentMethod.expiration_year,
        ownerName: apiPaymentMethod.owner_name,
        paymentAccountNumber: apiPaymentMethod.payment_account_number,
        paymentType: apiPaymentMethod.payment_type,
    };
}

export function mapPaymentMethods(apiPaymentMethods: ApiPaymentMethods): {
    methods: Array<PaymentMethod>;
    defaultId?: string;
} {
    const methods = apiPaymentMethods.payment_methods.map(mapPaymentMethod);
    return { methods, defaultId: apiPaymentMethods.default_payment_method_id };
}
