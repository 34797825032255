import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ResourcesBySku, OverviewResource, ServiceOverviewState } from './types';
import { ServiceOverviewItem } from '../types';
import { Selector } from 'reselect';
import { RootState } from '../../../../../configuration/setup/store';

const initialState: ServiceOverviewState = {
    serviceOverviewList: [],
    cancellationInProgress: [],
    serviceResources: {},
};

export const { reducer: serviceOverviewReducer, actions: serviceOverviewActions } = createSlice({
    name: 'services',
    initialState,
    reducers: {
        setServiceOverviewList: (state: ServiceOverviewState, action: PayloadAction<Array<ServiceOverviewItem>>) => {
            state.serviceOverviewList = action.payload;
        },
        addToSubscriptionCancelInProgress: (state: ServiceOverviewState, action: PayloadAction<Array<string>>) => {
            state.cancellationInProgress.push(...action.payload);
        },
        removeFromSubscriptionCancelInProgress: (state: ServiceOverviewState, action: PayloadAction<Array<string>>) => {
            state.cancellationInProgress = state.cancellationInProgress.filter((id) => !action.payload.includes(id));
        },
        addResourcesForSku: (
            state: ServiceOverviewState,
            action: PayloadAction<{ resources: Array<OverviewResource>; sku: string }>
        ) => {
            state.serviceResources[action.payload.sku] = action.payload.resources;
        },
    },
});

const getBase = (state: RootState): ServiceOverviewState => state.marketplace.services;
export const getServiceOverviewList = (state: RootState) => getBase(state).serviceOverviewList;
export const getCancellingSubscriptions = (state: RootState) => getBase(state).cancellationInProgress;
export const getServiceListResources: Selector<RootState, ResourcesBySku> = (state: RootState) =>
    getBase(state).serviceResources;
