import { Elements } from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';
import React from 'react';
import { PaymentMethodType } from '../../api/paymentMethods/paymentMethodTypes.types';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { PaymentMethod } from './redux/types';
import useDarkMode from '@rio-cloud/rio-uikit/useDarkMode';
import { RootDispatch, RootState } from '../../../../configuration/setup/store';
import { fetchBillingAddressIfNecessaryByTenant } from '../billing/billingAddress.thunk';
import { connect } from 'react-redux';
import { RioPaymentMethod } from './RioPaymentMethod';
import { fetchStripeClientSecretThunk } from './fetchStripeKeys.thunk';
import { publishPaymentMethodThunk } from './paymentMethodsPublish.thunk';
import { getStripeClientSecret } from './redux/paymentMethods.redux';
import { getOptions } from './stripeElementsConfig';
import { BillingAddress } from '../billing/redux/types';
import { getBillingAddress } from '../billing/redux/billing.redux';

export type StripePaymentMethod = 'card' | 'sepa_debit';

interface Props {
    selectedPaymentMethod: StripePaymentMethod;
    stripe: Stripe;
    paymentInformation?: PaymentMethod;
    publishPaymentInformation: (payment: { id?: string; type: PaymentMethodType }) => void;
    stripeClientSecret?: string;
    billingAddress?: BillingAddress;
    fetchStripeClientSecret: () => void;
    fetchBillingAddress: () => void;
}

export const PaymentElementForm: React.FC<Props & WrappedComponentProps> = (props) => {
    const isDarkMode = useDarkMode();
    const stripeOptions = getOptions(props.selectedPaymentMethod, isDarkMode);

    return (
        <div>
            <Elements stripe={props.stripe} options={stripeOptions}>
                <RioPaymentMethod
                    selectedPaymentMethod={props.selectedPaymentMethod}
                    stripe={props.stripe}
                    stripeClientSecret={props.stripeClientSecret}
                    publishPaymentInformation={props.publishPaymentInformation}
                    fetchStripeClientSecret={props.fetchStripeClientSecret}
                    paymentInformation={props.paymentInformation}
                    intl={props.intl}
                    fetchBillingAddress={props.fetchBillingAddress}
                    billingAddress={props.billingAddress}
                />
            </Elements>
        </div>
    );
};

function mapStateToProps(state: RootState) {
    return {
        stripeClientSecret: getStripeClientSecret(state),
        billingAddress: getBillingAddress(state),
    };
}

function mapDispatchToProps(dispatch: RootDispatch) {
    return {
        publishPaymentInformation: ({ type, id }: { type: PaymentMethodType; id?: string }) => {
            dispatch(publishPaymentMethodThunk(type, id));
        },
        fetchBillingAddress: () => dispatch(fetchBillingAddressIfNecessaryByTenant()),
        fetchStripeClientSecret: () => dispatch(fetchStripeClientSecretThunk),
    };
}

export const PaymentElementContainer = connect(mapStateToProps, mapDispatchToProps)(injectIntl(PaymentElementForm));
