import { useIntl } from 'react-intl';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import TableSearch from '@rio-cloud/rio-uikit/lib/es/TableSearch';
import { contractActions, getAssetSearch } from './redux/contract.redux';
import { RootState } from '../../../../configuration/setup/store';

interface Props {
    searchValue: string;
    onSearchChange: (searchValue: string) => void;
}

const ContractsTableSearch = (props: Props) => {
    const { searchValue, onSearchChange } = props;
    const intl = useIntl();

    return (
        <TableSearch
            placeholder={intl.formatMessage({ id: 'marketplace.usage.summary.search.placeholder' })}
            value={searchValue}
            onChange={onSearchChange}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    searchValue: getAssetSearch(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onSearchChange: (searchValue: string) => dispatch(contractActions.setAssetSearch(searchValue)),
});

export const ContractsTableSearchContainer = connect(mapStateToProps, mapDispatchToProps)(ContractsTableSearch);
