import * as t from 'io-ts';
import { reportErrorToSentry } from '../../../configuration/setup/sentry';
import { decodeJson } from './api';

const ProblemJsonCodec = t.intersection([
    t.type({
        title: t.string,
        status: t.number,
    }),
    t.partial({
        detail: t.string,
    }),
]);
export type ProblemJson = t.TypeOf<typeof ProblemJsonCodec>;

function parseProblemJson(text: string) {
    try {
        const parsedJson = JSON.parse(text);
        return decodeJson(ProblemJsonCodec)(parsedJson);
    } catch (e: unknown) {
        reportErrorToSentry(
            new Error(`Response did not contain a valid ProblemJson: ${text}. ${(e as Error).message}`)
        );
    }
    return undefined;
}

export class FetchError extends Error {
    statusCode: number;
    problemJson?: ProblemJson;
    problemJsonText?: string;

    constructor(response: Response, text: string) {
        super(
            `Error during REST call to ${response.url}: ${response.status} ${response.statusText}. Response: ${text}`
        );
        this.statusCode = response.status;
        if (response.headers?.get('content-type')?.startsWith('application/problem+json')) {
            this.problemJson = parseProblemJson(text);
            this.problemJsonText = text;
        }
    }
}
