import { FormattedMessage } from 'react-intl';
import orderBy from 'lodash/orderBy';
import { HistoryEvent } from './redux/types';
import { HistoryItem } from './HistoryItem';
import { HistoryLoadMoreButtonContainer } from './HistoryLoadMoreButtonContainer';
import { HistoryActionInfo } from './HistoryActionInfo';

interface Props {
    historyEvents: Array<HistoryEvent>;
}

export function HistoryTable({ historyEvents }: Props) {
    const orderedByDate = orderBy(historyEvents, [(event: HistoryEvent) => event.timestamp], ['desc']);
    return (
        <div className={'margin-bottom-20'}>
            <table className={'table table-head-filled table-sticky margin-bottom-20'}>
                <colgroup>
                    <col />
                    <col />
                    <col />
                    <col className='width-300' />
                    <col />
                </colgroup>
                <thead>
                    <tr>
                        <th>
                            <FormattedMessage id={'marketplace.history.header.date'} />
                        </th>
                        <th>
                            <FormattedMessage id={'marketplace.history.header.action'} />
                            <HistoryActionInfo />
                        </th>
                        <th>
                            <FormattedMessage id={'marketplace.history.header.service'} />
                        </th>
                        <th>
                            <FormattedMessage id={'marketplace.history.header.details'} />
                        </th>
                        <th>
                            <FormattedMessage id={'marketplace.history.header.triggeredBy'} />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {orderedByDate.map((historyEvent, index) => (
                        <HistoryItem item={historyEvent} key={index} />
                    ))}
                </tbody>
            </table>
            <HistoryLoadMoreButtonContainer />
        </div>
    );
}
