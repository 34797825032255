import { useEffect, useState } from 'react';
import { ResourceSelectionSearchContainer } from './ResourceSelectionSearch';
import { MultiLevelResourceSelectionBookableResourceListContainer } from './MultiLevelResourceSelectionBookableResourceList';
import { ResourceSelectionBookableResourceListContainer } from './ResourceSelectionBookableResourceList';
import { getSelectedProduct } from '../redux/checkout.redux';
import { isMultiLevelProduct } from '../redux/types';
import { connect } from 'react-redux';
import { SelectionMenuContainer } from './SelectionMenuContainer';
import { RootState } from '../../../../configuration/setup/store';

interface Props {
    productIsMultiLevel: boolean;
}

const stickyHeaderClassName =
    'display-flex justify-content-between flex-wrap gap-15 padding-15 ' +
    'padding-bottom-5 position-sticky top-0 z-index-1 bg-white bg-lightest';

function readHeightAndAdaptClassName() {
    return window.innerHeight < 940 ? 'max-height-300' : 'max-height-50vh';
}

export function BookableResourcesSelection(props: Props) {
    const { productIsMultiLevel } = props;
    const [className, setClassName] = useState(readHeightAndAdaptClassName());

    function calculateClassName() {
        const newClassName = readHeightAndAdaptClassName();
        if (newClassName !== className) {
            setClassName(newClassName);
        }
    }

    useEffect(() => {
        window.addEventListener('resize', calculateClassName);
        return () => window.removeEventListener('resize', calculateClassName);
    });

    return (
        <div className={`${className} overflow-auto bg-lightest rounded-bottom-left rounded-bottom-right`}>
            <div className='panel-body padding-0 bg-lightest'>
                <div className={stickyHeaderClassName}>
                    <div className='width-100pct-xs width-100pct-ls width-auto-sm'>
                        <SelectionMenuContainer />
                    </div>
                    <div className='flex-1-1 min-width-200'>
                        <ResourceSelectionSearchContainer />
                    </div>
                </div>
                {productIsMultiLevel ? (
                    <MultiLevelResourceSelectionBookableResourceListContainer />
                ) : (
                    <ResourceSelectionBookableResourceListContainer />
                )}
            </div>
        </div>
    );
}

const mapStateToProps = (state: RootState) => ({
    productIsMultiLevel: isMultiLevelProduct(getSelectedProduct(state)),
});

export const BookableResourcesSelectionContainer = connect(mapStateToProps)(BookableResourcesSelection);
