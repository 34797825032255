import * as t from 'io-ts';
import { DisplayMessages, MessageKey } from '../../../configuration';
import { Period, PeriodUnit } from '../common/termedLevel/types';

const periodRegex = /^P\d+[MYD]$/;
export const PeriodCodec = new t.Type<string, string, unknown>(
    'period',
    (input: unknown): input is string => typeof input === 'string',
    (input, context) =>
        typeof input === 'string' && periodRegex.test(input) ? t.success(input) : t.failure(input, context),
    t.identity
);

export function getPeriodLength(apiLength: string): number {
    return parseInt(apiLength.substr(1, apiLength.length - 2), 10);
}

export function getPeriodUnit(apiLength: string): PeriodUnit {
    switch (apiLength[apiLength.length - 1]) {
        case 'Y':
            return PeriodUnit.YEAR;
        case 'M':
            return PeriodUnit.MONTH;
        case 'D':
            return PeriodUnit.DAY;
        default:
            throw Error(`Invalid contract length unit in ${apiLength}`);
    }
}

export function mapContractPeriod(contractPeriod: string | undefined): Period | undefined {
    if (contractPeriod !== undefined) {
        return {
            length: getPeriodLength(contractPeriod),
            unit: getPeriodUnit(contractPeriod),
        };
    }
    return undefined;
}

export function translatePeriodInProductLevelName(productLevelName: string, displayMessages: DisplayMessages): string {
    const durationUnits = ['days', 'day', 'months', 'month', 'years', 'year'];

    for (let i = 0; i < durationUnits.length; i++) {
        const stringToTestFor = ` ${durationUnits[i]}`;
        if (productLevelName.includes(stringToTestFor)) {
            return productLevelName.replace(
                stringToTestFor,
                ` ${displayMessages[`marketplace.durationUnit.${durationUnits[i]}` as MessageKey]}`
            );
        }
    }
    return productLevelName;
}
