import {
    getAllSelectedResourceIds,
    getAvailableResources,
    getInvalidSelectedResourceIds,
} from './redux/resourceSelection.redux';
import { BookableResource, ProductState } from './redux/types';
import { RootState } from '../../../../configuration/setup/store';

export const NOT_FOUND_MISSING_FEATURE =
    'Vehicle could not be found in the system, please consult the fleet administration';

export function getInvalidSelectedResources(state: RootState): Array<BookableResource> {
    return getInvalidSelectedResourceIds(state)
        .filter(
            (it) =>
                !getAvailableResources(state)
                    .map((resource) => resource.id)
                    .includes(it)
        )
        .map((it) => ({
            id: it,
            name: it,
            bookable: false,
            productState: ProductState.INACTIVE,
            missingRequirements: [[NOT_FOUND_MISSING_FEATURE]],
        }));
}

export function isSelectedFilter(state: RootState) {
    return (it: BookableResource) => getAllSelectedResourceIds(state).includes(it.id);
}
