export function isPresent<T>(t: T | undefined | null | void): t is T {
    return t !== undefined && t !== null;
}

export type UikitOnSelectFn = (value: number | undefined, event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;

// from https://github.com/krzkaczor/ts-essentials
export class UnreachableCaseError extends Error {
    constructor(value: never) {
        super(`Unreachable case: ${value}`);
    }
}
