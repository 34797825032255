/* eslint-disable camelcase */
import * as t from 'io-ts';
import { PartnerConnectionStatus } from '../../customerCenter/partners/redux/types';

const ApiPartnerCodec = t.intersection([t.type({ partner_name: t.string }), t.partial({ display_name: t.string })]);
export const ApiPartnersCodec = t.type({
    items: t.array(ApiPartnerCodec),
});

const ApiPartnerConnectionCodec = t.type({
    id: t.string,
    account_id: t.string,
    partner_name: t.string,
    permission_type: t.string,
    status: t.union([
        t.literal(PartnerConnectionStatus.ACTIVATED),
        t.literal(PartnerConnectionStatus.FAILED_ACTIVATION),
        t.literal(PartnerConnectionStatus.FAILED_DEACTIVATION),
    ]),
    created_at: t.string,
});

export const ApiPartnerConnectionsCodec = t.type({
    items: t.array(ApiPartnerConnectionCodec),
});

export type ApiPartner = t.TypeOf<typeof ApiPartnerCodec>;
export type ApiPartners = t.TypeOf<typeof ApiPartnersCodec>;
export type ApiPartnerConnection = t.TypeOf<typeof ApiPartnerConnectionCodec>;
export type ApiPartnerConnections = t.TypeOf<typeof ApiPartnerConnectionsCodec>;
