import { getMarketplaceBackend } from '../../config/pathURL/config';
import { getRequest } from '../requests';
import { decodeJson, jsonOrReject, onRejected } from '../api';
import { ApiDiscountsCodec } from './discount.types';
import { mapApiDiscounts } from './discountMapper';
import { getParameters } from '../parameterService';
import { Discounts } from '../../common/discount/redux/types';

export function fetchDiscounts(productId?: string, level?: string): Promise<Discounts> {
    // eslint-disable-next-line camelcase
    const params = getParameters({ product_id: productId, level: level });
    return fetch(`${getMarketplaceBackend()}/discounts${params}`, getRequest())
        .then(jsonOrReject)
        .then(decodeJson(ApiDiscountsCodec))
        .then(mapApiDiscounts)
        .catch(onRejected([401]));
}
