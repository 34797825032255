import { ResourceUsage } from '../redux/types';
import isEmpty from 'lodash/isEmpty';

export function calculatePriceForResources(enrichedResourceUsages: Array<ResourceUsage>): number | undefined {
    if (isEmpty(enrichedResourceUsages)) {
        return undefined;
    }
    // allowed because we validate for isNaN in line below
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const triedSum = enrichedResourceUsages
        .map((resourceUsage) => resourceUsage.price! * 100)
        .reduce((a, b) => a + b, 0);
    return isNaN(triedSum) ? undefined : Math.round(triedSum) / 100;
}
