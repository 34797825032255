import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PartnerConnection, PartnersState } from './types';
import { RootState } from '../../../../../configuration/setup/store';

const initialState: PartnersState = {
    connections: [],
    permissions: true,
};

export const { reducer: partnersReducer, actions: partnersActions } = createSlice({
    name: 'partners',
    initialState,
    reducers: {
        setConnections: (state: PartnersState, action: PayloadAction<Array<PartnerConnection>>) => {
            state.connections = action.payload;
        },
        setHasPermissions: (state: PartnersState, action: PayloadAction<boolean>) => {
            state.permissions = action.payload;
        },
    },
});

const getBase = (state: RootState): PartnersState => state.marketplace.partners;

export const getConnections = (state: RootState) => getBase(state).connections;
export const getHasPartnerPermissions = (state: RootState) => getBase(state).permissions;
