import { FreeTrialProduct, FreeTrialState, FreeTrialStatus } from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../configuration/setup/store';
import { differenceInDays } from 'date-fns';

const initialState: FreeTrialState = {
    freeTrialStatus: FreeTrialStatus.INELIGIBLE,
};

export const { reducer: freeTrialReducer, actions: freeTrialActions } = createSlice({
    name: 'freeTrial',
    initialState,
    reducers: {
        setFreeTrialStatus: (state: FreeTrialState, action: PayloadAction<FreeTrialStatus>) => {
            state.freeTrialStatus = action.payload;
        },
        setIsFreeTrialEndDate: (state: FreeTrialState, action: PayloadAction<string | undefined>) => {
            state.freeTrialEndDate = action.payload;
        },
        setFreeTrialProducts: (state: FreeTrialState, action: PayloadAction<Array<FreeTrialProduct> | undefined>) => {
            state.products = action.payload;
        },
    },
});

const getBase = (state: RootState): FreeTrialState => state.marketplace.freeTrial;

export const getFreeTrialStatus = (state: RootState) => getBase(state).freeTrialStatus;
export const getIsIneligibleForFreeTrial = (state: RootState) =>
    getBase(state).freeTrialStatus === FreeTrialStatus.INELIGIBLE;
export const getIsEligibleForFreeTrial = (state: RootState) =>
    getBase(state).freeTrialStatus === FreeTrialStatus.ELIGIBLE;
export const getIsFreeTrialActive = (state: RootState) => getBase(state).freeTrialStatus === FreeTrialStatus.ACTIVE;
export const getFreeTrialEndDate = (state: RootState) => {
    if (!getIsFreeTrialActive(state)) {
        return undefined;
    }
    return getBase(state).freeTrialEndDate;
};

export const isFreeTrialExpiringInLessThan30Days = (state: RootState) => {
    const freeTrialEndDate = getBase(state).freeTrialEndDate;
    if (getIsFreeTrialActive(state) && freeTrialEndDate) {
        return differenceInDays(new Date(freeTrialEndDate), new Date()) <= 30;
    }
    return false;
};
export const getFreeTrialProducts = (state: RootState) => getBase(state).products;

export function isFreeTrialProduct(state: RootState, productId: string): boolean {
    return Boolean(getFreeTrialProducts(state)?.some((p) => p.productId.toLowerCase() === productId.toLowerCase()));
}
