import { setEnforcedLocale } from '../../lang/languageConfiguration';
import { parseQueryString } from '../utils/qsUtil';

function convertToBool(value) {
    return value && value.toLowerCase() === 'true';
}

let enableLATAMToggle = false;
export const isLATAMToggleEnabled = () => enableLATAMToggle;
export const setLATAMToggle = (enabled) => {
    enableLATAMToggle = enabled;
};

let enableProfileToggle = false;
export const isProfileToggleEnabled = () => enableProfileToggle;
export const setProfileToggle = (enabled) => {
    enableProfileToggle = enabled;
};

let enableOrder = false;
export const isOrderEnabled = () => enableOrder;
export const setOrderToggle = (enabled) => {
    enableOrder = enabled;
};

export const initToggles = () => {
    const searchParams = parseQueryString(window.location.search);

    // Feature toggle to test various languages on demand
    setEnforcedLocale(searchParams.enforceLocale);

    // Toggle to show the latam teaser page
    setLATAMToggle(convertToBool(searchParams.enableLatam));

    // Toggle profile tab in customer center
    setProfileToggle(convertToBool(searchParams.pro));

    // Toggle for create order button
    setOrderToggle(convertToBool(searchParams.order));
};
