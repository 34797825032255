import { ProductRatePlan } from '../../customerCenter/serviceOverview/types';
import { isFreeTrialLevel } from '../termedLevel/freeTrialLevel';

export function hasBookableRatePlans(ratePlans: Array<ProductRatePlan> | undefined): boolean {
    if (ratePlans === undefined) {
        return false;
    }
    return ratePlans.some(isRatePlanBookable);
}

export function isRatePlanBookable(ratePlan: Pick<ProductRatePlan, 'legacy' | 'level'>): boolean {
    return !isLegacy(ratePlan) && !isFreeTrial(ratePlan);
}

export function isFreeTrial(ratePlan: Pick<ProductRatePlan, 'level'>): boolean {
    return isFreeTrialLevel(ratePlan.level);
}

export function isLegacy(ratePlan: Pick<ProductRatePlan, 'legacy'> | undefined): boolean {
    return Boolean(ratePlan?.legacy);
}
