import TagList from '@rio-cloud/rio-uikit/lib/es/TagList';
import { ContractSelectionItem } from './ContractSelectionItem';
import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';
import { FormattedMessage } from 'react-intl';
import { MessageKey } from '../../../../../configuration';
import { TermedLevel } from '../types';
import { AssetDiscount } from '../../discount/redux/types';

export const CONTRACT_TAGS_PER_ROW = 3;

const ContractTagList = (props: {
    contracts: Array<TermedLevel>;
    findDiscount?: (assetId: string) => AssetDiscount | undefined;
}) => {
    const { contracts, findDiscount } = props;

    return (
        <TagList className={'BookableAssets margin-5 max-height-25vh overflow-auto'} tagsPerRow={CONTRACT_TAGS_PER_ROW}>
            {contracts.map((contract) => (
                <ContractSelectionItem
                    contract={contract}
                    key={contract.resourceId}
                    discount={findDiscount && findDiscount(contract.resourceId)}
                />
            ))}
            {contracts.length === 0 && (
                <div>
                    <FormattedMessage id={'marketplace.contracts.search.noContractFound'} />
                </div>
            )}
        </TagList>
    );
};

interface Props {
    contracts: Array<TermedLevel>;
    labelId: MessageKey;
    searchTerm: string;
    open: boolean;
    singleContractView: boolean;
    findDiscount?: (assetId: string) => AssetDiscount | undefined;
}

export function ContractList(props: Props) {
    const { contracts, labelId, open, searchTerm, singleContractView, findDiscount } = props;
    if (contracts.length === 0) {
        return null;
    }

    if (singleContractView) {
        return (
            <div className='panel panel-default'>
                <div className='panel-heading'>
                    <FormattedMessage id={labelId} />
                </div>
                <div className='panel-body padding-0'>
                    <ContractTagList contracts={contracts} findDiscount={findDiscount} />
                </div>
            </div>
        );
    }

    const filteredContracts = contracts.filter((contract) =>
        (contract.resourceName?.toLowerCase() || contract.resourceId.toLowerCase()).includes(searchTerm.toLowerCase())
    );
    return (
        <ExpanderPanel
            title={
                <b>
                    <FormattedMessage id={labelId} />
                    {` (${contracts.length})`}
                </b>
            }
            bsStyle={'default'}
            titleClassName={'width-100pct'}
            open={open}
            bodyClassName={'padding-0 padding-top-1'}
        >
            <ContractTagList contracts={filteredContracts} findDiscount={findDiscount} />
        </ExpanderPanel>
    );
}
