import { UsageProduct } from '../../customerCenter/usage/redux/types';
import pipe from 'lodash/fp/pipe';
import sortBy from 'lodash/fp/sortBy';
import groupBy from 'lodash/fp/groupBy';
import values from 'lodash/fp/values';
import map from 'lodash/fp/map';
import { sortRatePlans } from '../productSorter';
import flatten from 'lodash/flatten';

export function sortProducts(products: Array<UsageProduct>): Array<UsageProduct> {
    return pipe(
        sortBy('productName'),
        groupBy((product: UsageProduct) => product.productSku),
        values,
        map((ratePlans: UsageProduct[]) => sortRatePlans(ratePlans)),
        flatten
    )(products);
}
