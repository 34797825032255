import { PartnerConnection } from '../redux/types';
import { FormattedMessage } from 'react-intl';
import { orderBy } from 'lodash';
import { PartnersTableRow } from './PartnersTableRow';

interface Props {
    partnerConnections: Array<PartnerConnection>;
}

export function PartnersTable({ partnerConnections }: Props) {
    const orderedByStatus = orderBy(
        partnerConnections,
        [(connection: PartnerConnection) => connection.status],
        ['asc']
    );
    return (
        <div className={'margin-bottom-20'}>
            <table className={'table table-bordered table-head-filled table-sticky'}>
                <colgroup>
                    <col className='width-400' />
                    <col />
                    <col />
                    <col />
                    <col />
                </colgroup>
                <thead>
                    <tr>
                        <th>
                            <FormattedMessage id={'marketplace.partners.table.header.partner'} />
                        </th>
                        <th>
                            <FormattedMessage id={'marketplace.partners.table.header.status'} />
                        </th>
                        <th>
                            <FormattedMessage id={'marketplace.partners.table.header.createdDate'} />
                        </th>
                        <th>
                            <FormattedMessage id={'marketplace.partners.table.header.privacyPolicy'} />
                        </th>
                        <th>
                            <FormattedMessage id={'marketplace.partners.table.header.action'} />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {orderedByStatus.map((partnerConnection, index) => (
                        <PartnersTableRow item={partnerConnection} key={index} />
                    ))}
                </tbody>
            </table>
        </div>
    );
}
