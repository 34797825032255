/* eslint-disable camelcase */

import * as t from 'io-ts';

export const API_PRODUCT_STATE_ACTIVE = 'active';
export const API_PRODUCT_STATE_INACTIVE = 'inactive';
export const API_PRODUCT_STATE_PENDING_CANCELLATION = 'pending-cancellation';
export const API_PRODUCT_STATE_PENDING_ACTIVATION = 'pending-activation';

export const ApiProductStateCodec = t.union([
    t.literal(API_PRODUCT_STATE_ACTIVE),
    t.literal(API_PRODUCT_STATE_INACTIVE),
    t.literal(API_PRODUCT_STATE_PENDING_CANCELLATION),
    t.literal(API_PRODUCT_STATE_PENDING_ACTIVATION),
]);
export type ApiProductState = t.TypeOf<typeof ApiProductStateCodec>;

export const ApiActivationCodec = t.intersection([
    t.type({
        resource_id: t.string,
        resource_type: t.string,
        activation_state: ApiProductStateCodec,
    }),
    t.partial({
        resource_name: t.string,
        origin_level: t.string,
        target_level: t.string,
    }),
]);

export const ApiActivationsCodec = t.type({
    activations: t.array(ApiActivationCodec),
});
export type ApiActivations = t.TypeOf<typeof ApiActivationsCodec>;
