import { connect } from 'react-redux';
import { HistoryTable } from './HistoryTable';
import {
    getHistoryEvents,
    getHistoryFromDate,
    getHistoryResourceSearch,
    getHistorySelectedProductIds,
    getHistoryToDate,
    historyActions,
} from './redux/history.redux';
import { historyThunk } from './history.thunk';
import { getHasError, getIsLoading } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import { HistoryEvent } from './redux/types';
import { FormattedMessage } from 'react-intl';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import { HistoryTableToolbar } from './HistoryTableToolbar';
import { RootDispatch, RootState } from '../../../../configuration/setup/store';
import { isServiceVisible } from '../../common/utils/isServiceVisible';
import EmptyState from '@rio-cloud/rio-uikit/lib/es/EmptyState';
import ErrorState from '@rio-cloud/rio-uikit/lib/es/ErrorState';

interface HistoryMapStateToProps {
    historyEvents: Array<HistoryEvent>;
    fetchInProgress: boolean;
    hasError: boolean;
    fromDate?: string;
    toDate?: string;
    resourceSearch?: string;
    productIds?: Array<string>;
}

interface HistoryMapDispatchToProps {
    fetchHistory: (nextId?: string) => void;
    clearHistory: () => void;
    clearHistoryEvents: () => void;
}

type Props = HistoryMapStateToProps & HistoryMapDispatchToProps;

function HistoryWrapper({
    clearHistory,
    clearHistoryEvents,
    fetchHistory,
    fetchInProgress,
    hasError,
    historyEvents,
    fromDate,
    toDate,
    resourceSearch,
    productIds,
}: Props) {
    useEffect(() => {
        clearHistoryEvents();
        fetchHistory();
    }, [fromDate, toDate, productIds, resourceSearch]);

    useEffect(() => () => clearHistory(), []);

    let content;

    if (hasError) {
        content = <ErrorState headline={<FormattedMessage id={'marketplace.history.fetch.error'} />} />;
    } else if (fetchInProgress && isEmpty(historyEvents)) {
        content = <Spinner text={<FormattedMessage id={'marketplace.history.loading'} />} isInverse={false} />;
    } else if (!historyEvents.length) {
        content = (
            <EmptyState
                headline={<FormattedMessage id={'marketplace.history.not.found'} />}
                outerClassName='margin-top-15pct'
            />
        );
    } else {
        content = <HistoryTable historyEvents={historyEvents} />;
    }

    return (
        <div className='History container-fluid fluid-default'>
            <HistoryTableToolbar />
            {content}
        </div>
    );
}

export function mapStateToProps(state: RootState): HistoryMapStateToProps {
    return {
        historyEvents: getHistoryEvents(state).filter((event) => isServiceVisible({ name: event.productName })),
        fetchInProgress: getIsLoading(state, ApiCalls.HISTORY_EVENTS),
        hasError: getHasError(state, ApiCalls.HISTORY_EVENTS),
        fromDate: getHistoryFromDate(state),
        toDate: getHistoryToDate(state),
        resourceSearch: getHistoryResourceSearch(state),
        productIds: getHistorySelectedProductIds(state),
    };
}

export function mapDispatchToProps(dispatch: RootDispatch): HistoryMapDispatchToProps {
    return {
        fetchHistory: async (nextId?: string) => {
            await dispatch(historyThunk(undefined, nextId));
        },
        clearHistory: () => dispatch(historyActions.clearHistory()),
        clearHistoryEvents: () => dispatch(historyActions.clearHistoryEvents()),
    };
}

export const HistoryContainer = connect(mapStateToProps, mapDispatchToProps)(HistoryWrapper);
