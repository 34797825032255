import { RootDispatch, RootState } from '../../../../configuration/setup/store';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { connect, useSelector } from 'react-redux';
import { getAccountId } from '../../../../configuration';
import { getIsLoading } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import { activateFreeTrialThunk } from '../activateFreeTrial.thunk';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import withPermissionValidator from '../../common/permissions/withPermissionValidator';
import LoadingErrorState from '../../common/error/LoadingErrorState';
import { SUBSCRIPTION_WRITE } from '../../common/permissions/permissions';
import classnames from 'classnames';
import { OverlayTrigger, Tooltip } from '../../common/utils/tooltipReExports';
import { DEFAULT_TOOLTIP_DELAY } from '../../config/config';

interface Props {
    loading: boolean;
    activateFreeTrial: () => void;
    trackVisibility: boolean;
    userHasPermissions: (requiredPermissions: string[]) => boolean;
}

export function FreeTrialActivationButton({ loading, userHasPermissions, activateFreeTrial, trackVisibility }: Props) {
    type GoogleAnalyticsEventAction = 'activateFreeTrial';
    const accountId = useSelector(getAccountId);
    const getTrackingAttributes = (action: GoogleAnalyticsEventAction, trigger: 'click' | 'visibility') => ({
        'data-track-ga-event-trigger': trigger,
        'data-track-ga-event-category': 'marketplace',
        'data-track-ga-event-action': `${action}_${trigger}`,
        'data-track-ga-event-label': `accountId::${accountId}`,
    });

    const optionalDisabledClass = loading ? 'disabled' : '';
    const buttonLabel = loading ? (
        <Spinner text={<FormattedMessage id={'marketplace.loading'} />} isInverse={false} />
    ) : (
        <span>
            <FormattedMessage id={'marketplace.freeTrial.button.activate'} />
        </span>
    );
    const allowActivate = userHasPermissions([SUBSCRIPTION_WRITE]);
    const className = classnames(
        'scale-150 transform-origin-top-left',
        optionalDisabledClass,
        allowActivate ? 'cursor-pointer' : 'cursor-not-allowed'
    );

    const button = (
        <div className={className} {...(trackVisibility && getTrackingAttributes('activateFreeTrial', 'visibility'))}>
            <button
                type='button'
                className='btn btn-secondary'
                disabled={loading || !allowActivate}
                onClick={activateFreeTrial}
                {...getTrackingAttributes('activateFreeTrial', 'click')}
            >
                {buttonLabel}
            </button>
        </div>
    );

    if (allowActivate) {
        return button;
    }
    return (
        <OverlayTrigger
            trigger={OverlayTrigger.TRIGGER_HOVER}
            placement='top'
            overlay={
                <Tooltip id='tooltip'>
                    <FormattedMessage id='marketplace.unauthorized.dialog.message' />
                </Tooltip>
            }
            delay={DEFAULT_TOOLTIP_DELAY}
        >
            {button}
        </OverlayTrigger>
    );
}

const mapStateToProps = (state: RootState) => ({
    loading: getIsLoading(state, ApiCalls.ACTIVATE_FREE_TRIAL),
});

const mapDispatchToProps = (dispatch: RootDispatch) => ({
    activateFreeTrial: () => dispatch(activateFreeTrialThunk),
});

export const FreeTrialActivationButtonContainer = compose<React.ComponentType<{ trackVisibility: boolean }>>(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissionValidator(LoadingErrorState)
)(FreeTrialActivationButton);
