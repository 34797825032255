import { HistoryAction } from './redux/types';
import { MessageKey } from '../../../../configuration';
import { UnreachableCaseError } from '../../../../utils/typescriptUtil';

// Used for info popup
export const historyActionInfoMessageKeys = [
    'marketplace.history.action.subscription.created',
    'marketplace.history.action.subscription.canceled',
    'marketplace.history.action.resource.added',
    'marketplace.history.action.resource.removed',
    'marketplace.history.action.resource.activated',
    'marketplace.history.action.resource.deactivated',
    'marketplace.history.action.contract.renews.to.changed',
    'marketplace.history.action.contract.renewed',
    'marketplace.history.action.contract.ended',
] as const;

// eslint-disable-next-line complexity
function actionToMessage(action: HistoryAction, resourceType?: string): MessageKey {
    switch (action) {
        case HistoryAction.SERVICE_CREATED:
            return 'marketplace.history.action.subscription.created';
        case HistoryAction.SERVICE_CANCELLED:
            return 'marketplace.history.action.subscription.canceled';
        case HistoryAction.CONTRACT_RENEWS_TO_CHANGED:
            return 'marketplace.history.action.contract.renews.to.changed';
        case HistoryAction.CONTRACT_RENEWED:
            return 'marketplace.history.action.contract.renewed';
        case HistoryAction.CONTRACT_ENDED:
            return 'marketplace.history.action.contract.ended';
        case HistoryAction.RESOURCE_ACTIVATION_REQUESTED:
            return resourceType === 'user'
                ? 'marketplace.history.action.resource.added.user'
                : 'marketplace.history.action.resource.added.asset';
        case HistoryAction.RESOURCE_DEACTIVATION_REQUESTED:
            return resourceType === 'user'
                ? 'marketplace.history.action.resource.removed.user'
                : 'marketplace.history.action.resource.removed.asset';
        case HistoryAction.RESOURCE_ACTIVATED:
            return resourceType === 'user'
                ? 'marketplace.history.action.resource.activated.user'
                : 'marketplace.history.action.resource.activated.asset';
        case HistoryAction.RESOURCE_DEACTIVATED:
            return resourceType === 'user'
                ? 'marketplace.history.action.resource.deactivated.user'
                : 'marketplace.history.action.resource.deactivated.asset';
        default:
            throw new UnreachableCaseError(action);
    }
}

export const getActionMessage = (action?: HistoryAction, resourceType?: string): MessageKey | undefined => {
    return action && actionToMessage(action, resourceType);
};
