import { connect } from 'react-redux';
import { getUsageSummaryColumns } from './usageSummaryColumnService';
import { UsageSummaryColumn, UsageSummaryColumnType } from './types';
import { RootState } from '../../../../../configuration/setup/store';

interface TableColGroupsProps {
    columns: Array<UsageSummaryColumn>;
}

const UsageSummaryTableColGroups = (props: TableColGroupsProps) => {
    const { columns } = props;
    return (
        <colgroup>
            {columns.map((_, index) => {
                return <col key={index} />;
            })}
        </colgroup>
    );
};

const mapStateToResourceProps = (): TableColGroupsProps => ({
    columns: [{ type: UsageSummaryColumnType.RESOURCE }],
});
const mapStateToUsagesProps = (state: RootState): TableColGroupsProps => ({
    columns: getUsageSummaryColumns(state),
});
const mapStateToPriceProps = (): TableColGroupsProps => ({
    columns: [{ type: UsageSummaryColumnType.PRICE }],
});

export const UsageSummaryTableResourceColGroupsContainer = connect(mapStateToResourceProps)(UsageSummaryTableColGroups);
export const UsageSummaryTableUsagesColGroupsContainer = connect(mapStateToUsagesProps)(UsageSummaryTableColGroups);
export const UsageSummaryTablePriceColGroupsContainer = connect(mapStateToPriceProps)(UsageSummaryTableColGroups);
