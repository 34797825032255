import { formatDurationUnit } from '../../common/utils/formatDurationUnit';
import { useIntl } from 'react-intl';
import { TermedLevel } from '../../common/termedLevel/types';
import { DATE_FORMAT } from '../../common/utils/dateUtil';

export function ContractDuration(props: { contract: TermedLevel }) {
    const intl = useIntl();
    return (
        <>
            <div>{`${props.contract.periodLength} ${formatDurationUnit(props.contract.periodUnit, intl)}`}</div>
            {props.contract.startsAt && props.contract.endsAt && (
                <div className='text-color-gray'>
                    {intl.formatDate(props.contract.startsAt, DATE_FORMAT)} -{' '}
                    {intl.formatDate(props.contract.endsAt, DATE_FORMAT)}
                </div>
            )}
        </>
    );
}
