export enum ApiCalls {
    DISCOUNTS = 'discounts',
    HISTORY_EVENTS = 'historyEvents',
    USAGE_EXPORT = 'usageExport',
    USAGE_SUMMARY = 'usageSummary',
    INVOICES_GET = 'invoicesGet',
    INVOICE_DOWNLOAD = 'invoiceDownload',
    PAYMENT_METHODS_GET = 'paymentMethodsGet',
    PAYMENT_METHODS_TYPES_GET = 'paymentMethodTypesGet',
    PAYMENT_METHODS_POST = 'paymentMethodPost',
    PAYMENT_SECRET = 'paymentSecret',
    FAILED_PAYMENTS = 'failedPayments',
    ACTIVATION_SUMMARY = 'activationSummary',
    SUBSCRIPTION_CANCEL = 'subscriptionCancel',
    SUBSCRIPTION_CREATE = 'subscriptionCreate',
    CONTRACT_UPDATE = 'contractUpdate',
    TERMED_LEVEL_GET = 'termedLevelGet',
    CONTRACT_PRODUCT = 'contractProduct',
    CONTRACT_BOOKABLE = 'contractBookable',
    SERVICE_RESOURCES = 'serviceResources',
    ASSET_OVERVIEW_PRODUCTS = 'assetOverviewProducts',
    ASSET_OVERVIEW_INFO = 'assetOverviewInfo',
    ASSET_OVERVIEW_TAGS = 'assetOverviewTags',
    ASSET_OVERVIEW_ASSET_INFO = 'assetOverviewAssetInfo',
    RESOURCE_SELECTION = 'resourceSelection',
    CHECKOUT_PRODUCT_DETAILS = 'checkoutProductDetails',
    BILLING_ADDRESS = 'billing_address',
    STRIPE_API_KEY = 'stripeApiKey',
    STRIPE_CLIENT_SECRET = 'stripeClientSecret',
    PERMISSIONS = 'permissions',
    PARTNER_CONNECTION_GET = 'partnerConnectionsGet',
    PARTNER_CONNECTION_DELETE = 'partnerConnectionDelete',
    FREE_TRIAL_GET = 'freeTrialGet',
    ACTIVATE_FREE_TRIAL = 'activateFreeTrial',
}

export interface ApiLoadingState {
    loading: { [key: string]: boolean };
    error: { [key: string]: boolean };
}

export interface SetLoadingPayload {
    type: ApiCalls;
    loading?: boolean;
    error?: boolean;
}
