import { Component } from 'react';
import { connect } from 'react-redux';
import ReauthenticationPage from './ReauthenticationPage';
import { PAYMENT_CODE_REQUIRES_AUTHENTICATION, PAYMENT_CODES_REQUIRES_PAYMENT_METHOD } from './payment';
import PropTypes from 'prop-types';
import { getHasError, getIsLoading } from '../api/redux/api.redux';
import { ApiCalls } from '../api/redux/types';
import { getFailedPayments, getPaymentSecret } from './redux/payments.redux';
import { fetchFailedPaymentsThunk, fetchPaymentSecretThunk } from './payment.thunk';

export class ReauthenticationPageContainer extends Component {
    constructor(props) {
        super(props);
        this.performAuthentication = this.performAuthentication.bind(this);
        this.state = {
            didFetchFailedPayments: false,
            error: undefined,
        };
    }

    componentDidMount() {
        if (!this.props.failedPayment) {
            this.props.fetchFailedPayments();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.finishedFetchingFailedPayments(prevProps)) {
            if (this.props.failedPayment) {
                this.props.fetchPaymentSecret(this.props.failedPayment.id);
            }
            this.setState({ didFetchFailedPayments: true });
        }
    }

    finishedFetchingFailedPayments(prevProps) {
        return prevProps.failedPaymentsFetchInProgress && !this.props.failedPaymentsFetchInProgress;
    }

    isDataLoaded() {
        if (!this.state.didFetchFailedPayments) {
            return false;
        }

        if (!this.props.failedPayment) {
            return true;
        }

        return !!this.props.paymentSecret;
    }

    fetchPaymentIntent() {
        return this.props.stripe.retrievePaymentIntent(this.props.paymentSecret);
    }

    requiresPaymentMethod(paymentIntent) {
        return (
            paymentIntent.status === PAYMENT_CODES_REQUIRES_PAYMENT_METHOD.REQUIRES_SOURCE ||
            paymentIntent.status === PAYMENT_CODES_REQUIRES_PAYMENT_METHOD.REQUIRES_PAYMENT_METHOD
        );
    }

    addPaymentMethod(data, paymentIntent) {
        try {
            const paymentMethodId = paymentIntent.last_payment_error.payment_method.id;
            return {
                ...data,
                // eslint-disable-next-line camelcase
                payment_method: paymentMethodId,
            };
        } catch (error) {
            this.setState(error);
            throw error;
        }
    }

    performAuthentication() {
        return this.fetchPaymentIntent().then(({ paymentIntent }) => {
            let data = {};
            if (paymentIntent && this.requiresPaymentMethod(paymentIntent)) {
                data = this.addPaymentMethod(data, paymentIntent);
            }

            return this.props.stripe
                .handleCardPayment(this.props.paymentSecret, data)
                .then((resultingPaymentIntent) => {
                    if (resultingPaymentIntent.error) {
                        const error = Error(
                            `Payment authentication failed: ${JSON.stringify(resultingPaymentIntent.error)}`
                        );
                        error.stripeMessage = resultingPaymentIntent.error.message;
                        throw error;
                    }
                });
        });
    }

    render() {
        return (
            <ReauthenticationPage
                isFetchingData={!this.isDataLoaded()}
                hasError={this.props.hasError || !!this.state.error}
                performAuthentication={this.performAuthentication}
                failedPayment={this.props.failedPayment}
            />
        );
    }
}

export function extractPaymentToAuthenticate(failedPayments) {
    return (
        failedPayments &&
        failedPayments.find((payment) => payment.status && codeMatchesRequiresAuthentication(payment.status.code))
    );
}

export function codeMatchesRequiresAuthentication(code) {
    return (
        code === PAYMENT_CODE_REQUIRES_AUTHENTICATION ||
        code === PAYMENT_CODES_REQUIRES_PAYMENT_METHOD.REQUIRES_SOURCE ||
        code === PAYMENT_CODES_REQUIRES_PAYMENT_METHOD.REQUIRES_PAYMENT_METHOD
    );
}

export function mapStateToProps(state) {
    const failedPayments = getFailedPayments(state);
    return {
        paymentSecret: getPaymentSecret(state),
        failedPayment: extractPaymentToAuthenticate(failedPayments),
        failedPaymentsFetchInProgress: getIsLoading(state, ApiCalls.FAILED_PAYMENTS),
        hasError: getHasError(state, ApiCalls.FAILED_PAYMENTS),
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        fetchFailedPayments: () => {
            dispatch(fetchFailedPaymentsThunk);
        },
        fetchPaymentSecret: (paymentId) => {
            dispatch(fetchPaymentSecretThunk(paymentId));
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReauthenticationPageContainer);

ReauthenticationPageContainer.propTypes = {
    hasError: PropTypes.bool,
    failedPayment: PropTypes.shape({
        id: PropTypes.string,
        status: PropTypes.string,
        timestamp: PropTypes.string,
        amount: PropTypes.string,
        currency: PropTypes.string,
    }),
    paymentSecret: PropTypes.string,
    failedPaymentsFetchInProgress: PropTypes.bool,
    fetchFailedPayments: PropTypes.func,
    fetchPaymentSecret: PropTypes.func,
    stripe: PropTypes.shape({
        retrievePaymentIntent: PropTypes.func,
        handleCardPayment: PropTypes.func,
    }),
};
