import { ProductRatePlan } from '../types';
import { isFreeTrialLevel } from '../../../common/termedLevel/freeTrialLevel';
import { isPresent } from '../../../../../utils/typescriptUtil';
import flatten from 'lodash/flatten';

export function findSmallestNonFreeTrialLevel(tabList: ProductRatePlan[][]): string {
    const levels = flatten(tabList)
        .map((productRatePlan) => productRatePlan.level)
        .filter((level) => !isFreeTrialLevel(level))
        .filter(isPresent);
    return levels.sort()[0];
}
