import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getUsageOverallCurrency } from '../redux/usage.redux';
import { formatPrice } from '../../../common/utils/currencyUtil';
import { RootState } from '../../../../../configuration/setup/store';
import { getSearchedResourceSummaries } from './usageSummaryItemFilterService';
import { createSelector } from 'reselect';
import { calculatePriceForResources } from './calculatePriceForResources';

interface UsageSummaryTableRowProps {
    overallCurrency: string | undefined;
    totalSum: number | undefined;
}

const UsageSummaryTotalPrice = (props: UsageSummaryTableRowProps) => {
    const { overallCurrency, totalSum } = props;
    const intl = useIntl();
    if (totalSum === undefined || !overallCurrency) {
        return null;
    }
    return (
        <div className='text-bold text-size-20 margin-bottom-10'>
            {`${intl.formatMessage({ id: 'marketplace.usage.summary.totalSum' })}: ${formatPrice(
                intl,
                totalSum,
                overallCurrency
            )}`}
            * {/* Reference to list-price explanation */}
        </div>
    );
};

const calculateTotalSum = createSelector(getSearchedResourceSummaries, calculatePriceForResources);

const mapStateToProps = (state: RootState): UsageSummaryTableRowProps => ({
    overallCurrency: getUsageOverallCurrency(state),
    totalSum: calculateTotalSum(state),
});

export const UsageSummaryTotalPriceContainer = connect(mapStateToProps)(UsageSummaryTotalPrice);
