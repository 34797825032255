import { useLocation } from 'react-router';
import { AssetOverviewBottomBarContainer } from './assetOverview/AssetOverviewBottomBarContainer';
import { CustomerCenterTab } from './types';
import { getCustomerCenterPath } from '../common/routes';

export function useMarketplaceBottomBar(): JSX.Element | null {
    const { pathname } = useLocation();
    const assetOverviewPath = getCustomerCenterPath(CustomerCenterTab.ASSETS);
    if (pathname === assetOverviewPath) {
        return <AssetOverviewBottomBarContainer />;
    }
    // Hook is necessary because the ApplicationLayoutBody adds an 'has-footer' css class
    // if a truthy React component is passed as bottomBar.
    return null;
}
