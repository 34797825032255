import { MARKETPLACE_START_FREE_TRIAL } from '../common/routes';
import { getPopupWindowProps } from '../common/popupService';
import { getWindowScreen } from '../common/screenWrapper';
import { FormattedMessage } from 'react-intl';
import 'iframe-resizer/js/iframeResizer.contentWindow'; // Required for resizing when included as iframe
import { getStartFreeTrialTrackingAttributes } from '../common/utils/googleTagManagerAttributes';
export function FreeTrialButton() {
    const targetPath = `/${MARKETPLACE_START_FREE_TRIAL}`;
    const windowId = 'free-trial';
    const { windowName, sizeString } = getPopupWindowProps(getWindowScreen(), windowId, 'freeTrialButton');

    return (
        <div className={'buyButtonLink'} {...getStartFreeTrialTrackingAttributes('visibility')}>
            <a
                className={'btn btn-secondary text-size-18'}
                onClick={() => window.open(targetPath, windowName, sizeString)}
                {...getStartFreeTrialTrackingAttributes('click')}
            >
                <FormattedMessage id={'marketplace.freeTrial.button'} />
            </a>
        </div>
    );
}
