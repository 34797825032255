import { getTypo3IconName } from '../../config/typo3ProductNameMapping';
import pattern from '../style/images/pattern.svg';

export const ProductIcon = ({ productId, className }: { productId: string; className: string }) => {
    const iconName = getTypo3IconName(productId);
    let url = pattern;
    if (iconName) {
        url = `https://rio.cloud/fileadmin/Marketplace/AppIcons/${iconName}.svg`;
    }
    return <img className={`ProductIcon ${className}`} src={url} alt={productId} />;
};
