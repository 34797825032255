import { ResourceSelectionItemContainer } from './ResourceSelectionItem';
import { Dispatch } from 'redux';
import { resourceSelectionActions, getSelectedResourceIds } from './redux/resourceSelection.redux';
import { connect } from 'react-redux';
import TagList from '@rio-cloud/rio-uikit/lib/es/TagList';
import { RESOURCE_SELECTION_TAGS_PER_ROW } from './ResourceSelectionListContainer';
import { getFilteredGroupedResources } from './resourceSelectionSearchService';
import { BookableResource } from './redux/types';
import { RootState } from '../../../../configuration/setup/store';
import { ProductTypeAwareFormattedMessage } from './ProductTypeAwareFormattedMessage';

interface Props {
    bookableFilteredResources: Array<BookableResource>;
    handleSelection: (resourceId: string) => void;
    selectedResourceIds: Array<string>;
}

const ResourceSelectionBookableResourceList = (props: Props) => {
    const { bookableFilteredResources, handleSelection, selectedResourceIds } = props;

    if (bookableFilteredResources.length === 0) {
        return (
            <div className='text-danger margin-15'>
                <ProductTypeAwareFormattedMessage id='noSearchedResourcesFound' />
            </div>
        );
    }

    return (
        <TagList className={'margin-5'} tagsPerRow={RESOURCE_SELECTION_TAGS_PER_ROW}>
            {bookableFilteredResources.map((resource) => {
                return (
                    <ResourceSelectionItemContainer
                        resource={resource}
                        onSelect={(selectedItem) => handleSelection(selectedItem)}
                        key={resource.id}
                        isSelected={selectedResourceIds.includes(resource.id)}
                    />
                );
            })}
        </TagList>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        bookableFilteredResources: getFilteredGroupedResources(state).bookable,
        selectedResourceIds: getSelectedResourceIds(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        handleSelection: (id: string) => dispatch(resourceSelectionActions.selectResource(id)),
    };
};

export const ResourceSelectionBookableResourceListContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ResourceSelectionBookableResourceList);
