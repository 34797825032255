import StatsWidgets from '@rio-cloud/rio-uikit/lib/es/StatsWidgets';
import StatsWidget from '@rio-cloud/rio-uikit/lib/es/StatsWidget';
import StatsWidgetNumber from '@rio-cloud/rio-uikit/lib/es/StatsWidgetNumber';
import { connect } from 'react-redux';
import {
    endOfNextMonth,
    endOfThisMonth,
    getExpiresNextMonth,
    getExpiresThisMonth,
    startOfNextMonth,
    today,
} from './redux/contractOverviewService';
import { contractActions } from './redux/contract.redux';
import { Dispatch } from 'redux';
import { FormattedMessage } from 'react-intl';
import { RootState } from '../../../../configuration/setup/store';
import { getContracts } from '../../common/termedLevel/redux/termedLevelRenewal.redux';

interface Props {
    thisMonth: number;
    nextMonth: number;
    activeContracts: number;
    filterThisMonth: () => void;
    filterNextMonth: () => void;
    filterActiveContracts: () => void;
}

export function ContractStatsWidgets(props: Props) {
    return (
        <div className={'margin-bottom-20'}>
            <StatsWidgets className={'display-flex justify-content-between'}>
                <StatsWidget className={'flex-1-1-0 shadow-default'}>
                    <StatsWidget.Body className={'padding-0'}>
                        <StatsWidgetNumber
                            className={
                                'text-color-danger width-100pct margin-0 padding-top-10 padding-left-20 padding-right-20 padding-bottom-20'
                            }
                            value={props.thisMonth}
                            clickable={true}
                            onClick={props.filterThisMonth}
                            label={<FormattedMessage id={'marketplace.contracts.expiresThisMonth'} />}
                        />
                    </StatsWidget.Body>
                </StatsWidget>
                <StatsWidget className={'flex-1-1-0 shadow-default'}>
                    <StatsWidget.Body className={'padding-0'}>
                        <StatsWidgetNumber
                            className={
                                'text-color-warning width-100pct margin-0 padding-top-10 padding-left-20 padding-right-20 padding-bottom-20'
                            }
                            value={props.nextMonth}
                            clickable={true}
                            onClick={props.filterNextMonth}
                            label={<FormattedMessage id={'marketplace.contracts.expiresNextMonth'} />}
                        />
                    </StatsWidget.Body>
                </StatsWidget>
                <StatsWidget className={'flex-1-1-0 shadow-default'}>
                    <StatsWidget.Body className={'padding-0'}>
                        <StatsWidgetNumber
                            className={
                                'text-color-success width-100pct margin-0 padding-top-10 padding-left-20 padding-right-20 padding-bottom-20'
                            }
                            value={props.activeContracts}
                            clickable={true}
                            onClick={props.filterActiveContracts}
                            label={<FormattedMessage id={'marketplace.contracts.activeContracts'} />}
                        />
                    </StatsWidget.Body>
                </StatsWidget>
            </StatsWidgets>
        </div>
    );
}

const filterDates = (from: Date, to: Date, dispatch: Dispatch) => {
    dispatch(contractActions.setFromDate(from.toISOString()));
    dispatch(contractActions.setToDate(to.toISOString()));
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    filterThisMonth: () => filterDates(today(), endOfThisMonth(), dispatch),
    filterNextMonth: () => filterDates(startOfNextMonth(), endOfNextMonth(), dispatch),
    filterActiveContracts: () => {
        dispatch(contractActions.setAssetSearch(''));
        dispatch(contractActions.setFromDate(undefined));
        dispatch(contractActions.setToDate(undefined));
    },
});

const mapStateToProps = (state: RootState) => ({
    thisMonth: getExpiresThisMonth(state),
    nextMonth: getExpiresNextMonth(state),
    activeContracts: getContracts(state).length,
});

export const ContractStatsWidgetsContainer = connect(mapStateToProps, mapDispatchToProps)(ContractStatsWidgets);
