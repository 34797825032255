import { useState } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { ServiceOverviewItem } from '../types';
import { OverviewResource } from '../redux/types';
import { CancelResourceSubscriptionsDialog } from './CancelResourceSubscriptionsDialog';
import { isSubscriptionCancelling } from '../services/subscriptionService';
import { connect } from 'react-redux';
import ButtonDropdown from '@rio-cloud/rio-uikit/lib/es/ButtonDropdown';
import { openCheckoutPopup } from '../services/checkoutService';
import { isPresent, UikitOnSelectFn } from '../../../../../utils/typescriptUtil';
import { GtmPromotion } from '../services/gtmPromotion';
import { getProductLabel } from '../../../common/utils/productLabelUtil';
import { isProductInMaintenanceMode } from '../../../../../configuration/featureToggle/featureToggleService';
import { RootState } from '../../../../../configuration/setup/store';
import { isRatePlanBookable } from '../../../common/product/productService';
import { ProductType } from '../../../common/product/product';
import type { MenuItemProps as MenuItem } from '@rio-cloud/rio-uikit/lib/es/components/menuItems/MenuItem';

interface Props {
    service: ServiceOverviewItem;
    currentLevel?: string;
    isCurrentlyProcessingCancellation: (sku: string) => boolean;
    handleConfirmUpdate: () => void;
    selectedResources: Array<OverviewResource>;
    wouldCancelService: boolean;
    serviceName: string;
    isProductInMaintenance: (productId?: string) => boolean;
}

const actionItemClassName = 'white-space-nowrap';

function getUpgradeDowngradeLabel(isUpgrade: boolean, intl: IntlShape, levelName: string) {
    return (
        <div className={actionItemClassName}>
            <span
                className={`margin-right-5 rioglyph rioglyph-arrow-${isUpgrade ? 'up' : 'down'}`}
                aria-hidden='true'
            />
            <span>{`${
                isUpgrade
                    ? `${intl.formatMessage({ id: 'marketplace.services.upgrade' })}:`
                    : `${intl.formatMessage({ id: 'marketplace.services.downgrade' })}:`
            } ${levelName}`}</span>
        </div>
    );
}

function CancelLabel() {
    return (
        <div className={actionItemClassName}>
            <span className='margin-right-5 rioglyph rioglyph-trash text-color-danger' aria-hidden='true' />
            <span>
                <FormattedMessage id={'marketplace.myServices.subscription.cancel.button'} />
            </span>
        </div>
    );
}

export function SelectionActions(props: Props) {
    const intl = useIntl();
    const {
        service,
        currentLevel,
        isCurrentlyProcessingCancellation,
        handleConfirmUpdate,
        selectedResources,
        wouldCancelService,
        serviceName,
        isProductInMaintenance,
    } = props;
    const [showDialog, setShowDialog] = useState(false);

    if (!service.hasRatePlan) {
        return null;
    }

    const openUpDowngradePopup = (promotion: GtmPromotion, level?: string) => {
        const { sku, hasRatePlan, legacy, productRatePlans } = service;
        if (!hasRatePlan || legacy) {
            return () => {
                throw new Error();
            };
        }
        const levelExtra = productRatePlans && productRatePlans.length > 1 ? level : undefined;
        const openCheckout = openCheckoutPopup(
            sku,
            promotion,
            levelExtra,
            selectedResources.map((it) => it.id)
        ).callback;

        return openCheckout as unknown as UikitOnSelectFn;
    };
    const upgradeDowngradeActions = (service.productRatePlans ?? [])
        .filter((ratePlan) => ratePlan.level !== currentLevel)
        .filter(isRatePlanBookable)
        .filter((ratePlan) => isPresent(ratePlan))
        .map((ratePlan) => {
            const isUpgrade = (ratePlan.level ?? '0') > (currentLevel ?? '0');
            const action: MenuItem = {
                value: getUpgradeDowngradeLabel(isUpgrade, intl, getProductLabel(ratePlan, intl)),
                onSelect: isUpgrade
                    ? openUpDowngradePopup(GtmPromotion.customerCenterUpgrade, ratePlan.level)
                    : openUpDowngradePopup(GtmPromotion.customerCenterDowngrade, ratePlan.level),
            };
            return action;
        });
    const cancelAction: MenuItem = {
        value: <CancelLabel />,
        onSelect: () => setShowDialog(true),
    };
    const actionItems: Array<MenuItem> = [...upgradeDowngradeActions, cancelAction];

    if (isCurrentlyProcessingCancellation(service.sku) || selectedResources.length === 0) {
        const noSelectionTranslationKey =
            service.productType === ProductType.USER_BASED
                ? 'marketplace.services.users.noSelection.dropdown'
                : 'marketplace.services.noSelection.dropdown';
        const selectionButtonLabel = intl.formatMessage({ id: noSelectionTranslationKey });

        return actionItems.length === 1 ? (
            <span title={selectionButtonLabel}>
                <button className={'btn btn-danger btn-outline'} disabled>
                    <CancelLabel />
                </button>
            </span>
        ) : (
            <ButtonDropdown title={selectionButtonLabel} items={actionItems} disabled />
        );
    }
    const useSelectionFor = intl.formatMessage({ id: 'marketplace.services.selection.dropdown' });
    return (
        <div>
            {actionItems.length === 1 ? (
                <button className={'btn btn-danger btn-outline'} onClick={() => setShowDialog(true)}>
                    <span className={'margin-right-5'}>({selectedResources.length})</span>
                    <CancelLabel />
                </button>
            ) : (
                <ButtonDropdown
                    title={`(${selectedResources.length}) ${useSelectionFor}`}
                    items={actionItems}
                    bsStyle='primary'
                    dropdownClassName='width-auto'
                />
            )}
            <CancelResourceSubscriptionsDialog
                show={showDialog}
                isMaintenanceModeEnabled={isProductInMaintenance(service.sku)}
                serviceName={serviceName}
                selectedResources={selectedResources}
                wouldCancelService={wouldCancelService}
                onClickCancelCallback={() => setShowDialog(false)}
                onClickConfirmCallback={() => {
                    setShowDialog(false);
                    handleConfirmUpdate();
                }}
                productType={service.productType}
            />
        </div>
    );
}

const mapStateToProps = (state: RootState) => ({
    isProductInMaintenance: isProductInMaintenanceMode(state),
    isCurrentlyProcessingCancellation: (sku: string, resourceId?: string) =>
        isSubscriptionCancelling(state, sku, resourceId),
});

export const SelectionActionsContainer = connect(mapStateToProps)(SelectionActions);
