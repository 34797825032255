/* eslint-disable camelcase */
import * as t from 'io-ts';
import { ApiPaymentMethodTypeCodec } from './paymentMethodTypes.types';

export const ApiPaymentMethodCodec = t.partial({
    id: t.string,
    bank_code: t.string,
    brand: t.string,
    country: t.string,
    expiration_month: t.string,
    expiration_year: t.string,
    owner_name: t.string,
    payment_account_number: t.string,
    payment_type: ApiPaymentMethodTypeCodec,
});
export type ApiPaymentMethod = t.TypeOf<typeof ApiPaymentMethodCodec>;

export const ApiPaymentMethodsCodec = t.intersection([
    t.type({
        payment_methods: t.array(ApiPaymentMethodCodec),
    }),
    t.partial({
        default_payment_method_id: t.string,
    }),
]);

export type ApiPaymentMethods = t.TypeOf<typeof ApiPaymentMethodsCodec>;
