import { PartnerConnection } from '../redux/types';
import { PartnersTableDeleteButtonContainer } from './PartnersTableDeleteButton';
import { PartnersTableStatusLabel } from './PartnersTableStatusLabel';
import { FormattedDate, FormattedTime } from 'react-intl';
import { PrivacyPolicyOpener } from '../../../common/PrivacyPolicyOpener';
import { canPartnerConnectionBeDeleted } from '../PartnerService';

interface Props {
    item: PartnerConnection;
}

export function PartnersTableRow({ item }: Props) {
    const deletionPossible = canPartnerConnectionBeDeleted(item);

    return (
        <tr>
            <td>{item.partnerDisplayName}</td>
            <td>
                <PartnersTableStatusLabel status={item.status} />
            </td>
            <td>
                <FormattedDate month='2-digit' day='2-digit' value={item.createdAt} year='numeric' />{' '}
                <FormattedTime value={item.createdAt} />
            </td>
            <td>
                <PrivacyPolicyOpener partnerName={item.partnerName} />
            </td>
            <td>
                <PartnersTableDeleteButtonContainer enabled={deletionPossible} connectionId={item.id} />
            </td>
        </tr>
    );
}
