import { createSelector } from '@reduxjs/toolkit';
import { getResourceSearchTerm, getAvailableResources, getSelectedResourceIds } from './redux/resourceSelection.redux';
import { BookableResource, ProductState } from './redux/types';
import { getSelectedProductId, getSelectedProductLevel } from '../redux/checkout.redux';
import { isServiceCareLBeta } from '../../common/betaService';

function filterByResourceName(
    resources: Array<BookableResource>,
    searchTerm: string,
    selectedResourceIds: Array<string>
) {
    return resources.filter((resource) =>
        selectedResourceIds.includes(resource.id)
            ? true
            : resource.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
}

interface GroupedResources {
    bookable: Array<BookableResource>;
    booked: Array<BookableResource>;
    notBookable: Array<BookableResource>;
}

const bookableFilter = (productId: string | undefined) => (it: BookableResource) => {
    return it.bookable && !isServiceCareLBeta(productId, it.targetLevel);
};
const bookedFilter = (requestedLevel: string | undefined) => (it: BookableResource) => {
    if (it.hasOpenContract) {
        return true;
    }

    if (it.productState === ProductState.PENDING_ACTIVATION || it.productState === ProductState.PENDING_CANCELLATION) {
        return !it.bookable && (requestedLevel === it.originLevel || requestedLevel === it.targetLevel);
    }

    return !it.bookable && it.productState !== ProductState.INACTIVE && requestedLevel === it.targetLevel;
};
const notBookableFilter =
    (productId: string | undefined, requestedLevel: string | undefined) => (it: BookableResource) => {
        if (it.hasOpenContract) {
            return false;
        }
        if (
            it.productState === ProductState.PENDING_ACTIVATION ||
            it.productState === ProductState.PENDING_CANCELLATION
        ) {
            return !it.bookable && requestedLevel !== it.targetLevel && it.originLevel !== requestedLevel;
        }
        if (isServiceCareLBeta(productId, it.targetLevel)) {
            return true;
        }
        return !it.bookable && (it.productState === ProductState.INACTIVE || requestedLevel !== it.targetLevel);
    };

export const getFilteredGroupedResources = createSelector(
    [
        getAvailableResources,
        getResourceSearchTerm,
        getSelectedResourceIds,
        getSelectedProductId,
        getSelectedProductLevel,
    ],
    (resources, searchTerm, selectedResourceIds, productId, requestedLevel): GroupedResources => {
        const filteredResources = filterByResourceName(resources, searchTerm, selectedResourceIds);
        return {
            bookable: filteredResources.filter(bookableFilter(productId)),
            booked: filteredResources.filter(bookedFilter(requestedLevel)),
            notBookable: filteredResources.filter(notBookableFilter(productId, requestedLevel)),
        };
    }
);
