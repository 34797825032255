import { FormattedMessage } from 'react-intl';
import { AssetOverviewGotoContractsDialog } from '../../assetOverview/AssetOverviewGotoContractsDialog';
import { useState } from 'react';

export function ManageContractsButton() {
    const [showDialog, setShowDialog] = useState(false);

    return (
        <>
            {showDialog && <AssetOverviewGotoContractsDialog closeDialog={() => setShowDialog(false)} />}
            <button className='btn btn-primary' onClick={() => setShowDialog(true)}>
                <FormattedMessage id={'marketplace.myServices.manageContracts'} />
            </button>
        </>
    );
}
