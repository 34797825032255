import { connect } from 'react-redux';
import { RootState } from '../../../../configuration/setup/store';
import { getHasError, getIsLoading } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import { getIsCheckoutLocked, getSelectedProductType } from '../redux/checkout.redux';
import { ExternalAdminHint } from '../../common/ExternalAdminHint';

export const mapStateToProps = (state: RootState) => ({
    hasHidden:
        getHasError(state, ApiCalls.RESOURCE_SELECTION) ||
        getIsLoading(state, ApiCalls.CHECKOUT_PRODUCT_DETAILS) ||
        getIsCheckoutLocked(state),
    productType: getSelectedProductType(state),
});

export const ExternalAdminHintContainer = connect(mapStateToProps)(ExternalAdminHint);
