import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import { HistoryResourceList } from './HistoryResourceList';
import { getActionMessage } from './historyActionMessages';
import { Actor, HistoryEvent } from './redux/types';

const getDetails = (item: HistoryEvent) => {
    let content;
    if (item.resources && item.resources.length) {
        content = <HistoryResourceList resources={item.resources} />;
    }
    return <div>{content}</div>;
};

type Props = {
    item: HistoryEvent;
};

function getActor(actor?: Actor): string | null | React.ReactElement<typeof FormattedMessage> {
    if (!actor) {
        return <FormattedMessage id={'marketplace.history.header.triggeredBy.system'} />;
    }
    if (actor.name) {
        return actor.name;
    }

    let actorMessageId;
    if (actor?.id?.toUpperCase() === 'SYSTEM') {
        actorMessageId = 'marketplace.history.header.triggeredBy.system';
    } else if (actor?.id?.toUpperCase() === 'USER') {
        actorMessageId = 'marketplace.history.header.triggeredBy.user';
    } else if (actor?.id && actor.id.length) {
        actorMessageId = 'marketplace.history.header.triggeredBy.user.unavailable';
    } else {
        actorMessageId = 'marketplace.history.header.triggeredBy.unavailable';
    }
    return <FormattedMessage id={actorMessageId} />;
}

export const HistoryItem = ({ item }: Props) => {
    const date = new Date(item.timestamp);
    const resourceType = item.resources && item.resources[0]?.type;
    const actionMessage = getActionMessage(item.action, resourceType);

    return (
        <tr>
            <td>
                <FormattedDate month='2-digit' day='2-digit' value={date} year='numeric' />{' '}
                <FormattedTime value={date} />
            </td>
            <td>{actionMessage ? <FormattedMessage id={actionMessage} /> : ''}</td>
            <td className={item.productName?.toUpperCase().includes('FREE TRIAL') ? 'text-color-secondary' : ''}>
                {item.productName || item.sku}
                {item.orderInfo && ` - ${item.orderInfo}`}
            </td>
            <td>{getDetails(item)}</td>
            <td>{getActor(item.actor)}</td>
        </tr>
    );
};
