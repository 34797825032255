import { useEffect } from 'react';
import { connect } from 'react-redux';
import { NextButton } from './components/NavigationButtons';
import { ResourceSelectionContainer } from './resourceSelection/ResourceSelectionContainer';
import { getAvailableResources, getSelectedResourceIds } from './resourceSelection/redux/resourceSelection.redux';
import {
    CheckoutSteps,
    GACheckoutStepData,
    getGACheckoutStepData,
    getGAEventCheckoutProgress,
} from './checkoutGTMUtils';
import { safeDataLayerPush } from '../common/utils/googleTagManagerWrapper';
import { getIsCheckoutLocked, getSelectedProductLevel } from './redux/checkout.redux';
import { VariantSelectionContainer } from './components/VariantSelectionContainer';
import { getHasError, getIsLoading } from '../api/redux/api.redux';
import { ApiCalls } from '../api/redux/types';
import { RootState } from '../../../configuration/setup/store';
import { ProductTypeAwareFormattedMessage } from './resourceSelection/ProductTypeAwareFormattedMessage';
import { ExternalAdminHintContainer } from './components/ExternalAdminHintContainer';
import { BusCustomerHintContainer } from './components/BusCustomerHintContainer';
import EmptyState from '@rio-cloud/rio-uikit/lib/es/EmptyState';

interface Props {
    nextButtonDisabled: boolean;
    nextPath: string;
    gAData: GACheckoutStepData;
    isResourceSelectionLocked: boolean;
    hasAvailableResources: boolean;
}

const CheckoutResourceSelection = ({
    nextButtonDisabled,
    nextPath,
    gAData,
    isResourceSelectionLocked,
    hasAvailableResources,
}: Props) => {
    useEffect(() => {
        if (gAData.product) {
            safeDataLayerPush(() => getGAEventCheckoutProgress(gAData));
        }
        // eslint-disable-next-line
    }, [gAData.product]);

    return (
        <div className='container-fluid fluid-small'>
            <div className='text-center'>
                <h3>
                    <ProductTypeAwareFormattedMessage id={isResourceSelectionLocked ? 'headlineLocked' : 'headline'} />
                </h3>
            </div>
            {hasAvailableResources ? (
                <div className='assetSelection-panel panel panel-default'>
                    <div className='panel-body'>
                        <VariantSelectionContainer />
                        <div className='display-flex justify-content-center'>
                            <ResourceSelectionContainer />
                        </div>
                        <div>
                            <ExternalAdminHintContainer />
                            <BusCustomerHintContainer />
                            <NextButton disabled={nextButtonDisabled} nextPath={nextPath} />
                        </div>
                    </div>
                </div>
            ) : (
                <EmptyState
                    headline={<ProductTypeAwareFormattedMessage id='noResources' />}
                    message={<ExternalAdminHintContainer />}
                    outerClassName='margin-top-15pct'
                />
            )}
        </div>
    );
};
const mapStateToProps = (state: RootState) => ({
    nextButtonDisabled: getSelectedResourceIds(state).length === 0 || getIsLoading(state, ApiCalls.RESOURCE_SELECTION),
    gAData: getGACheckoutStepData(state, CheckoutSteps.TO_BILLING_ADDRESS),
    isResourceSelectionLocked: getIsCheckoutLocked(state),
    selectedProductLevel: getSelectedProductLevel(state),
    hasAvailableResources:
        getIsLoading(state, ApiCalls.RESOURCE_SELECTION) ||
        getHasError(state, ApiCalls.RESOURCE_SELECTION) ||
        getAvailableResources(state).length > 0,
});

export const CheckoutResourceSelectionContainer = connect(mapStateToProps)(CheckoutResourceSelection);
