import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PopUpState } from './types';
import { RootState } from '../../../configuration/setup/store';

const initialState: PopUpState = {
    isOpenedInPopup: false,
};

export const { reducer: popupReducer, actions: popupActions } = createSlice({
    name: 'popup',
    initialState,
    reducers: {
        setIsOpenedInPopup: (state: PopUpState, action: PayloadAction<boolean>) => {
            state.isOpenedInPopup = action.payload;
        },
    },
});

const getBase = (state: RootState) => state.popup;

export const getIsOpenedInPopup = (state: RootState) => getBase(state).isOpenedInPopup;
