import { getMarketplaceBackend } from '../../config/pathURL/config';
import { getRequest, postRequest } from '../requests';
import { decodeJson, ignore404Error, jsonOrReject, onRejected } from '../api';
import { ApiPaymentMethodCodec, ApiPaymentMethodsCodec } from './paymentInformation.types';
import { mapPaymentMethod, mapPaymentMethods } from './paymentInformationMapper';
import { PaymentMethod } from '../../common/payment/redux/types';
import { PaymentMethodType } from './paymentMethodTypes.types';

export function fetchPaymentMethods(accountId: string): Promise<{ methods: Array<PaymentMethod>; defaultId?: string }> {
    return fetch(`${getMarketplaceBackend()}/accounts/${accountId}/payment-methods`, getRequest())
        .then(jsonOrReject)
        .then(decodeJson(ApiPaymentMethodsCodec))
        .then(mapPaymentMethods)
        .catch(onRejected([403, 404]))
        .catch((error) => {
            return ignore404Error(error, { methods: [] });
        });
}

export function publishPaymentMethod(
    accountId: string,
    paymentType: PaymentMethodType,
    paymentMethodId?: string
): Promise<PaymentMethod> {
    // eslint-disable-next-line camelcase
    const requestBody = { payment_type: paymentType, payment_method_id: paymentMethodId };
    return fetch(`${getMarketplaceBackend()}/accounts/${accountId}/payment-methods`, postRequest(requestBody))
        .then(jsonOrReject)
        .then(decodeJson(ApiPaymentMethodCodec))
        .then(mapPaymentMethod)
        .catch(onRejected([403]));
}
