import { Dispatch } from 'redux';
import { apiCallAction } from '../../../api/redux/api.redux';
import { ApiCalls } from '../../../api/redux/types';
import { serviceOverviewActions } from '../redux/serviceOverview.redux';
import { fetchResourcesForProduct } from '../../../api/asset/assetCalls';

export function fetchResourcesForSkuThunk(sku: string) {
    return (dispatch: Dispatch): Promise<void> => {
        dispatch(apiCallAction(ApiCalls.SERVICE_RESOURCES, true, false));
        return fetchResourcesForProduct(sku)
            .then((response) => {
                dispatch(serviceOverviewActions.addResourcesForSku({ sku, resources: response }));
                dispatch(apiCallAction(ApiCalls.SERVICE_RESOURCES, false, false));
            })
            .catch(() => {
                dispatch(apiCallAction(ApiCalls.SERVICE_RESOURCES, false, true));
            });
    };
}
