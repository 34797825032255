import { connect, useSelector } from 'react-redux';
import { useEffect } from 'react';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { FormattedMessage } from 'react-intl';
import LoadingErrorState from '../../common/error/LoadingErrorState';
import groupBy from 'lodash/groupBy';
import { getHasError, getIsLoading } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import { RootState, useAppDispatch } from '../../../../configuration/setup/store';
import { FreeTrialTableToolBar } from './FreeTrialTableToolBar';
import { FreeTrialPanel } from './FreeTrialPanel';
import { getFilteredContractsByAssets } from './freeTrialSearchService';
import { RenewDialogContainer } from '../../common/termedLevel/dialog/RenewDialog';
import { fetchContractsThunk } from '../../common/termedLevel/termedLevel.thunk';
import { useNavigate } from 'react-router';
import { getIsFreeTrialActive } from '../../freeTrial/redux/freeTrial.redux';
import { MARKETPLACE_CUSTOMER_CENTER_PATH } from '../../common/routes';
import { FreeTrialInformationBox } from './FreeTrialInformationBox';
import EmptyState from '@rio-cloud/rio-uikit/lib/es/EmptyState';
import { getFreeTrialContracts } from '../../common/termedLevel/redux/termedLevelRenewal.redux';
import { fetchDiscountsThunk } from '../../common/discount/discounts.thunk';
import { LimitedPermissionsInfoBanner } from '../../common/permissions/LimitedPermissionsInfoBanner';

const FreeTrialOverview = () => {
    const dispatch = useAppDispatch();
    const { message, displayRows } = useSelector(getFilteredContractsByAssets);
    const freeTrialActive = useSelector(getIsFreeTrialActive);
    const isLoading = useSelector((state: RootState) => getIsLoading(state, ApiCalls.TERMED_LEVEL_GET));
    const hasError = useSelector((state: RootState) => getHasError(state, ApiCalls.TERMED_LEVEL_GET));
    const isEmpty = useSelector((state: RootState) => getFreeTrialContracts(state).length === 0);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(fetchContractsThunk());
        dispatch(fetchDiscountsThunk());
    }, []);

    if (!freeTrialActive) {
        navigate(`/${MARKETPLACE_CUSTOMER_CENTER_PATH}`);
    }

    if (isLoading) {
        return <Spinner text={<FormattedMessage id={'marketplace.loading'} />} isInverse={false} />;
    }
    if (hasError) {
        return <LoadingErrorState />;
    }
    if (isEmpty) {
        return (
            <EmptyState
                headline={<FormattedMessage id={'marketplace.contracts.noContractsPresent'} />}
                outerClassName='margin-top-15pct'
            />
        );
    }

    const groupedFreeTrials = groupBy(displayRows, 'productId');
    return (
        <>
            <FreeTrialInformationBox />
            <LimitedPermissionsInfoBanner />
            <FreeTrialTableToolBar />
            {Object.keys(groupedFreeTrials).map((productId) => (
                <FreeTrialPanel key={productId} contracts={groupedFreeTrials[productId]} open={false} />
            ))}
            <div className='text-center'>{message && <FormattedMessage id={message} />}</div>
            <RenewDialogContainer titleId={'marketplace.freeTrialTab.renewal'} />
        </>
    );
};

export const FreeTrialOverviewContainer = connect()(FreeTrialOverview);
