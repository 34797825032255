import CustomState from '@rio-cloud/rio-uikit/lib/es/CustomState';
import { useIntl } from 'react-intl';

const imageUrl = 'http://cdn.rio.cloud/svg/dashboard/dashboard_coming_soon.svg';

export function MaintenanceView() {
    const intl = useIntl();

    return (
        <CustomState
            headline={intl.formatMessage({ id: 'marketplace.maintenance.headline' })}
            message={intl.formatMessage({ id: 'marketplace.maintenance.message' })}
            fullWidth
            image={<img className='max-width-500' src={imageUrl} alt={''} />}
            innerClassName={'maintenanceMode'}
        />
    );
}
