import { useSelector } from 'react-redux';
import { getOptionalAccountId } from '../../../../configuration/tokenHandling/tokenSlice';
import { isOnFreeTrialPage } from './isOnFreeTrialPage';

type GoogleAnalyticsEventAction = 'startFreeTrial' | 'freeTrial' | 'checkoutSwitchToGeoL';

/*
 * Only use this function top-level inside React components, see https://legacy.reactjs.org/docs/hooks-rules.html
 */
export function getTrackingAttributes(action: GoogleAnalyticsEventAction, trigger: string) {
    const accountId = useSelector(getOptionalAccountId);
    return {
        'data-track-ga-event-trigger': trigger,
        'data-track-ga-event-category': 'marketplace',
        'data-track-ga-event-action': `${action}_${trigger}`,
        'data-track-ga-event-label': `accountId::${accountId}`,
    };
}

export function getFreeTrialTrackingAttributes(trigger: 'click' | 'visibility') {
    if (!isOnFreeTrialPage()) {
        return;
    }

    return getTrackingAttributes('freeTrial', trigger);
}

export function getStartFreeTrialTrackingAttributes(trigger: 'click' | 'visibility') {
    return getTrackingAttributes('startFreeTrial', trigger);
}
