import { FreeTrialTabState } from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../../configuration/setup/store';
import { TermedLevelId } from '../../../common/termedLevel/redux/types';

const initialState: FreeTrialTabState = {
    freeTrials: [],
    search: '',
    selectedFreeTrialActivations: [],
};

export const { reducer: freeTrialTabReducer, actions: freeTrialTabActions } = createSlice({
    name: 'freeTrialTab',
    initialState,
    reducers: {
        setAssetSearch: (state: FreeTrialTabState, action: PayloadAction<string>) => {
            state.search = action.payload;
        },
        toggleSelectedFreeTrial: (state: FreeTrialTabState, { payload }: PayloadAction<TermedLevelId>) => {
            const index = state.selectedFreeTrialActivations.findIndex(
                (freeTrialId) =>
                    freeTrialId.productId === payload.productId && freeTrialId.resourceId === payload.resourceId
            );
            if (index >= 0) {
                state.selectedFreeTrialActivations.splice(index, 1);
            } else {
                state.selectedFreeTrialActivations.push(payload);
            }
        },
        setSelectedFreeTrials: (state: FreeTrialTabState, action: PayloadAction<Array<TermedLevelId>>) => {
            state.selectedFreeTrialActivations = action.payload;
        },
    },
});

const getBase = (state: RootState) => state.marketplace.freeTrialTab;
export const getAssetSearch = (state: RootState) => getBase(state).search;
export const getSelectedFreeTrialActivations = (state: RootState) => getBase(state).selectedFreeTrialActivations;
