import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getCurrencyOfSelectedProduct, isPurchaseFree } from '../CheckoutService';
import { PriceDetailsContainer } from './PriceDetails';
import { formatPrice } from '../../common/utils/currencyUtil';
import { DATE_FORMAT } from '../../common/utils/dateUtil';
import { getSelectedProductOrProductLevel } from '../redux/checkout.redux';
import { formatDurationUnit } from '../../common/utils/formatDurationUnit';
import { TotalPrice } from '../../common/price/TotalPrice';
import { RootState } from '../../../../configuration/setup/store';
import { Period } from '../../common/termedLevel/types';
import { ProductType } from '../../common/product/product';
import { getDiscounts } from '../../common/discount/redux/discount.redux';
import { getTotalPrices } from '../../common/price/priceService';
import { getSelectedResourceIds } from '../resourceSelection/redux/resourceSelection.redux';

interface Props {
    totalPrice: number;
    currency: string | undefined;
    isFree: boolean;
    futurePrices?: Array<{ totalPrice: number; startDate: Date }>;
    contractPeriod: Period | undefined;
    productType: ProductType | undefined;
}

const Price = ({ isFree, totalPrice, currency, futurePrices, contractPeriod, productType }: Props) => {
    const intl = useIntl();
    if (isFree) {
        return (
            <div className='text-size-14 line-height-20 text-medium label label-success label-filled'>
                <FormattedMessage id={'marketplace.free'} />
            </div>
        );
    }
    return (
        <div className='margin-left-20-sm margin-right-10-sm margin-top-20-xs margin-top-20-ls margin-top-0-sm'>
            <PriceDetailsContainer productType={productType} />
            <hr className='margin-top-3 margin-bottom-3' />
            <div className='text-right'>
                <TotalPrice totalPrice={totalPrice} currency={currency} />
            </div>
            <div className='text-right text-size-14'>
                {futurePrices &&
                    futurePrices.map((futurePrice, index) => (
                        <div key={index}>
                            <FormattedMessage
                                id={'marketplace.from'}
                                values={{
                                    date: intl.formatDate(futurePrice.startDate, DATE_FORMAT),
                                }}
                            />
                            {': '}
                            {formatPrice(intl, futurePrice.totalPrice, currency)}{' '}
                            <FormattedMessage id={'marketplace.productDetails.price.rate.total'} />
                        </div>
                    ))}
            </div>
            {contractPeriod !== undefined && (
                <div className='text-right text-italic'>
                    <FormattedMessage id='intl-msg:common-message.contracts.period' /> {contractPeriod.length}{' '}
                    {formatDurationUnit(contractPeriod.unit, intl)}
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    const discounts = getDiscounts(state);
    const selectedProduct = getSelectedProductOrProductLevel(state);
    const contractPeriod = selectedProduct?.contractPeriod;
    const selectedResourceIds = getSelectedResourceIds(state);
    const { totalPrice, futurePrices } = getTotalPrices(discounts, selectedProduct, selectedResourceIds);

    return {
        totalPrice,
        currency: getCurrencyOfSelectedProduct(state),
        isFree: isPurchaseFree(state),
        futurePrices,
        contractPeriod,
    };
};
export const PriceContainer = connect(mapStateToProps)(Price);
