import { FormattedMessage } from 'react-intl';
import { useEffect } from 'react';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import LoadingErrorState from '../../common/error/LoadingErrorState';
import { fetchContractsThunk } from '../../common/termedLevel/termedLevel.thunk';
import { getHasError, getIsLoading } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import { connect } from 'react-redux';
import groupBy from 'lodash/groupBy';
import { getFilteredContractsByAssets } from './redux/contractSearchService';
import { ContractPanel } from './ContractPanel';
import { RenewDialogContainer } from '../../common/termedLevel/dialog/RenewDialog';
import { ContractStatsWidgetsContainer } from './ContractStatsWidgetsContainer';
import { ContractTableToolBarContainer } from './ContractTableToolBarContainer';
import { contractActions } from './redux/contract.redux';
import { RootDispatch, RootState } from '../../../../configuration/setup/store';
import { useContractDeepLinkingHook } from './contractDeepLinkingHook';
import { TermedLevel } from '../../common/termedLevel/types';
import { getContracts, termedLevelRenewalActions } from '../../common/termedLevel/redux/termedLevelRenewal.redux';
import EmptyState from '@rio-cloud/rio-uikit/lib/es/EmptyState';
import { fetchDiscountsThunk } from '../../common/discount/discounts.thunk';
import { LimitedPermissionsInfoBanner } from '../../common/permissions/LimitedPermissionsInfoBanner';

interface Props {
    contracts: Array<TermedLevel>;
    fetchContractsAndDiscounts: () => void;
    resetContractsState: () => void;
    hasError: boolean;
    isLoading: boolean;
    tableMessage?: string;
    isEmpty: boolean;
}

const ContractsPage = (props: Props) => {
    const { contracts, fetchContractsAndDiscounts, hasError, isLoading, tableMessage, resetContractsState, isEmpty } =
        props;

    useContractDeepLinkingHook();

    useEffect(() => {
        fetchContractsAndDiscounts();
        return () => resetContractsState();
    }, []);

    if (isLoading) {
        return <Spinner text={<FormattedMessage id={'marketplace.loading'} />} isInverse={false} />;
    }
    if (hasError) {
        return <LoadingErrorState />;
    }
    if (isEmpty) {
        return (
            <EmptyState
                headline={<FormattedMessage id={'marketplace.contracts.noContractsPresent'} />}
                outerClassName='margin-top-15pct'
            />
        );
    }

    const groupedContracts = groupBy(contracts, 'productId');
    return (
        <>
            <ContractStatsWidgetsContainer />
            <LimitedPermissionsInfoBanner />
            <ContractTableToolBarContainer />
            {Object.keys(groupedContracts).map((productId) => (
                <ContractPanel key={productId} contracts={groupedContracts[productId]} open={true} />
            ))}
            <div className='text-center'>{tableMessage && <FormattedMessage id={tableMessage} />}</div>
            <RenewDialogContainer titleId={'intl-msg:common-message.contracts.renewal'} />
        </>
    );
};

const mapDispatchToProps = (dispatch: RootDispatch) => ({
    fetchContractsAndDiscounts: () => {
        dispatch(fetchContractsThunk());
        dispatch(fetchDiscountsThunk());
    },
    resetContractsState: () => {
        dispatch(termedLevelRenewalActions.clearUpdateState());
        dispatch(contractActions.setSelectedContracts([]));
    },
});

const mapStateToProps = (state: RootState) => {
    const { displayRows, message } = getFilteredContractsByAssets(state);
    return {
        isEmpty: getContracts(state).length === 0,
        contracts: displayRows,
        isLoading: getIsLoading(state, ApiCalls.TERMED_LEVEL_GET),
        hasError: getHasError(state, ApiCalls.TERMED_LEVEL_GET),
        tableMessage: message,
    };
};

export const ContractPageContainer = connect(mapStateToProps, mapDispatchToProps)(ContractsPage);
