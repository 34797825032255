import {
    getAssetOverviewRows,
    getAssetsIdsToDisplayInAssetOverview,
    getSelectedAssetIdsInAssetOverview,
    getTableSearch,
} from './redux/assetOverview.redux';
import { AssetOverviewRow } from './redux/types';
import { createSelector } from 'reselect';

function matchesSearch(searchTerm: string, it: { assetName: string }) {
    return it.assetName.toLowerCase().includes(searchTerm.toLowerCase());
}

function getAssetOverviewTableMessage(
    rowsToSearchIn: Array<AssetOverviewRow>,
    displayRows: Array<AssetOverviewRow>,
    selectedAssetIds: Array<string>,
    searchTerm: string
) {
    if (rowsToSearchIn.length === 0) {
        return 'marketplace.assetOverview.emptyVehicleGroup';
    }
    const allDisplayedAssetsAreSelected = displayRows.length === selectedAssetIds.length;
    const noDisplayedVehicleMatchesTheSearch = !displayRows.find((it) => matchesSearch(searchTerm, it));
    if (allDisplayedAssetsAreSelected && noDisplayedVehicleMatchesTheSearch) {
        return 'marketplace.resourceSelection.asset.noSearchedResourcesFound';
    }
    return undefined;
}

export const getAssetOverviewFilteredRows = createSelector(
    [getSelectedAssetIdsInAssetOverview, getAssetOverviewRows, getAssetsIdsToDisplayInAssetOverview, getTableSearch],
    (
        selectedAssetIds: Array<string>,
        allRows: Array<AssetOverviewRow>,
        taggedAssets: Array<string> | undefined,
        searchTerm: string
    ) => {
        const rowsToSearchIn = taggedAssets ? allRows.filter((it) => taggedAssets.includes(it.assetId)) : allRows;
        const displayRows = rowsToSearchIn.filter(
            (it) => matchesSearch(searchTerm, it) || selectedAssetIds.includes(it.assetId)
        );
        const message = getAssetOverviewTableMessage(rowsToSearchIn, displayRows, selectedAssetIds, searchTerm);
        return { displayRows, message };
    }
);
