import { FormattedMessage } from 'react-intl';

export const PaymentInformationBankTransfer = () => {
    return (
        <>
            <small className='payment-label'>
                <FormattedMessage id='marketplace.payment.bankTransfer.label.paymentType' />
                {': '}
            </small>
            <span className='payment-value text-bold'>
                <FormattedMessage id='marketplace.payment.method.bankTransfer' />
            </span>
        </>
    );
};
