import TableToolbar from '@rio-cloud/rio-uikit/lib/es/TableToolbar';
import TableViewToggles from '@rio-cloud/rio-uikit/lib/es/TableViewToggles';
import { TagSelectionDropdownContainer } from './TagSelectionDropdown';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { assetOverviewActions } from './redux/assetOverview.redux';
import { AssetOverviewTableSearchContainer } from './AssetOverviewTableSearchContainer';
import { TableViewTogglesValues } from './redux/types';

interface Props {
    setViewType: (viewType: TableViewTogglesValues) => void;
}

const AssetOverviewTableToolbar = (props: Props) => {
    const { setViewType } = props;

    return (
        <TableToolbar className={''}>
            <div className='table-toolbar-container'>
                <div className='table-toolbar-group-left'>
                    <div className='table-toolbar-column min-width-400'>
                        <TagSelectionDropdownContainer />
                    </div>
                </div>
                <div className='table-toolbar-group-right'>
                    <div className='table-toolbar-column'>
                        <AssetOverviewTableSearchContainer />
                    </div>
                    <div className='table-toolbar-column'>
                        <TableViewToggles onViewTypeChange={setViewType} />
                    </div>
                </div>
            </div>
        </TableToolbar>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setViewType: (viewType: TableViewTogglesValues) => dispatch(assetOverviewActions.setViewType(viewType)),
});

export const AssetOverviewTableToolbarContainer = connect(null, mapDispatchToProps)(AssetOverviewTableToolbar);
