import { RenewFooterNoRenewal } from './RenewFooterNoRenewal';
import { updateSelectedContractsThunk } from '../termedLevel.thunk';
import { connect } from 'react-redux';
import { RenewFooterRenewal } from './RenewFooterRenewal';
import { getIsLoading } from '../../../api/redux/api.redux';
import { ApiCalls } from '../../../api/redux/types';
import { RootDispatch, RootState } from '../../../../../configuration/setup/store';
import { getProductForUpdate, getRenewsToLevelForUpdate } from '../redux/termedLevelRenewal.redux';
import { TermedLevelsForUpdate, TermedProductInfo } from '../redux/types';
import { getTermedLevelsForUpdate } from './dialogService';

interface Props {
    selectedLevel: string | undefined;
    product: TermedProductInfo | undefined;
    contracts: TermedLevelsForUpdate;
    updateContract: () => void;
    isUpdating: boolean;
}

function isSingleContractViewAndCurrentLevel(contracts: TermedLevelsForUpdate, selectedLevel: string | undefined) {
    const allContracts = [...contracts.updatable, ...contracts.notUpdatable];
    return allContracts.length === 1 && allContracts[0].renewsToLevel === selectedLevel;
}

const RenewDialogFooter = (props: Props) => {
    const { selectedLevel, product, contracts, updateContract, isUpdating } = props;

    if (!product) {
        return null;
    }

    if (isSingleContractViewAndCurrentLevel(contracts, selectedLevel)) {
        return null;
    }

    if (!selectedLevel) {
        return <RenewFooterNoRenewal updateContract={updateContract} isUpdating={isUpdating} />;
    }

    return (
        <RenewFooterRenewal
            selectedLevel={selectedLevel}
            product={product}
            updateContract={updateContract}
            updatableContracts={contracts.updatable}
            isUpdating={isUpdating}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    selectedLevel: getRenewsToLevelForUpdate(state),
    contracts: getTermedLevelsForUpdate(state),
    product: getProductForUpdate(state),
    isUpdating: getIsLoading(state, ApiCalls.CONTRACT_UPDATE),
});

const mapDispatchToProps = (dispatch: RootDispatch) => ({
    updateContract: () => dispatch(updateSelectedContractsThunk),
});

export const RenewDialogFooterContainer = connect(mapStateToProps, mapDispatchToProps)(RenewDialogFooter);
