export enum FreeTrialStatus {
    ELIGIBLE,
    INELIGIBLE,
    ACTIVE,
}

export interface FreeTrial {
    status: FreeTrialStatus;
    endsAt?: Date;
    products?: Array<FreeTrialProduct>;
}

export interface FreeTrialProduct {
    productId: string;
    level: string;
}

export interface FreeTrialState {
    freeTrialStatus: FreeTrialStatus;
    freeTrialEndDate?: string;
    products?: Array<FreeTrialProduct>;
}
