import { useState } from 'react';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import { historyActionInfoMessageKeys } from './historyActionMessages';
import { FormattedMessage } from 'react-intl';
import { MessageKey } from '../../../../configuration';

const DialogBody = () => {
    return (
        <div>
            <table className={'table'}>
                <thead>
                    <tr>
                        <th>
                            <FormattedMessage id={'marketplace.history.header.action'} />
                        </th>
                        <th>
                            <FormattedMessage id={'marketplace.description'} />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {historyActionInfoMessageKeys.map((action) => {
                        const description: MessageKey = `${action}.descr`;
                        return (
                            <tr key={action}>
                                <td className={'white-space-nowrap'}>
                                    <FormattedMessage id={action} />
                                </td>
                                <td>
                                    <FormattedMessage id={description} />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export const HistoryActionInfo = () => {
    const [open, setOpen] = useState(false);
    return (
        <span className={'margin-left-10'}>
            <span
                className={'rioglyph rioglyph-info-sign cursor-pointer text-color-dark'}
                onClick={() => setOpen(true)}
            />
            <Dialog
                title={<FormattedMessage id={'marketplace.action.info.title'} />}
                show={open}
                body={<DialogBody />}
                onHide={() => setOpen(false)}
                showCloseButton={true}
            />
        </span>
    );
};
