import sortBy from 'lodash/fp/sortBy';
import { FormattedMessage, useIntl } from 'react-intl';
import { InvoiceDownloadButtonContainer } from './InvoiceDownloadButtonContainer';
import { Invoice, InvoiceType } from './redux/types';
import { formatPrice } from '../../common/utils/currencyUtil';
import { UnreachableCaseError } from '../../../../utils/typescriptUtil';

const getInvoiceDocumentTranslation = (type: InvoiceType) => {
    switch (type) {
        case InvoiceType.CREDIT_MEMO:
            return 'marketplace.financial.document.type.credit.memo';
        case InvoiceType.INVOICE:
            return 'marketplace.financial.document.type.invoice';
        default:
            throw new UnreachableCaseError(type);
    }
};

interface Props {
    invoices: Array<Invoice>;
}

const HEADER_MESSAGE_IDS = [
    'marketplace.invoice.number',
    'marketplace.invoice.partnerName',
    'marketplace.invoice.documentType',
    'marketplace.invoice.amount',
];

export const InvoiceTable = (props: Props) => {
    const { invoices } = props;
    const intl = useIntl();
    const sortedByInvoiceNumber = sortBy<Invoice>('invoiceNumber')(invoices);

    return (
        <div className='table-responsive'>
            <table className='table margin-bottom-0' data-testid='invoiceTable'>
                <thead>
                    <tr>
                        {HEADER_MESSAGE_IDS.map((id) => (
                            <th key={id}>
                                <FormattedMessage id={id} />
                            </th>
                        ))}
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {sortedByInvoiceNumber.map((invoice, index) => {
                        return (
                            <tr key={index}>
                                <td className='invoiceNumber col-sm-3'>{invoice.invoiceNumber}</td>
                                <td className='partnerName col-sm-3'>{invoice.partnerName}</td>
                                <td className='documentType col-sm-3'>
                                    <FormattedMessage id={getInvoiceDocumentTranslation(invoice.type)} />
                                </td>
                                <td className='amount col-sm-3 white-space-nowrap text-bold'>
                                    {invoice.amount !== undefined
                                        ? formatPrice(intl, invoice.amount, invoice.currency)
                                        : 'n/a'}
                                </td>
                                <td className='download col-sm-2 text-right'>
                                    <InvoiceDownloadButtonContainer invoiceId={invoice.id} />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};
