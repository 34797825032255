interface ProductSlideProps {
    productName: string;
    iconUrl: string;
}

export function ProductSlide({ productName, iconUrl }: ProductSlideProps) {
    const imgAltText = `${productName} Icon`;

    return (
        <div className='margin-x-20'>
            <img className='width-150 height-150 scale-90 hover-scale-100' alt={imgAltText} src={iconUrl} />
        </div>
    );
}
