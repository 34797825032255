import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { NavLink, useLocation } from 'react-router';
import ActionBarItem from '@rio-cloud/rio-uikit/lib/es/ActionBarItem';
import ApplicationHeader from '@rio-cloud/rio-uikit/lib/es/ApplicationHeader';
import ApplicationLayout from '@rio-cloud/rio-uikit/lib/es/ApplicationLayout';
import IframeResizer from 'iframe-resizer-react';
import { DefaultUserMenu } from '@rio-cloud/rio-user-menu-component';
import { RioNotifications } from '@rio-cloud/rio-notifications-component';
import { config } from '../config';
import { TabInfo } from './app/customerCenter/types';
import {
    getMainNavigationCustomerCenterTabs,
    isSubTabOfTabActive,
} from './app/customerCenter/customerCenterTabService';
import { getCustomerCenterPath } from './app/common/routes';
import { RootState } from '../configuration/setup/store';
import { getLocaleShort } from './app/lang/languageData';

function customerCenterTabs(tabs: Array<TabInfo>, pathName: string) {
    return tabs.map((tab) => {
        const isParentOfActiveSubTab = isSubTabOfTabActive(tab.componentName, pathName);
        return {
            key: tab.componentName,
            route: (
                <NavLink
                    to={getCustomerCenterPath(tab.componentName)}
                    className={isParentOfActiveSubTab ? 'active' : undefined}
                >
                    <FormattedMessage id={tab.labelId} />
                </NavLink>
            ),
        };
    });
}

export function AppHeader({ availableTabs }: { availableTabs: Array<TabInfo> }) {
    const { pathname } = useLocation();
    const appTitle = <FormattedMessage id={'marketplace.customerCenter'} />;

    const menuUrl = config.backend.MENU_SERVICE as string;

    const appNavigator = <IframeResizer className='iFrameResizer' src={menuUrl} />;

    // eslint-disable-next-line
    const homeLink = <a href={config.homeRoute} />;

    return (
        <ApplicationLayout.Header>
            <ApplicationHeader
                label={appTitle}
                appNavigator={appNavigator}
                homeRoute={homeLink}
                navItems={customerCenterTabs(availableTabs, pathname)}
                actionBarItems={[
                    <InformationActionBarItem key={'faq'} />,
                    <RioNotifications key={'notifications'} />,
                    <DefaultUserMenu environment={process.env.NODE_ENV as string} key={'menu'} />,
                ]}
            />
        </ApplicationLayout.Header>
    );
}

const InformationActionBarItem = () => {
    const locale = getLocaleShort();
    const faqUri = `https://rio.cloud/${locale}/faqs#c83506`;
    return (
        <ActionBarItem>
            <ActionBarItem.Icon>
                <a href={faqUri} target='_blank' rel='noreferrer'>
                    <span className='icon rioglyph rioglyph-info-sign' />
                </a>
            </ActionBarItem.Icon>
        </ActionBarItem>
    );
};

export const mapStateToProps = (state: RootState) => ({
    availableTabs: getMainNavigationCustomerCenterTabs(state),
});

export const AppHeaderContainer = connect(mapStateToProps)(AppHeader);
