import { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

const STRIPE_CURRENCY_TO_SYMBOL = {
    eur: '€',
};

export class AuthenticationInformation extends Component {
    getCurrencySymbol() {
        const currency = this.props.failedPayment.currency;
        return STRIPE_CURRENCY_TO_SYMBOL[currency];
    }

    getAmountDecimal() {
        const amount = this.props.failedPayment.amount;
        return parseFloat(amount).toFixed(2);
    }

    getDisplayedMessage() {
        if (this.props.failedPayment) {
            const currencySymbol = this.getCurrencySymbol();
            const amountDecimal = this.getAmountDecimal();
            return (
                <FormattedMessage
                    id={'marketplace.payments.authentication.info'}
                    values={{ amountDecimal, currencySymbol }}
                />
            );
        }
        return <FormattedMessage id={'marketplace.payments.authentication.noPayments.info'} />;
    }

    render() {
        const displayedMessage = this.getDisplayedMessage();

        return (
            <div className='AuthenticationInformation text-center text-color-dark'>
                <h2 className='margin-bottom-20 '>
                    <FormattedMessage id={'marketplace.payments.authentication.heading'} />
                </h2>
                <p>{displayedMessage}</p>
            </div>
        );
    }
}

export default injectIntl(AuthenticationInformation);

AuthenticationInformation.propTypes = {
    failedPayment: PropTypes.shape({
        id: PropTypes.string,
        status: PropTypes.string,
        timestamp: PropTypes.string,
        amount: PropTypes.string,
        currency: PropTypes.string,
    }),
};
