import { fetchAssetInfo, fetchTags } from '../../api/tags/tagCalls';
import { apiCallAction } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import { assetOverviewActions } from './redux/assetOverview.redux';
import { Dispatch } from 'redux';
import { RootDispatch } from '../../../../configuration/setup/store';

export const fetchAssetInfoThunk = (dispatch: Dispatch) => {
    dispatch(apiCallAction(ApiCalls.ASSET_OVERVIEW_ASSET_INFO, true, false));

    return fetchAssetInfo()
        .then((tagToAssetMap) => {
            dispatch(assetOverviewActions.setTagToAssetMap(tagToAssetMap));
            dispatch(apiCallAction(ApiCalls.ASSET_OVERVIEW_ASSET_INFO, false, false));
        })
        .catch(() => {
            dispatch(apiCallAction(ApiCalls.ASSET_OVERVIEW_ASSET_INFO, false, true));
        });
};

export const fetchTagsThunk = (dispatch: RootDispatch) => {
    dispatch(apiCallAction(ApiCalls.ASSET_OVERVIEW_TAGS, true, false));

    return fetchTags()
        .then((tags) => {
            dispatch(assetOverviewActions.setTags(tags));
            dispatch(apiCallAction(ApiCalls.ASSET_OVERVIEW_TAGS, false, false));
        })
        .catch(() => {
            dispatch(apiCallAction(ApiCalls.ASSET_OVERVIEW_TAGS, false, true));
        });
};

export const fetchTagsInfoThunk = (dispatch: RootDispatch) => {
    return Promise.all([dispatch(fetchTagsThunk), dispatch(fetchAssetInfoThunk)]);
};
