import { fetchUsageDownloadThunk } from './usage.thunk';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { getIsLoading } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import { RootDispatch, RootState } from '../../../../configuration/setup/store';

const UsageDownloadButton = (props: { downloadUsage: () => void; isLoading: boolean }) => {
    const { downloadUsage, isLoading } = props;

    if (isLoading) {
        return (
            <button key='loading' className={'btn btn-default btn-loading'} type={'button'} disabled={true}>
                <FormattedMessage id={'marketplace.usage.download.button'} />
            </button>
        );
    }

    return (
        <button key='ready' className={'btn btn-default'} type={'button'} onClick={downloadUsage}>
            <span className={'rioglyph rioglyph-download'} aria-hidden={true} />
            <FormattedMessage id={'marketplace.usage.download.button'} />
        </button>
    );
};

const mapStateToProps = (state: RootState) => ({
    isLoading: getIsLoading(state, ApiCalls.USAGE_EXPORT),
});

const mapDispatchToProps = (dispatch: RootDispatch) => ({
    downloadUsage: () => dispatch(fetchUsageDownloadThunk),
});

export const UsageDownloadButtonContainer = connect(mapStateToProps, mapDispatchToProps)(UsageDownloadButton);
