import { FormattedMessage } from 'react-intl';
import { PartnerConnection, PartnerConnectionStatus } from './redux/types';
import { getConnections, getHasPartnerPermissions } from './redux/partners.redux';
import { connect } from 'react-redux';
import { getLocale } from '../../../../configuration';
import { getTenant } from '../../common/utils/tenants';
import { getMarketplaceLink } from '../../common/ServiceBookButtonContainer';
import { getPartnerConnectionsThunk } from './partners.thunk';
import { useEffect } from 'react';
import { getHasError, getIsLoading } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import { PartnersTable } from './table/PartnersTable';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import LoadingErrorStateWithReload from '../../common/error/LoadingErrorStateWithReload';
import ForbiddenState from '@rio-cloud/rio-uikit/lib/es/ForbiddenState';
import EmptyState from '@rio-cloud/rio-uikit/lib/es/EmptyState';
import { differenceInDays } from 'date-fns';
import { RootDispatch, RootState } from '../../../../configuration/setup/store';

interface Props {
    connections: Array<PartnerConnection>;
    hasPermissions: boolean;
    marketplaceLink: string;
    isLoading: boolean;
    hasError: boolean;
    fetchConnections: () => void;
}

export const Partners = ({
    connections,
    marketplaceLink,
    fetchConnections,
    isLoading,
    hasError,
    hasPermissions,
}: Props) => {
    useEffect(() => {
        fetchConnections();
    }, []);

    if (isLoading) {
        return <Spinner />;
    }
    if (!hasPermissions) {
        return (
            <div className='container-fluid fluid-default'>
                <ForbiddenState
                    headline={<FormattedMessage id={'marketplace.unauthorized.dialog.title'} />}
                    message={<FormattedMessage id={'marketplace.unauthorized.dialog.message'} />}
                />
            </div>
        );
    }

    if (hasError) {
        return (
            <LoadingErrorStateWithReload
                headline={'marketplace.customerCenter.loading.error'}
                onReload={fetchConnections}
            />
        );
    }

    const recentlyActivatedConnections = connections.some(
        ({ status, createdAt }) =>
            status === PartnerConnectionStatus.ACTIVATED && differenceInDays(new Date(), new Date(createdAt)) < 5
    );
    return (
        <div className='container-fluid fluid-default'>
            {connections.length === 0 ? (
                <EmptyState
                    headline={<FormattedMessage id='marketplace.partners.noConnection' />}
                    message={<FormattedMessage id='marketplace.partners.noConnection.details' />}
                    icon='rioglyph-compare'
                    buttons={[
                        {
                            text: <FormattedMessage id='marketplace.partners.connectNow' />,
                            href: marketplaceLink,
                        },
                    ]}
                    outerClassName={'margin-top-15pct'}
                />
            ) : (
                <>
                    {recentlyActivatedConnections && (
                        <div className='alert alert-warning margin-bottom-20 padding-10'>
                            <div className='display-flex align-items-center'>
                                <span className='display-flex justify-content-center align-items-center margin-left-5 margin-right-10'>
                                    <span className='text-color-warning text-size-h2 rioglyph rioglyph-warning-sign'></span>
                                </span>
                                <div>
                                    <FormattedMessage id={'marketplace.partners.waitingDisclaimer'} />
                                </div>
                            </div>
                        </div>
                    )}
                    <PartnersTable partnerConnections={connections} />
                </>
            )}
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    connections: getConnections(state),
    hasPermissions: getHasPartnerPermissions(state),
    marketplaceLink: `${getMarketplaceLink(getLocale(state), getTenant(state))}partner`,
    isLoading:
        getIsLoading(state, ApiCalls.PARTNER_CONNECTION_GET) || getIsLoading(state, ApiCalls.PARTNER_CONNECTION_DELETE),
    hasError: getHasError(state, ApiCalls.PARTNER_CONNECTION_GET),
});

const mapDispatchToProps = (dispatch: RootDispatch) => ({
    fetchConnections: () => dispatch(getPartnerConnectionsThunk),
});

export const PartnersContainer = connect(mapStateToProps, mapDispatchToProps)(Partners);
