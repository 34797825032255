export enum InvoiceType {
    INVOICE,
    CREDIT_MEMO,
}

export interface Invoice {
    id: string;
    amount: number | undefined;
    type: InvoiceType;
    invoiceDate: string;
    invoiceNumber: string;
    partnerName: string | undefined;
    currency: string | undefined;
}
export interface DatedInvoices {
    invoiceDate: string;
    invoices: Array<Invoice>;
}

export interface InvoicesState {
    invoices: Array<DatedInvoices>;
    downloadId: string | undefined;
}
