/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { getHasError, getIsLoading } from '../api/redux/api.redux';
import { ApiCalls } from '../api/redux/types';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import ErrorState from '@rio-cloud/rio-uikit/lib/es/ErrorState';
import { FormattedMessage } from 'react-intl';
import { PreviousButton } from './components/NavigationButtons';
import { getSelectedProduct, getSelectedProductId } from './redux/checkout.redux';
import { sendError } from '../../../configuration/lang/services';
import { BookingSuccessMessage, SUCCESSFULLY_BOOKED } from '../common/postMessage.types';
import {
    getOpenMarketplaceLink,
    getOpenMarketplaceProductLink,
    getServiceLinkForProductId,
} from '../config/linkAtEndOfCheckout';
import { Product } from './redux/types';
import { RootState } from '../../../configuration/setup/store';
import { getIsOpenedInPopup } from '../popup/popup.redux';
import ConfettiExplosion from 'react-confetti-explosion';
import { sendIFrameCloseMessageToParent } from '../common/utils/iframeUtils';

type GoogleAnalyticsEventAction = 'forwardToService' | 'goToCustomerCenter';

interface Props {
    subscriptionCreationInProgress: boolean;
    hasError: boolean;
    previousPath: string;
    isOpenedInPopup: boolean;
    productId?: string;
    product?: Product;
}

const CheckoutConfirmationPage = (props: Props) => {
    let content;
    const productId = props.productId;
    const serviceLink = getServiceLinkForProductId(productId);
    const openMarketplaceProductLink = getOpenMarketplaceProductLink(productId);
    const openMarketplaceHomeLink = getOpenMarketplaceLink();
    const getTrackingAttributes = (action: GoogleAnalyticsEventAction, trigger: 'click' | 'visibility') => ({
        'data-track-ga-event-trigger': trigger,
        'data-track-ga-event-category': 'marketplace',
        'data-track-ga-event-action': `${action}_${trigger}`,
        'data-track-ga-event-label': `productId::${productId}`,
    });
    const closePopup = () => {
        if (props.isOpenedInPopup) {
            setTimeout(() => {
                sendIFrameCloseMessageToParent();
                window.close();
            }, 10);
        }
    };

    useEffect(() => {
        const success = !props.hasError && !props.subscriptionCreationInProgress;
        if (success && window.opener) {
            try {
                const message: BookingSuccessMessage = { type: SUCCESSFULLY_BOOKED, productId: props.productId };
                window.opener.postMessage(message, window.origin);
            } catch (e) {
                sendError(e);
            }
        }
    }, [props.hasError, props.subscriptionCreationInProgress, props.productId]);

    if (props.hasError) {
        content = (
            <ErrorState
                message={
                    <div className='display-flex flex-column gap-10'>
                        <FormattedMessage id={'marketplace.subscription.create.error'} />
                        <div className='display-flex justify-content-center margin-top-10'>
                            <PreviousButton previousPath={props.previousPath} />
                        </div>
                    </div>
                }
            />
        );
    } else if (props.subscriptionCreationInProgress) {
        content = (
            <Spinner text={<FormattedMessage id={'marketplace.subscription.create.loading'} />} isInverse={false} />
        );
    } else {
        content = (
            <div className='panel panel-default padding-20 padding-50-sm margin-x-auto max-width-700 margin-top-15pct'>
                <div className='text-center line-height-125rel margin-bottom-25 margin-top-10 margin-top-0-sm'>
                    <div className='text-size-h3 text-color-highlight text-medium margin-bottom-10 text-wrap-balance'>
                        <FormattedMessage id={'marketplace.checkout.success.title'} />
                    </div>
                    <div className='width-0 height-0 position-absolute left-50pct'>
                        <ConfettiExplosion duration={5000} colors={['#5296a3', '#69afbd', '#81c8d7', '#9ae1ef']} />
                    </div>
                    <div className='text-color-dark text-wrap-balance'>
                        <FormattedMessage id={'marketplace.checkout.success.subtitle'} />
                    </div>
                </div>

                <div className='display-flex flex-column flex-row-sm gap-20 padding-top-10'>
                    {serviceLink && (
                        <div className='panel panel-default panel-body bg-decent line-height-125rel flex-1-1-0 display-flex flex-column'>
                            <div className='text-size-20 text-bold margin-bottom-10'>
                                <FormattedMessage id={'marketplace.checkout.success.letsstart.title'} />
                            </div>
                            <div className='margin-bottom-20 flex-1-1'>
                                <FormattedMessage id={'marketplace.checkout.success.letsstart.text'} />
                            </div>
                            <a
                                href={serviceLink}
                                target='_blank'
                                rel={'noreferrer'}
                                onClick={() => closePopup()}
                                {...getTrackingAttributes('forwardToService', 'click')}
                                className={'ProductButton btn btn-primary btn-block'}
                            >
                                <FormattedMessage id={'marketplace.checkout.success.letsstart.button'} />
                            </a>
                        </div>
                    )}
                    {openMarketplaceProductLink && (
                        <div className='panel panel-default panel-body bg-decent line-height-125rel flex-1-1-0 display-flex flex-column'>
                            <div className='text-size-20 text-bold margin-bottom-10'>
                                <FormattedMessage id={'marketplace.checkout.success.howtouse.title'} />
                            </div>
                            <div className='margin-bottom-20 flex-1-1'>
                                <FormattedMessage id={'marketplace.checkout.success.howtouse.text'} />
                            </div>
                            <a
                                href={openMarketplaceProductLink}
                                target='_blank'
                                rel={'noreferrer'}
                                className={'OpenMarketplaceProductLink btn btn-primary btn-outline btn-block'}
                            >
                                <FormattedMessage id={'marketplace.checkout.success.howtouse.button'} />
                            </a>
                        </div>
                    )}
                    <div className='panel panel-default panel-body bg-decent line-height-125rel flex-1-1-0 display-flex flex-column'>
                        <div className='text-size-20 text-bold margin-bottom-10'>
                            <FormattedMessage id={'marketplace.checkout.success.exploremore.title'} />
                        </div>
                        <div className='margin-bottom-20 flex-1-1'>
                            <FormattedMessage id={'marketplace.checkout.success.exploremore.text'} />
                        </div>
                        <a
                            href={openMarketplaceHomeLink}
                            target='_blank'
                            rel={'noreferrer'}
                            className={'OpenMarketplaceLink btn btn-primary btn-outline btn-block'}
                        >
                            <FormattedMessage id={'marketplace.checkout.success.exploremore.button'} />
                        </a>
                    </div>
                </div>
                <div className='text-center margin-top-25'>
                    <FormattedMessage id={'marketplace.checkout.success.note'} />
                    {props.isOpenedInPopup && (
                        <div>
                            <button
                                className={'btn btn-primary text-center margin-top-10'}
                                onClick={() => closePopup()}
                            >
                                <FormattedMessage id={'marketplace.checkout.closePopup'} />
                            </button>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    return <div className='Subscription'>{content}</div>;
};

const mapStateToProps = (state: RootState) => ({
    subscriptionCreationInProgress: getIsLoading(state, ApiCalls.SUBSCRIPTION_CREATE),
    hasError: getHasError(state, ApiCalls.SUBSCRIPTION_CREATE),
    isOpenedInPopup: getIsOpenedInPopup(state),
    productId: getSelectedProductId(state),
    product: getSelectedProduct(state),
});

export const CheckoutConfirmationPageContainer = connect(mapStateToProps)(CheckoutConfirmationPage);
