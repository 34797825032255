import {
    KOREA_OPENMARKETPLACE_BASE_URL,
    LATAM_OPENMARKETPLACE_BASE_URL,
    OPENMARKETPLACE_BASE_URL,
    PRODUCT_DETAILS_REDIRECT,
    REDIRECT_CONFIG,
    setLocaleForRedirect,
} from '../config/openMarketplaceRedirectConfig';
import { Tenant } from '../common/utils/tenants';
import { normalizeLocaleForKoreanUserOrHandThrough } from '../common/utils/koreaUtil';
import { getMarketplaceLink } from '../common/ServiceBookButtonContainer';

export function redirectIfSkuMatches(sku: string | undefined, locale: string, tenant: Tenant) {
    if (!sku) {
        return;
    }
    if (tenant === Tenant.BRAZIL) {
        window.location.replace(LATAM_OPENMARKETPLACE_BASE_URL);
        return;
    }
    const isKoreanUser = tenant === Tenant.SOUTH_KOREA;
    const normalizedLocale = normalizeLocaleForKoreanUserOrHandThrough(locale, isKoreanUser);
    const redirect = REDIRECT_CONFIG.filter((entry) => (entry.korea || false) === isKoreanUser).find(
        (entry) => entry.sku.toLowerCase() === sku.toLowerCase()
    );
    if (!redirect) {
        const redirectToStartPage = isKoreanUser
            ? setLocaleForRedirect(KOREA_OPENMARKETPLACE_BASE_URL, normalizedLocale)
            : setLocaleForRedirect(OPENMARKETPLACE_BASE_URL, normalizedLocale);
        window.location.replace(redirectToStartPage);
        return;
    }
    window.location.replace(setLocaleForRedirect(redirect.url, normalizedLocale));
}

export function redirectIfCategoryMatches(category: string | undefined, locale: string, tenant: Tenant) {
    if (tenant === Tenant.BRAZIL || tenant === Tenant.SOUTH_KOREA) {
        window.location.replace(getMarketplaceLink(locale, tenant));
        return;
    }
    const redirect = PRODUCT_DETAILS_REDIRECT.find((it) => it.category.toLowerCase() === category?.toLowerCase());
    if (!redirect) {
        window.location.replace(getMarketplaceLink(locale, tenant));
        return;
    }
    window.location.replace(setLocaleForRedirect(redirect.url, locale));
}
