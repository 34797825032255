import { ApiCalls, ApiLoadingState, SetLoadingPayload } from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../configuration/setup/store';

const initialState: ApiLoadingState = {
    loading: {},
    error: {},
};

export const { reducer: apiCallReducer, actions: apiCallActions } = createSlice({
    name: 'api',
    initialState,
    reducers: {
        setLoadingForApiCall: (state: ApiLoadingState, action: PayloadAction<SetLoadingPayload>) => {
            if (action.payload.loading !== undefined) {
                state.loading[action.payload.type] = action.payload.loading;
            }
            if (action.payload.error !== undefined) {
                state.error[action.payload.type] = action.payload.error;
            }
        },
    },
});

export function apiCallAction(type: ApiCalls, loading?: boolean, error?: boolean) {
    return apiCallActions.setLoadingForApiCall({
        type: type,
        loading: loading,
        error: error,
    });
}

const getBase = (state: RootState): ApiLoadingState | undefined => state.marketplace.api;
const getLoading = (state: RootState) => getBase(state)?.loading;
const getError = (state: RootState) => getBase(state)?.error;

export const getIsLoading = (state: RootState, apiCall: ApiCalls): boolean => getLoading(state)?.[apiCall] || false;
export const getHasError = (state: RootState, apiCall: ApiCalls): boolean => getError(state)?.[apiCall] || false;
