import { UsageSummaryTableRow } from './UsageSummaryTableRow';
import { ResourceUsage } from '../redux/types';
import { connect } from 'react-redux';
import { UsageSummaryColumn, UsageSummaryColumnType } from './types';
import { getUsageSummaryColumns } from './usageSummaryColumnService';
import { getSearchedResourceSummaries } from './usageSummaryItemFilterService';
import { RootState } from '../../../../../configuration/setup/store';

interface UsageSummaryTableBodyProps {
    summaryItems: Array<ResourceUsage>;
    columns: Array<UsageSummaryColumn>;
}
const UsageSummaryTableBody = (props: UsageSummaryTableBodyProps) => {
    const { summaryItems, columns } = props;
    return (
        <tbody>
            {summaryItems.map((summaryItem, index) => {
                return <UsageSummaryTableRow key={index} summaryItem={summaryItem} columns={columns} />;
            })}
        </tbody>
    );
};

const mapStateToResourceProps = (state: RootState) => ({
    summaryItems: getSearchedResourceSummaries(state),
    columns: [{ type: UsageSummaryColumnType.RESOURCE }],
});

const mapStateToUsagesProps = (state: RootState) => ({
    summaryItems: getSearchedResourceSummaries(state),
    columns: getUsageSummaryColumns(state),
});

const mapStateToPriceProps = (state: RootState) => ({
    summaryItems: getSearchedResourceSummaries(state),
    columns: [{ type: UsageSummaryColumnType.PRICE }],
});

export const UsageSummaryTableResourceBodyContainer = connect(mapStateToResourceProps)(UsageSummaryTableBody);
export const UsageSummaryTableUsagesBodyContainer = connect(mapStateToUsagesProps)(UsageSummaryTableBody);
export const UsageSummaryTablePriceBodyContainer = connect(mapStateToPriceProps)(UsageSummaryTableBody);
