import { FormattedMessage } from 'react-intl';
import { ProductState } from '../../checkout/resourceSelection/redux/types';
import { TermedLevel } from '../../common/termedLevel/types';

export function ContractResourceName(props: { contract: TermedLevel }) {
    return (
        <>
            {props.contract.resourceName || props.contract.resourceId}
            {props.contract.hasInactiveActivationState && (
                <div className='text-color-danger'>
                    <FormattedMessage id={'marketplace.history.action.resource.removed'} />
                </div>
            )}
            {props.contract.activationState === ProductState.PENDING_ACTIVATION && (
                <div className='text-color-warning'>
                    <FormattedMessage id={'marketplace.contracts.pendingActivation'} />
                </div>
            )}
        </>
    );
}
