import { getMarketplaceBackend } from '../../config/pathURL/config';
import { getRequest } from '../requests';
import { decodeJson, jsonOrReject, onRejected } from '../api';
import { ApiPermissionsSpec } from './permissions.types';

export function fetchPermissions(): Promise<Array<string>> {
    return fetch(`${getMarketplaceBackend()}/client-permissions`, getRequest())
        .then(jsonOrReject)
        .then(decodeJson(ApiPermissionsSpec))
        .catch(onRejected());
}
