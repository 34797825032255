export interface ConfigState {
    backend: {
        AUTHENTICATION_SERVICE: string | undefined;
        MENU_SERVICE: string | undefined;
    };
    featureToggles: {
        splitApiKey: string | undefined;
    };
    homeRoute: string | undefined;
    id: string | undefined;
    login: {
        authority: string | undefined;
        clientId: string | undefined;
        oauthScope: string[];
        mockAuthorization: boolean;
        mockLocale: string | undefined;
        preventRedirect: boolean;
        redirectUri: string;
        silentRedirectUri: string | undefined;
        requestType: string;
    };
    logoutUri: string | undefined;
    redirectToOpenMarketplace: boolean;
    sentryToken: string | undefined;
}

function getBoolEnvValue(envValue: string) {
    return typeof process.env[envValue] !== 'undefined' && process.env[envValue] === 'true';
}

export const config: ConfigState = {
    backend: {
        AUTHENTICATION_SERVICE: process.env.REACT_APP_AUTHENTICATION_SERVICE,
        MENU_SERVICE: process.env.REACT_APP_MENU_SERVICE,
    },
    featureToggles: {
        splitApiKey: process.env.REACT_APP_FEATURE_TOGGLE_SPLIT_API_KEY,
    },
    homeRoute: process.env.REACT_APP_HOME_ROUTE,
    id: process.env.REACT_APP_ID,
    login: {
        authority: process.env.REACT_APP_LOGIN_AUTHORITY,
        clientId: process.env.REACT_APP_CLIENT_ID,
        oauthScope: [
            'default',
            'openid',
            'profile',
            'email',
            'marketplace.read',
            'marketplace.write',
            'partner-connection.write',
            'partner-connection.read',
        ],
        requestType: getBoolEnvValue('REACT_APP_LOGIN_REQUEST_TYPE_CODE') ? 'code' : 'id_token token',
        mockAuthorization: getBoolEnvValue('REACT_APP_LOGIN_MOCK_AUTHORIZATION'),
        mockLocale: process.env.REACT_APP_LOGIN_MOCK_LOCALE,
        preventRedirect: getBoolEnvValue('REACT_APP_LOGIN_PREVENT_REDIRECT'),
        redirectUri: process.env.REACT_APP_LOGIN_REDIRECT_URI as string,
        silentRedirectUri: process.env.REACT_APP_LOGIN_SILENT_REDIRECT_URI,
    },
    logoutUri: process.env.REACT_APP_LOGOUT_URI,
    redirectToOpenMarketplace: getBoolEnvValue('REACT_APP_OPENMARKETPLACE_REDIRECT'),
    sentryToken: process.env.REACT_APP_SENTRY_DSN,
};
