import { Dispatch } from 'redux';
import { sendError } from '../../../../configuration/lang/services';
import { fetchBillingAddress } from '../../api/billingAddress/billingAddressCalls';
import { apiCallAction } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import { FetchError } from '../../api/FetchError';
import { getAccountId } from '../../../../configuration';
import { AppThunk, RootState } from '../../../../configuration/setup/store';
import { isBrazilianTenant } from '../utils/tenants';
import { billingActions, getBillingAddress } from './redux/billing.redux';

export const fetchBillingAddressInfoThunk = (dispatch: Dispatch, getState: () => RootState) => {
    const accountId = getAccountId(getState());
    if (!accountId) {
        sendError(new Error('Billing address fetch called without an authenticated Account Id'));
        return Promise.reject();
    }

    const billingInfo = getBillingAddress(getState());
    if (billingInfo && billingInfo.countryCode) {
        return Promise.resolve();
    }

    dispatch(apiCallAction(ApiCalls.BILLING_ADDRESS, true, false));
    return fetchBillingAddress(accountId)
        .then((billingAddress) => {
            dispatch(billingActions.setBillingAddress(billingAddress));
            dispatch(apiCallAction(ApiCalls.BILLING_ADDRESS, false, false));
        })
        .catch((error) => {
            dispatch(billingActions.setBillingErrorCode((error as FetchError).statusCode));
            dispatch(apiCallAction(ApiCalls.BILLING_ADDRESS, false, true));
        });
};

export function fetchBillingAddressIfNecessaryByTenant(): AppThunk {
    return (dispatch, getState) => {
        if (!isBrazilianTenant(getState())) {
            dispatch(fetchBillingAddressInfoThunk);
        }
    };
}
