import { useCallback, useEffect, useRef, useState } from 'react';
import {
    UsageSummaryTableResourceColGroupsContainer,
    UsageSummaryTablePriceColGroupsContainer,
    UsageSummaryTableUsagesColGroupsContainer,
} from './UsageSummaryTableColGroupsContainer';
import {
    UsageSummaryTableResourceBodyContainer,
    UsageSummaryTablePriceBodyContainer,
    UsageSummaryTableUsagesBodyContainer,
} from './UsageSummaryTableBodyContainer';
import {
    UsageSummaryTableResourceHeadContainer,
    UsageSummaryTablePriceHeadContainer,
    UsageSummaryTableUsagesHeadContainer,
} from './UsageSummaryTableHeadContainer';

function extractHeight(tableWrapperDiv: HTMLDivElement | null): number | null {
    if (tableWrapperDiv === null) {
        return null;
    }
    // Somehow we need to subtract 2px because of border magic together with flexbox layout
    const calculatedHeight = tableWrapperDiv.scrollTop + tableWrapperDiv.offsetHeight - 2;
    return calculatedHeight > tableWrapperDiv.scrollHeight ? tableWrapperDiv.scrollHeight : calculatedHeight;
}

const priceTableWrapperId = 'priceTableWrapper';

export function UsageSummaryTable(props: { searchValue: string }) {
    const [height, setHeight] = useState<number | null>(null);
    const tableDiv = useRef<HTMLDivElement>(null);

    // Scrollbar of middle table should always be visible
    const calculateHeight = useCallback(() => {
        const tableAndScrollHeight = extractHeight(tableDiv.current);
        if (tableAndScrollHeight !== height && tableAndScrollHeight !== null) {
            setHeight(tableAndScrollHeight);
        }
    }, [tableDiv, height, setHeight]);

    useEffect(() => {
        // Need to calculate height after everything is rendered, thus with setTimeout
        const timeoutId = setTimeout(calculateHeight, 1);
        return () => clearTimeout(timeoutId);
    }, [props.searchValue, calculateHeight]);

    useEffect(() => {
        const scroll = tableDiv.current?.scroll?.bind(tableDiv.current);
        if (typeof scroll === 'function') {
            scroll({ top: 0 });
        }
    }, [props.searchValue, tableDiv]);

    useEffect(() => {
        window.addEventListener('resize', calculateHeight);
        return () => window.removeEventListener('resize', calculateHeight);
    });

    return (
        <div
            id={priceTableWrapperId}
            className={'panel panel-default display-flex overflow-x-hidden overflow-y-auto'}
            style={{ maxHeight: '60vh' }}
            ref={tableDiv}
            onScroll={calculateHeight}
        >
            <div>
                <table className={'table margin-bottom-0'}>
                    <UsageSummaryTableResourceColGroupsContainer />
                    <UsageSummaryTableResourceHeadContainer />
                    <UsageSummaryTableResourceBodyContainer />
                </table>
            </div>

            <div className={'position-relative flex-1-1'}>
                <div
                    id={'priceTableScrollarea'}
                    className={
                        'border-style-solid border-width-2 border-color-lighter border-top-0 border-bottom-0 ' +
                        'position-absolute top-0 right-0 left-0 overflow-x-auto overflow-y-hidden'
                    }
                    style={{ height: `${height}px` }}
                >
                    <table className={'table table-sticky margin-bottom-0'}>
                        <UsageSummaryTableUsagesColGroupsContainer />
                        <UsageSummaryTableUsagesHeadContainer />
                        <UsageSummaryTableUsagesBodyContainer />
                    </table>
                </div>
            </div>

            <div>
                <table className={'table margin-bottom-0'}>
                    <UsageSummaryTablePriceColGroupsContainer />
                    <UsageSummaryTablePriceHeadContainer />
                    <UsageSummaryTablePriceBodyContainer />
                </table>
            </div>
        </div>
    );
}
