/* eslint-disable camelcase */
import { getMarketplaceBackend } from '../../config/pathURL/config';
import { postRequest } from '../requests';
import { onRejected, rejectIfNotOk } from '../api';
import { ApiSubscriptionCancelPayload } from './subscription.types';
import { toSubscriptionCreateBody } from './subscriptionMapper';
import { BookableProduct } from '../../checkout/redux/types';

export function postSubscriptionCancel(productId: string, resourceIds: Array<string>): Promise<void> {
    const body: ApiSubscriptionCancelPayload = { resource_ids: resourceIds };
    return fetch(`${getMarketplaceBackend()}/activations/${productId}/deactivate`, postRequest(body))
        .then(rejectIfNotOk)
        .catch(onRejected());
}

export async function postSubscriptionCreate(resourceIds: Array<string>, product: BookableProduct): Promise<void> {
    const body = toSubscriptionCreateBody(resourceIds, product);

    return await fetch(`${getMarketplaceBackend()}/subscriptions`, postRequest(body)).then(rejectIfNotOk);
}
