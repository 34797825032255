import { IntlShape } from 'react-intl';

const getTechnicalDetails = (missingFeatures: Array<string>) => {
    return missingFeatures.map((feature, index) => {
        return <li key={index}>{feature}</li>;
    });
};

interface Props {
    intl: IntlShape;
    missingRequirements: Array<Array<string>>;
}

export const MissingRequirement = (props: Props) => {
    const { intl, missingRequirements } = props;
    return (
        <div className='missingFeatures text-left margin-bottom-10'>
            <br />
            {`${intl.formatMessage({ id: 'marketplace.missingFeatureTooltip.header' })}:`}
            {missingRequirements.map((missingFeatures, index) => (
                <div key={index}>
                    {index > 0 && intl.formatMessage({ id: 'marketplace.missingFeature.requirementDivider' })}
                    <ul className='margin-bottom-0'>{getTechnicalDetails(missingFeatures)}</ul>
                </div>
            ))}
        </div>
    );
};
