import { DEFAULT_BILLING_PERIOD, PRODUCT_CATEGORY_GA } from '../common/product/product';
import { BookableProduct, isBookableProduct } from './redux/types';
import { getSelectedProduct, getSelectedProductOrProductLevel } from './redux/checkout.redux';
import { getSelectedResourceIds } from './resourceSelection/redux/resourceSelection.redux';
import { getGTMPurchaseId } from '../common/utils/purchaseIdUtil';
import { RootState } from '../../../configuration/setup/store';

export enum CheckoutSteps {
    TO_BILLING_ADDRESS = 1,
    PAYMENT_INFORMATION_ENTERED = 2,
    ORDER_PREVIEWED = 3,
    // ORDER_CONFIRMED = 4, // getGAEventPurchase is sent
}
export interface GACheckoutStepData {
    product: BookableProduct | undefined;
    numberOfSelectedResources: number;
    step: number;
}

export function getGACheckoutStepData(state: RootState, step: CheckoutSteps): GACheckoutStepData {
    let product;
    try {
        product = getSelectedProduct(state) && getSelectedProductOrProductLevel(state);
    } catch (e) {
        // ignore because service is not bookable in this case
    }
    return {
        product: product,
        numberOfSelectedResources: getSelectedResourceIds(state).length,
        step: step.valueOf(),
    };
}

export function getGtmProductId(product: BookableProduct) {
    return product.sku + (product.level ? `_${product.level}` : '');
}

export function getGAEventCheckoutProgress(gaData: GACheckoutStepData) {
    const { product, numberOfSelectedResources, step } = gaData;
    if (!product) {
        throw Error('Product is always present within the Checkout process, but was not');
    }

    return {
        event: 'EECcheckout',
        ecommerce: {
            currencyCode: product.currency,
            checkout: {
                actionField: { step: step },
                products: [
                    {
                        name: product.name,
                        id: getGtmProductId(product),
                        price: product.price.toString(),
                        priceModel: DEFAULT_BILLING_PERIOD,
                        category: isBookableProduct(product) ? PRODUCT_CATEGORY_GA.PAID : PRODUCT_CATEGORY_GA.FREE,
                        quantity: numberOfSelectedResources,
                    },
                ],
            },
        },
    };
}

export function getGAEventPurchase(
    product: BookableProduct,
    numberOfSelectedResources: number,
    totalPrice: number,
    accountId: string
) {
    const bookingDate = new Date();
    return {
        event: 'EECpurchase',
        ecommerce: {
            currencyCode: isBookableProduct(product) ? product.currency : undefined,
            purchase: {
                actionField: {
                    id: getGTMPurchaseId(product.sku, accountId, product.level),
                    revenue: totalPrice, // Total transaction value (without tax)
                    tax: 0, // It should be sent as zero since the information is not used
                },
                products: [
                    {
                        name: product.name,
                        id: getGtmProductId(product),
                        price: isBookableProduct(product) ? product.price.toString() : undefined,
                        priceModel: DEFAULT_BILLING_PERIOD,
                        category: isBookableProduct(product) ? PRODUCT_CATEGORY_GA.PAID : PRODUCT_CATEGORY_GA.FREE,
                        quantity: numberOfSelectedResources,
                        bookingDate:
                            `${bookingDate.getUTCDate()}` +
                            `.${bookingDate.getUTCMonth() + 1}.${bookingDate.getUTCFullYear()}`,
                    },
                ],
            },
        },
    };
}

export function getGAEventTermsAccepted({ product }: GACheckoutStepData) {
    return (
        product && {
            event: 'TermsAndConditions',
            eventPayload: {
                productId: getGtmProductId(product),
            },
        }
    );
}
