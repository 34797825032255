import { ProductInfo } from '../redux/types';
import { ProductCellBookedContainer } from './ProductCellBooked';
import { ProductCellBookableContainer } from './ProductCellBookable';
import { ProductCellNotBookableContainer } from './ProductCellNotBookable';
import { ProductCellPendingContainer } from './ProductCellPending';
import { ProductCellBookedContractContainer } from './ProductCellBookedContract';

interface Props {
    product: ProductInfo;
    assetId: string;
    assetName: string;
}

export const ProductCell = (props: Props) => {
    const { product, assetId, assetName } = props;

    if (product.booked && !product.isPending && product.hasContractPeriod && !product.bookable) {
        return <ProductCellBookedContractContainer product={product} assetId={assetId} />;
    } else if (product.booked && !product.isPending && !product.hasContractPeriod) {
        return <ProductCellBookedContainer product={product} assetId={assetId} assetName={assetName} />;
    } else if (product.isPending) {
        return <ProductCellPendingContainer product={product} />;
    } else if (product.bookable) {
        return <ProductCellBookableContainer product={product} assetId={assetId} assetName={assetName} />;
    }

    return <ProductCellNotBookableContainer product={product} />;
};
