export const PAYMENT_METHOD_PRESENT_TYPE = 'PAYMENT_METHOD_PRESENT';

const ALLOWED_TARGET_ORIGINS = [/rio.cloud(\/|$)/i, /localhost(\/|$)/i];

function isAnAllowedOrigin(targetOrigin: string) {
    return ALLOWED_TARGET_ORIGINS.some((origin) => origin.test(targetOrigin));
}

let postMessageSent = false;

export function resetPaymentMethodPostMessageFlag() {
    postMessageSent = false;
}

export function postPaymentMethodPresentIfInIframe() {
    if (postMessageSent) {
        return;
    }
    const runsInIframe = window.parent && window.parent !== window;

    const targetOrigin = document.referrer;
    if (!targetOrigin || !isAnAllowedOrigin(targetOrigin)) {
        return;
    }

    if (runsInIframe) {
        window.parent.postMessage({ type: PAYMENT_METHOD_PRESENT_TYPE }, targetOrigin);
        postMessageSent = true;
    }
}
