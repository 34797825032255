import { FormattedMessage } from 'react-intl';
import { getLocale } from '../../../configuration';
import { connect } from 'react-redux';
import { getTenant, Tenant } from './utils/tenants';
import { normalizeLocaleForKoreanUserOrHandThrough } from './utils/koreaUtil';
import {
    KOREA_OPENMARKETPLACE_BASE_URL,
    LATAM_OPENMARKETPLACE_BASE_URL,
    OPENMARKETPLACE_BASE_URL,
    setLocaleForRedirect,
} from '../config/openMarketplaceRedirectConfig';
import { RootState } from '../../../configuration/setup/store';

export function getMarketplaceLink(locale: string, tenant: Tenant) {
    if (tenant === Tenant.BRAZIL) {
        return LATAM_OPENMARKETPLACE_BASE_URL;
    } else if (tenant === Tenant.SOUTH_KOREA) {
        const koreanNormalizedLocale = normalizeLocaleForKoreanUserOrHandThrough(locale, true);
        return setLocaleForRedirect(KOREA_OPENMARKETPLACE_BASE_URL, koreanNormalizedLocale);
    }
    return setLocaleForRedirect(OPENMARKETPLACE_BASE_URL, locale);
}

const ServiceBookButton = ({ locale, tenant }: { locale: string; tenant: Tenant }) => {
    const marketplaceLink = getMarketplaceLink(locale, tenant);
    return (
        <a className={'btn btn-default'} href={marketplaceLink} target='_blank' rel='noopener noreferrer'>
            <span className='rioglyph rioglyph-shopping-cart' aria-hidden='true' />
            <FormattedMessage id='marketplace.exploreServices' />
        </a>
    );
};

const mapStateToProps = (state: RootState) => ({
    locale: getLocale(state),
    tenant: getTenant(state),
});

export const ServiceBookButtonContainer = connect(mapStateToProps)(ServiceBookButton);
