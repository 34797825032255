import { InMemoryWebStorage, Profile, User, UserManager, UserSettings, WebStorageStateStore } from 'oidc-client';
import join from 'lodash/fp/join';
import { mapUserProfile } from './userProfile';
import { config } from '../../config';
import { AccessToken } from '../tokenHandling/tokenSlice';
import { UserProfile } from './loginSlice';
import { isLATAMToggleEnabled } from '../../features/app/common/toggles/featureToggles';
import { jwtDecode } from 'jwt-decode';

export interface OAuthConfig {
    onSessionExpired: Function;
    onSessionRenewed: Function;
}

export interface SessionRenewedResult {
    accessToken: AccessToken;
    idToken: Profile;
    profile: UserProfile;
    locale: string;
}

const trace =
    process.env.NODE_ENV !== 'production' ? (...args: any[]) => console.log(`[oidcLogin]`, ...args) : () => {};
const RETRY_SIGNIN_TIMEOUT_IN_MS = 30000;

const retrySigninSilent = (oauthConfig: OAuthConfig, userManager: UserManager) => {
    userManager.signinSilent().catch((error: Error) => {
        if (error.message === 'login_required') {
            oauthConfig.onSessionExpired();
        } else {
            setTimeout(() => retrySigninSilent(oauthConfig, userManager), RETRY_SIGNIN_TIMEOUT_IN_MS);
        }
    });
};

export const adaptPublishedInfo = (result: UserSettings): SessionRenewedResult => ({
    accessToken: result.access_token,
    idToken: result.profile,
    locale: result.profile?.locale ?? 'en-GB',
    profile: mapUserProfile(result.profile),
});

export const createUserManager = () => {
    const redirectUri = config.login.redirectUri;
    const silentRedirectUri = config.login.silentRedirectUri;

    const settings = {
        authority: `${config.login.authority}`,
        client_id: `${config.login.clientId}`,
        loadUserInfo: false,
        redirect_uri: `${redirectUri}`,
        response_type: `${config.login.requestType}`,
        scope: join(' ', config.login.oauthScope),
        silent_redirect_uri: `${silentRedirectUri || redirectUri}`,
        includeIdTokenInSilentRenew: false,
        automaticSilentRenew: true,
        staleStateAge: 600,
        userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
    };

    trace('oidc.auth.settings', settings);

    return new UserManager(settings);
};

export const configureUserManager = (oauthConfig: OAuthConfig, userManager: UserManager) => {
    userManager.events.addUserLoaded((user) => {
        trace('oidc.signinSilent success!');
        oauthConfig.onSessionRenewed(adaptPublishedInfo(user as UserSettings));
    });

    userManager.events.addUserUnloaded(() => {
        oauthConfig.onSessionExpired();
    });

    userManager.events.addSilentRenewError((error) => {
        trace('oidc.silentRenewError', error);
        retrySigninSilent(oauthConfig, userManager);
    });

    userManager.events.addUserSignedOut((...args) => {
        trace('oidc.userSignedOut', ...args);
        oauthConfig.onSessionExpired();
    });

    return userManager;
};

export const configureMockUserManager = (oauthConfig: OAuthConfig, accessToken?: string, idToken?: string) => {
    // eslint-disable-next-line no-console
    console.warn(`[feature/login/oidc-session] Using mocked authorization due to config setting`);

    const signinSilent = () => {
        const userSettings: UserSettings = {
            access_token: accessToken || 'valid-mocked-oauth-bogus-token',
            // eslint-disable-next-line no-magic-numbers
            profile: idToken
                ? jwtDecode(idToken)
                : {
                      account: 'mockaccount',
                      azp: 'test-client',
                      email: 'test@example.com',
                      family_name: 'Client',
                      given_name: 'Test',
                      locale: config.login.mockLocale,
                      name: 'Test Client',
                      username: 'test@example.com',
                      tenant: isLATAMToggleEnabled() ? 'rio-brazil.prod' : 'rio-eu.prod',
                      sub: 'prod-rio-users:mock-user',
                  },
        } as UserSettings;
        const user = new User(userSettings);
        oauthConfig.onSessionRenewed(adaptPublishedInfo(userSettings));
        return Promise.resolve(user);
    };
    const clearStaleState = () => {
        console.log('stale state cleared');
        return Promise.resolve();
    };

    return { signinSilent, clearStaleState };
};
