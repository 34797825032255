import { getSelectedProduct, getSelectedProductLevel } from '../redux/checkout.redux';
import { isMultiLevelProduct, MultiLevelProduct, ProductLevel } from '../redux/types';
import { getFilteredGroupedResources } from '../resourceSelection/resourceSelectionSearchService';
import { getSelectedResourceIds } from '../resourceSelection/redux/resourceSelection.redux';
import { BookableResource } from '../resourceSelection/redux/types';
import { FormattedMessage, useIntl } from 'react-intl';
import { formatPrice } from '../../common/utils/currencyUtil';
import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';
import { connect } from 'react-redux';
import { RootState } from '../../../../configuration/setup/store';
import { ProductTypeAwareFormattedMessage } from '../resourceSelection/ProductTypeAwareFormattedMessage';

interface Props {
    product?: MultiLevelProduct;
    selectedProductLevel?: string;
    selectedResources: Array<BookableResource>;
}

function getResourceCount(selectedResources: Array<BookableResource>, level: string) {
    return selectedResources.filter((resource) => resource.targetLevel === level).length;
}

function calculateEffectivePrice(
    selectedLevel: ProductLevel,
    notSelectedLevels: Array<ProductLevel>,
    selectedResources: Array<BookableResource>
) {
    const pricePerLevel = notSelectedLevels.map((it) => {
        const resourceCount = getResourceCount(selectedResources, it.level);
        return resourceCount * it.price;
    });
    return selectedResources.length * selectedLevel.price - pricePerLevel.reduce((a, b) => a + b, 0);
}

function TableRow(props: { productLevel: ProductLevel; resourceCount: number; added: boolean }) {
    const { productLevel, resourceCount, added } = props;
    const intl = useIntl();
    const sign = added ? 1 : -1;
    return (
        <tr key={productLevel.level}>
            <td>{intl.formatMessage({ id: `marketplace.preview.multilevel.${added ? 'added' : 'removed'}` })}</td>
            <td>{productLevel.name}</td>
            <td className={'text-center'}>{resourceCount}</td>
            <td className={'text-right'}>{formatPrice(intl, sign * productLevel.price, productLevel.currency)} *</td>
            <td className={'text-right'}>
                {formatPrice(intl, sign * resourceCount * productLevel.price, productLevel.currency)}
            </td>
        </tr>
    );
}

const MultiLevelPriceDetails = (props: Props) => {
    const { product, selectedProductLevel, selectedResources } = props;
    const intl = useIntl();

    if (!product || !selectedProductLevel) {
        return null;
    }

    const selectedLevel = product.levels.find((it) => it.level === selectedProductLevel);
    if (!selectedLevel) {
        throw Error(`Selected level ${selectedLevel} not found.`);
    }

    const notSelectedLevels = product.levels
        .filter((it) => it.level !== selectedProductLevel)
        .filter((it) => getResourceCount(selectedResources, it.level) > 0);
    if (notSelectedLevels.length === 0) {
        return null;
    }
    const effectivePrice = calculateEffectivePrice(selectedLevel, notSelectedLevels, selectedResources);
    const effectiveFormattedPrice = formatPrice(intl, effectivePrice, selectedLevel.currency);

    const title = (
        <div>
            <div>
                <span className={'margin-right-5 rioglyph rioglyph-info-sign'} />
                <span>{intl.formatMessage({ id: 'marketplace.preview.multilevel.panel.title' })}</span>
                <span className={'margin-right-20'}>:</span>
                <span className={'margin-right-5'}>{effectiveFormattedPrice}</span>
                <span>{intl.formatMessage({ id: 'marketplace.productDetails.price.rate.total' })}</span>
            </div>
        </div>
    );
    return (
        <div>
            <ExpanderPanel title={title} bsStyle={'default'} titleClassName={'width-100pct'}>
                <table className={'table'}>
                    <thead>
                        <tr>
                            <th>
                                {intl.formatMessage({ id: 'marketplace.preview.multilevel.removed' })}/
                                {intl.formatMessage({ id: 'marketplace.preview.multilevel.added' })}
                            </th>
                            <th>{intl.formatMessage({ id: 'marketplace.preview.multilevel.service' })}</th>
                            <th>
                                <ProductTypeAwareFormattedMessage id={'previewTable.header.resources'} />
                            </th>
                            <th className={'text-right'}>
                                <ProductTypeAwareFormattedMessage id={'previewTable.header.price'} />
                            </th>
                            <th className={'text-right'}>
                                {intl.formatMessage({ id: 'marketplace.preview.multilevel.total.per.day' })}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {notSelectedLevels.map((it) => (
                            <TableRow
                                key={it.level}
                                productLevel={it}
                                resourceCount={getResourceCount(selectedResources, it.level)}
                                added={false}
                            />
                        ))}
                        <TableRow productLevel={selectedLevel} resourceCount={selectedResources.length} added={true} />
                        <tr>
                            <td colSpan={4} className={'text-right'}>
                                {intl.formatMessage({ id: 'marketplace.preview.multilevel.total.per.day' })}
                            </td>
                            <td className={'text-right'}>{effectiveFormattedPrice}</td>
                        </tr>
                    </tbody>
                </table>
                <div className={'text-right'}>
                    {'* '}
                    <FormattedMessage id={'marketplace.usage.summary.price.disclaimer'} />
                </div>
            </ExpanderPanel>
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    const selectedProduct = getSelectedProduct(state);
    const bookableResource = getFilteredGroupedResources(state).bookable;
    const selectedResourceIds = getSelectedResourceIds(state);

    return {
        product: isMultiLevelProduct(selectedProduct) ? selectedProduct : undefined,
        selectedProductLevel: getSelectedProductLevel(state),
        selectedResources: bookableResource.filter((it) => selectedResourceIds.includes(it.id)),
    };
};

export const MultiLevelPriceDetailsContainer = connect(mapStateToProps)(MultiLevelPriceDetails);
