/* eslint-disable camelcase */
import * as t from 'io-ts';

export const ApiPaymentSecretCodec = t.type({
    secret: t.string,
});
export type ApiPaymentSecret = t.TypeOf<typeof ApiPaymentSecretCodec>;

export const ApiPaymentCodec = t.type({
    id: t.string,
    status: t.intersection([
        t.type({
            type: t.string,
        }),
        t.partial({
            code: t.string,
        }),
    ]),
    timestamp: t.string,
    amount: t.number,
    currency: t.string,
});

export const ApiPaymentsCodec = t.type({
    payments: t.array(ApiPaymentCodec),
});

export type ApiPayment = t.TypeOf<typeof ApiPaymentCodec>;
export type ApiPayments = t.TypeOf<typeof ApiPaymentsCodec>;
