import pick from 'lodash/pick';
import Checkbox from '@rio-cloud/rio-uikit/lib/es/Checkbox';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { ContractTableRowRenewCell } from './ContractTableRowRenewCell';
import { contractActions, getSelectedContracts } from './redux/contract.redux';
import { ContractState } from './ContractState';
import { ReactElement } from 'react';
import { ContractResourceName } from './ContractResourceName';
import { ContractDuration } from './ContractDuration';
import { getProductLabelFromContract } from '../../common/utils/productLabelUtil';
import { RootDispatch, RootState } from '../../../../configuration/setup/store';
import { TermedLevel } from '../../common/termedLevel/types';
import { termedLevelRenewalActions } from '../../common/termedLevel/redux/termedLevelRenewal.redux';
import { TermedLevelId } from '../../common/termedLevel/redux/types';
import { isTermedLevelSelectable } from '../../common/termedLevel/termedLevelSelectionValidationService';
import { ProductTypedFormattedMessage } from '../../common/product/ProductTypedFormattedMessage';
import { ProductType } from '../../common/product/product';
import { useIsFleetUser } from '../../common/permissions/hooks';

export const getColumns = (
    productType: ProductType
): Array<{ propertyName: keyof TermedLevel; label: ReactElement; width?: string }> => [
    {
        propertyName: 'resourceName',
        label: (
            <ProductTypedFormattedMessage
                productType={productType}
                userBasedId='marketplace.contracts.user.table.header'
                assetBasedId='marketplace.contracts.asset.table.header'
            />
        ),
    },
    { propertyName: 'productLevelName', label: <FormattedMessage id={'intl-msg:common-message.contracts.type'} /> },
    {
        propertyName: 'periodLength',
        label: <FormattedMessage id={'intl-msg:common-message.contracts.period'} />,
        width: '15%',
    },
    {
        propertyName: 'remainingDays',
        label: <FormattedMessage id={'intl-msg:common-message.contracts.remainingDays'} />,
        width: '10%',
    },
    {
        propertyName: 'contractState',
        label: <FormattedMessage id={'intl-msg:common-message.contracts.state'} />,
        width: '15%',
    },
    { propertyName: 'renewsToLevelName', label: <FormattedMessage id={'intl-msg:common-message.contracts.renewal'} /> },
];

interface Props {
    contract: TermedLevel;
    updateContract: (data: TermedLevelId & { renewsToLevel: string | undefined }) => void;
    isContractSelected: boolean;
    toggleSelectedContract: (contractId: TermedLevelId) => void;
}

export function ContractTableRow(props: Props) {
    const { contract, updateContract, isContractSelected, toggleSelectedContract } = props;
    const fleetUser = useIsFleetUser();

    const onClickRenewalButton = (e: React.MouseEvent) => {
        e.stopPropagation();
        updateContract({
            productId: contract.productId,
            resourceId: contract.resourceId,
            renewsToLevel: contract.renewsToLevel,
        });
    };

    return (
        <tr
            onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                if (fleetUser) {
                    return;
                }
                if (isTermedLevelSelectable(contract)) {
                    toggleSelectedContract(pick(contract, ['productId', 'resourceId']));
                }
            }}
        >
            <td>
                {isTermedLevelSelectable(contract) && <Checkbox checked={isContractSelected} disabled={fleetUser} />}
            </td>
            <td>
                <ContractResourceName contract={contract} />
            </td>
            <td>{getProductLabelFromContract(contract, useIntl())}</td>
            <td>
                <ContractDuration contract={contract} />
            </td>
            <td>
                {contract.remainingDays && (
                    <span className={classNames({ 'text-color-warning text-medium': contract.criticalRemainingDays })}>
                        {contract.remainingDays} <FormattedMessage id={'marketplace.days'} />
                    </span>
                )}
            </td>
            <td>
                <ContractState contractState={contract.contractState} />
            </td>
            <td>
                <ContractTableRowRenewCell contract={contract} onClick={onClickRenewalButton} />
            </td>
        </tr>
    );
}

const mapStateToProps = (state: RootState, ownProps: { contract: TermedLevel }) => ({
    isContractSelected:
        getSelectedContracts(state).find(
            (contractId) =>
                ownProps.contract.productId === contractId.productId &&
                ownProps.contract.resourceId === contractId.resourceId
        ) !== undefined,
});

const mapDispatchToProps = (dispatch: RootDispatch) => ({
    updateContract: (data: TermedLevelId & { renewsToLevel: string | undefined }) => {
        const { renewsToLevel, ...contractId } = data;
        dispatch(
            termedLevelRenewalActions.selectForUpdate({
                contracts: [contractId],
                renewsToLevel,
                productId: data.productId,
            })
        );
    },
    toggleSelectedContract: (contractId: TermedLevelId) => dispatch(contractActions.toggleSelectedContract(contractId)),
});

export const ContractTableRowContainer = connect(mapStateToProps, mapDispatchToProps)(ContractTableRow);
