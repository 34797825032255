import { UsageDownloadButtonContainer } from './UsageDownloadButtonContainer';
import { UsageMonthSelectorContainer } from './UsageMonthSelectorContainer';
import { UsageSummaryTableContainer } from './table/UsageSummaryTableContainer';
import { UsageTableSearchContainer } from './UsageTableSearchContainer';
import { WidthToggleButton, useWidthToggleState } from './WidthToggleButton';
import { UsageResourceTypeSelectorContainer } from './UsageResourceTypeSelectorContainer';

export const Usage = () => {
    const widthToggleState = useWidthToggleState();

    return (
        <div className={`UsageContainer container-fluid ${widthToggleState.isFullWidth ? '' : 'fluid-default'}`}>
            <div className={'table-toolbar margin-bottom-0'}>
                <div className={'table-toolbar-container'}>
                    <div className={'table-toolbar-group-left'}>
                        <div className={'table-toolbar-column'}>
                            <UsageResourceTypeSelectorContainer />
                        </div>
                        <div className={'table-toolbar-column'}>
                            <UsageMonthSelectorContainer />
                        </div>
                        <div className={'table-toolbar-column'}>
                            <div className={'btn-toolbar table-btn-toolbar'}>
                                <UsageDownloadButtonContainer />
                            </div>
                        </div>
                    </div>
                    <div className={'table-toolbar-group-right'}>
                        <div className={'table-toolbar-column'}>
                            <div className={'table-toolbar-search input-group'}>
                                <UsageTableSearchContainer />
                            </div>
                        </div>
                        <div className={'table-toolbar-column'}>
                            <WidthToggleButton {...widthToggleState} />
                        </div>
                    </div>
                </div>
            </div>
            <UsageSummaryTableContainer />
        </div>
    );
};
