import { useEffect } from 'react';
import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';
import TagList from '@rio-cloud/rio-uikit/lib/es/TagList';
import { ResourceSelectionItemContainer } from './ResourceSelectionItem';
import { connect } from 'react-redux';
import { RESOURCE_SELECTION_TAGS_PER_ROW } from './ResourceSelectionListContainer';
import { getFilteredGroupedResources } from './resourceSelectionSearchService';
import { BookableResource } from './redux/types';
import { getInvalidSelectedResources, isSelectedFilter } from './preSelectionService';
import { reportErrorToSentry } from '../../../../configuration/setup/sentry';
import { RootState } from '../../../../configuration/setup/store';
import {
    ProductTypeAwareFormattedMessage,
    ProductTypeAwareFormattedMessageId,
} from './ProductTypeAwareFormattedMessage';

interface Props extends React.PropsWithChildren {
    resources: Array<BookableResource>;
    labelId: ProductTypeAwareFormattedMessageId;
    isBooked: boolean;
    open?: boolean;
    isSelected?: boolean;
}

const ResourceSelectionExpander = ({
    children,
    resources,
    labelId,
    isBooked,
    isSelected = false,
    open = false,
}: Props) => {
    if (resources.length === 0) {
        return null;
    }

    return (
        <ExpanderPanel
            title={
                <b>
                    <ProductTypeAwareFormattedMessage id={labelId} />
                    {` (${resources.length})`}
                </b>
            }
            bsStyle='default'
            open={open}
        >
            <TagList tagsPerRow={RESOURCE_SELECTION_TAGS_PER_ROW}>
                {resources.map((resource) => {
                    return (
                        <ResourceSelectionItemContainer
                            resource={resource}
                            key={resource.id}
                            isBooked={isBooked}
                            isSelected={isSelected}
                            noBookableTooltip={isSelected}
                        />
                    );
                })}
            </TagList>
            {children}
        </ExpanderPanel>
    );
};

const mapStateToBookedProps = (state: RootState) => ({
    resources: getFilteredGroupedResources(state).booked,
    labelId: 'bookedResources' as const,
    isBooked: true,
});

const mapStateToNotBookableProps = (state: RootState) => ({
    resources: getFilteredGroupedResources(state).notBookable,
    labelId: 'notBookableResources' as const,
    isBooked: false,
});

const mapStateToPreSelectedProps = (state: RootState) => ({
    resources: getFilteredGroupedResources(state).bookable.filter(isSelectedFilter(state)),
    labelId: 'bookableResources' as const,
    isBooked: false,
    open: true,
    isSelected: true,
});

const mapStateToPreSelectedNotBookableProps = (state: RootState) => ({
    resources: getFilteredGroupedResources(state).notBookable.filter(isSelectedFilter(state)),
    labelId: 'notBookableResources' as const,
    isBooked: false,
    open: true,
});

const mapStateToPreSelectedBookedProps = (state: RootState) => ({
    resources: getFilteredGroupedResources(state).booked.filter(isSelectedFilter(state)),
    labelId: 'alreadyBookedResources' as const,
    isBooked: true,
    open: true,
});

const mapStateToPreSelectedInvalidProps = (state: RootState) => ({
    resources: getInvalidSelectedResources(state),
    labelId: 'notFoundResources' as const,
    isBooked: false,
    open: true,
});

export const ResourceSelectionBookedExpander = connect(mapStateToBookedProps)(ResourceSelectionExpander);
export const ResourceSelectionNotBookableExpander = connect(mapStateToNotBookableProps)(ResourceSelectionExpander);

export const PreSelectedValidResourcesExpander = connect(mapStateToPreSelectedProps)(ResourceSelectionExpander);
export const PreSelectedAlreadyBookedResourcesExpander = connect(mapStateToPreSelectedBookedProps)(
    ResourceSelectionExpander
);
export const PreSelectedNotBookableResourcesExpander = connect(mapStateToPreSelectedNotBookableProps)(
    ResourceSelectionExpander
);

const InvalidResourceSelectionExpander = (props: Props) => {
    useEffect(() => {
        if (props.resources.length > 0) {
            const resourceIds = props.resources.map((it) => it.id);
            reportErrorToSentry(`Unknown resource ids found in resource selection: ${resourceIds}`);
        }
    }, [props.resources.length, props.resources]);
    return <ResourceSelectionExpander {...props} />;
};
export const PreSelectedNotExistentResourceExpander = connect(mapStateToPreSelectedInvalidProps)(
    InvalidResourceSelectionExpander
);
