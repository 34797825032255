import { DEFAULT_LOCALE, extractLanguage, supportedLocaleMap } from './lang/lang';
import langReducer from './lang/langSlice';
import { getDisplayMessages, getLocale } from './lang/langSlice';
import { configureFetchDisplayMessages } from './lang/services';
import { userProfileObtained, userSessionExpired, userSessionRenewed } from './login/loginSlice';
import { configureMockUserManager, configureUserManager, createUserManager, SessionRenewedResult } from './login/login';
import handleLoginRedirect from './login/redirect';
import loginReducer from './login/loginSlice';
import { getUserAccount, isUserSessionExpired, getUserTenant } from './login/loginSlice';
import configReducer from './setup/configSlice';
import { store } from './setup/store';
import { accessToken } from './tokenHandling/accessToken';
import { accessTokenStored, idTokenStored } from './tokenHandling/tokenSlice';
import tokenHandlingReducer from './tokenHandling/tokenSlice';
import { getAccessToken, getIdToken, getAccountId } from './tokenHandling/tokenSlice';
import { trace } from './setup/trace';
import { attemptInitialSignIn } from './setup/oauth';
import { config } from '../config';
import { reportErrorToSentry } from './setup/sentry';
import { UserManager } from 'oidc-client';
import { messagesEN } from '../features/test/testUtils';

type AllLocales = typeof supportedLocaleMap;
export type SupportedLocale = keyof AllLocales;

export type DisplayMessages = { [K in keyof typeof messagesEN]: string };
export type MessageKey = keyof DisplayMessages;

export const main = async (renderApp: Function) => {
    const fetchDisplayMessages = configureFetchDisplayMessages(store);

    // We want the `<html lang>` attribute to be synced with the
    // language currently displayed
    store.subscribe(() => {
        const lang = extractLanguage(getLocale(store.getState()));
        const html = document.querySelector('html');

        if (html && lang && html.getAttribute('lang') !== lang) {
            html.setAttribute('lang', lang);
        }
    });

    const oauthConfig = {
        onSessionExpired: () => {
            trace('index.onTokenExpired');

            accessToken.discardAccessToken();
            store.dispatch(userSessionExpired());
        },
        onSessionRenewed: (result: SessionRenewedResult) => {
            trace('index.onTokenRenewed', result);

            accessToken.saveAccessToken(result.accessToken);
            store.dispatch(accessTokenStored(result.accessToken));
            store.dispatch(idTokenStored(result.idToken));
            store.dispatch(userProfileObtained(result.profile));

            store.dispatch(userSessionRenewed());

            // You will need to get the user language by yourself then
            // you may fetch the suitable messages. Depending
            // on when and from where you fetch the user settings you might
            // want to employ a loading spinner while the request is ongoing.
            fetchDisplayMessages(result.locale);
        },
    };

    const isAllowedToMockAuth = process.env.NODE_ENV !== 'production';
    const testAccessToken = (sessionStorage && sessionStorage.getItem('test_access_token')) || undefined; // enables mocking of authentication in production
    const testIdToken = (sessionStorage && sessionStorage.getItem('test_id_token')) || undefined; // enables mocking of authentication in production
    const userManager =
        (isAllowedToMockAuth && config.login.mockAuthorization) || testAccessToken
            ? configureMockUserManager(oauthConfig, testAccessToken, testIdToken)
            : configureUserManager(oauthConfig, createUserManager());

    document.addEventListener('rio.core.user.language.changed', userManager.signinSilent.bind(userManager));
    document.addEventListener('rio.core.user.profile.changed', userManager.signinSilent.bind(userManager));

    try {
        await userManager.clearStaleState();
        await attemptInitialSignIn(userManager as UserManager);
        renderApp();
    } catch (error) {
        trace('could not start application', error);
        reportErrorToSentry(error);
    }
};

export {
    configReducer,
    getAccessToken,
    getUserTenant,
    getIdToken,
    getAccountId,
    getDisplayMessages,
    getLocale,
    getUserAccount,
    handleLoginRedirect,
    isUserSessionExpired,
    langReducer,
    loginReducer,
    store,
    tokenHandlingReducer,
    DEFAULT_LOCALE,
};
