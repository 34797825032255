import { ServiceOverviewItem } from '../types';
import { FormattedMessage } from 'react-intl';
import { getLinkForProductDetails } from '../../../common/serviceDetailsService';
import { connect } from 'react-redux';
import { getLocale } from '../../../../../configuration';
import { isKoreanTenant } from '../../../common/utils/tenants';
import { RootState } from '../../../../../configuration/setup/store';

interface Props {
    level: string | undefined;
    service: ServiceOverviewItem;
    locale: string;
    isKoreanUser: boolean;
}

export function ServiceDetailsButton({ service, level, locale, isKoreanUser }: Props) {
    const productDetailsLink = getLinkForProductDetails(service.sku, level, locale, isKoreanUser);
    if (!service.legacy && productDetailsLink) {
        return (
            <a href={productDetailsLink} className={'serviceDetailsButton btn btn-default'}>
                <FormattedMessage id={'marketplace.myServices.serviceDetails'} />
            </a>
        );
    }
    return null;
}

const mapStateToProps = (state: RootState) => ({
    locale: getLocale(state),
    isKoreanUser: isKoreanTenant(state),
});

export const ServiceDetailsButtonContainer = connect(mapStateToProps)(ServiceDetailsButton);
