import { getMarketplaceBackend } from '../../config/pathURL/config';
import { getRequest } from '../requests';
import { decodeJson, jsonOrReject, onRejected } from '../api';
import { ApiActivationSummaryCodec } from './service.types';
import { ServiceOverviewItem } from '../../customerCenter/serviceOverview/types';
import { mapActivationSummaryResponse } from './activationSummaryMapper';

export function fetchActivationSummaryDetailed(): Promise<Array<ServiceOverviewItem>> {
    return fetch(`${getMarketplaceBackend()}/activations/summary?embed=product`, getRequest())
        .then(jsonOrReject)
        .then(decodeJson(ApiActivationSummaryCodec))
        .then((response) => mapActivationSummaryResponse(response))
        .catch(onRejected([401, 403]));
}
