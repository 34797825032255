import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PermissionsState } from './types';
import { RootState } from '../../../../../configuration/setup/store';

const initialState: PermissionsState = {};

export const { reducer: permissionsReducer, actions: permissionsActions } = createSlice({
    name: 'permissions',
    initialState,
    reducers: {
        setPermissions: (state: PermissionsState, action: PayloadAction<Array<string> | undefined>) => {
            state.permissionList = action.payload;
        },
    },
});

const getBase = (state: RootState): PermissionsState => state.marketplace.permissions;

export const getPermissions = (state: RootState) => getBase(state).permissionList;
export const isFleetAdmin = (state: RootState) => getBase(state).permissionList?.includes('subscription.write');
