import { ResourceUsage, UsageSummary, UsageUnit, UsageUnitDiscount } from '../../customerCenter/usage/redux/types';
import { ApiAssetUsage, ApiProduct, ApiUsageSummary, ApiUsageUnit } from './usageSummary.types';
import isEmpty from 'lodash/isEmpty';
import { sortingByName } from '../../common/utils/sortUtils';
import { mapContractPeriod } from '../periods';
import { sortProducts } from './usageProductSorter';

function enrichWithPriceAndCurrency(unit: ApiUsageUnit, products: Array<ApiProduct>): UsageUnit {
    const relatedProduct = products.find(
        (product) => product.product_sku === unit.product_sku && product.rate_plan_id === unit.rate_plan_id
    );
    return {
        amount: unit.amount,
        productSku: unit.product_sku,
        productName: relatedProduct?.product_name ?? unit.product_sku,
        price: relatedProduct?.price,
        currency: relatedProduct?.currency,
        ratePlanId: unit.rate_plan_id,
        discounts: unit.discounts,
        totalPrice: calculatePriceForUsageUnit({
            price: relatedProduct?.price,
            amount: unit.amount,
            discounts: unit.discounts,
        }),
        variantName: relatedProduct?.variant_name,
        contractPeriod: mapContractPeriod(relatedProduct?.contract_period),
        level: relatedProduct?.level,
    };
}

function sum(numbers: Array<number>): number {
    return numbers.reduce((a, b) => a + b, 0);
}

function calculatePriceForUsageUnit(unit: { price?: number; amount: number; discounts?: Array<UsageUnitDiscount> }) {
    if (unit.price === undefined) {
        return undefined;
    }
    const discountedAmount = (unit.discounts || []).map((discount) => (discount.amount * discount.percentage) / 100);
    const calculatedPrice = (unit.amount - sum(discountedAmount)) * unit.price;
    return isNaN(calculatedPrice) ? undefined : Math.round(calculatedPrice * 100) / 100;
}

export function calculatePriceForAsset(enrichedServices: Array<UsageUnit>): number | undefined {
    if (isEmpty(enrichedServices)) {
        return undefined;
    }
    // allowed because we validate for isNaN in line below
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const triedSum = sum(enrichedServices.map((unit) => calculatePriceForUsageUnit(unit) ?? NaN));
    return isNaN(triedSum) ? undefined : Math.round(triedSum * 100) / 100;
}

function enrichResourceUsage(usage: ApiAssetUsage, products: Array<ApiProduct>): ResourceUsage {
    const enrichedServices = usage.usage_units.map((unit) => enrichWithPriceAndCurrency(unit, products));
    return {
        resourceId: usage.resource_id,
        resourceType: usage.resource_type,
        resourceName: usage.resource_name,
        resourceIdentification: usage.resource_identification,
        price: calculatePriceForAsset(enrichedServices),
        services: enrichedServices,
    };
}

function resourceUsageSorter(a: ResourceUsage, b: ResourceUsage) {
    if (!a.resourceName) {
        return 1;
    }
    if (!b.resourceName) {
        return -1;
    }
    if (a?.resourceName === b?.resourceName) {
        if (!a.resourceIdentification) {
            return 1;
        }
        if (!b.resourceIdentification) {
            return -1;
        }
        return sortingByName({ name: a.resourceIdentification }, { name: b.resourceIdentification });
    }
    return sortingByName({ name: a.resourceName }, { name: b.resourceName });
}

export function mapUsageSummary() {
    return (apiUsageSummary: ApiUsageSummary): UsageSummary => {
        return {
            products: sortProducts(
                apiUsageSummary.products.map((p) => ({
                    productSku: p.product_sku,
                    productName: p.product_name,
                    productType: p.product_type,
                    currency: p.currency,
                    price: p.price,
                    ratePlanId: p.rate_plan_id,
                    contractPeriod: mapContractPeriod(p.contract_period),
                    variantName: p.variant_name,
                    level: p.level,
                }))
            ),
            resourceUsages: apiUsageSummary.usages
                .filter((assetUsage) => !isEmpty(assetUsage.usage_units))
                .map((usage) => enrichResourceUsage(usage, apiUsageSummary.products))
                .sort(resourceUsageSorter),
        } as UsageSummary;
    };
}

export function extractOverallCurrency(products: Array<{ currency: string }>): string | undefined {
    const currenciesOccurring = Array.from(new Set(products.map((product) => product.currency)));
    if (currenciesOccurring.length === 1) {
        return currenciesOccurring[0];
    }
    return undefined;
}
