/* eslint-disable camelcase */
import { getMarketplaceBackend } from '../../config/pathURL/config';
import { getRequest, postRequest } from '../requests';
import { decodeJson, jsonOrReject, onRejected, rejectIfNotOk } from '../api';
import { mapApiContracts } from './contractMapper';
import { ApiContractsCodec } from './contract.types';
import { TermedLevel } from '../../common/termedLevel/types';

export function fetchTermedActivations(): Promise<Array<TermedLevel>> {
    return fetch(`${getMarketplaceBackend()}/contracts`, getRequest())
        .then(jsonOrReject)
        .then(decodeJson(ApiContractsCodec))
        .then(mapApiContracts())
        .catch(onRejected([401]));
}

export interface UpdateTermedLevelData {
    productId: string;
    resourceIds: Array<string>;
    renewsToLevel?: string;
}

export function updateTermedActivations({
    resourceIds,
    productId,
    renewsToLevel,
}: UpdateTermedLevelData): Promise<void> {
    const requestBody = {
        resource_ids: resourceIds,
        renews_to_level: renewsToLevel,
    };
    return fetch(`${getMarketplaceBackend()}/contracts/${productId}/config`, postRequest(requestBody)).then(
        rejectIfNotOk
    );
}
