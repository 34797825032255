import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import ButtonDropdown from '@rio-cloud/rio-uikit/lib/es/ButtonDropdown';
import Checkbox from '@rio-cloud/rio-uikit/lib/es/Checkbox';
import { isMultiLevelProduct, MultiLevelProduct, ProductLevel } from '../redux/types';
import { getSelectedProduct, getSelectedProductLevel } from '../redux/checkout.redux';
import { resourceSelectionActions, getSelectedResourceIds, NO_LEVEL } from './redux/resourceSelection.redux';
import { useCheckboxState } from '../../common/utils/checkboxUtil';
import { BookableResource } from './redux/types';
import { getFilteredGroupedResources } from './resourceSelectionSearchService';
import { RootState } from '../../../../configuration/setup/store';
import { ProductTypeAwareFormattedMessage } from './ProductTypeAwareFormattedMessage';

interface Props {
    selectedProduct: MultiLevelProduct | undefined;
    selectedProductLevel: string | undefined;
    handleToggleAll: (checked: boolean, level?: string | typeof NO_LEVEL) => void;
    bookableResources: Array<BookableResource>;
    selectedResourceIds: Array<string>;
}

function bookableResourcesForLevelExist(bookableResources: Array<BookableResource>, it: ProductLevel) {
    return bookableResources.filter((resource) => resource.targetLevel === it.level).length > 0;
}

export function SelectionMenu(props: Props) {
    const { selectedProduct, handleToggleAll, bookableResources, selectedResourceIds, selectedProductLevel } = props;
    const isChecked = useCheckboxState(bookableResources, selectedResourceIds);

    if (!selectedProduct || !selectedProductLevel) {
        return (
            <div className='padding-top-5'>
                <Checkbox
                    onClick={() => handleToggleAll(!isChecked)}
                    checked={Boolean(isChecked)}
                    indeterminate={isChecked === undefined}
                    inline
                >
                    <ProductTypeAwareFormattedMessage id={'selectAll'} />
                </Checkbox>
            </div>
        );
    }
    const otherLevels = selectedProduct.levels.filter((it) => it.level !== selectedProductLevel);
    const actionItems = [
        {
            value: <ProductTypeAwareFormattedMessage id={'dropdownSelectAll'} />,
            onSelect: () => handleToggleAll(true, undefined),
        },
        {
            value: <ProductTypeAwareFormattedMessage id={'selectNotBooked'} />,
            onSelect: () => handleToggleAll(true, NO_LEVEL),
        },
        ...otherLevels
            .filter((it) => bookableResourcesForLevelExist(bookableResources, it))
            .map((l) => ({
                value: <ProductTypeAwareFormattedMessage id={'selectAllFrom'} values={{ level: l.name }} />,
                onSelect: () => handleToggleAll(true, l.level),
            })),
        {
            value: <ProductTypeAwareFormattedMessage id={'deselectAll'} />,
            onSelect: () => handleToggleAll(false, undefined),
        },
    ];
    return (
        <ButtonDropdown
            className={'width-100pct'}
            toggleClassName={'width-100pct'}
            title={
                <div>
                    <span className={'rioglyph rioglyph-checkboxes margin-right-5'} />
                    <ProductTypeAwareFormattedMessage id={'selectHere'} />
                </div>
            }
            items={actionItems}
        />
    );
}

const mapStateToProps = (state: RootState) => {
    const selectedProduct = getSelectedProduct(state);
    return {
        selectedProduct: isMultiLevelProduct(selectedProduct) ? selectedProduct : undefined,
        selectedProductLevel: getSelectedProductLevel(state),
        selectedResourceIds: getSelectedResourceIds(state),
        bookableResources: getFilteredGroupedResources(state).bookable,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    handleToggleAll: (selected: boolean, level?: string | typeof NO_LEVEL) =>
        dispatch(resourceSelectionActions.selectAllResources({ selected, level })),
});

export const SelectionMenuContainer = connect(mapStateToProps, mapDispatchToProps)(SelectionMenu);
