export enum ProductState {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    PENDING_CANCELLATION = 'PENDING_CANCELLATION',
    PENDING_ACTIVATION = 'PENDING_ACTIVATION',
}

export interface BookableResource {
    id: string;
    name: string;
    bookable: boolean;
    productState: ProductState;
    originLevel?: string;
    targetLevel?: string;
    missingRequirements?: Array<Array<string>>;
    hasOpenContract?: boolean;
}

export interface ResourceSelectionState {
    resourceSearchTerm: string;
    selectedResourceIds: Array<string>;
    availableResources: Array<BookableResource>;
}
