import { createSelector } from 'reselect';
import { getServiceListResources } from '../redux/serviceOverview.redux';
import { ResourcesBySku } from '../redux/types';
import { ProductState } from '../../../checkout/resourceSelection/redux/types';
import { RootState } from '../../../../../configuration/setup/store';

const getSku = (state: RootState, sku: string) => sku;

export const makeGetServiceResourcesForSku = () => {
    return createSelector([getSku, getServiceListResources], (sku: string, serviceResources: ResourcesBySku) => {
        return (
            serviceResources[sku] &&
            serviceResources[sku].filter((resource) => {
                return (
                    resource.productState !== ProductState.INACTIVE &&
                    resource.productState !== ProductState.PENDING_CANCELLATION
                );
            })
        );
    });
};

export const makeHasPendingActivationResources = () => {
    return createSelector([getSku, getServiceListResources], (sku: string, serviceResources: ResourcesBySku) => {
        const resourcesInPendingActivation =
            serviceResources[sku] &&
            serviceResources[sku].filter((asset) => {
                return asset.productState === ProductState.PENDING_ACTIVATION;
            });
        return !!resourcesInPendingActivation && resourcesInPendingActivation.length > 0;
    });
};
