import { SuccessScreen } from './success/SuccessScreen';
import { useSelector } from 'react-redux';
import { getIsFreeTrialActive, getIsIneligibleForFreeTrial } from './redux/freeTrial.redux';
import { FreeTrialExplanationContainer } from './explanation/FreeTrialExplanationContainer';
import { useEffect } from 'react';
import { freeTrialStatusThunk } from './freeTrialStatus.thunk';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { getHasError, getIsLoading } from '../api/redux/api.redux';
import { ApiCalls } from '../api/redux/types';
import { RootState, useAppDispatch } from '../../../configuration/setup/store';
import { FormattedMessage } from 'react-intl';
import ErrorState from '@rio-cloud/rio-uikit/lib/es/ErrorState';

export function FreeTrialPopupContainer() {
    const isFreeTrialIneligible = useSelector(getIsIneligibleForFreeTrial);
    const isFreeTrialActive = useSelector(getIsFreeTrialActive);
    const isLoading = useSelector((state: RootState) => getIsLoading(state, ApiCalls.FREE_TRIAL_GET));
    const hasError = useSelector((state: RootState) => getHasError(state, ApiCalls.FREE_TRIAL_GET));
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(freeTrialStatusThunk);
    }, []);

    if (isLoading) {
        return <Spinner />;
    }

    if (hasError) {
        return <ErrorState headline={<FormattedMessage id={'marketplace.freeTrial.error'} />} />;
    }

    if (isFreeTrialIneligible) {
        return (
            <div className={'module-content text-center'}>
                <h2>
                    <FormattedMessage id={'marketplace.freeTrial.notEligible'} />
                </h2>
                <button className={'btn btn-primary center margin-top-25'} onClick={window.close}>
                    <FormattedMessage id={'marketplace.checkout.closePopup'} />
                </button>
            </div>
        );
    }

    return isFreeTrialActive ? <SuccessScreen /> : <FreeTrialExplanationContainer />;
}
