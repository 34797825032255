import { Elements } from '@stripe/react-stripe-js';
import { connect } from 'react-redux';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { getLocaleShort } from '../lang/languageData';
import ReauthenticationPageContainer from './ReauthenticationPageContainer';
import { FormattedMessage } from 'react-intl';
import { UnauthorizedDialog } from '../common/error/UnauthorizedDialog';
import { PAYMENT_INFORMATION_WRITE } from '../common/permissions/permissions';
import { compose } from 'redux';
import withPermissionValidator from '../common/permissions/withPermissionValidator';
import LoadingErrorState from '../common/error/LoadingErrorState';
import { getHasError } from '../api/redux/api.redux';
import { ApiCalls } from '../api/redux/types';
import { getStripeApiKey } from '../common/payment/redux/paymentMethods.redux';
import { fetchStripeApiKeyThunk } from '../common/payment/fetchStripeKeys.thunk';
import { loadStripe, Stripe, StripeElementLocale } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { RootDispatch, RootState } from '../../../configuration/setup/store';
import { isBrazilianTenant } from '../common/utils/tenants';

interface Props {
    stripeApiKey?: string;
    stripeApiKeyFetchError: boolean;
    isAllowedTenant: boolean;
    fetchStripeApiKey: () => void;
    userHasPermissions: (permissions: Array<string>) => boolean;
}

export function ReauthenticationPageStripeContainer(props: Props) {
    const userHasPermissionsToWritePaymentDetails = () =>
        props.isAllowedTenant && props.userHasPermissions([PAYMENT_INFORMATION_WRITE]);
    const [stripe, setStripe] = useState<Stripe | null>(null);

    useEffect(() => {
        if (userHasPermissionsToWritePaymentDetails()) {
            if (!props.stripeApiKey) {
                props.fetchStripeApiKey();
            } else if (!stripe) {
                loadStripe(props.stripeApiKey).then(setStripe);
            }
        }
    }, [props.stripeApiKey, stripe]);

    if (!userHasPermissionsToWritePaymentDetails()) {
        return <UnauthorizedDialog />;
    }
    if (props.stripeApiKeyFetchError) {
        return <LoadingErrorState />;
    }
    if (!props.stripeApiKey || !stripe) {
        return (
            <div>
                <Spinner text={<FormattedMessage id={'marketplace.loading'} />} isInverse={false} />
            </div>
        );
    }

    return (
        <Elements stripe={stripe} options={{ locale: getLocaleShort() as StripeElementLocale }}>
            <ReauthenticationPageContainer stripe={stripe} />
        </Elements>
    );
}

export function mapStateToProps(state: RootState) {
    return {
        stripeApiKey: getStripeApiKey(state),
        stripeApiKeyFetchError: getHasError(state, ApiCalls.STRIPE_API_KEY),
        isAllowedTenant: !isBrazilianTenant(state),
    };
}

export function mapDispatchToProps(dispatch: RootDispatch) {
    return {
        fetchStripeApiKey: () => {
            dispatch(fetchStripeApiKeyThunk);
        },
    };
}

export default compose<React.ComponentType>(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissionValidator(LoadingErrorState)
)(ReauthenticationPageStripeContainer);
