import { ApiDiscounts } from './discount.types';
import { Discounts } from '../../common/discount/redux/types';

export const mapApiDiscounts = (apiDiscounts: ApiDiscounts): Discounts => {
    const assetDiscounts = apiDiscounts.asset_discounts.map((apiDiscount) => ({
        assetId: apiDiscount.asset_id,
        productId: apiDiscount.product_id,
        productLevels: apiDiscount.product_levels,
        discountPercentage: apiDiscount.discount_percentage,
        durationPeriod: apiDiscount.duration_period,
        durationUnit: apiDiscount.duration_unit,
        endsAt: apiDiscount.ends_at,
        campaign: apiDiscount.campaign,
    }));

    const accountDiscounts = apiDiscounts.account_discounts.map((apiDiscount) => ({
        discountPercentage: apiDiscount.discount_percentage,
        durationPeriod: apiDiscount.duration_period,
        durationUnit: apiDiscount.duration_unit,
        endsAt: apiDiscount.ends_at,
        campaign: apiDiscount.campaign,
    }));

    const productDiscounts = apiDiscounts.product_discounts.map((apiDiscount) => ({
        productId: apiDiscount.product_id,
        levels: apiDiscount.levels,
        discountPercentage: apiDiscount.discount_percentage,
        durationPeriod: apiDiscount.duration_period,
        durationUnit: apiDiscount.duration_unit,
        endsAt: apiDiscount.ends_at,
        campaign: apiDiscount.campaign,
    }));
    return { assetDiscounts: assetDiscounts, accountDiscounts: accountDiscounts, productDiscounts: productDiscounts };
};
