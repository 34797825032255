/* eslint-disable camelcase */
import * as t from 'io-ts';

export enum PaymentMethodType {
    SEPA = 'SEPA',
    CREDIT_CARD = 'CREDIT_CARD',
    BANK_TRANSFER = 'BANK_TRANSFER',
}

export const ApiPaymentMethodTypeCodec = t.union([
    t.literal(PaymentMethodType.SEPA),
    t.literal(PaymentMethodType.CREDIT_CARD),
    t.literal(PaymentMethodType.BANK_TRANSFER),
]);

export const ApiPaymentMethodTypesCodec = t.type({
    payment_method_types: t.array(ApiPaymentMethodTypeCodec),
});

export type ApiPaymentMethodTypes = t.TypeOf<typeof ApiPaymentMethodTypesCodec>;
