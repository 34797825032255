import { getAllSelectedResourceIds, getSelectedResourceIds } from '../resourceSelection/redux/resourceSelection.redux';
import qs from 'qs';
import { STRINGIFY_OPTIONS } from '../../common/utils/qsUtil';
import { getIsCheckoutLocked, getSelectedProductId, getSelectedProductLevel } from '../redux/checkout.redux';
import { RootState } from '../../../../configuration/setup/store';
import { getIsOpenedInPopup } from '../../popup/popup.redux';
import { UrlParameterKeys } from './UrlParameterKeys';

export const getCheckoutRouteSearch = (state: RootState): string => {
    return qs.stringify(
        {
            [UrlParameterKeys.PRODUCT_ID]: getSelectedProductId(state),
            [UrlParameterKeys.POPUP]: getIsOpenedInPopup(state) ? 'true' : undefined,
            [UrlParameterKeys.LEVEL]: getSelectedProductLevel(state) ? getSelectedProductLevel(state) : undefined,
            [UrlParameterKeys.RESOURCE_IDS]:
                getAllSelectedResourceIds(state).length > 0 ? getAllSelectedResourceIds(state) : undefined,
            [UrlParameterKeys.LOCKED]: getIsCheckoutLocked(state),
        },
        STRINGIFY_OPTIONS
    );
};

export const getServiceCareCheckoutRoute = (state: RootState): string => {
    return qs.stringify(
        {
            [UrlParameterKeys.PRODUCT_ID]: getSelectedProductId(state),
            [UrlParameterKeys.POPUP]: getIsOpenedInPopup(state) ? 'true' : undefined,
            [UrlParameterKeys.LEVEL]: getSelectedProductLevel(state) ? getSelectedProductLevel(state) : undefined,
            [UrlParameterKeys.ASSET_IDS]:
                getSelectedResourceIds(state).length > 0 ? getSelectedResourceIds(state) : undefined,
        },
        STRINGIFY_OPTIONS
    );
};
