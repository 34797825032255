import { FormattedMessage } from 'react-intl';
import ApplicationLayout from '@rio-cloud/rio-uikit/lib/es/ApplicationLayout';
import { FreeTrialExplanation } from './FreeTrialExplanation';
import { FreeTrialProductsShowcase } from './FreeTrialProductsShowcase';
import { FreeTrialActivationButtonContainer } from './FreeTrialActivationButton';
import { FreeTrialExplanationBottomBar } from './FreeTrialExplanationBottomBar';
import handPointingLeft from '../images/hand_pointing_left.webp';
import handPointingRight from '../images/hand_pointing_right.webp';

export function FreeTrialExplanationContainer() {
    return (
        <ApplicationLayout className='bg-primary'>
            <ApplicationLayout.Body bottomBar={<FreeTrialExplanationBottomBar />} enableScrollToTop={true}>
                <div className='width-100pct max-width-800 rounded-circle bg-white aspect-ratio-1 position-absolute top-0 left-50pct translate-x-50pct-y-50pct opacity-30 blur-50'></div>
                <img
                    className='img-responsive position-absolute top-0 margin-top-5pct left-0 width-200 hidden-xs hidden-ls hidden-sm'
                    src={handPointingRight}
                    alt='Hand pointing right'
                />
                <div className='max-width-1000 margin-x-auto'>
                    <div className='margin-x-10-xs margin-x-10pct'>
                        <div className='text-center text-size-h1 text-bold'>
                            <FormattedMessage id='marketplace.freeTrial.explanation.header' />
                        </div>
                        <div className='text-center text-size-20'>
                            <FormattedMessage id='marketplace.freeTrial.explanation.subheader' />
                        </div>
                        <div className='display-flex-sm margin-top-10pct gap-25'>
                            <FreeTrialProductsShowcase />

                            <div className='margin-bottom-25'>
                                <div className='text-size-h2 text-bold display-grid grid-cols-1 gap-20 line-height-125rel padding-top-25'>
                                    <FreeTrialExplanation />
                                    <div className='display-flex gap-20 margin-top-25'>
                                        <img
                                            className='img-responsive position-absolute margin-top-25 right-0 width-250 hidden-xs hidden-ls hidden-sm'
                                            src={handPointingLeft}
                                            alt='Hand pointing left'
                                        />
                                        <div className='opacity-0'>
                                            <span className='rioglyph rioglyph-ok-sign'></span>
                                        </div>
                                        <FreeTrialActivationButtonContainer trackVisibility={true} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='max-width-700 text-center text-size-12 margin-y-50 margin-x-auto'>
                            <FormattedMessage id='marketplace.freeTrial.explanation.details' />
                        </div>
                    </div>
                </div>
            </ApplicationLayout.Body>
        </ApplicationLayout>
    );
}
