import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';
import TagList from '@rio-cloud/rio-uikit/lib/es/TagList';
import { ResourceSelectionSummaryContainer } from '../preview/ResourceSelectionSummary';
import { MultiLevelSelectedResourceListContainer } from '../resourceSelection/MultiLevelResourceSelectionBookableResourceList';
import { RESOURCE_SELECTION_TAGS_PER_ROW } from '../resourceSelection/ResourceSelectionListContainer';
import { ResourceSelectionItemContainer } from '../resourceSelection/ResourceSelectionItem';
import { connect } from 'react-redux';
import { isMultiLevelProduct } from '../redux/types';
import { getSelectedProduct } from '../redux/checkout.redux';
import { BookableResource } from '../resourceSelection/redux/types';
import { getAvailableResources, getSelectedResourceIds } from '../resourceSelection/redux/resourceSelection.redux';
import { RootState } from '../../../../configuration/setup/store';

interface Props {
    productIsMultiLevel: boolean;
    selectedResources: Array<BookableResource>;
}

export function SelectedResourcesOverview(props: Props) {
    const { productIsMultiLevel, selectedResources } = props;
    return (
        <div>
            <ExpanderPanel
                title={<ResourceSelectionSummaryContainer />}
                bsStyle={'default'}
                titleClassName={'width-100pct'}
                bodyClassName={'bg-lightest'}
            >
                {productIsMultiLevel ? (
                    <MultiLevelSelectedResourceListContainer />
                ) : (
                    <TagList tagsPerRow={RESOURCE_SELECTION_TAGS_PER_ROW}>
                        {selectedResources.map((resource) => {
                            return (
                                <ResourceSelectionItemContainer
                                    resource={resource}
                                    key={resource.id}
                                    isBooked={false}
                                    isSelected={true}
                                    noBookableTooltip={true}
                                />
                            );
                        })}
                    </TagList>
                )}
            </ExpanderPanel>
        </div>
    );
}

const mapStateToProps = (state: RootState) => {
    const selectedResourceIds = getSelectedResourceIds(state);
    return {
        productIsMultiLevel: isMultiLevelProduct(getSelectedProduct(state)),
        selectedResources: getAvailableResources(state).filter((it) => selectedResourceIds.includes(it.id)),
    };
};

export const SelectedResourcesOverviewContainer = connect(mapStateToProps)(SelectedResourcesOverview);
