import { Dispatch } from 'redux';
import { apiCallAction } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import { fetchPermissions } from '../../api/permissions/permissionsCalls';
import { getPermissions, permissionsActions } from './redux/permissions.redux';
import { AppThunk, RootState } from '../../../../configuration/setup/store';

export const fetchPermissionsThunk: AppThunk<Promise<void>> = (dispatch: Dispatch, getState: () => RootState) => {
    if (getPermissions(getState())) {
        return Promise.resolve();
    }
    dispatch(apiCallAction(ApiCalls.PERMISSIONS, true, false));
    return fetchPermissions()
        .then((permissions) => {
            dispatch(permissionsActions.setPermissions(permissions));
            dispatch(apiCallAction(ApiCalls.PERMISSIONS, false));
        })
        .catch(() => {
            dispatch(apiCallAction(ApiCalls.PERMISSIONS, false, true));
        });
};
