import TableSearch from '@rio-cloud/rio-uikit/lib/es/TableSearch';
import { useIntl } from 'react-intl';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { getUsageSearchTerm, usageActions } from './redux/usage.redux';
import { getHasError } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import { RootState } from '../../../../configuration/setup/store';

interface UsageTableSearchProps {
    searchValue: string;
    onSearchChange: (searchValue: string) => void;
    hasError: boolean;
}

export const UsageTableSearch = (props: UsageTableSearchProps) => {
    const { searchValue, onSearchChange, hasError } = props;
    const intl = useIntl();

    if (hasError) {
        return null;
    }

    return (
        <TableSearch
            placeholder={intl.formatMessage({ id: 'marketplace.usage.summary.search.placeholder' })}
            value={searchValue}
            onChange={onSearchChange}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    searchValue: getUsageSearchTerm(state),
    hasError: getHasError(state, ApiCalls.USAGE_SUMMARY),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onSearchChange: (searchValue: string) => dispatch(usageActions.setTableSearch(searchValue)),
});

export const UsageTableSearchContainer = connect(mapStateToProps, mapDispatchToProps)(UsageTableSearch);
