import { Tips } from './types';
import { loadFromLocalStorage, saveInLocalStorage } from './handleLocalStorage';

export const saveOnboardingTip = (tip: Tips, value: boolean) => {
    saveInLocalStorage(tip, value);
};
export const loadOnboardingTip = (tip: Tips): boolean => {
    const loadedShowTipFlag = loadFromLocalStorage(tip);
    return loadedShowTipFlag === undefined ? true : loadedShowTipFlag;
};
