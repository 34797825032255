import { Component } from 'react';
import PropTypes from 'prop-types';
import { StripeCreatePaymentButton } from '../common/payment/StripeCreatePaymentButton';
import CustomState from '@rio-cloud/rio-uikit/lib/es/CustomState';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { FormattedMessage, injectIntl } from 'react-intl';
import AuthenticationInformation from './AuthenticationInformation';
import AuthenticationSuccessInfo from './AuthenticationSuccessInfo';
import LoadingErrorState from '../common/error/LoadingErrorState';
import { getCustomerCenterPath } from '../common/routes';
import { CustomerCenterTab } from '../customerCenter/types';

export const AUTHENTICATION_STATUS = {
    SUCCESSFUL_AUTHENTICATION: 'successful authentication',
    FAILED_AUTHENTICATION: 'failed authentication',
    NOT_YET_AUTHENTICATED: 'not yet authenticated',
    AUTHENTICATION_IN_PROGRESS: 'authentication in progress',
};

export class ReauthenticationPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            authenticationState: AUTHENTICATION_STATUS.NOT_YET_AUTHENTICATED,
            error: undefined,
        };
    }

    handleButtonClick() {
        this.setState({
            authenticationState: AUTHENTICATION_STATUS.AUTHENTICATION_IN_PROGRESS,
        });

        return this.props
            .performAuthentication()
            .then(() => {
                this.setState({
                    authenticationState: AUTHENTICATION_STATUS.SUCCESSFUL_AUTHENTICATION,
                    error: undefined,
                });
            })
            .catch((error) => {
                this.setState({
                    authenticationState: AUTHENTICATION_STATUS.FAILED_AUTHENTICATION,
                    error: error,
                });
            });
    }

    isAuthenticationInProgress() {
        return this.state.authenticationState === AUTHENTICATION_STATUS.AUTHENTICATION_IN_PROGRESS;
    }

    isAuthenticationSuccessful() {
        return this.state.authenticationState === AUTHENTICATION_STATUS.SUCCESSFUL_AUTHENTICATION;
    }

    isAuthenticationFailed() {
        return this.state.authenticationState === AUTHENTICATION_STATUS.FAILED_AUTHENTICATION;
    }

    renderPaymentErrorMessage() {
        const errorMessage = this.state.error?.stripeMessage;
        return (
            <div className='SourceValidationErrorMessage text-center margin-top-20'>
                <span className='text-danger'>
                    <FormattedMessage
                        id={'marketplace.payments.authentication.errorTryAgain'}
                        values={{
                            link: (chunks) => (
                                <a href={getCustomerCenterPath(CustomerCenterTab.PAYMENT_METHODS)}>{chunks}</a>
                            ),
                        }}
                    />
                    {errorMessage && (
                        <div className='margin-top-5'>
                            <FormattedMessage id={'marketplace.payments.authentication.errorTryAgain.details'} />:{' '}
                            {errorMessage}
                        </div>
                    )}
                </span>
            </div>
        );
    }

    renderLoadingSpinner() {
        return (
            <div>
                <Spinner text={<FormattedMessage id={'marketplace.loading'} />} isInverse={false} />
            </div>
        );
    }

    renderAuthenticateButton() {
        return (
            <div className={'text-center margin-top-20'}>
                <StripeCreatePaymentButton
                    confirmMessageId={'marketplace.payments.authentication.button.clickable'}
                    loadingMessageId={'marketplace.payments.authentication.button.inProgress'}
                    isInProgress={this.isAuthenticationInProgress()}
                    onClick={(event) => this.handleButtonClick(event)}
                />
            </div>
        );
    }

    renderContent({ authenticationInformation, authenticationButton, failureInfo, successInfo }) {
        return (
            <div>
                {authenticationInformation}
                {authenticationButton}
                {failureInfo}
                {successInfo}
            </div>
        );
    }

    render() {
        if (this.props.hasError) {
            return <LoadingErrorState />;
        }

        if (this.props.isFetchingData) {
            return this.renderLoadingSpinner();
        }

        const authenticationInformation = <AuthenticationInformation failedPayment={this.props.failedPayment} />;

        const authenticationButton =
            !this.isAuthenticationSuccessful() && this.props.failedPayment
                ? this.renderAuthenticateButton()
                : undefined;

        const failureInfo = this.isAuthenticationFailed() ? this.renderPaymentErrorMessage() : undefined;

        const successInfo = this.isAuthenticationSuccessful() ? <AuthenticationSuccessInfo /> : undefined;

        return (
            <div className={'margin-top-5pct'}>
                <CustomState
                    message={this.renderContent({
                        authenticationInformation,
                        authenticationButton,
                        failureInfo,
                        successInfo,
                    })}
                />
            </div>
        );
    }
}

ReauthenticationPage.propTypes = {
    performAuthentication: PropTypes.func,
    failedPayment: PropTypes.shape({
        id: PropTypes.string,
        status: PropTypes.string,
        timestamp: PropTypes.string,
        amount: PropTypes.string,
        currency: PropTypes.string,
    }),
    isFetchingData: PropTypes.bool,
    hasError: PropTypes.bool,
};

export default injectIntl(ReauthenticationPage);
