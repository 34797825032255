import { getUsageOverallCurrency, getUsageProducts } from '../redux/usage.redux';
import { UsageSummaryColumn, UsageSummaryColumnType } from './types';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../../configuration/setup/store';

export const getUsageSummaryColumns: (state: RootState) => Array<UsageSummaryColumn> = createSelector(
    getUsageOverallCurrency,
    getUsageProducts,
    (overallCurrency, products) => {
        const columns: Array<UsageSummaryColumn> = [];

        products.forEach((product) => {
            if (columns.length !== 0) {
                columns.push({
                    type: UsageSummaryColumnType.SPACER_COLUMN,
                    symbol: overallCurrency ? '+' : '',
                });
            }
            columns.push({
                productName: product.productName,
                productSku: product.productSku,
                type: UsageSummaryColumnType.PRODUCT_COLUMN,
                ratePlanId: product.ratePlanId,
                variantName: product.variantName,
                contractPeriod: product.contractPeriod,
                level: product.level,
            });
        });
        return columns;
    }
);
