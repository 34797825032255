import { FormattedMessage } from 'react-intl';

interface Props {
    from?: string;
    to: string;
}

export const ResourceGroupHeader = (props: Props) => {
    const { from, to } = props;
    return (
        <div>
            <div className='position-relative padding-top-10 padding-bottom-10'>
                <hr className='margin-0 border-width-2' />
                {/* eslint-disable-next-line max-len */}
                <span className='bg-lightest padding-10 text-size-h6 position-absolute top-50pct left-50pct translate-x-50pct-y-50pct'>
                    <span>{from ?? <FormattedMessage id={'marketplace.notBooked'} />}</span>
                    <span className='rioglyph rioglyph-arrow-right margin-left-5 margin-right-5' />
                    <span>{to}</span>
                </span>
            </div>
        </div>
    );
};
