import { connect } from 'react-redux';
import { PreSelectedValidResourcesExpander } from './ResourceSelectionExpanderContainer';
import { getSelectedProduct } from '../redux/checkout.redux';
import { MultiLevelSelectedResourceListContainer } from './MultiLevelResourceSelectionBookableResourceList';
import { isMultiLevelProduct } from '../redux/types';
import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';
import { getFilteredGroupedResources } from './resourceSelectionSearchService';
import { getSelectedResourceIds } from './redux/resourceSelection.redux';
import { RootState } from '../../../../configuration/setup/store';
import { ProductTypeAwareFormattedMessage } from './ProductTypeAwareFormattedMessage';

interface Props {
    productIsMultiLevel: boolean;
    numberOfSelectedValidResources: number;
}

function ReadOnlySelectedResources(props: Props) {
    const { productIsMultiLevel, numberOfSelectedValidResources } = props;
    if (numberOfSelectedValidResources === 0) {
        return null;
    }
    return productIsMultiLevel ? (
        <ExpanderPanel
            title={
                <b>
                    <ProductTypeAwareFormattedMessage id={'headlineLocked'} />
                    {` (${numberOfSelectedValidResources})`}
                </b>
            }
            bsStyle='default'
            bodyClassName={'bg-lightest'}
            open
        >
            <MultiLevelSelectedResourceListContainer />
        </ExpanderPanel>
    ) : (
        <PreSelectedValidResourcesExpander />
    );
}

const mapStateToProps = (state: RootState) => ({
    productIsMultiLevel: isMultiLevelProduct(getSelectedProduct(state)),
    numberOfSelectedValidResources: getFilteredGroupedResources(state).bookable.filter((it) =>
        getSelectedResourceIds(state).includes(it.id)
    ).length,
});

export const ReadOnlySelectedResourcesContainer = connect(mapStateToProps)(ReadOnlySelectedResources);
