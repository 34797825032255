import { FormattedMessage, useIntl } from 'react-intl';
import { getSelectedResourceIds } from '../resourceSelection/redux/resourceSelection.redux';
import { connect } from 'react-redux';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { getNumberOfBookableResources } from './bookableResourcesService';
import { BookableProduct } from '../redux/types';
import { getSelectedProductOrProductLevel } from '../redux/checkout.redux';
import { ProductIcon } from '../../common/productImages/ProductIcon';
import { PriceContainer } from './PriceContainer';
import { getProductLabel } from '../../common/utils/productLabelUtil';
import { RootState } from '../../../../configuration/setup/store';

interface Props {
    selectedResourcesCount: number;
    numberOfBookableResources: number;
    selectedProduct?: BookableProduct;
}

const ResourceSelectionSummary = (props: Props) => {
    const { selectedProduct, numberOfBookableResources, selectedResourcesCount } = props;

    if (!selectedProduct) {
        return <Spinner />;
    }
    const productName = getProductLabel(selectedProduct, useIntl());
    const sku = selectedProduct.sku;

    return (
        // eslint-disable-next-line max-len
        <div className='display-flex-sm flex-wrap-sm align-items-center-sm justify-content-around-sm margin-right-10-xs padding-top-5 padding-bottom-5'>
            <div className='display-flex flex-1-0'>
                <ProductIcon
                    productId={sku}
                    className={'productIcon width-50 height-50 margin-right-10 position-relative'}
                />
                <div>
                    {productName && (
                        <div className='productName text-size-h3 line-height-h3 text-bold margin-bottom-5'>
                            {productName}
                        </div>
                    )}
                    <div>
                        <FormattedMessage
                            id='marketplace.assetDialog.selectedAssets'
                            values={{ amount: selectedResourcesCount, total: numberOfBookableResources }}
                        />
                    </div>
                </div>
            </div>
            <PriceContainer productType={selectedProduct.productType} />
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    selectedResourcesCount: getSelectedResourceIds(state).length,
    selectedProduct: getSelectedProductOrProductLevel(state),
    numberOfBookableResources: getNumberOfBookableResources(state),
});

export const ResourceSelectionSummaryContainer = connect(mapStateToProps)(ResourceSelectionSummary);
