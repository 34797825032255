import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Profile } from 'oidc-client';
import { accessToken } from './accessToken';
import { RootState } from '../setup/store';

export type AccessToken = string | undefined | null;

export interface RioProfile extends Partial<Profile> {
    account?: string;
}

export interface AccessTokenState {
    accessToken: AccessToken;
    idToken: RioProfile | null;
}

const initialState: AccessTokenState = {
    accessToken: accessToken.getAccessToken(),
    idToken: null,
};

const tokenSlice = createSlice({
    name: 'tokenHandling',
    initialState,
    reducers: {
        accessTokenStored: (state, action: PayloadAction<AccessToken>) => {
            state.accessToken = action.payload;
        },
        idTokenStored: (state, action: PayloadAction<RioProfile>) => {
            state.idToken = action.payload;
        },
    },
});

export const { accessTokenStored, idTokenStored } = tokenSlice.actions;

export const getAccessToken = (state: RootState) => state.tokenHandling.accessToken ?? 'NO_ACCESS_TOKEN_AVAILABLE';
export const getIdToken = (state: RootState) => state.tokenHandling.idToken;
export const getOptionalAccountId = (state: RootState) => state.tokenHandling.idToken?.account;

export const getAccountId = (state: RootState) => {
    const accountIdOrUndefined = getOptionalAccountId(state);
    if (!accountIdOrUndefined) {
        throw new Error('expected accountId to be present but it was not');
    }
    return accountIdOrUndefined;
};

export default tokenSlice.reducer;
