import { useEffect, useState } from 'react';

export function calculateIsChecked(available: Array<unknown> | undefined, selected: Array<unknown>) {
    const isAllSelected = available?.length === selected.length;
    const isNothingSelected = selected.length === 0;
    if (isNothingSelected) {
        return false;
    } else if (isAllSelected) {
        return true;
    }
    return undefined;
}

export function useCheckboxState(available: Array<unknown> | undefined, selected: Array<unknown>) {
    const [isChecked, setIsChecked] = useState<boolean | undefined>();

    useEffect(() => {
        setIsChecked(calculateIsChecked(available, selected));
    }, [available, selected]);
    return isChecked;
}
