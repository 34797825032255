import { Dispatch } from 'redux';
import { apiCallAction } from '../api/redux/api.redux';
import { ApiCalls } from '../api/redux/types';
import { freeTrialActions } from './redux/freeTrial.redux';
import { activateFreeTrial } from '../api/freeTrial/freeTrialCalls';

export const activateFreeTrialThunk = (dispatch: Dispatch) => {
    dispatch(apiCallAction(ApiCalls.ACTIVATE_FREE_TRIAL, true, false));

    return activateFreeTrial()
        .then((freeTrial) => {
            dispatch(freeTrialActions.setFreeTrialStatus(freeTrial.status));
            dispatch(freeTrialActions.setIsFreeTrialEndDate(freeTrial.endsAt?.toISOString()));
            dispatch(apiCallAction(ApiCalls.ACTIVATE_FREE_TRIAL, false, false));
        })
        .catch(() => {
            dispatch(apiCallAction(ApiCalls.ACTIVATE_FREE_TRIAL, false, true));
        });
};
