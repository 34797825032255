import { connect } from 'react-redux';
import { getAvailableResources } from './redux/resourceSelection.redux';
import { ResourceSelectionSummaryContainer } from '../preview/ResourceSelectionSummary';
import { ResourceSelectionSearchContainer } from './ResourceSelectionSearch';
import {
    ResourceSelectionBookedExpander,
    ResourceSelectionNotBookableExpander,
} from './ResourceSelectionExpanderContainer';
import { isResourceBookable } from '../../common/utils/resourceUtil';
import { isMultiLevelProduct } from '../redux/types';
import { getProductLevelsForVariant, getSelectedProduct } from '../redux/checkout.redux';
import { BookableResourcesSelectionContainer } from './BookableResourcesSelectionContainer';
import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';
import { getIsLoading } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { RootState } from '../../../../configuration/setup/store';
import { ProductTypeAwareFormattedMessage } from './ProductTypeAwareFormattedMessage';
import { GeoLHint } from './GeoLHint';

interface Props {
    hasBookableResources: boolean;
    hasAvailableResources: boolean;
    showExtraPadding: boolean;
    isLoading: boolean;
}

export const RESOURCE_SELECTION_TAGS_PER_ROW = 2;

function ResourceSelectionDialogList(props: Props) {
    const { hasBookableResources, hasAvailableResources, showExtraPadding, isLoading } = props;
    if (!hasAvailableResources && !isLoading) {
        return (
            <div className='width-100pct'>
                <p>
                    <ProductTypeAwareFormattedMessage id={'noResources'} />
                </p>
            </div>
        );
    }
    return (
        <div className='width-100pct'>
            <div>
                <ExpanderPanel
                    title={<ResourceSelectionSummaryContainer />}
                    bsStyle={'default'}
                    titleClassName={`width-100pct${showExtraPadding ? ' padding-top-15' : ''}`}
                    bodyClassName={'padding-0 padding-top-1'}
                    className={'margin-bottom-20'}
                    open={true}
                >
                    {isLoading ? (
                        <div className='margin-20'>
                            <Spinner />
                        </div>
                    ) : (
                        <div>
                            {hasBookableResources ? (
                                <BookableResourcesSelectionContainer />
                            ) : (
                                <div className='panel-body text-center margin-15'>
                                    <ProductTypeAwareFormattedMessage id={'noBookableResources'} />
                                </div>
                            )}
                            {!hasBookableResources && (
                                <div className='width-300 margin-20'>
                                    <ResourceSelectionSearchContainer />
                                </div>
                            )}
                        </div>
                    )}
                </ExpanderPanel>
                {!isLoading && (
                    <>
                        <ResourceSelectionBookedExpander />
                        <ResourceSelectionNotBookableExpander>
                            <GeoLHint />
                        </ResourceSelectionNotBookableExpander>
                    </>
                )}
            </div>
        </div>
    );
}

const mapStateToProps = (state: RootState) => ({
    isLoading: getIsLoading(state, ApiCalls.RESOURCE_SELECTION),
    hasBookableResources: getAvailableResources(state).filter(isResourceBookable).length > 0,
    productIsMultiLevel: isMultiLevelProduct(getSelectedProduct(state)),
    hasAvailableResources: getAvailableResources(state).length > 0,
    showExtraPadding: getProductLevelsForVariant(state) !== undefined,
});

export const ResourceSelectionListContainer = connect(mapStateToProps)(ResourceSelectionDialogList);
