import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { getIsLoading } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import { getHistoryNextPageCursor } from './redux/history.redux';
import { historyThunk } from './history.thunk';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { RootDispatch, RootState } from '../../../../configuration/setup/store';

interface Props {
    loadMore: (next: string) => void;
    next?: string;
    isLoading: boolean;
}

export const HistoryLoadMoreButton = ({ isLoading, loadMore, next }: Props) => {
    if (isLoading) {
        return <Spinner text={<FormattedMessage id={'marketplace.history.loading'} />} isInverse={false} />;
    }

    if (next) {
        return (
            <div className={'text-center'}>
                <button className={`LoadMoreButton btn btn-default`} onClick={() => loadMore(next)}>
                    <FormattedMessage id={'marketplace.history.button.loadMore'} />
                </button>
            </div>
        );
    }
    return null;
};

const mapStateToProps = (state: RootState) => ({
    isLoading: getIsLoading(state, ApiCalls.HISTORY_EVENTS),
    next: getHistoryNextPageCursor(state),
});

const mapDispatchToProps = (dispatch: RootDispatch) => ({
    loadMore: (nextId: string) => {
        dispatch(historyThunk(undefined, nextId));
    },
});

export const HistoryLoadMoreButtonContainer = connect(mapStateToProps, mapDispatchToProps)(HistoryLoadMoreButton);
