export interface PartnerConnection {
    id: string;
    accountId: string;
    partnerName: string;
    partnerDisplayName: string;
    status: PartnerConnectionStatus;
    createdAt: string;
}

export enum PartnerConnectionStatus {
    ACTIVATED = 'activated',
    FAILED_ACTIVATION = 'failed-activation',
    FAILED_DEACTIVATION = 'failed-deactivation',
}

export interface PartnersState {
    connections: Array<PartnerConnection>;
    permissions: boolean;
}
