import { IntlShape, useIntl } from 'react-intl';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import Tag from '@rio-cloud/rio-uikit/lib/es/Tag';
import { ProductState } from '../../checkout/resourceSelection/redux/types';
import { MessageKey } from '../../../../configuration';
import { OverviewResource } from './redux/types';
import { DiscountBadgeWithTooltip } from '../../common/discount/DiscountBadge';
import { connect } from 'react-redux';
import { DiscountIdentifier, mapStateToFindDiscountProps } from '../../common/discount/DiscountService';
import { AssetDiscount } from '../../common/discount/redux/types';
import { OverlayTrigger, Tooltip } from '../../common/utils/tooltipReExports';
import { isServiceCareLBeta } from '../../common/betaService';
import { ProductType } from '../../common/product/product';
import { DEFAULT_TOOLTIP_DELAY } from '../../config/config';

function getErrorMessage(
    status: ProductState | undefined,
    intl: IntlShape,
    productType: ProductType | undefined
): string {
    const isAsset = productType === ProductType.ASSET_BASED;
    let messageId: MessageKey;

    if (status === ProductState.PENDING_ACTIVATION) {
        messageId = isAsset ? 'marketplace.asset.pending.activation.info' : 'marketplace.user.pending.activation.info';
    } else if (status === ProductState.PENDING_CANCELLATION) {
        messageId = isAsset
            ? 'marketplace.resourceSelection.asset.tooltipPendingCancellation'
            : 'marketplace.resourceSelection.user.tooltipPendingCancellation';
    } else {
        messageId = isAsset ? 'marketplace.asset.unknownState.info' : 'marketplace.user.unknownState.info';
    }
    return intl.formatMessage({ id: messageId });
}

export interface ServiceResourceProps {
    resource: OverviewResource;
    resourceInProgress: boolean;
    isRemovable: boolean;
    onSelectResource: (resource: OverviewResource) => void;
    findDiscount: (discountIdentifier: DiscountIdentifier) => AssetDiscount | undefined;
    isSelected: boolean;
    productId: string;
    displayedLevel?: string;
    productType: ProductType | undefined;
}

const MaybeWrapWithOverlay = ({
    wrap,
    status,
    children,
    productType,
}: {
    wrap: boolean;
    status: ProductState | undefined;
    children: React.ReactElement;
    productType: ProductType | undefined;
}) => {
    const intl = useIntl();
    if (wrap) {
        return (
            <OverlayTrigger
                trigger={OverlayTrigger.TRIGGER_HOVER}
                placement={'bottom'}
                delay={DEFAULT_TOOLTIP_DELAY}
                overlay={
                    <Tooltip id={'assetTooltip'} className={'width-auto max-width-300'}>
                        {getErrorMessage(status, intl, productType)}
                    </Tooltip>
                }
            >
                {children}
            </OverlayTrigger>
        );
    }
    return children;
};

const baseClassNames = `rounded display-flex align-items-center`;

function getClassNames(resource: OverviewResource, isSelected: boolean, displayedLevel: string | undefined) {
    if (resource.productState === ProductState.ACTIVE) {
        return `selectable ${baseClassNames} ${isSelected ? 'active' : ''}`;
    }
    if (
        resource.productState === ProductState.PENDING_ACTIVATION &&
        resource.targetLevel &&
        resource.originLevel &&
        resource.originLevel === displayedLevel
    ) {
        const symbol = resource.originLevel < resource.targetLevel ? 'rioglyph-arrow-up' : 'rioglyph-arrow-down';
        return `${baseClassNames} cursor-not-allowed bg-lightest rioglyph ${symbol}`;
    }
    return `${baseClassNames} disabled rioglyph rioglyph-hour-glass`;
}

export const ServiceResource = (props: ServiceResourceProps) => {
    const {
        resource,
        isSelected,
        onSelectResource,
        resourceInProgress,
        isRemovable,
        displayedLevel,
        findDiscount,
        productId,
        productType,
    } = props;
    const status = resource.productState;

    const isActive = status === ProductState.ACTIVE;
    const tagClassName = getClassNames(resource, isSelected, displayedLevel);
    const canBeRemoved =
        isRemovable && isActive && !resourceInProgress && !isServiceCareLBeta(productId, resource.targetLevel);

    const discount = findDiscount({ assetId: resource.id, productId: productId, productLevel: displayedLevel });

    return (
        <MaybeWrapWithOverlay wrap={!isActive} status={status} productType={productType}>
            <Tag
                className={tagClassName}
                {...(canBeRemoved && {
                    onClick: () => onSelectResource(resource),
                    clickable: true,
                    selectable: true,
                })}
            >
                <div className={'flex-1-1 display-flex justify-content-between'}>
                    <div className={'text-left'}>{resource.name}</div>
                    {discount && <DiscountBadgeWithTooltip discount={discount} />}
                    {resourceInProgress && <Spinner />}
                </div>
            </Tag>
        </MaybeWrapWithOverlay>
    );
};

export const ServiceResourceContainer = connect(mapStateToFindDiscountProps)(ServiceResource);
