import { FreeTrialProductInfo } from '../FreeTrialConfig';
import { ProductIcon } from '../../common/productImages/ProductIcon';
import { FormattedMessage } from 'react-intl';
import { SavingHint } from './SavingHint';

type Props = {
    productDetails: FreeTrialProductInfo & { price?: number; currency?: string };
};

export function FreeTrialProductDetails({ productDetails }: Props) {
    const { productId, productName, shortExplanationId, longExplanationId } = productDetails;
    return (
        <div className='product bg-white rounded-large padding-25 width-300 text-color-darkest margin-x-25'>
            <div className='display-flex gap-10 margin-bottom-20'>
                <ProductIcon productId={productId} className='width-50 height-50' />
                <div>
                    <div className='text-size-20 text-bold'>{productName}</div>
                    <div className='text-size-16 text-color-dark'>
                        <FormattedMessage id={shortExplanationId} />
                    </div>
                </div>
            </div>
            <div className='text-size-16'>
                <FormattedMessage id={longExplanationId} />
            </div>
            <SavingHint price={productDetails.price} currency={productDetails.currency} />
        </div>
    );
}
