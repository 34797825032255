import { Dispatch } from 'redux';
import { apiCallAction } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import { fetchDiscounts } from '../../api/discount/discountCalls';
import { discountActions } from './redux/discount.redux';
import { Discounts } from './redux/types';

export const fetchCheckoutDiscountDataThunk = fetchDiscountsThunk();

export function fetchDiscountsThunk(productId?: string, level?: string) {
    return (dispatch: Dispatch): Promise<void> => {
        dispatch(discountActions.setDiscounts({ assetDiscounts: [], accountDiscounts: [], productDiscounts: [] }));
        dispatch(apiCallAction(ApiCalls.DISCOUNTS, true, false));
        return fetchDiscounts(productId, level)
            .then((response: Discounts) => {
                dispatch(discountActions.setDiscounts(response));
                dispatch(apiCallAction(ApiCalls.DISCOUNTS, false));
            })
            .catch(() => {
                dispatch(apiCallAction(ApiCalls.DISCOUNTS, false, true));
            });
    };
}
