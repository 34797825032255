import { Dispatch } from 'redux';
import { fetchStripeApiKey, fetchStripeClientSecret } from '../../api/paymentMethods/stripeCalls';
import { paymentMethodsActions } from './redux/paymentMethods.redux';
import { apiCallAction } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import { getAccountId } from '../../../../configuration';
import { RootState } from '../../../../configuration/setup/store';

export const fetchStripeApiKeyThunk = (dispatch: Dispatch): Promise<void> => {
    dispatch(apiCallAction(ApiCalls.STRIPE_API_KEY, true));

    return fetchStripeApiKey()
        .then((stripeApiKey) => {
            dispatch(paymentMethodsActions.setStripeApiKey(stripeApiKey));
        })
        .then(() => {
            dispatch(apiCallAction(ApiCalls.STRIPE_API_KEY, false, false));
        })
        .catch(() => {
            dispatch(apiCallAction(ApiCalls.STRIPE_API_KEY, false, true));
        });
};

export const fetchStripeClientSecretThunk = (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
    dispatch(apiCallAction(ApiCalls.STRIPE_CLIENT_SECRET, true));

    const accountId = getAccountId(getState());

    return fetchStripeClientSecret(accountId)
        .then((stripeClientSecret) => {
            dispatch(paymentMethodsActions.setStripeClientSecret(stripeClientSecret));
        })
        .then(() => {
            dispatch(apiCallAction(ApiCalls.STRIPE_CLIENT_SECRET, false, false));
        })
        .catch(() => {
            dispatch(apiCallAction(ApiCalls.STRIPE_CLIENT_SECRET, false, true));
        });
};
