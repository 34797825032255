import { Dispatch } from 'redux';
import { fetchFailedPayments, fetchPaymentSecret } from '../api/payments/paymentsCalls';
import { getAccountId } from '../../../configuration';
import { paymentsActions } from './redux/payments.redux';
import { apiCallAction } from '../api/redux/api.redux';
import { ApiCalls } from '../api/redux/types';
import { RootState } from '../../../configuration/setup/store';

export function fetchPaymentSecretThunk(paymentId: string) {
    return (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        dispatch(apiCallAction(ApiCalls.PAYMENT_SECRET, true, false));
        const accountId = getAccountId(getState());
        return fetchPaymentSecret(accountId, paymentId)
            .then((response) => {
                dispatch(paymentsActions.setPaymentSecret(response));
                dispatch(apiCallAction(ApiCalls.PAYMENT_SECRET, false, false));
            })
            .catch(() => {
                dispatch(apiCallAction(ApiCalls.PAYMENT_SECRET, false, true));
            });
    };
}

export const fetchFailedPaymentsThunk = (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
    dispatch(apiCallAction(ApiCalls.FAILED_PAYMENTS, true, false));
    const accountId = getAccountId(getState());
    return fetchFailedPayments(accountId)
        .then((response) => {
            dispatch(paymentsActions.setFailedPayments(response));
            dispatch(apiCallAction(ApiCalls.FAILED_PAYMENTS, false, false));
        })
        .catch(() => {
            dispatch(apiCallAction(ApiCalls.FAILED_PAYMENTS, false, true));
        });
};
