import { useEffect, useState } from 'react';
import { fetchAllNonLegacyProducts } from '../../api/product/productCalls';
import { isBookableProduct, isMultiLevelProduct, Product } from '../../checkout/redux/types';

type ProductPriceDetail = { productId: string; level?: string; price: number; currency?: string };

export function usePriceFetching(): Array<ProductPriceDetail> {
    const [priceDetails, setPriceDetails] = useState<Array<ProductPriceDetail>>([]);

    useEffect(() => {
        fetchAllNonLegacyProducts()
            .then((products) => {
                const details = products.flatMap(mapProductToPriceDetails);
                setPriceDetails(details);
            })
            .catch();
    }, []);
    return priceDetails;
}

function mapProductToPriceDetails(product: Product): Array<ProductPriceDetail> {
    if (isBookableProduct(product)) {
        return [{ productId: product.sku, level: product.level, price: product.price, currency: product.currency }];
    } else if (isMultiLevelProduct(product)) {
        return product.levels.map((level) => ({
            productId: product.sku,
            level: level.level,
            price: level.price,
            currency: level.currency,
        }));
    }
    return [];
}
