import { CLOSE_IFRAME, CloseIframeMessage } from '../postMessage.types';

export const sendIFrameCloseMessageToParent = () => {
    try {
        const message: CloseIframeMessage = { type: CLOSE_IFRAME };
        window.parent.postMessage(message, '*');
    } catch (e) {
        // ignore error because some browsers block this request, and it is only a usability error
    }
};
