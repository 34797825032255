import Checkbox from '@rio-cloud/rio-uikit/lib/es/Checkbox';
import { connect } from 'react-redux';
import { assetOverviewActions, getSelectedAssetIdsInAssetOverview } from './redux/assetOverview.redux';
import { RootDispatch, RootState } from '../../../../configuration/setup/store';
import { useIsFleetUser } from '../../common/permissions/hooks';

interface Props {
    selected: boolean;
    selectAsset: () => void;
}

function AssetCheckbox(props: Props) {
    const fleetUser = useIsFleetUser();

    return <Checkbox checked={props.selected} onClick={props.selectAsset} disabled={fleetUser} />;
}

const mapStateToProps = (state: RootState, ownProps: { assetId: string }) => ({
    selected: getSelectedAssetIdsInAssetOverview(state).includes(ownProps.assetId),
});

const mapDispatchToProps = (dispatch: RootDispatch, ownProps: { assetId: string }) => ({
    selectAsset: () => dispatch(assetOverviewActions.selectAsset(ownProps.assetId)),
});

export const AssetCheckboxContainer = connect(mapStateToProps, mapDispatchToProps)(AssetCheckbox);
