import { InvoiceTable } from './InvoiceTable';
import { DatedInvoices } from './redux/types';
import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';
import { InvoicesMonthTitle } from './InvoicesMonthTitle';

interface Props {
    invoicesMonth: DatedInvoices;
}

export const InvoicesMonth = (props: Props) => {
    const { invoicesMonth } = props;
    return (
        <div className='InvoiceMonth'>
            <br />
            <ExpanderPanel
                title={<InvoicesMonthTitle invoicesMonth={invoicesMonth} />}
                bsStyle='default'
                bodyClassName='padding-0'
            >
                <InvoiceTable invoices={invoicesMonth.invoices} />
            </ExpanderPanel>
        </div>
    );
};
