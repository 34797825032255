import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import LoadingErrorState from '../../common/error/LoadingErrorState';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { fetchBillingAddressIfNecessaryByTenant } from '../../common/billing/billingAddress.thunk';
import { getHasError, getIsLoading } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import { RootDispatch, RootState } from '../../../../configuration/setup/store';
import { BillingAddress as BillingAddressState } from '../../common/billing/redux/types';
import { getBillingAddress } from '../../common/billing/redux/billing.redux';

interface Props {
    billingAddress?: BillingAddressState;
    hasError: boolean;
    isLoading: boolean;
    fetchBillingAddress: () => void;
}

const BillingAddress = ({ billingAddress, hasError, fetchBillingAddress, isLoading }: Props) => {
    useEffect(() => {
        fetchBillingAddress();
    }, [fetchBillingAddress]);

    if (hasError) {
        return <LoadingErrorState />;
    }

    if (isLoading) {
        return <Spinner text={<FormattedMessage id={'marketplace.billingAddress.loading'} />} isInverse={false} />;
    }

    const { company, street, postalCode, city, countryCode, vatNumber } = billingAddress || {};

    return (
        <address className='BillingAddress'>
            <div>
                <div>{company}</div>
                <div>{street}</div>
                <div>
                    {postalCode} {city}
                </div>
                <div>{countryCode}</div>
            </div>
            <br />
            <div>
                {vatNumber ? (
                    <div>
                        <FormattedMessage id='marketplace.preview.taxNumber' /> {vatNumber}
                    </div>
                ) : null}
            </div>
        </address>
    );
};

const mapStateToProps = (state: RootState) => ({
    billingAddress: getBillingAddress(state),
    hasError: getHasError(state, ApiCalls.BILLING_ADDRESS),
    isLoading: getIsLoading(state, ApiCalls.BILLING_ADDRESS),
});

const mapDispatchToProps = (dispatch: RootDispatch) => ({
    fetchBillingAddress: () => dispatch(fetchBillingAddressIfNecessaryByTenant()),
});

export const BillingAddressContainer = connect(mapStateToProps, mapDispatchToProps)(BillingAddress);
