import { useState } from 'react';
import { naturalSortByProperty, SortDirectionType } from '@rio-cloud/rio-uikit/lib/es/SortUtils';
import SortDirection from '@rio-cloud/rio-uikit/lib/es/SortDirection';

export function useSorting<T>(initialSortingPropertyName: keyof T) {
    const [sortBy, setSortBy] = useState<keyof T | undefined>(initialSortingPropertyName);
    const [direction, setDirection] = useState<SortDirectionType>(SortDirection.ASCENDING);

    const sortDirectionForProperty = (propertyName: keyof T) => (sortBy === propertyName ? direction : undefined);
    const sort = (items: Array<T>): Array<T> => (sortBy ? naturalSortByProperty(items, sortBy, direction) : items);
    const handleSortChange = (propertyName: keyof T) => {
        if (sortBy === propertyName) {
            setDirection(direction === SortDirection.ASCENDING ? SortDirection.DESCENDING : SortDirection.ASCENDING);
        } else {
            setSortBy(propertyName);
            setDirection(SortDirection.ASCENDING);
        }
    };
    return {
        sort,
        sortDirectionForProperty,
        handleSortChange,
    };
}
