import { FormattedMessage } from 'react-intl';
import { MARKETPLACE_START_FREE_TRIAL } from '../common/routes';

export const CheckoutFreeTrialWarning = () => (
    <div className='alert alert-warning display-flex align-items-start'>
        <span className='rioglyph rioglyph-warning-sign text-size-h3 padding-right-10 align-self-center' />
        <span>
            <FormattedMessage
                id={'marketplace.checkout.freeTrial.warning'}
                values={{
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    link: (chunks: Array<string>) => (
                        <a href={`/${MARKETPLACE_START_FREE_TRIAL}`} target='_blank' rel='noreferrer'>
                            {chunks}
                        </a>
                    ),
                }}
            />
        </span>
    </div>
);
