import { getMarketplaceBackend } from '../../config/pathURL/config';
import { getRequest } from '../requests';
import { decodeJson, jsonOrReject, onRejected } from '../api';
import { getParameters } from '../parameterService';
import { ApiBookableResourcesCodec } from './bookable.types';
import { BookableResource } from '../../checkout/resourceSelection/redux/types';
import { mapBookableResponse } from './bookableMapper';
import { DisplayMessages } from '../../../../configuration';

export function fetchBookableResources(
    sku: string,
    level: string | undefined,
    displayMessages: DisplayMessages
): Promise<Array<BookableResource>> {
    const params = getParameters({ level: level });
    return fetch(`${getMarketplaceBackend()}/activations/${sku}/bookable${params}`, getRequest())
        .then(jsonOrReject)
        .then(decodeJson(ApiBookableResourcesCodec))
        .then(mapBookableResponse(displayMessages))
        .catch(onRejected([403]));
}
