import { DEFAULT_LOCALE } from './languageConfiguration';

let aemLanguageCode: string | undefined;

export function setAemLanguageCode(locale: string) {
    aemLanguageCode = locale;
}

export function getAemLanguageCode() {
    return aemLanguageCode || DEFAULT_LOCALE;
}
