import Checkbox from '@rio-cloud/rio-uikit/lib/es/Checkbox';
import { connect } from 'react-redux';
import { assetOverviewActions, getSelectedAssetIdsInAssetOverview } from './redux/assetOverview.redux';
import { getAssetOverviewFilteredRows } from './assetOverviewSearchService';
import { useCheckboxState } from '../../common/utils/checkboxUtil';
import { AssetOverviewRow } from './redux/types';
import { RootDispatch, RootState } from '../../../../configuration/setup/store';
import { useIsFleetUser } from '../../common/permissions/hooks';

interface Props {
    selectedAssets: Array<unknown>;
    displayedAssets: Array<AssetOverviewRow>;
    setSelectedAssets: (selectedAssetIds: Array<string>) => void;
}

function AllAssetsCheckbox({ selectedAssets, displayedAssets, setSelectedAssets }: Props) {
    const checked = useCheckboxState(displayedAssets, selectedAssets);
    const assetsToSelect = checked ? [] : displayedAssets.map((asset) => asset.assetId);
    const fleetUser = useIsFleetUser();

    return (
        <Checkbox
            checked={Boolean(checked)}
            indeterminate={checked === undefined}
            onClick={() => setSelectedAssets(assetsToSelect)}
            disabled={fleetUser}
        />
    );
}

const mapStateToProps = (state: RootState) => ({
    displayedAssets: getAssetOverviewFilteredRows(state).displayRows,
    selectedAssets: getSelectedAssetIdsInAssetOverview(state),
});

const mapDispatchToProps = (dispatch: RootDispatch) => ({
    setSelectedAssets: (selectedAssetIds: Array<string>) =>
        dispatch(assetOverviewActions.setSelectedAssets(selectedAssetIds)),
});

export const AllAssetsCheckboxContainer = connect(mapStateToProps, mapDispatchToProps)(AllAssetsCheckbox);
