import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getSelectedProductType } from '../redux/checkout.redux';
import { MessageKey } from '../../../../configuration';
import { ProductType } from '../../common/product/product';

const commonIds = [
    'dropdownSelectAll',
    'selectNotBooked',
    'selectAllFrom',
    'deselectAll',
    'tooltipAlreadyBooked',
] as const;

type CommonId = typeof commonIds[number];

type Id =
    | 'noSearchedResourcesFound'
    | 'noResources'
    | 'noBookableResources'
    | 'invalidResourceSelectionHeader'
    | 'invalidResourceSelectionHint'
    | 'showAllResources'
    | 'headline'
    | 'headlineLocked'
    | 'selectAll'
    | 'selectHere'
    | 'bookedResources'
    | 'notBookableResources'
    | 'bookableResources'
    | 'alreadyBookedResources'
    | 'notFoundResources'
    | 'searchPlaceholder'
    | 'tooltipNotEligible'
    | 'tooltipOpenContract'
    | 'tooltipPendingCancellation'
    | 'externalAdmin.hint'
    | 'externalAdmin.link'
    | 'previewTable.header.resources'
    | 'previewTable.header.price'
    | CommonId;

export type ProductTypeAwareFormattedMessageId = Id;

function isCommonTranslationId(id: Id): id is CommonId {
    return commonIds.includes(id as CommonId);
}

function useMappedTranslationId() {
    const productType = useSelector(getSelectedProductType);
    return (id: Id): MessageKey => {
        if (isCommonTranslationId(id)) {
            return `marketplace.resourceSelection.common.${id}`;
        }
        if (productType === ProductType.USER_BASED) {
            return `marketplace.resourceSelection.user.${id}`;
        }
        return `marketplace.resourceSelection.asset.${id}`;
    };
}

export function ProductTypeAwareFormattedMessage({
    id,
    ...props
}: {
    id: Id;
    values?: Record<string, string | number>;
}) {
    const translationId = useMappedTranslationId()(id);
    return <FormattedMessage id={translationId} {...props} />;
}

export function useProductTypeAwareIntl() {
    const intl = useIntl();
    const mappedTranslationId = useMappedTranslationId();
    return {
        formatMessage: ({ id, ...props }: { id: Id }) => intl.formatMessage({ id: mappedTranslationId(id), ...props }),
    };
}
