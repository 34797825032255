import LoadingErrorStateWithReload from '../../common/error/LoadingErrorStateWithReload';
import { connect } from 'react-redux';
import { fetchCheckoutProductDetailsThunk } from '../thunks/productDetails.thunk';
import { MessageKey } from '../../../../configuration';
import { RootDispatch } from '../../../../configuration/setup/store';

const mapStateToProps = (): { headline: MessageKey } => ({
    headline: 'marketplace.productDetails.fetch.error',
});

const mapDispatchToProps = (dispatch: RootDispatch) => ({
    onReload: () => dispatch(fetchCheckoutProductDetailsThunk),
});

export const CheckoutServiceError = connect(mapStateToProps, mapDispatchToProps)(LoadingErrorStateWithReload);
