import { PaymentMethodPageContainer } from '../../payment/PaymentMethodPageContainer';
import { FormattedMessage } from 'react-intl';

export const RenewDialogPaymentMethodContent = () => {
    return (
        <div>
            <div className='panel panel-warning'>
                <div className='panel-body'>
                    <FormattedMessage id={'marketplace.renewDialog.paymentMethod.prompt'} />
                </div>
            </div>
            <PaymentMethodPageContainer />
        </div>
    );
};
