export const PRODUCT_CATEGORY_GA = {
    PAID: 'Paid',
    FREE: 'Free',
    VIEW_ONLY: 'ViewOnly',
};

export const DEFAULT_BILLING_PERIOD = 'Daily';

export enum ProductType {
    ASSET_BASED = 'ASSET_BASED',
    USER_BASED = 'USER_BASED',
}
