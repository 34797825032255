/* eslint-disable camelcase */
import * as t from 'io-ts';
import { HistoryAction } from '../../customerCenter/history/redux/types';

const ActorCodec = t.intersection([t.type({ id: t.string }), t.partial({ name: t.string })]);
const ResourceCodec = t.intersection([t.type({ id: t.string, type: t.string }), t.partial({ name: t.string })]);

export const ApiHistoryActionTypeCodec = t.union([
    t.literal(HistoryAction.SERVICE_CREATED),
    t.literal(HistoryAction.SERVICE_CANCELLED),
    t.literal(HistoryAction.RESOURCE_ACTIVATION_REQUESTED),
    t.literal(HistoryAction.RESOURCE_ACTIVATED),
    t.literal(HistoryAction.RESOURCE_DEACTIVATION_REQUESTED),
    t.literal(HistoryAction.RESOURCE_DEACTIVATED),
    t.literal(HistoryAction.CONTRACT_RENEWS_TO_CHANGED),
    t.literal(HistoryAction.CONTRACT_RENEWED),
    t.literal(HistoryAction.CONTRACT_ENDED),
]);

export const ApiHistoryEventCodec = t.intersection([
    t.type({
        action: ApiHistoryActionTypeCodec,
        service_name: t.string,
        created_at: t.string,
    }),
    t.partial({
        resources: t.array(ResourceCodec),
        actor: ActorCodec,
        order_info: t.string,
    }),
]);
export type ApiHistoryEvent = t.TypeOf<typeof ApiHistoryEventCodec>;

export const ApiHistoryEventListCodec = t.intersection([
    t.type({
        items: t.array(ApiHistoryEventCodec),
    }),
    t.partial({
        next: t.string,
    }),
]);
export type ApiHistoryEventList = t.TypeOf<typeof ApiHistoryEventListCodec>;
