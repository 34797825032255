import { ResourceUsage } from '../redux/types';
import { getFilteredResourceTypes, getResourceUsages, getUsageSearchTerm } from '../redux/usage.redux';
import { getDisplayMessages } from '../../../../../configuration';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../../configuration/setup/store';

function resourceIsWithinSearch(
    resourceUsage: ResourceUsage,
    usageSearchTerm: string,
    unknownResourcesMatch: undefined | boolean
) {
    return (
        resourceUsage.resourceName?.toLocaleLowerCase().includes(usageSearchTerm) ||
        resourceUsage.resourceIdentification?.toLocaleLowerCase().includes(usageSearchTerm) ||
        (unknownResourcesMatch &&
            (resourceUsage.resourceName === undefined || resourceUsage.resourceIdentification === undefined))
    );
}

export const getSearchedResourceSummaries: (state: RootState) => Array<ResourceUsage> = createSelector(
    getResourceUsages,
    getUsageSearchTerm,
    getDisplayMessages,
    getFilteredResourceTypes,
    (resourceUsages, usageSearchTerm, languageData, filteredResourceTypes) => {
        const lowerCaseSearchTerm = usageSearchTerm.toLocaleLowerCase();
        const unknownVehicleLabel = languageData['marketplace.history.details.tooltip.unknown'];

        const unknownVehiclesMatch = unknownVehicleLabel?.toLocaleLowerCase().includes(lowerCaseSearchTerm);
        return resourceUsages.filter(
            (usage) =>
                (filteredResourceTypes as Array<string>).includes(usage.resourceType) &&
                (lowerCaseSearchTerm === '' || resourceIsWithinSearch(usage, lowerCaseSearchTerm, unknownVehiclesMatch))
        );
    }
);
