import { useIntl } from 'react-intl';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { assetOverviewActions, getTableSearch } from './redux/assetOverview.redux';
import TableSearch from '@rio-cloud/rio-uikit/lib/es/TableSearch';
import { RootState } from '../../../../configuration/setup/store';

interface Props {
    searchValue: string;
    onSearchChange: (searchValue: string) => void;
}

const AssetOverviewTableSearch = (props: Props) => {
    const { searchValue, onSearchChange } = props;
    const intl = useIntl();

    return (
        <TableSearch
            placeholder={intl.formatMessage({ id: 'marketplace.usage.summary.search.placeholder' })}
            value={searchValue}
            onChange={onSearchChange}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    searchValue: getTableSearch(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onSearchChange: (searchValue: string) => dispatch(assetOverviewActions.setTableSearch(searchValue)),
});

export const AssetOverviewTableSearchContainer = connect(mapStateToProps, mapDispatchToProps)(AssetOverviewTableSearch);
