import { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchProductInfoForAssets } from './assetOverview.thunk';
import { fetchTagsInfoThunk } from './assetInfo.thunk';
import { getHasError, getIsLoading } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import LoadingErrorStateWithReload from '../../common/error/LoadingErrorStateWithReload';
import { FormattedMessage } from 'react-intl';
import { assetOverviewActions, getAssetOverviewRows, getTagToAssetMap } from './redux/assetOverview.redux';
import { AssetOverviewCancelDialogContainer } from './AssetOverviewCancelDialogContainer';
import { AssetOverviewTableToolbarContainer } from './AssetOverviewTableToolbar';
import { AssetOverviewTableContainer } from './AssetOverviewTableContainer';
import { BookingSuccessMessage, SUCCESSFULLY_BOOKED } from '../../common/postMessage.types';
import { RootDispatch, RootState } from '../../../../configuration/setup/store';
import EmptyState from '@rio-cloud/rio-uikit/lib/es/EmptyState';
import { ExternalAdminHint } from '../../common/ExternalAdminHint';
import { ProductType } from '../../common/product/product';
import { LimitedPermissionsInfoBanner } from '../../common/permissions/LimitedPermissionsInfoBanner';

interface Props {
    fetchData: () => void;
    isLoading: boolean;
    hasError: boolean;
    isEmpty: boolean;
    deselectedAssets: () => void;
    reloadDataSilently: () => void;
    clearSelection: () => void;
}

export const AssetOverview = (props: Props) => {
    const { fetchData, clearSelection, isLoading, hasError, isEmpty, deselectedAssets, reloadDataSilently } = props;
    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        window.addEventListener('message', listenToSuccessfulFinishOfBooking);
        return () => {
            window.removeEventListener('message', listenToSuccessfulFinishOfBooking);
            clearSelection();
        };
    }, []);

    function listenToSuccessfulFinishOfBooking(message: MessageEvent) {
        if (message.origin !== window.origin) {
            return;
        }
        const messageData = message.data as unknown as BookingSuccessMessage;
        if (messageData.type === SUCCESSFULLY_BOOKED) {
            reloadDataSilently();
            deselectedAssets();
            setTimeout(() => reloadDataSilently(), 5000);
        }
    }

    if (isLoading) {
        return <Spinner text={<FormattedMessage id={'marketplace.loading'} />} isInverse={false} />;
    }

    if (hasError) {
        return <LoadingErrorStateWithReload onReload={fetchData} headline={'marketplace.myServices.fetch.error'} />;
    }

    if (isEmpty) {
        return (
            <EmptyState
                headline={<FormattedMessage id={'marketplace.resourceSelection.asset.noResources'} />}
                message={<ExternalAdminHint productType={ProductType.ASSET_BASED} />}
                outerClassName='margin-top-15pct'
            />
        );
    }

    return (
        <div>
            <LimitedPermissionsInfoBanner />
            <AssetOverviewCancelDialogContainer />
            <AssetOverviewTableToolbarContainer />
            <AssetOverviewTableContainer />
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    isLoading:
        getIsLoading(state, ApiCalls.ASSET_OVERVIEW_ASSET_INFO) ||
        getIsLoading(state, ApiCalls.ASSET_OVERVIEW_INFO) ||
        getIsLoading(state, ApiCalls.ASSET_OVERVIEW_TAGS) ||
        getIsLoading(state, ApiCalls.ASSET_OVERVIEW_PRODUCTS),
    hasError:
        getHasError(state, ApiCalls.ASSET_OVERVIEW_ASSET_INFO) ||
        getHasError(state, ApiCalls.ASSET_OVERVIEW_INFO) ||
        getHasError(state, ApiCalls.ASSET_OVERVIEW_TAGS) ||
        getHasError(state, ApiCalls.ASSET_OVERVIEW_PRODUCTS),
    isEmpty: Object.keys(getTagToAssetMap(state)).length === 0 && getAssetOverviewRows(state).length === 0,
});

const mapDispatchToProps = (dispatch: RootDispatch) => ({
    fetchData: () => {
        Promise.all([dispatch(fetchProductInfoForAssets(true)), dispatch(fetchTagsInfoThunk)]);
    },
    reloadDataSilently: () => dispatch(fetchProductInfoForAssets(false)),
    deselectedAssets: () => dispatch(assetOverviewActions.clearAssetSelection()),
    clearSelection: () => {
        dispatch(assetOverviewActions.clearAssetSelection());
        dispatch(assetOverviewActions.setTableSearch(''));
        dispatch(assetOverviewActions.setAssetsToDisplayByTags([]));
    },
});

export const AssetOverviewContainer = connect(mapStateToProps, mapDispatchToProps)(AssetOverview);
