import { FormattedMessage, useIntl } from 'react-intl';
import { OverlayTrigger, Tooltip } from '../../utils/tooltipReExports';
import { PriceInfoLongTerm } from '../../price/PriceInfoLongTerm';
import { PriceInfoDaily } from '../../price/PriceInfoDaily';
import { formatDurationUnit } from '../../utils/formatDurationUnit';
import { TermedProductLevel } from '../redux/types';
import { ProductType } from '../../product/product';
import { AssetDiscount } from '../../discount/redux/types';
import { TermedLevel } from '../types';
import { DEFAULT_TOOLTIP_DELAY } from '../../../config/config';

interface Props {
    productLevel: TermedProductLevel;
    isActiveTab: boolean;
    productType: ProductType;
    contracts: Array<TermedLevel>;
    findDiscount?: (assetId: string) => AssetDiscount | undefined;
}

export function RenewDialogDataTabTitle({ productLevel, isActiveTab, productType, contracts, findDiscount }: Props) {
    const { price, currency, contractPeriod, variantName, productLevelName } = productLevel;
    const isDiscountAvailable = findDiscount
        ? contracts.some((contract) => findDiscount(contract.resourceId))
        : undefined;

    const intl = useIntl();
    return (
        <div className='text-color-darkest'>
            <div className='margin-bottom-10 line-height-125rel display-flex'>
                <span className='rioglyph rioglyph rioglyph-repeat text-color-success margin-right-3' />
                <span className={`${isActiveTab ? 'text-bold' : ''}`}>{variantName ?? productLevelName}</span>

                {isDiscountAvailable && (
                    <span className={'margin-left-auto'}>
                        <OverlayTrigger
                            trigger={OverlayTrigger.TRIGGER_HOVER}
                            placement='top'
                            overlay={
                                <Tooltip id='tooltip' className={'width-auto max-width-300 display-flex'}>
                                    <FormattedMessage id='marketplace.contracts.discountsAvailable' />
                                </Tooltip>
                            }
                            delay={DEFAULT_TOOLTIP_DELAY}
                        >
                            <span className='bg-success padding-left-5 padding-right-5 rounded'>%</span>
                        </OverlayTrigger>
                    </span>
                )}
            </div>
            <ul className='padding-left-15 square-list'>
                <li>
                    {contractPeriod ? (
                        <>
                            <FormattedMessage id='intl-msg:common-message.contracts.period' /> {contractPeriod.length}{' '}
                            {formatDurationUnit(contractPeriod.unit, intl)}
                        </>
                    ) : (
                        <FormattedMessage id='marketplace.productDetails.evergreen' />
                    )}
                </li>
                <li>
                    <PriceInfoDaily price={price} currency={currency} productType={productType} />{' '}
                    {contractPeriod && (
                        <OverlayTrigger
                            trigger={OverlayTrigger.TRIGGER_HOVER}
                            placement='bottom'
                            overlay={
                                <Tooltip id='tooltip' className={'width-auto max-width-300'}>
                                    <PriceInfoLongTerm
                                        price={price}
                                        currency={currency}
                                        contractPeriod={contractPeriod}
                                        productType={productType}
                                    />
                                    <br />
                                    <FormattedMessage id='marketplace.productDetails.price.fullTerm.explanation' />
                                </Tooltip>
                            }
                            delay={DEFAULT_TOOLTIP_DELAY}
                        >
                            <span className='PriceDetails-estimated-info-icon rioglyph rioglyph-info-sign' />
                        </OverlayTrigger>
                    )}
                </li>
            </ul>
        </div>
    );
}
