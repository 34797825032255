import Checkbox from '@rio-cloud/rio-uikit/lib/es/Checkbox';
import { connect } from 'react-redux';
import { useCheckboxState } from '../../common/utils/checkboxUtil';
import { RootDispatch, RootState } from '../../../../configuration/setup/store';
import { freeTrialTabActions, getSelectedFreeTrialActivations } from './redux/freeTrialTab.redux';
import { getFilteredContractsByAssets } from './freeTrialSearchService';
import { TermedLevelId } from '../../common/termedLevel/redux/types';
import { isTermedLevelSelectable } from '../../common/termedLevel/termedLevelSelectionValidationService';
import { useIsFleetUser } from '../../common/permissions/hooks';

interface Props {
    productId: string;
    displayedContracts: Array<TermedLevelId>;
    relevantSelectedContracts: Array<TermedLevelId>;
    otherSelectedContracts: Array<TermedLevelId>;
    setSelectedContracts: (selectedContracts: Array<TermedLevelId>) => void;
}

function AllFreeTrialsCheckbox({
    displayedContracts,
    relevantSelectedContracts,
    otherSelectedContracts,
    setSelectedContracts,
}: Props) {
    const checked = useCheckboxState(displayedContracts, relevantSelectedContracts);
    const fleetUser = useIsFleetUser();
    const contractsToSelect = checked ? otherSelectedContracts : [...otherSelectedContracts, ...displayedContracts];

    return (
        <Checkbox
            checked={Boolean(checked)}
            indeterminate={checked === undefined}
            onClick={() => setSelectedContracts(contractsToSelect)}
            disabled={fleetUser}
        />
    );
}

const mapStateToProps = (state: RootState, { productId }: Pick<Props, 'productId'>) => ({
    displayedContracts: getFilteredContractsByAssets(state).displayRows.filter(
        (contract) => contract.productId === productId && isTermedLevelSelectable(contract)
    ),
    relevantSelectedContracts: getSelectedFreeTrialActivations(state).filter(
        (contract) => contract.productId === productId
    ),
    otherSelectedContracts: getSelectedFreeTrialActivations(state).filter(
        (contract) => contract.productId !== productId
    ),
});

const mapDispatchToProps = (dispatch: RootDispatch) => ({
    setSelectedContracts: (selectedContracts: Array<TermedLevelId>) =>
        dispatch(freeTrialTabActions.setSelectedFreeTrials(selectedContracts)),
});

export const AllContractsCheckboxContainer = connect(mapStateToProps, mapDispatchToProps)(AllFreeTrialsCheckbox);
