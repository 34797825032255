import { useIntl } from 'react-intl';
import { ServiceBookButtonContainer } from '../../common/ServiceBookButtonContainer';

export const CheckoutNoServiceSelected = () => {
    const intl = useIntl();
    return (
        <div className='container-fluid fluid-small'>
            <div className='row panel panel-default'>
                <div className='panel-body text-center'>
                    <div className='text margin-top-20 text-size-large'>
                        {intl.formatMessage({ id: 'marketplace.checkout.noServiceSelected' })}
                        {': '}
                        <a href='mailto:support@rio.cloud'>support@rio.cloud</a>
                    </div>
                    <div className='margin-top-20'>
                        <ServiceBookButtonContainer />
                    </div>
                </div>
            </div>
        </div>
    );
};
