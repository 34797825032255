import { useIntl } from 'react-intl';
import { DatedInvoices } from './redux/types';
import { getTotalByMonth } from './invoiceService';
import { formatPrice } from '../../common/utils/currencyUtil';
import { DATE_FORMAT } from '../../common/utils/dateUtil';

interface Props {
    invoicesMonth: DatedInvoices;
}

export function InvoicesMonthTitle(props: Props) {
    const intl = useIntl();
    const { invoicesMonth } = props;

    const date = intl.formatDate(invoicesMonth.invoiceDate, DATE_FORMAT);
    const invoicesString = `${intl.formatMessage({ id: 'marketplace.invoices' })}: `;
    const numberOfInvoices = invoicesMonth.invoices.length;
    const totalSumString = `${intl.formatMessage({ id: 'marketplace.invoice.totalAmount' })}: `;
    const totalSum = formatPrice(intl, getTotalByMonth(invoicesMonth.invoices), invoicesMonth.invoices[0].currency);

    return (
        <div className='text-size-16 line-height-125rel text-color-darkest'>
            <span>{date}</span>
            <span className='text-color-light'>&nbsp;/&nbsp;</span>
            <span>{invoicesString}</span>
            <b>{numberOfInvoices}</b>
            <span className='text-color-light'>&nbsp;/&nbsp;</span>
            <span>{totalSumString}</span>
            <b>{totalSum}</b>
        </div>
    );
}
