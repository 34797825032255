import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { formatDurationUnit } from '../../../common/utils/formatDurationUnit';
import { Period } from '../../../common/termedLevel/types';
import { getCustomerCenterPath } from '../../../common/routes';
import { CustomerCenterTab } from '../../types';
import { NavLink } from 'react-router';
import { OverlayTrigger, Tooltip } from '../../../common/utils/tooltipReExports';
import { ProductDiscount } from '../../../common/discount/redux/types';
import { DiscountBadgeWithTooltip } from '../../../common/discount/DiscountBadge';

interface Props {
    period: Period | undefined;
    freeTrial: boolean;
    integratedOffer: boolean;
    legacy: boolean;
    discount: ProductDiscount | undefined;
}

function getFreeTrialDividerText() {
    return (
        <NavLink className={'btn btn-secondary btn-sm'} to={`${getCustomerCenterPath(CustomerCenterTab.FREE_TRIAL)}`}>
            <FormattedMessage id='marketplace.free.trial' />
        </NavLink>
    );
}

function getIntegratedOfferDividerText() {
    return <FormattedMessage id='marketplace.integrated.offer.divider' />;
}

function getLegacyInfoIcon() {
    return (
        <>
            {' '}
            <OverlayTrigger
                placement='top'
                overlay={
                    <Tooltip>
                        <FormattedMessage id='marketplace.product.legacy.tooltip' />
                    </Tooltip>
                }
            >
                <span className={'rioglyph rioglyph-info-sign'} />
            </OverlayTrigger>
        </>
    );
}

function getPeriodDividerText(intl: IntlShape, period: Period) {
    return (
        <>
            <FormattedMessage id='intl-msg:common-message.contracts.period' />{' '}
            <b>
                {period.length} {formatDurationUnit(period.unit, intl)}
            </b>
        </>
    );
}

function getDividerText(
    intl: IntlShape,
    period: Period | undefined,
    freeTrialLevel: boolean,
    integratedOfferLevel: boolean
) {
    if (freeTrialLevel) {
        return getFreeTrialDividerText();
    }

    if (integratedOfferLevel) {
        return getIntegratedOfferDividerText();
    }

    if (period) {
        return getPeriodDividerText(intl, period);
    }

    return <FormattedMessage id='marketplace.productDetails.evergreen' />;
}

export const LevelDivider = ({ period, freeTrial, integratedOffer, legacy, discount }: Props) => {
    const intl = useIntl();
    const dividerText = getDividerText(intl, period, freeTrial, integratedOffer);
    const legacyInfoIcon = legacy ? getLegacyInfoIcon() : undefined;

    return (
        <div className='position-relative padding-top-15 padding-bottom-15'>
            <hr className='margin-0 border-width-2 margin-left-15 margin-right-15' />
            {/* eslint-disable-next-line max-len */}
            <span className='bg-lightest padding-10 text-size-h6 position-absolute top-50pct left-50pct translate-x-50pct-y-50pct'>
                {dividerText}
                {legacyInfoIcon}
                {discount && (
                    <>
                        <span>&nbsp;</span>
                        <DiscountBadgeWithTooltip discount={discount} />
                    </>
                )}
            </span>
        </div>
    );
};
