/* eslint-disable camelcase */
import { getMarketplaceBackend } from '../../config/pathURL/config';
import { getRequest } from '../requests';
import { decodeJson, jsonOrReject } from '../api';
import { ApiProductCodec, ApiProductsCodec } from './product.types';
import { mapContractProductDetails, mapProduct } from './productMapper';
import { Product, ViewOnlyProduct } from '../../checkout/redux/types';
import { TermedProductInfo } from '../../common/termedLevel/redux/types';
import { mapProductType } from '../mapProductType';
import { isServiceVisible } from '../../common/utils/isServiceVisible';

export function fetchAllProductDetails(): Promise<Array<ViewOnlyProduct & { legacy: boolean }>> {
    return fetch(`${getMarketplaceBackend()}/products`, getRequest())
        .then(jsonOrReject)
        .then((json) =>
            // eslint-disable-next-line camelcase
            decodeJson(ApiProductsCodec)(json)
                .products.filter((product) => isServiceVisible({ sku: product.id }))
                .map(({ name, id, legacy, product_type }) => ({
                    sku: id,
                    name,
                    legacy,
                    productType: mapProductType(product_type),
                }))
        );
}

export function fetchAllNonLegacyProducts(): Promise<Array<Product>> {
    return fetch(`${getMarketplaceBackend()}/products`, getRequest())
        .then(jsonOrReject)
        .then((json) =>
            decodeJson(ApiProductsCodec)(json)
                .products.filter((product) => isServiceVisible({ sku: product.id }))
                .filter((p) => !p.legacy)
                .map(mapProduct)
        );
}

export function fetchProductDetails(sku: string): Promise<Product> {
    return fetch(`${getMarketplaceBackend()}/products/${sku}`, getRequest())
        .then(jsonOrReject)
        .then((json) => mapProduct(decodeJson(ApiProductCodec)(json)));
}

export function fetchContractProductDetails(sku: string): Promise<TermedProductInfo> {
    return fetch(`${getMarketplaceBackend()}/products/${sku}`, getRequest())
        .then(jsonOrReject)
        .then((json) => mapContractProductDetails(decodeJson(ApiProductCodec)(json)));
}
