export interface Item {
    id?: string;
    type: string;
    name?: string;
}

export interface Actor {
    id: string;
    name?: string;
}

export enum HistoryAction {
    SERVICE_CREATED = 'service-created',
    SERVICE_CANCELLED = 'service-cancelled',
    RESOURCE_ACTIVATION_REQUESTED = 'resource-activation-requested',
    RESOURCE_ACTIVATED = 'resource-activated',
    RESOURCE_DEACTIVATION_REQUESTED = 'resource-deactivation-requested',
    RESOURCE_DEACTIVATED = 'resource-deactivated',
    CONTRACT_RENEWS_TO_CHANGED = 'contract-renews-to-changed',
    CONTRACT_RENEWED = 'contract-renewed',
    CONTRACT_ENDED = 'contract-ended',
}

export interface HistoryEvent {
    timestamp: string;
    sku?: string;
    productName?: string;
    action?: HistoryAction;
    ratePlanId?: string;
    actor?: Actor;
    resources?: Array<Item>;
    orderInfo?: string;
}

export interface HistoryState {
    events: Array<HistoryEvent>;
    nextPageCursor?: string;
    resourceNameSearch?: string;
    selectedProductIds?: Array<string>;
    fromDate?: string;
    toDate?: string;
}

export interface FetchedHistoryEvents {
    nextPageCursor?: string;
    results: Array<HistoryEvent>;
}
