export const sortingByName = (a: { name?: string }, b: { name?: string }) => {
    if (!a.name) {
        return 1;
    }
    if (!b.name) {
        return -1;
    }
    return a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: 'base',
    });
};
