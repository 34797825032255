import { ProductLevel } from '../redux/types';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { formatDurationUnit } from '../../common/utils/formatDurationUnit';

function getSaving(productLevel: ProductLevel, highestPrice: number) {
    return Math.round((1 - productLevel.price / highestPrice) * 100);
}

function getContractDuration(productLevel: ProductLevel, intl: IntlShape) {
    return productLevel.contractPeriod !== undefined
        ? `${productLevel.contractPeriod.length} ${formatDurationUnit(productLevel.contractPeriod.unit, intl)}`
        : intl.formatMessage({ id: 'marketplace.productDetails.evergreen' });
}

interface Props {
    levelsOfVariant: Array<ProductLevel>;
    widthClassName: string;
}

export const VariantSelectionLabels = ({ levelsOfVariant, widthClassName }: Props) => {
    const intl = useIntl();
    const labelClassName = `flex-1-1-0 text-center line-height-125rel margin-left-10 margin-right-10 ${widthClassName}`;
    const highestPrice = levelsOfVariant
        .map((productLevel) => productLevel.price)
        .sort()
        .reverse()[0];

    return (
        <div className='display-flex content-space-between column-gap-25 margin-bottom-25'>
            {levelsOfVariant.map((productLevel) => {
                const saving = getSaving(productLevel, highestPrice);
                return (
                    <div key={productLevel.level} className={labelClassName}>
                        <div className='text-size-16 text-medium margin-bottom-5'>
                            {getContractDuration(productLevel, intl)}
                        </div>
                        {saving > 0 && (
                            <div className='text-size-12 text-color-dark'>
                                <FormattedMessage id={'marketplace.saving'} values={{ percentage: saving }} />
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
};
