import { TermedLevel } from './types';
import { ProductState } from '../../checkout/resourceSelection/redux/types';
import { isManNowProduct } from '../../config/manNowProducts';

export const isTermedLevelSelectable = (contract: TermedLevel) =>
    !(
        contract.hasInactiveActivationState ||
        contract.activationState === ProductState.PENDING_ACTIVATION ||
        (isManNowProduct(contract.productId) && !contract.paid)
    );
