import { CustomFontSource, StripeElementsOptions } from '@stripe/stripe-js/dist/stripe-js/elements-group';
import { StripePaymentMethod } from './PaymentElementContainer';
import { getLocaleShort } from '../../lang/languageData';
import { StripeElementLocale } from '@stripe/stripe-js';

const variablesLight: Record<string, string> = {
    colorPrimary: '#55b1be',
    colorBackground: '#ffffff',
    colorText: '#2a3740',
    colorTextSecondary: '#2a3740',
    colorDanger: '#d3403c',
    fontFamily: 'Source Sans Pro, system-ui, sans-serif',
    fontSizeBase: '14px',
    spacingUnit: '3px',
    borderRadius: '4px',
};
const rulesLight: Record<string, Record<string, string>> = {
    '.Tab': {
        border: '1px solid #d1d8de',
        boxShadow: '0px',
    },
    '.Tab:focus': {
        border: '1px solid var(--colorPrimary)',
        boxShadow: '0px',
    },
    '.Tab:active': {
        border: '1px solid var(--colorPrimary)',
        boxShadow: '0px',
    },
    '.Tab--selected': {
        border: '1px solid var(--colorPrimary)',
        boxShadow: '0px',
    },
    '.Tab--selected:focus': {
        border: '1px solid var(--colorPrimary)',
        boxShadow: '0px',
    },
    '.Input': {
        border: '1px solid #d1d8de',
        boxShadow: '0px',
    },
    '.Input:focus': {
        border: '1px solid var(--colorPrimary)',
        boxShadow:
            '0 1px 1px -1px color-mix(in srgb,#000000 10%,transparent),0 1px 5px 0 color-mix(in srgb,#000000 5%,transparent),0 1px 10px 0 color-mix(in srgb,#000000 5%,transparent)',
    },
    '.Input--invalid': {
        border: '1px solid var(--colorDanger)',
        boxShadow: '0px',
    },
    '.Input--invalid:focus': {
        border: '1px solid var(--colorDanger)',
        boxShadow: '0px',
    },
};

const variablesDark: Record<string, string> = {
    colorPrimary: '#55b1be',
    colorBackground: '#1d1e21',
    colorText: '#ffffff',
    colorTextSecondary: '#ffffff',
    colorDanger: '#d3403c',
    fontFamily: 'Source Sans Pro, system-ui, sans-serif',
    fontSizeBase: '14px',
    spacingUnit: '3px',
    borderRadius: '4px',
};

const rulesDark: Record<string, Record<string, string>> = {
    '.Tab': {
        border: '1px solid #373a40',
        boxShadow: '0px',
    },
    '.Tab:focus': {
        border: '1px solid var(--colorPrimary)',
        boxShadow: '0px',
    },
    '.Tab:active': {
        border: '1px solid var(--colorPrimary)',
        boxShadow: '0px',
    },
    '.Tab--selected': {
        border: '1px solid var(--colorPrimary)',
        boxShadow: '0px',
    },
    '.Tab--selected:focus': {
        border: '1px solid var(--colorPrimary)',
        boxShadow: '0px',
    },
    '.Input': {
        border: '1px solid #373a40',
        boxShadow: '0px',
    },
    '.Input:focus': {
        border: '1px solid var(--colorPrimary)',
        boxShadow:
            '0 1px 1px -1px color-mix(in srgb,#000000 10%,transparent),0 1px 5px 0 color-mix(in srgb,#000000 5%,transparent),0 1px 10px 0 color-mix(in srgb,#000000 5%,transparent)',
    },
    '.Input--invalid': {
        border: '1px solid var(--colorDanger)',
        boxShadow: '0px',
    },
    '.Input--invalid:focus': {
        border: '1px solid var(--colorDanger)',
        boxShadow: '0px',
    },
};

const rioFonts: CustomFontSource[] = [
    {
        family: 'Source Sans Pro',
        style: 'normal',
        weight: '200',
        src: 'url(https://cdn.rio.cloud/fonts/source-sans-pro/source-sans-pro-200.woff)',
    },
    {
        family: 'Source Sans Pro',
        style: 'italic',
        weight: '200',
        src: 'url(https://cdn.rio.cloud/fonts/source-sans-pro/source-sans-pro-200italic.woff)',
    },
    {
        family: 'Source Sans Pro',
        style: 'normal',
        weight: '300',
        src: 'url(https://cdn.rio.cloud/fonts/source-sans-pro/source-sans-pro-300.woff)',
    },
    {
        family: 'Source Sans Pro',
        style: 'italic',
        weight: '300',
        src: 'url(https://cdn.rio.cloud/fonts/source-sans-pro/source-sans-pro-300italic.woff)',
    },
    {
        family: 'Source Sans Pro',
        style: 'normal',
        weight: '400',
        src: 'url(https://cdn.rio.cloud/fonts/source-sans-pro/source-sans-pro-regular.woff)',
    },
    {
        family: 'Source Sans Pro',
        style: 'italic',
        weight: '400',
        src: 'url(https://cdn.rio.cloud/fonts/source-sans-pro/source-sans-pro-italic.woff)',
    },
    {
        family: 'Source Sans Pro',
        style: 'normal',
        weight: '600',
        src: 'url(https://cdn.rio.cloud/fonts/source-sans-pro/source-sans-pro-600.woff)',
    },
    {
        family: 'Source Sans Pro',
        style: 'italic',
        weight: '600',
        src: 'url(https://cdn.rio.cloud/fonts/source-sans-pro/source-sans-pro-600italic.woff)',
    },
    {
        family: 'Source Sans Pro',
        style: 'normal',
        weight: '700',
        src: 'url(https://cdn.rio.cloud/fonts/source-sans-pro/source-sans-pro-700.woff)',
    },
    {
        family: 'Source Sans Pro',
        style: 'italic',
        weight: '700',
        src: 'url(https://cdn.rio.cloud/fonts/source-sans-pro/source-sans-pro-700italic.woff)',
    },
];

export const getOptions = (paymentMethod: StripePaymentMethod, isDarkMode: boolean): StripeElementsOptions => {
    const variables = isDarkMode ? variablesDark : variablesLight;
    const rules = isDarkMode ? rulesDark : rulesLight;

    return {
        paymentMethodTypes: [paymentMethod],
        locale: getLocaleShort() as StripeElementLocale,
        fonts: rioFonts,
        mode: 'setup',
        currency: 'eur',
        appearance: {
            disableAnimations: false,
            theme: 'stripe',
            labels: 'above',
            variables: variables,
            rules: rules,
        },
    };
};
