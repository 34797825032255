import { ProductRatePlan, ServiceOverviewItem } from '../types';
import {
    hasTwoOrMoreLevels,
    isResourceActiveForLevel,
    isRatePlanWithContract,
    isTermedLevel,
} from '../services/serviceOverviewLevelService';
import { ManageContractsButton } from './ManageContractsButton';
import { SelectionActionsContainer } from './SelectionActionsContainer';
import { OverviewResource } from '../redux/types';
import { AddMoreButton } from './AddMoreButton';
import { isManNowParentProduct } from '../../../config/manNowProducts';
import groupBy from 'lodash/groupBy';
import { hasBookableRatePlans, isFreeTrial, isRatePlanBookable } from '../../../common/product/productService';

interface Props {
    service: ServiceOverviewItem;
    currentLevel: string | undefined;
    tab: string;
    handleConfirmUpdate: () => void;
    resources: Array<OverviewResource>;
    selectedResources: Array<OverviewResource>;
}

function getServiceName(service: ServiceOverviewItem, tab: string) {
    return (
        (hasTwoOrMoreLevels(service)
            ? service.productRatePlans?.find((it) => it.level === tab)?.name ?? service.sku
            : service.productName) ?? service.sku
    );
}

function getWouldCancelService(
    service: ServiceOverviewItem,
    resources: Array<OverviewResource>,
    tab: string,
    selectedResources: Array<OverviewResource>
) {
    return hasTwoOrMoreLevels(service)
        ? resources?.filter(isResourceActiveForLevel(tab)).length === selectedResources.length
        : resources?.length === selectedResources.length;
}

function getNextBookableLevel(ratePlans: Array<ProductRatePlan> | undefined): string | undefined {
    const bookableRatePlans = ratePlans?.filter(isRatePlanBookable);
    return bookableRatePlans && bookableRatePlans[0]?.level;
}

function getNextSelectableLevel(ratePlans: Array<ProductRatePlan> | undefined): string | undefined {
    const upgradableRatePlans = ratePlans?.filter((ratePlan) => !isFreeTrial(ratePlan));
    return upgradableRatePlans && upgradableRatePlans[0]?.level;
}

export const ChangeSubscriptionButtons = (props: Props) => {
    const { service, currentLevel, tab, handleConfirmUpdate, resources, selectedResources } = props;
    const tabs = Object.values(groupBy(service.productRatePlans, (ratePlan) => ratePlan.variantName ?? ratePlan.level));
    const ratePlansOfTab = tabs.find((ratePlans) => ratePlans.map((ratePlan) => ratePlan.level).includes(currentLevel));
    const wouldCancelService = getWouldCancelService(service, resources, tab, selectedResources);
    const isManNow = isManNowParentProduct(service.sku);
    const checkoutLevel = getNextBookableLevel(ratePlansOfTab) || currentLevel;
    const selectableLevel = getNextSelectableLevel(ratePlansOfTab) || currentLevel;

    return (
        <div className='table-toolbar-group-right'>
            {(ratePlansOfTab?.some((ratePlan) => isRatePlanWithContract(service, ratePlan.level)) || isManNow) && (
                <div className='table-toolbar-column'>
                    <ManageContractsButton />
                </div>
            )}
            {ratePlansOfTab?.some((ratePlan) => !isTermedLevel(service, ratePlan.level)) && !isManNow && (
                <div className='table-toolbar-column'>
                    <SelectionActionsContainer
                        service={service}
                        currentLevel={selectableLevel}
                        handleConfirmUpdate={handleConfirmUpdate}
                        selectedResources={selectedResources}
                        wouldCancelService={wouldCancelService}
                        serviceName={getServiceName(service, tab)}
                    />
                </div>
            )}
            {service.hasRatePlan && !service.legacy && hasBookableRatePlans(ratePlansOfTab) && (
                <div className='table-toolbar-column'>
                    <AddMoreButton service={service} level={checkoutLevel} isPrimary={selectedResources.length === 0} />
                </div>
            )}
        </div>
    );
};
