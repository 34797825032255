import { Dispatch } from 'redux';
import { fetchUsageExport, fetchUsageSummary } from '../../api/usage/usageCalls';
import FileSaver from 'file-saver';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import { getUsageMonth, getUsageYear, usageActions } from './redux/usage.redux';
import { getDisplayMessages } from '../../../../configuration';
import { apiCallAction } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import { extractOverallCurrency } from '../../api/usage/usageMapper';
import { RootState } from '../../../../configuration/setup/store';

function getFilenameForDownload(year: number, month: number, translatedUsage: string | undefined) {
    return `RIO-${translatedUsage ? translatedUsage : 'Usage'}-${year}-${month}.xlsx`;
}

export const fetchUsageDownloadThunk = (dispatch: Dispatch, getState: () => RootState) => {
    const year = getUsageYear(getState());
    const month = getUsageMonth(getState());
    const languageData = getDisplayMessages(getState());

    dispatch(apiCallAction(ApiCalls.USAGE_EXPORT, true));
    return fetchUsageExport({ year, month })
        .then((response) => {
            const translatedUsage = languageData['marketplace.usage'];
            FileSaver.saveAs(response, getFilenameForDownload(year, month, translatedUsage));
            dispatch(apiCallAction(ApiCalls.USAGE_EXPORT, false));
        })
        .catch(() => {
            Notification.error(languageData['marketplace.usage.download.error']);
            dispatch(apiCallAction(ApiCalls.USAGE_EXPORT, false));
        });
};

export const fetchUsageSummaryThunk = (dispatch: Dispatch, getState: () => RootState) => {
    const year = getUsageYear(getState());
    const month = getUsageMonth(getState());
    dispatch(usageActions.setUsageSummaries({ products: [], resourceUsages: [] }));
    dispatch(usageActions.setOverallCurrency(undefined));

    dispatch(apiCallAction(ApiCalls.USAGE_SUMMARY, true, false));
    return fetchUsageSummary({ year, month })
        .then((response) => {
            dispatch(usageActions.setUsageSummaries(response));
            dispatch(usageActions.setOverallCurrency(extractOverallCurrency(response.products)));
            dispatch(apiCallAction(ApiCalls.USAGE_SUMMARY, false));
        })
        .catch(() => {
            dispatch(apiCallAction(ApiCalls.USAGE_SUMMARY, false, true));
        });
};
