import { Period } from '../../../common/termedLevel/types';

export enum UsageSummaryColumnType {
    PRODUCT_COLUMN,
    SPACER_COLUMN,
    RESOURCE,
    PRICE,
}

export interface UsageSummaryColumn {
    productName?: string;
    productSku?: string;
    ratePlanId?: string;
    symbol?: string;
    variantName?: string;
    contractPeriod?: Period;
    type: UsageSummaryColumnType;
    level?: string;
}
