import React, { ErrorInfo, ReactNode } from 'react';
import { sendError } from '../../../configuration/lang/services';

interface ErrorBoundaryProps {
    children?: ReactNode;
}

// componentDidCatch is not supported by lifecycle hooks yet in React 16.12.0
export class ErrorBoundary extends React.Component<ErrorBoundaryProps, { error: Error | null }> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = {
            error: null,
        };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        this.setState({ error });
        sendError(error, errorInfo);
    }

    render(): ReactNode {
        if (this.state.error) {
            return <h3 className='text-center'>An Error occurred, please reload the page</h3>;
        }
        return this.props.children;
    }
}
