import { FormattedMessage } from 'react-intl';

export function FreeTrialExplanation() {
    return (
        <FormattedMessage
            id={'marketplace.freeTrial.explanation.reasons.items'}
            values={{
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                li: (value: string) => (
                    <div className='display-flex gap-20'>
                        <div>
                            <span className='rioglyph rioglyph-ok-sign'></span>
                        </div>
                        <div>{value}</div>
                    </div>
                ),
            }}
        />
    );
}
