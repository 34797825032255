import { FormattedMessage, injectIntl } from 'react-intl';

function AuthenticationSuccessInfo() {
    return (
        <div className={'text-center margin-top-20'}>
            <div className='text-size-h2 margin-bottom-20'>
                <span className='rioglyph text-color-light rioglyph-ok text-size-300pct' />
            </div>
            <p className='text-color-dark text-center'>
                <FormattedMessage id={'marketplace.payments.authentication.success'} />
            </p>
        </div>
    );
}

export default injectIntl(AuthenticationSuccessInfo);
