import { MessageKey } from '../../../configuration';

export interface FreeTrialProductInfo {
    productId: string;
    productName: string;
    shortExplanationId: MessageKey;
    longExplanationId: MessageKey;
    level?: string;
    iconUrl: string;
}

export const FREE_TRIAL_PRODUCTS: Array<FreeTrialProductInfo> = [
    {
        productId: 'rio-sku00000047',
        level: '10',
        productName: 'Geo',
        shortExplanationId: 'marketplace.freeTrial.product.geo.short',
        longExplanationId: 'marketplace.freeTrial.product.geo.long',
        iconUrl: 'https://rio.cloud/fileadmin/Marketplace/AppIcons/Geo.svg',
    },
    {
        productId: 'rio-sku00000027',
        productName: 'Timed',
        level: '10',
        shortExplanationId: 'marketplace.freeTrial.product.timed.short',
        longExplanationId: 'marketplace.freeTrial.product.timed.long',
        iconUrl: 'https://rio.cloud/fileadmin/Marketplace/AppIcons/Timed.svg',
    },
    {
        productId: 'man-sku00000004',
        level: '21',
        productName: 'Compliant M',
        shortExplanationId: 'marketplace.freeTrial.product.compliant.short',
        longExplanationId: 'marketplace.freeTrial.product.compliant.long',
        iconUrl: 'https://rio.cloud/fileadmin/Marketplace/AppIcons/Compliant.svg',
    },
    {
        productId: 'man-sku00000005',
        productName: 'Perform',
        level: '10',
        shortExplanationId: 'marketplace.freeTrial.product.perform.short',
        longExplanationId: 'marketplace.freeTrial.product.perform.long',
        iconUrl: 'https://rio.cloud/fileadmin/Marketplace/AppIcons/Perform.svg',
    },
    {
        productId: 'man-sku00000049',
        productName: 'MAN ServiceCare M',
        level: '20',
        shortExplanationId: 'marketplace.freeTrial.product.servicecarem.short',
        longExplanationId: 'marketplace.freeTrial.product.servicecarem.long',
        iconUrl: 'https://rio.cloud/fileadmin/Marketplace/AppIcons/MAN_ServiceCare.svg',
    },
    {
        productId: 'man-sku00000050',
        productName: 'MAN eManager M',
        level: '20',
        shortExplanationId: 'marketplace.freeTrial.product.emanagerm.short',
        longExplanationId: 'marketplace.freeTrial.product.emanagerm.long',
        iconUrl: 'https://rio.cloud/fileadmin/Marketplace/AppIcons/MAN_eManager.svg',
    },
];
