import { combineReducers } from 'redux';
import { configReducer, langReducer } from './configuration';
import loginReducer from './configuration/login/loginSlice';
import tokenHandlingReducer from './configuration/tokenHandling/tokenSlice';
import { marketplaceReducers } from './features/app/marketplaceReducers';
import { popupReducer } from './features/app/popup/popup.redux';

export const rootReducer = combineReducers({
    marketplace: marketplaceReducers,
    config: configReducer,
    lang: langReducer,
    login: loginReducer,
    popup: popupReducer,
    tokenHandling: tokenHandlingReducer,
});
