/* eslint-disable camelcase */
import * as t from 'io-ts';
import { PeriodCodec } from '../periods';

export const API_PRODUCT_TYPE_ASSET_BASED = 'asset-based';
export const API_PRODUCT_TYPE_USER_BASED = 'user-based';

export const ApiProductTypeCodec = t.union([
    t.literal(API_PRODUCT_TYPE_ASSET_BASED),
    t.literal(API_PRODUCT_TYPE_USER_BASED),
]);

export type ApiProductType = t.TypeOf<typeof ApiProductTypeCodec>;

const ApiRatePlanCodec = t.intersection([
    t.type({
        price: t.number,
        currency: t.string,
        id: t.string,
        name: t.string,
    }),
    t.partial({
        level: t.string,
        contract_period: PeriodCodec,
        variant_name: t.string,
        legacy: t.boolean,
        renews_to_level: t.string,
    }),
]);

export type ApiRatePlan = t.TypeOf<typeof ApiRatePlanCodec>;

export const ApiProductCodec = t.intersection([
    t.type({
        id: t.string,
        name: t.string,
        legacy: t.boolean,
        product_type: ApiProductTypeCodec,
    }),
    t.partial({
        rate_plans: t.array(ApiRatePlanCodec),
    }),
]);

export type ApiProduct = t.TypeOf<typeof ApiProductCodec>;

export const ApiProductsCodec = t.type({ products: t.array(ApiProductCodec) });
export type ApiProducts = t.TypeOf<typeof ApiProductsCodec>;
