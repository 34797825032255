import { ProductRatePlan } from '../types';
import { OverviewResource } from '../redux/types';
import { ProductState } from '../../../checkout/resourceSelection/redux/types';

export function ServiceLevelTabTitle(props: {
    isActiveTab: boolean;
    ratePlan: ProductRatePlan;
    resources: Array<OverviewResource>;
}) {
    const { resources, ratePlan, isActiveTab } = props;
    const level = ratePlan.level as string;
    const activeBookings = resources.filter(
        (resource) =>
            resource.productState === ProductState.ACTIVE ||
            (resource.productState === ProductState.PENDING_ACTIVATION && resource.originLevel === level)
    ).length;
    const pendingBookings = resources.filter(
        (resource) => resource.productState === ProductState.PENDING_ACTIVATION && resource.targetLevel === level
    ).length;

    return (
        <div className='text-color-darkest'>
            <div className='text-color-dark margin-bottom-10 line-height-125rel'>
                <span className={`${isActiveTab ? 'text-bold' : ''}`}>{ratePlan.variantName ?? ratePlan.name}</span>
            </div>
            <div className='display-flex gap-10'>
                <div className='display-flex gap-5 align-items-center'>
                    <span className='rioglyph rioglyph-truck-baseline text-size-20' />
                    <span className='label label-muted label-filled label-condensed padding-y-3'>{activeBookings}</span>
                </div>
                {pendingBookings > 0 && (
                    <div className='display-flex gap-5 align-items-center'>
                        <span className='rioglyph rioglyph-hour-glass text-size-20' />
                        <span className='label label-muted label-filled label-condensed padding-y-3'>
                            {pendingBookings}
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
}
