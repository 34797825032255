/* eslint-disable camelcase */
import * as t from 'io-ts';
import { ApiProductStateCodec } from '../asset/asset.types';
import { PeriodCodec } from '../periods';

export const API_CONTRACT_STATE_ACTIVE = 'active';
export const API_CONTRACT_STATE_PENDING = 'pending';
const ApiContractStateCodec = t.union([t.literal(API_CONTRACT_STATE_ACTIVE), t.literal(API_CONTRACT_STATE_PENDING)]);
export type ApiContractState = t.TypeOf<typeof ApiContractStateCodec>;

const ApiContractRenewsToCodec = t.intersection([
    t.type({
        level: t.string,
        product_level_name: t.string,
    }),
    t.partial({
        product_variant_name: t.string,
        period: t.string,
    }),
]);

const ApiContractCodec = t.intersection([
    t.type({
        resource_id: t.string,
        resource_type: t.string,
        product_id: t.string,
        product_name: t.string,
        product_level_name: t.string,
        level: t.string,
        state: ApiContractStateCodec,
        activation_state: ApiProductStateCodec,
        period: PeriodCodec,
        product_variant_name: t.string,
    }),
    t.partial({
        resource_name: t.string,
        starts_at: t.string,
        ends_at: t.string,
        renews_to: ApiContractRenewsToCodec,
        paid: t.boolean,
    }),
]);

export type ApiContract = t.TypeOf<typeof ApiContractCodec>;

export const ApiContractsCodec = t.type({
    contracts: t.array(ApiContractCodec),
});
export type ApiContracts = t.TypeOf<typeof ApiContractsCodec>;
