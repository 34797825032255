import { ApiPartner, ApiPartnerConnections } from './partner.types';
import { PartnerConnection } from '../../customerCenter/partners/redux/types';

export const mapApiPartnerConnections = (
    apiPartnerConnections: ApiPartnerConnections,
    partners: Array<ApiPartner>
): Array<PartnerConnection> => {
    return apiPartnerConnections.items
        .filter((apiPartner) => apiPartner.permission_type === 'account')
        .map((apiPartnerConnection) => ({
            id: apiPartnerConnection.id,
            partnerName: apiPartnerConnection.partner_name,
            partnerDisplayName:
                partners.find((partner) => partner.partner_name === apiPartnerConnection.partner_name)?.display_name ??
                apiPartnerConnection.partner_name,
            status: apiPartnerConnection.status,
            accountId: apiPartnerConnection.account_id,
            createdAt: apiPartnerConnection.created_at,
        }));
};
