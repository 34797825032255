import { ApiBillingAddress } from './billingAddress.types';

import { BillingAddress } from '../../common/billing/redux/types';

export function mapBillingAddress(apiBillingAddress: ApiBillingAddress): BillingAddress {
    const taxInfo = apiBillingAddress.tax;
    const address = apiBillingAddress.address;
    return {
        company: address.company,
        city: address.city,
        countryCode: address.country_code,
        postalCode: address.postal_code,
        street: address.street,
        vatNumber: taxInfo?.code,
    };
}
