import { OnboardingState } from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import get from 'lodash/get';
import { Tips } from '../types';
import { loadOnboardingTip, saveOnboardingTip } from '../onboardingToLocalStorage';
import { RootState } from '../../../../../configuration/setup/store';

const initialState: OnboardingState = {
    [Tips.USAGE_TAB]: loadOnboardingTip(Tips.USAGE_TAB),
    [Tips.RENEW_CONTRACTS]: loadOnboardingTip(Tips.RENEW_CONTRACTS),
};

const name = 'onboarding';

export const { reducer: onboardingReducer, actions: onboardingActions } = createSlice({
    name,
    initialState,
    reducers: {
        setShowOnboardingTip: (state: OnboardingState, action: PayloadAction<{ type: Tips; value: boolean }>) => {
            state[action.payload.type] = action.payload.value;
            saveOnboardingTip(action.payload.type, action.payload.value);
        },
    },
});

const getBase = (state: RootState): OnboardingState | undefined => state.marketplace.onboarding;
export const getShowOnboardingTip = (state: RootState, tip: Tips): boolean => get(getBase(state), tip, false);
