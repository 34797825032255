import { useSelector } from 'react-redux';
import { getFreeTrialEndDate } from '../redux/freeTrial.redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { DATE_FORMAT, getDateFromIsoString } from '../../common/utils/dateUtil';

export const FreeTrialTab = () => {
    const freeTrialEndDateIsoString = useSelector(getFreeTrialEndDate);
    const freeTrialEndDate = getDateFromIsoString(freeTrialEndDateIsoString);
    const intl = useIntl();

    if (!freeTrialEndDate) {
        return null;
    }
    const endDate = intl.formatDate(freeTrialEndDate, { ...DATE_FORMAT, timeZone: 'UTC' });
    return (
        <span className='text-color-white display-flex flex-column align-items-center gap-2'>
            <span className='text-bold'>
                <FormattedMessage id={'marketplace.free.trial'} />
            </span>
            <FormattedMessage id={'marketplace.until'} values={{ date: endDate }} />
        </span>
    );
};
