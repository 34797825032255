import { Dispatch } from 'redux';
import { apiCallAction } from '../../../api/redux/api.redux';
import { ApiCalls } from '../../../api/redux/types';
import { serviceOverviewActions } from '../redux/serviceOverview.redux';
import { fetchActivationSummaryDetailed } from '../../../api/services/activationSummaryCalls';

export const fetchActivationSummaryThunk = (reportLoading: boolean) => (dispatch: Dispatch) => {
    if (reportLoading) {
        dispatch(apiCallAction(ApiCalls.ACTIVATION_SUMMARY, true, false));
    }
    return fetchActivationSummaryDetailed()
        .then((response) => {
            dispatch(serviceOverviewActions.setServiceOverviewList(response));
            if (reportLoading) {
                dispatch(apiCallAction(ApiCalls.ACTIVATION_SUMMARY, false));
            }
        })
        .catch(() => {
            if (reportLoading) {
                dispatch(apiCallAction(ApiCalls.ACTIVATION_SUMMARY, false, true));
            }
        });
};
