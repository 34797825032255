/* eslint-disable camelcase */
import { ApiSubscriptionCreatePayload } from './subscription.types';
import { BookableProduct } from '../../checkout/redux/types';

function createPayload(productSku: string, resourceIds: Array<string>, level?: string) {
    return {
        product_sku: productSku,
        resource_ids: resourceIds,
        level: level,
    };
}

export function toSubscriptionCreateBody(
    resourceIds: Array<string>,
    product: BookableProduct
): ApiSubscriptionCreatePayload {
    return createPayload(product.sku, resourceIds, product.level);
}
