import { FormattedMessage } from 'react-intl';
import { ProductImageSlider } from './ProductImageSlider';
import manPointingUp from '../images/man_pointing_up.webp';
import { getCustomerCenterPath } from '../../common/routes';
import { CustomerCenterTab } from '../../customerCenter/types';

export function SuccessScreen() {
    const close = () => {
        window.open(getCustomerCenterPath(CustomerCenterTab.FREE_TRIAL), '_blank');
        window.close();
    };

    return (
        <div className='module-content-wrapper bg-primary'>
            <div className='module-content padding-top-20 padding-x-0 min-height-100vh'>
                <div
                    className={
                        // eslint-disable-next-line max-len
                        'width-100pct max-width-800 rounded-circle bg-white aspect-ratio-1 position-absolute top-0 left-50pct translate-x-50pct-y-50pct opacity-30 blur-50'
                    }
                ></div>
                <div>
                    <div className='text-center text-size-h1 text-bold'>
                        <FormattedMessage id={'marketplace.freeTrial.success.headline'} />
                    </div>
                    <div className='text-center text-size-20'>
                        <FormattedMessage id={'marketplace.freeTrial.success.text'} />
                    </div>
                    <ProductImageSlider />
                    <div className='display-flex justify-content-center'>
                        <button className='btn btn-secondary btn-lg translate-y-50pct' onClick={close}>
                            <FormattedMessage id={'marketplace.freeTrial.success.button'} />
                        </button>
                    </div>
                </div>
                <img
                    className='img-responsive position-absolute bottom-0 left-50pct width-450 translate-x-50pct'
                    alt='Man pointing up'
                    src={manPointingUp}
                />
            </div>
        </div>
    );
}
