import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { getResourceUsages, getUsageMonth, getUsageSearchTerm, getUsageYear } from '../redux/usage.redux';
import { fetchUsageSummaryThunk } from '../usage.thunk';
import { connect } from 'react-redux';
import { getHasError, getIsLoading } from '../../../api/redux/api.redux';
import { ApiCalls } from '../../../api/redux/types';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { FormattedMessage } from 'react-intl';
import LoadingErrorStateWithReload from '../../../common/error/LoadingErrorStateWithReload';
import { UsageSummaryTable } from './UsageSummaryTable';
import { UsageSummaryTotalPriceContainer } from './UsageSummaryTotalPriceContainer';
import { RootDispatch, RootState } from '../../../../../configuration/setup/store';
import EmptyState from '@rio-cloud/rio-uikit/lib/es/EmptyState';

interface UsageSummaryTableWrapperProps {
    year: number;
    month: number;
    isLoading: boolean;
    hasError: boolean;
    fetchUsageSummary: () => void;
    searchValue: string;
    isTableEmpty: boolean;
}

export class UsageSummaryTableWrapper extends React.Component<UsageSummaryTableWrapperProps> {
    componentDidMount(): void {
        this.props.fetchUsageSummary();
    }

    componentDidUpdate(prevProps: Readonly<UsageSummaryTableWrapperProps>): void {
        if (this.props.year !== prevProps.year || this.props.month !== prevProps.month) {
            this.props.fetchUsageSummary();
        }
    }

    render() {
        const { isLoading, hasError, isTableEmpty, searchValue, fetchUsageSummary } = this.props;

        if (isLoading) {
            return (
                <div className={'padding-25'}>
                    <Spinner text={<FormattedMessage id={'marketplace.loading'} />} />
                </div>
            );
        }

        if (hasError) {
            return (
                <LoadingErrorStateWithReload
                    headline={'marketplace.customerCenter.loading.error'}
                    onReload={fetchUsageSummary}
                />
            );
        }

        if (isTableEmpty) {
            return (
                <EmptyState
                    headline={<FormattedMessage id={'marketplace.usage.table.empty.headline'} />}
                    message={<FormattedMessage id={'marketplace.usage.table.empty'} />}
                    outerClassName='margin-top-15pct'
                />
            );
        }

        return (
            <div>
                <UsageSummaryTable searchValue={searchValue} />
                <div className={'margin-top-25 text-right'}>
                    <UsageSummaryTotalPriceContainer />
                    <div>
                        * {/* Reference to list-price explanation, and that discounts are only partially included. */}
                        <FormattedMessage id={'marketplace.usage.summary.price.disclaimer'} />
                    </div>
                    <div>
                        ** {/* Reference for the current month to disclaim, that usages are only updates once a day */}
                        <FormattedMessage id={'marketplace.usage.summary.price.disclaimer.month'} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    year: getUsageYear(state),
    month: getUsageMonth(state),
    isLoading: getIsLoading(state, ApiCalls.USAGE_SUMMARY),
    hasError: getHasError(state, ApiCalls.USAGE_SUMMARY),
    searchValue: getUsageSearchTerm(state),
    isTableEmpty: isEmpty(getResourceUsages(state)),
});

const mapDispatchToProps = (dispatch: RootDispatch) => ({
    fetchUsageSummary: () => dispatch(fetchUsageSummaryThunk),
});

export const UsageSummaryTableContainer = connect(mapStateToProps, mapDispatchToProps)(UsageSummaryTableWrapper);
