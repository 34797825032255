import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import DataTabs from '@rio-cloud/rio-uikit/lib/es/DataTabs';
import DataTab from '@rio-cloud/rio-uikit/lib/es/DataTab';
import { ContractList } from './ContractList';
import { RenewDialogNoRenewalContainer } from './RenewDialogNoRenewal';
import { useState } from 'react';
import TableSearch from '@rio-cloud/rio-uikit/lib/es/TableSearch';
import { selectRenewalDialogTabThunk } from './renewData.thunk';
import { RenewDialogDataTabTitle } from './RenewDialogDataTabTitle';
import { HIDE_SEARCH_IF_AMOUNT_OF_ITEMS_BELOW_THRESHOLD } from '../../../config/config';
import { RootDispatch, RootState } from '../../../../../configuration/setup/store';
import { getProductForUpdateOrThrow, getRenewsToLevelForUpdate } from '../redux/termedLevelRenewal.redux';
import { TermedLevelsForUpdate, TermedProductInfo } from '../redux/types';
import { getTermedLevelsForUpdate } from './dialogService';
import classnames from 'classnames';
import { DiscountIdentifier, mapStateToFindDiscountProps } from '../../discount/DiscountService';
import { AssetDiscount } from '../../discount/redux/types';

const NO_RENEWAL = 'NO_RENEWAL';

interface Props {
    product: TermedProductInfo;
    currentLevel: string | undefined;
    updateLevel: (level?: string) => void;
    contracts: TermedLevelsForUpdate;
    paymentMethodContainer: React.ReactNode | undefined;
    findDiscount: (discountIdentifier: DiscountIdentifier) => AssetDiscount | undefined;
}

const RenewDialogDataTabs = (props: Props) => {
    const { product, updateLevel, currentLevel, contracts, paymentMethodContainer, findDiscount } = props;
    const [searchTerm, setSearchTerm] = useState('');
    const intl = useIntl();

    const allContracts = [...contracts.updatable, ...contracts.notUpdatable];

    const onSelectTab = (tabKey: string | undefined) => {
        if (currentLevel === tabKey) {
            return;
        }
        updateLevel(tabKey === NO_RENEWAL ? undefined : tabKey);
    };

    return (
        <DataTabs
            activeKey={currentLevel ?? NO_RENEWAL}
            onSelectTab={onSelectTab}
            commonTabContent={paymentMethodContainer}
            tabsWrapperClassName='bg-lightest'
            arrowClassName='bg-white border-color-light margin-bottom--1'
            tabClassName='bg-lightest'
            tabHoverClassName='bg-white'
            tabActiveClassName='bg-white'
            tabContentClassName='rounded-bottom bg-lightest'
        >
            <DataTab
                tabKey={NO_RENEWAL}
                className='border-left-none'
                title={
                    <div className='text-color-darkest'>
                        <span className='rioglyph rioglyph-remove text-color-danger margin-right-3' />
                        <FormattedMessage id='marketplace.contracts.noRenew' />
                    </div>
                }
            >
                <RenewDialogNoRenewalContainer />
            </DataTab>
            {product.levels.filter(it => !it.level.endsWith('-io')).map((termedLevel, index) => {
                const dataTabClassName = classnames(index === product.levels.length - 1 && 'border-right-none');

                return (
                    <DataTab
                        key={termedLevel.level}
                        tabKey={termedLevel.level}
                        className={dataTabClassName}
                        title={
                            <RenewDialogDataTabTitle
                                productLevel={termedLevel}
                                isActiveTab={termedLevel.level === currentLevel}
                                productType={product.productType}
                                contracts={contracts.updatable}
                                findDiscount={(assetId: string) =>
                                    findDiscount({
                                        assetId,
                                        productId: product.productId,
                                        productLevel: termedLevel.level,
                                    })
                                }
                            />
                        }
                    >
                        {allContracts.length >= HIDE_SEARCH_IF_AMOUNT_OF_ITEMS_BELOW_THRESHOLD && (
                            <div className={'display-flex justify-content-end margin-bottom-20 margin-top-0'}>
                                <div className='width-300'>
                                    <TableSearch
                                        placeholder={intl.formatMessage({
                                            id: 'marketplace.resourceSelection.asset.searchPlaceholder',
                                        })}
                                        value={searchTerm}
                                        onChange={setSearchTerm}
                                    />
                                </div>
                            </div>
                        )}
                        <div>
                            <ContractList
                                contracts={contracts.updatable}
                                labelId={
                                    allContracts.length === 1 && allContracts[0].renewsToLevel === currentLevel
                                        ? 'marketplace.contracts.renewal.currentLevel.info'
                                        : 'marketplace.contracts.renewable'
                                }
                                searchTerm={searchTerm}
                                open={true}
                                singleContractView={allContracts.length === 1}
                                findDiscount={(assetId: string) =>
                                    findDiscount({
                                        assetId,
                                        productId: product.productId,
                                        productLevel: termedLevel.level,
                                    })
                                }
                            />
                            <ContractList
                                contracts={contracts.notUpdatable}
                                labelId={'marketplace.contracts.notRenewable'}
                                searchTerm={searchTerm}
                                open={contracts.updatable.length === 0}
                                singleContractView={allContracts.length === 1}
                                findDiscount={undefined} // Discount info is not helpful if contract cannot be renewed
                            />
                        </div>
                    </DataTab>
                );
            })}
        </DataTabs>
    );
};
const mapStateToProps = (state: RootState) => {
    return {
        contracts: getTermedLevelsForUpdate(state),
        currentLevel: getRenewsToLevelForUpdate(state),
        product: getProductForUpdateOrThrow(state),
        findDiscount: mapStateToFindDiscountProps(state).findDiscount,
    };
};

const mapDispatchToProps = (dispatch: RootDispatch) => ({
    updateLevel: async (level?: string) => await dispatch(selectRenewalDialogTabThunk(level)),
});
export const RenewDialogDataTabsContainer = connect(mapStateToProps, mapDispatchToProps)(RenewDialogDataTabs);
