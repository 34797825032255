import { OverviewResource } from '../../serviceOverview/redux/types';
import { Period } from '../../../common/termedLevel/types';

export type TableViewTogglesValues = 'SINGLE_CARD' | 'MULTI_CARDS' | 'TABLE';

export interface HeaderProduct {
    productId: string;
    productName: string;
    levels: Array<string>;
    bookable: ProductBookable;
    canBeCancelled: boolean;
    contractPeriod?: Period;
    variantName?: string;
    levelsWithContractPeriod: Array<string>;
    bookableLevels: Array<string>;
}

export enum ProductBookable {
    BOOKABLE,
    LEGACY,
    DEFAULT_ACTIVATED,
}

export interface ProductInfo {
    productId: string;
    productName: string;
    level?: string;
    booked: boolean;
    bookable: boolean;
    isPending: boolean;
    canBeCancelled: boolean;
    missingRequirements?: Array<Array<string>>;
    hasContractPeriod: boolean;
    contractActiveForAnotherLevel: boolean;
    bookableLevels: Array<string>;
}

export interface AssetOverviewRow {
    assetId: string;
    assetName: string;
    products: Record<string, ProductInfo>;
}

export interface Tag {
    id: string;
    name: string;
}

export interface TagToAssetsMap {
    [id: string]: Array<string>;
}

export interface CancelInfo {
    productId: string;
    productName: string;
    level?: string;
    assets: Array<OverviewResource>;
}

export interface AssetOverviewState {
    tableRows: Array<AssetOverviewRow>;
    tableColumns: Array<HeaderProduct>;
    assetsToDisplay: Array<string> | undefined;
    selectedAssets: Array<string>;
    tags: Array<Tag>;
    tagToAssetMap: TagToAssetsMap;
    viewType: TableViewTogglesValues;
    search: string;
    showCancelDialog: boolean;
    cancelInfo?: CancelInfo;
}
