import classnames from 'classnames';
import { createOrder, CreateOrderData } from '../../../api/orders/orderCalls';
import ClearableInput from '@rio-cloud/rio-uikit/lib/es/ClearableInput';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import { useState } from 'react';

export const CreateOrderButton = () => {
    const [assetId, setAssetId] = useState('');
    const createOrderData: CreateOrderData = {
        productId: 'RIO-SKU00000062',
        level: '10',
        orderInfo: 'LanguageUpdate French',
        assetIds: [assetId],
        externalReference: 'someId',
    };
    return (
        <div className={'form-group'}>
            <label>Asset id</label>
            <div className={'input-group'}>
                <ClearableInput className={'width-300'} onChange={setAssetId} value={assetId} />
                <span className={'input-group-btn'}>
                    <button
                        className={classnames('addMore btn btn-default', 'btn-primary')}
                        onClick={() =>
                            createOrder(createOrderData)
                                .then(() => Notification.success('Order successfully placed'))
                                .catch(() => Notification.error('Error while placing order'))
                        }
                    >
                        Create Order
                    </button>
                </span>
            </div>
        </div>
    );
};
