import { MessageKey } from '../../../configuration';
import { Tenant } from '../common/utils/tenants';

export interface TabInfo {
    componentName: CustomerCenterTab;
    limitedToTenants: Array<Tenant>;
    requiredPermissions: Array<string>;
    labelId: MessageKey;
}

export enum CustomerCenterTab {
    SERVICES = 'SERVICES',
    BOOKINGS = 'BOOKINGS',
    ASSETS = 'ASSETS',
    CONTRACTS = 'CONTRACTS',
    INVOICES = 'INVOICES',
    HISTORY = 'HISTORY',
    PAYMENT_METHODS = 'PAYMENTMETHODS',
    USAGE = 'USAGE',
    FREE_TRIAL = 'FREETRIAL',
    PROFILE = 'PROFILE',
    PARTNERS = 'PARTNERS',
    INBOUND = 'INBOUND',
    OUTBOUND = 'OUTBOUND',
}
