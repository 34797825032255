import { useState } from 'react';

interface Props {
    toggleWidth: () => void;
    isFullWidth: boolean;
}

export const WidthToggleButton = (props: Props) => (
    // Changing key is important to remove highlighting of button
    <button key={props.isFullWidth ? 1 : 0} className='btn btn-default btn-icon-only' onClick={props.toggleWidth}>
        <span
            data-testid='resize-glyph'
            className={`rioglyph ${props.isFullWidth ? 'rioglyph-resize-small' : 'rioglyph-resize-full'}`}
            aria-hidden={true}
        />
    </button>
);

export const useWidthToggleState = () => {
    const [isFullWidth, setFullWidth] = useState(false);
    const toggleWidth = () => setFullWidth((state) => !state);
    return { isFullWidth, toggleWidth };
};
