import { OverviewResource } from '../redux/types';
import { ProductState } from '../../../checkout/resourceSelection/redux/types';
import { ServiceOverviewItem } from '../types';
import { isFreeTrialLevel } from '../../../common/termedLevel/freeTrialLevel';

export const isResourceActiveForLevel = (level: string) => (resource: OverviewResource) => {
    if (resource.productState === ProductState.PENDING_ACTIVATION) {
        return resource.originLevel === level || resource.targetLevel === level;
    }
    return resource.targetLevel === level;
};

export function hasTwoOrMoreLevels(service: ServiceOverviewItem) {
    return service.productRatePlans && service.productRatePlans.length > 1;
}

export function isRatePlanWithContract(service: ServiceOverviewItem, currentLevel: string | undefined) {
    const currentRatePlan = service.productRatePlans?.find((ratePlan) => ratePlan.level === currentLevel);
    return currentRatePlan?.contractPeriod !== undefined;
}

export function isTermedLevel(service: ServiceOverviewItem, currentLevel: string | undefined) {
    return isRatePlanWithContract(service, currentLevel) || isFreeTrialLevel(currentLevel);
}
