import { connect } from 'react-redux';
import { getInvalidSelectedResourceIds, getSelectedResourceIds } from './redux/resourceSelection.redux';
import {
    PreSelectedAlreadyBookedResourcesExpander,
    PreSelectedNotBookableResourcesExpander,
    PreSelectedNotExistentResourceExpander,
} from './ResourceSelectionExpanderContainer';
import { ResourceSelectionSummaryContainer } from '../preview/ResourceSelectionSummary';
import { Dispatch } from 'redux';
import { checkoutActions, getProductLevelsForVariant, getSelectedProduct } from '../redux/checkout.redux';
import { ReadOnlySelectedResourcesContainer } from './ReadOnlySelectedResourcesContainer';
import { getNumberOfBookableResources } from '../preview/bookableResourcesService';
import { getIsLoading } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { RootState } from '../../../../configuration/setup/store';
import { ProductTypeAwareFormattedMessage } from './ProductTypeAwareFormattedMessage';

interface Props {
    selectedProductId: string | undefined;
    selectedResourceIds: Array<string>;
    hasInvalidResourceIds: boolean;
    unlockResourceSelection: () => void;
    numberOfBookableResources: number;
    showExtraPadding: boolean;
    isLoading: boolean;
}

function InvalidResourcesHeader() {
    return (
        <div className={'panel text-color-warning'}>
            <div className={'padding-10'}>
                <div className={'text-center'}>
                    <span className={'rioglyph rioglyph-warning-sign padding-right-10'} />
                    <b>
                        <ProductTypeAwareFormattedMessage id={'invalidResourceSelectionHeader'} />
                    </b>
                </div>
                <div className={'text-center'}>
                    <ProductTypeAwareFormattedMessage id={'invalidResourceSelectionHint'} />
                </div>
            </div>
        </div>
    );
}

function PreSelectedResourceSelectionList(props: Props) {
    const {
        selectedResourceIds,
        selectedProductId,
        hasInvalidResourceIds,
        unlockResourceSelection,
        numberOfBookableResources,
        showExtraPadding,
    } = props;
    if (selectedResourceIds.length === 0) {
        unlockResourceSelection();
        return null;
    }
    const getTrackingAttributes = (trigger: 'click' | 'visibility') => ({
        'data-track-ga-event-trigger': trigger,
        'data-track-ga-event-category': 'marketplace',
        'data-track-ga-event-action': `unlockAssetSelection_${trigger}`,
        'data-track-ga-event-label': `productId::${selectedProductId}`,
    });
    return (
        <div className='width-100pct'>
            <div>
                <div className={`panel panel-default margin-bottom-20${showExtraPadding ? 'padding-top-15' : ''}`}>
                    <div className='panel-heading'>
                        <ResourceSelectionSummaryContainer />
                    </div>
                </div>
                {props.isLoading ? (
                    <div className='margin-20'>
                        <Spinner />
                    </div>
                ) : (
                    <>
                        <div {...getTrackingAttributes('visibility')}>
                            <button
                                className={'btn btn-default margin-bottom-20'}
                                onClick={unlockResourceSelection}
                                {...getTrackingAttributes('click')}
                            >
                                <ProductTypeAwareFormattedMessage id={'showAllResources'} />
                                <span className={'margin-left-5'}>{`(${numberOfBookableResources})`}</span>
                            </button>
                        </div>
                        <ReadOnlySelectedResourcesContainer />
                        {hasInvalidResourceIds && (
                            <div className={'panel'}>
                                <InvalidResourcesHeader />
                                <div className={'margin-bottom-10'}>
                                    <PreSelectedNotBookableResourcesExpander />
                                </div>
                                <div className={'margin-bottom-10'}>
                                    <PreSelectedAlreadyBookedResourcesExpander />
                                </div>
                                <div className={'margin-bottom-10'}>
                                    <PreSelectedNotExistentResourceExpander />
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

const mapStateToProps = (state: RootState) => ({
    isLoading: getIsLoading(state, ApiCalls.RESOURCE_SELECTION),
    selectedProductId: getSelectedProduct(state)?.sku,
    selectedResourceIds: [...getSelectedResourceIds(state), ...getInvalidSelectedResourceIds(state)],
    hasInvalidResourceIds: getInvalidSelectedResourceIds(state).length > 0,
    numberOfBookableResources: getNumberOfBookableResources(state),
    showExtraPadding: getProductLevelsForVariant(state) !== undefined,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    unlockResourceSelection: () => dispatch(checkoutActions.setCheckoutIsLocked(false)),
});
export const PreSelectedResourceSelectionListContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PreSelectedResourceSelectionList);
