import ApplicationLayoutBodyBottomBar from '@rio-cloud/rio-uikit/lib/es/ApplicationLayoutBodyBottomBar';
import { FormattedMessage } from 'react-intl';

export function FreeTrialExplanationBottomBar() {
    return (
        <ApplicationLayoutBodyBottomBar className='justify-content-between justify-content-center text-center text-color-secondary text-size-20'>
            <span>
                <FormattedMessage id='marketplace.freeTrial.explanation.footer.primary' />
            </span>
            <span className='hidden-xs hidden-ls margin-left-5'>
                <FormattedMessage id='marketplace.freeTrial.explanation.footer.secondary' />
            </span>
        </ApplicationLayoutBodyBottomBar>
    );
}
