/**
 * @fileOverview: Reducer for configuration settings.
 */

/**
 * Service name for the backend marketplace service
 * @type {string}
 */
const marketplaceServicePath = '/';

export function getMarketplaceBackend() {
    return process.env.REACT_APP_MARKETPLACE_BACKEND_URL;
}

export function getMarketplaceServicePath() {
    return marketplaceServicePath;
}

export function getApiTags() {
    return process.env.REACT_APP_API_TAGS;
}

export function getApiAssets() {
    return process.env.REACT_APP_API_ASSETS;
}

export function getApiPartners() {
    return process.env.REACT_APP_PARTNERS_BACKEND;
}
