import handAlarmClock from '../../freeTrial/images/hand_alarm_clock.webp';
import { FormattedMessage } from 'react-intl';
import { openContactFormInPopup } from '../../common/popupService';

interface Props {
    endDate: string;
}
export function FreeTrialExpirationNotice(props: Props) {
    const { endDate } = props;

    return (
        <div className='display-flex justify-content-center'>
            <div>
                <div className='well padding-x-50-sm padding-top-25-sm padding-bottom-0 bg-primary display-flex-sm align-items-end-sm max-width-1000 gap-25 position-relative'>
                    <div className='width-100pct max-width-800 rounded-circle bg-white aspect-ratio-1 position-absolute top-0 left-50pct translate-x-50pct-y-50pct opacity-10 blur-50'></div>
                    <div className='max-width-250-sm display-flex justify-content-center margin-bottom-15 margin-bottom-0-sm position-relative z-index-1'>
                        <img
                            className='img-responsive margin-top-20 position-relative max-width-250 min-width-250-sm'
                            src={handAlarmClock}
                            alt='Hand alarm clock'
                        />
                    </div>
                    <div className='text-color-white padding-bottom-15 line-height-125rel position-relative z-index-1'>
                        <div className='text-medium text-size-h3 margin-bottom-20'>
                            <FormattedMessage id={'marketplace.freeTrialTab.informationBox.periodEnds'} />
                        </div>
                        <div className='text-light'>
                            <p className='margin-y-10'>
                                <FormattedMessage
                                    id={'marketplace.freeTrialTab.expirationNotice.text1'}
                                    values={{
                                        date: endDate,
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        bold: (chunks: Array<string>) => <strong>{chunks}</strong>,
                                    }}
                                />
                            </p>
                            <p className='margin-y-10'>
                                <FormattedMessage id={'marketplace.freeTrialTab.expirationNotice.text2'} />
                            </p>
                            <p className='margin-y-10'>
                                <FormattedMessage
                                    id={'marketplace.freeTrialTab.expirationNotice.text3'}
                                    values={{
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        emph: (chunks: Array<string>) => (
                                            <span className={'text-color-secondary'}>
                                                <strong>{chunks}</strong>
                                            </span>
                                        ),
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        bold: (chunks: Array<string>) => <strong>{chunks}</strong>,
                                    }}
                                />
                            </p>
                            <p>
                                <FormattedMessage
                                    id={'marketplace.freeTrialTab.expirationNotice.text4'}
                                    values={{
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        bold: (chunks: Array<string>) => <strong>{chunks}</strong>,
                                    }}
                                />
                            </p>
                            <hr className='border-color-highlight-light'></hr>
                            <p className='margin-y-10'>
                                <FormattedMessage
                                    id={'marketplace.freeTrialTab.informationBox.text4'}
                                    values={{
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        link: (chunks: Array<string>) => (
                                            <a
                                                className='text-color-white text-decoration-underline'
                                                onClick={() =>
                                                    openContactFormInPopup('freeTrialExpirationContactPopup')
                                                }
                                            >
                                                {chunks}
                                            </a>
                                        ),
                                    }}
                                />
                            </p>
                        </div>
                    </div>
                </div>
                <div className='text-center'>
                    <small>
                        <FormattedMessage id={'marketplace.freeTrialTab.informationBox.note'} />
                    </small>
                </div>
            </div>
        </div>
    );
}
