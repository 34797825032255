/*
 * Defines the default amount of months that shall be fetched for the invoices of a user.
 */
export const DEFAULT_INVOICE_MONTH_LIMIT = 12;

/*
 * Defines the default amount of months that shall be fetched for the history.
 */
export const DEFAULT_HISTORY_LIMIT = 50;

/*
 * Defines the default amount of milliseconds after which a tooltip is displayed. Can be mocked for testing tooltips.
 */
export const DEFAULT_TOOLTIP_DELAY = 300;

/*
 * Defines the number when a search should be included in he UI, e.g. in ServiceOverview, RenewDialog.
 */
export const HIDE_SEARCH_IF_AMOUNT_OF_ITEMS_BELOW_THRESHOLD = 10;
