import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { ConnectedOnboardingTip } from '../../common/onboarding/ConnectedOnboardingTip';
import { Tips } from '../../common/onboarding/types';
import { RootDispatch, RootState } from '../../../../configuration/setup/store';
import { getSelectedFreeTrialActivations } from './redux/freeTrialTab.redux';
import {
    getFreeTrialContracts,
    termedLevelRenewalActions,
} from '../../common/termedLevel/redux/termedLevelRenewal.redux';
import { TermedLevelId } from '../../common/termedLevel/redux/types';

interface Props {
    productId: string;
    updateContract: () => void;
    areContractsSelected: boolean;
}

export const FreeTriaRenewButton = ({ updateContract, areContractsSelected }: Props) => {
    if (!areContractsSelected) {
        return (
            <button className='btn btn-primary' disabled={true}>
                <span className='rioglyph rioglyph-pencil' aria-hidden={true} />
                <FormattedMessage id='intl-msg:common-message.contracts.configureRenewal' />
            </button>
        );
    }
    return (
        <ConnectedOnboardingTip
            tipType={Tips.RENEW_CONTRACTS}
            contentTranslationId={'marketplace.contracts.renewal.dialog.text.plural'}
            placement={'top'}
        >
            <button className='btn btn-primary' onClick={updateContract} disabled={!areContractsSelected}>
                <span className='rioglyph rioglyph-pencil' aria-hidden={true} />
                <FormattedMessage id='intl-msg:common-message.contracts.configureRenewal' />
            </button>
        </ConnectedOnboardingTip>
    );
};

const mapStateToProps = (state: RootState, ownProps: Pick<Props, 'productId'>) => ({
    areContractsSelected:
        getSelectedFreeTrialActivations(state).filter((contract) => contract.productId === ownProps.productId).length >
        0,
});

const mapDispatchToProps = (dispatch: RootDispatch, ownProps: Pick<Props, 'productId'>) => ({
    updateContract: () => {
        dispatch(updateContractThunk(ownProps.productId));
    },
});

function getHighestRenewalLevel(
    state: RootState,
    productId: string,
    selectedFreeTrialActivations: Array<TermedLevelId>
) {
    const contracts = getFreeTrialContracts(state);
    const assetIds = selectedFreeTrialActivations.map((it) => it.resourceId);

    const filteredContracts = contracts.filter(
        (contract) => contract.productId === productId && assetIds.includes(contract.resourceId)
    );

    const level = filteredContracts
        .flatMap((contract) => (contract.renewsToLevel ? [contract.renewsToLevel] : []))
        .reduce((a, b) => (a > b ? a : b), '');

    if (level === '') {
        return undefined;
    }
    return level;
}

function updateContractThunk(productId: string) {
    return (dispatch: RootDispatch, getState: () => RootState) => {
        const selectedContracts = getSelectedFreeTrialActivations(getState()).filter(
            (contract) => contract.productId === productId
        );
        const highestLevel = getHighestRenewalLevel(getState(), productId, selectedContracts);

        dispatch(
            termedLevelRenewalActions.selectForUpdate({
                renewsToLevel: highestLevel,
                contracts: selectedContracts,
                productId,
            })
        );
    };
}

export const FreeTrialRenewButtonContainer = connect(mapStateToProps, mapDispatchToProps)(FreeTriaRenewButton);
